import Serial from '../../core/models/Serial'
import { v4 } from 'uuid'

import Nav from '../state/Navigation'

export default class Folder extends Serial {
  constructor(ctx = {}) {
    super(ctx)
    // what you would expect
    this.uid = ctx.uid || v4()
    this.data = ctx.data
    this.children = ctx.children || []
    this.meta = ctx.meta || {}
    this.root = ctx.root || this
    if (ctx.opened)
      this.open()
  }

  cache = {}

  stack(path) {
    let ret = []
    if (path) {
      const split = path.split("_").reverse()
      split.pop() // pop off the root
      let root = this.root
      while (split.length) {
        root = root.children[Number(split.pop())]
        ret.push(root)
      }
      return ret
    }
  }

  lookup(path) {
    if (path) {
      const split = path.split("_").reverse()
      split.pop() // pop off the root
      let root = this.root
      while (split.length)
        root = root.children[Number(split.pop())]

      // cache it for later lookup
      this.root.cache[path] = root
      return root
    }
    //}
    //return Folder.cache[path]
  }

  parent(path, depth) {
    let parentPath = path.match(/(.*)(_[^_]*)$/)[1]
    for (var i = 0; i < depth; i++) {
      const match = parentPath.match(/(.*)(_[^_]*)$/)
      if (match)
        parentPath = match[1]
    }

    return this.root.lookup(parentPath)
  }

  findData(data) {
    for (const id in this.children) {
      const child = this.children[id]
      if (child.data === data)
        return id
    }
    return -1
  }

  removeData(data) {
    if (this.findData(data) >= 0)
      this.children.splice(this.findData(data), 1)
  }

  addData(data, index) {
    const newChild = new Folder({ data })
    if (index != null)
      this.children.splice(parseInt(index), 0, newChild)
    else
      this.children.push(newChild)
  }

  copy() {
    return new Folder({ article: this.article })
  }

  add(child, index) {
    if (index != null)
      this.children.splice(parseInt(index), 0, child)
    else
      this.children.push(child)
  }

  remove(index) {
    this.children.splice(parseInt(index), 1)
  }

  after(index, value) {
    this.add(value, parseInt(index) + 1)
  }

  before(index, value) {
    this.add(value, parseInt(index))
  }

  moveToIndex(old, path) {

  }

  moveToPath(old, path) {

  }

  isOpen(path) {
    return path ? this.lookup(path).isOpen() : Nav.opened[this.uid]
  }

  open() {
    Nav.opened[this.uid] = true
    this.emit("rebuild")
    Nav.emit("change")
  }

  toggle() {
    return Nav.opened[this.uid] ? this.close() : this.open()
  }

  close() {
    Nav.opened[this.uid] = false
    this.emit("rebuild")
    Nav.emit("change")
  }

  push(data) {
    this.children.push(new Folder({
      data,
      path: `${this.path}_${this.children.length - 1}`,
      children: []
    }))
    this.emit("change")
  }

  size() {
    var size = 0
    for (const child of this.children) {
      size += child.size() + 1
    }
    return size
  }

  pack() {
    const { children, uid, data, meta } = this
    const output = []
    for (const child of children)
      output.push(child.pack())

    return { data, uid, children: output, meta }
  }

  serialize() {
    return JSON.stringify(this.pack())
  }

  load(json) {
    this.children = []
    this.uid = json.uid
    if (json.data)
      this.data = json.data

    this.meta = json.meta

    for (const childData of json.children) {
      const child = new Folder({})
      child.load(childData)
      this.children.push(child)
    }

    this.emit("rebuild")
  }

  deserialize(rawJSON) {
    this.load(JSON.parse(rawJSON))
  }
}
