import React, { useEffect, useState, useRef } from 'react'
import Button from './Button'
import { Input } from 'antd'

export default opts => {
  const { inputClass, type, size, onChange, onConfirm, canEdit, defaultValue, placeholder } = opts
  const props = { ...opts }
  delete props.inputClass
  delete props.type
  delete props.size
  delete props.onChange
  delete props.onConfirm

  const saveInputRef = useRef()

  const [enterSearch, setEnterSearch] = useState(false)
  const [inputValue, setInputValue] = useState(defaultValue)

  useEffect(() => {
    if (enterSearch && saveInputRef && saveInputRef.current)
      saveInputRef.current.focus()
  }, [enterSearch])

  const showInput = () => setEnterSearch(!enterSearch)
  const handleInputChange = e => {
    setInputValue(e.target.value)
    if (onChange)
      onChange(e.target.value)
  }
  const handleInputConfirm = () => {
    if (onConfirm)
      onConfirm(inputValue)
    setInputValue("")
    setEnterSearch(false)
  }

  return enterSearch ?
    <Input
      ref={saveInputRef}
      type={type ?? "text"}
      size={size ?? "small"}
      value={inputValue}
      placeholder={placeholder}
      onChange={handleInputChange}
      onBlur={handleInputConfirm}
      onPressEnter={handleInputConfirm}
      className={inputClass ?? "foreground grow"}
    /> :
    <Button {...props} onClick={e => {
      if (canEdit(e))
        showInput()
    }} />
}