import React, { useEffect, useRef } from 'react'
import Div from "./Div"

const saved = {}
const resolution = 100
let next = 0
export default opts => {
  const { saveScroll, scrollStyle, scrollClassName } = opts
  const ref = useRef()
  useEffect(() => {
    if (ref && ref.current && saveScroll && saved[saveScroll]) {
      ref.current.scrollTop = saved[saveScroll].top || 0
      ref.current.scrollLeft = saved[saveScroll].left || 0
    }
  })
  return <Div grow relative style={scrollStyle} className={scrollClassName}>
    <Div fit absolute ref={ref} onScroll={saveScroll ? e => {
      saved[saveScroll] = {
        top: e.target.scrollTop,
        left: e.target.scrollLeft
      }
      if (next < Date.now()) {
        window.dispatchEvent(new Event("scrollevent"))
        next = Date.now() + resolution
      }
    } : null} scrollY="auto" scrollX="auto" {...opts} />
  </Div>
}