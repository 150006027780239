import React, { useState } from 'react'
import { Input, Typography } from 'antd'
import { DeleteFilled } from '@ant-design/icons'
import { Column, Row, Scroll, Button } from '../UI'

import useProfile, { getProfile, ProfileProvider } from './hooks/useProfile'

let tutorial = true
const EventList = ({style}) => {
  const {profileID, events} = useProfile()
  
  const [key, setKey] = useState("")

  const setEvent = e => {
    if (!key.length || !key.trim() || key.match(/[^ \d\w]/ig))
      return window.error("Invalid key")

    if (events.includes(key))
      return window.error("Event already exists")
    getProfile(profileID).setEvent(key, { script: "" })
  }

  if (tutorial) {
    window.info({message: "Automation is for Tabletop RPGs, with some work you can set up complex dice rolls and game-mechanics. Come to the discord to learn more", duration: 0})
    tutorial = false
  }

  return <Column grow>
    <Scroll scrollY>
      {(events || []).map((key, index) => <Column key={index} className="gaps-v">
        <Row between>
          <Row>
            <Button small color="secondary" onClick={e => getProfile(profileID).deleteEvent(key) }>
              <DeleteFilled />
            </Button>
            <Row center>
              <Typography>{key}</Typography>
            </Row>
          </Row>
          <Row>
            <Input
              className="background size-small"
              placeholder="Hotbar Category"
              onChange={e => getProfile(profileID).updateEvent(key, { category: e.target.value })}
              defaultValue={(getProfile(profileID).getEvent(key) || {}).category || ""}
            />
            <Input
              className="background size-small"
              placeholder="Button Mask"
              onChange={e => getProfile(profileID).updateEvent(key, { mask: e.target.value })}
              defaultValue={(getProfile(profileID).getEvent(key) || {}).mask || ""}
            />
          </Row>
        </Row>
          <Input.TextArea
            className="background size-small"
            defaultValue={getProfile(profileID).getEvent(key) ? getProfile(profileID).getEvent(key).script : ""}
            onChange={e => getProfile(profileID).updateEvent(key, { script: e.target.value })}
            rows={8}
          />
      </Column>)}
    </Scroll>
    <Row center>
      <Input className="card-bg size-small" defaultValue={key} onChange={e => setKey(e.target.value.toLowerCase())} placeholder="New Event Key" />
      <Button className="foreground" onClick={setEvent}>Register</Button>
    </Row>
  </Column>
}

export default ({style, profileID})=><ProfileProvider profileID={profileID}>
    <EventList style={style}/>
  </ProfileProvider>