import User from "../User"
import { auth, providers, functions, database } from '../Firebase'
import { Wing, socket, localSocket } from '../../Wing'

const { googleAuthProvider } = providers

class Account extends User {
  setAccountDetails(details) {
    if (!this.uid)
      return window.warning("Please sign in first")

    database.ref(`users/${this.uid}`).set(details).then(snap => {
      this.details = details
      this.emit("change")
    })
  }

  updateAccountDetails(details) {
    if (!this.uid)
      return window.warning("Please sign in first")

    this.details = { ...this.details, ...details }
    this.setAccountDetails(this.details)
  }

  verifyEmail() {
    if (!this.emailVerified)
      auth.currentUser.sendEmailVerification().then(e => {
        this.emailVerified = true
        window.success("Email Verified")
        this.emit("change")
      }).catch(e => window.warning("Unable to verify email"))
  }

  updateProfile(displayName, photoURL) {
    auth.currentUser.updateProfile({ displayName, photoURL }).then(e => {
      this.url = photoURL ?? this.url
      this.name = displayName ?? this.name
      this.emit("meta")
      this.emit("change")
      window.success("Updated Profile")
    }).catch(e => console.warn(e))
  }

  // a generalized way of interacting with firebase's authentication
  register(email, password, confirm) {
    // creates an account
    if (password !== confirm)
      return window.error("Passwords do not match!")

    auth.createUserWithEmailAndPassword(email, password)
      .then(e => this.auth(email, password)) // handle new user here
      .catch(e => window.warning(e.message))
  }

  wingAuth() {
    const { uid, accessToken } = this
    Wing.authenticate({ strategy: "local", uid, accessToken })
      .then(e => {
        this.authenticated = true
        // console.log(e)
        this.emit("authenticated", true)
        console.log("Authenticated with database-services")
        // Wing.authentication.getAccessToken().then(e => { console.log(e) })
      })
      .catch(e => {
        this.authenticated = false
        this.emit("authenticated", false)
        if (uid)
          console.log("Unable to authenticate with database-services")
        // console.log(e)
      })
  }

  processIDToken(e = {}) {
    console.log(e)
    this.accessToken = e
    this.wingAuth()
    this.getAccountDetails()
    window.login(false)
  }

  processAuth(e) {
    if (e) {
      this.uid = e.uid
      this.name = e.displayName || e.email
      // this.email = e.email
      this.url = e.photoURL
      this.emailVerified = e.emailVerified
      console.log(e)
      console.log("MAID", this.uid)
    }
    else
      delete this.uid

    if (this.uid && this.uid !== "local-user")
      window.success("Signed in")
    else if (this.loaded)
      window.notify("Signed out")

    this.emit("auth", this.uid ? true : false)
    this.updateMembership()
  }

  auth(email, password) {
    // handle the account state updates
    auth.signInWithEmailAndPassword(email, password)
      .then(e => { })
      .catch(e => console.warn(e))
  }

  gAuth() {
    auth.signInWithPopup(googleAuthProvider)
      .then(e => e.additionalUserInfo.isNewUser ? console.log(e) : null)
      .catch(e => console.warn(e))
  }

  signOut() {
    auth.signOut().then(e => Wing.logout()).catch(e => console.warn(e))
  }

  startSubscription(data) {
    // make a backup in firestore
    functions.httpsCallable('processOrder')({ ...data, uid: this.uid }).then(res => {
      window.success("Thank you for your generous support!")
      this.updateMemberData(res.data)
    })

    // return 
    // .then(function (result) {
    //   // Read result of the Cloud Function.
    //   var sanitizedMessage = result.data.text
    // }).catch(function (error) {
    //   // Getting the Error details.
    //   var code = error.code
    //   var message = error.message
    //   var details = error.details
    //   // ...
    // })
  }

  isSubscribed() {
    return this.data ? this.data.active : false
  }

  isMember() {
    const { data } = this
    if (data.valid_until) {
      const end = new Date(data.valid_until)
      const today = new Date()
      const isMember = data.valid_until ? end.getTime() >= today.getTime() : data.active

      return isMember
    }
    return data.active
  }

  updateMemberData(data = {}) {
    // console.log(data)
    this.data = data || {}
    this.emit("data", data)
    if (!this.loaded) {
      this.loaded = true
      this.emit("loaded", this.loaded)
    }
  }

  updateMembership(data) {
    const { uid } = this
    if (uid && uid !== "local-user" && !state.uid.match("steam-")) // only run if logged in
      // pull from cache if possible
      // const item = getItem(this.uid)
      // if (item && item.subscription && nextUpdate > Date.now()) 
      //   this.updateRank(subscription) // membership is valid
      // if (nextUpdate <= Date.now() || !)
      functions.httpsCallable('getMembership')({ uid }).then(res => {
        const { data } = res
        // apply updates
        // console.log(data)
        this.updateMemberData(data)
      }).catch(e => { window.error("Something went wrong"); console.warn(e) })
    else if (!this.loaded) {
      // confirm there is no account
      this.loaded = true
      this.emit("loaded", this.loaded)
    }
  }

  reactivateSubscription() {
    // if (this.uid && this.subscription) // only run if logged in and has a subscription
    functions.httpsCallable('reactivateSubscription')().then(res => {
      window.success("Subscription reactivated")
      this.updateMemberData(res.data)
    }).catch(e => { window.error("Something went wrong"); console.warn(e) })
  }

  cancelSubscription() {
    // if (this.uid && this.subscription) // only run if logged in and has a subscription
    functions.httpsCallable('cancelSubscription')().then(res => {
      window.warning("Subscription cancelled")
      this.updateMemberData(res.data)
    }).catch(e => { window.error("Something went wrong"); console.warn(e) })
  }

  loginCustomToken(token) {
    console.log(token)
    const cred = googleAuthProvider.credential(token.id_token)
    auth.signInWithCredential(cred).then(e => console.log(e)).catch(e => console.warn(e))
  }
}

const state = new Account()
if (window.isLocal) {
  // get local idea
  setTimeout(()=>{
    fetch("/accountid", {method: "GET", headers: {'Content-Type': 'application/json'}})
    .then(r=>r.json())
    .then(r=>{
      let steamID = r.steamId
      if (steamID)
        steamID = `steam-${steamID}`
      else
        steamID = "local-user"
      state.processAuth({uid: steamID, displayName: r.screenName || "Local User"})
      state.authenticated = true
      state.emit("loaded", state.loaded)
      state.emit("authenticated", true)
    })
    .catch(e=>{
      state.processAuth({uid: "local-user", name: "Local User"})
      state.authenticated = true
      state.emit("loaded", state.loaded)
      state.emit("authenticated", true)
    })
  }, 100)
}
// else {
  state.once("loaded", e => {
    // if (!state.uid)
    //   window.login(true)
    socket.on("connect", e => {
      setTimeout(() => {
        if (state.accessToken)
          state.wingAuth()
      }, 1000 + 10000 * Math.random()) // don't bombard the server as soon as it comes back online
    })
  })
  auth.onAuthStateChanged(e => {
    if (window.isLocal && (state.uid === "local-user" || state.uid.match("steam-"))) {
      if (e && e.uid) { // override with a cloud account
        state.processAuth(e)
      }
    }
    else 
      state.processAuth(e)
  })
  auth.onIdTokenChanged(e => {
    if (e) // user is signed in
      e.getIdToken().then(e => state.processIDToken(e)).catch(e => console.warn(e))
  })
// }

export default state