import React, { useEffect, useState, useContext, createContext } from 'react'
import Navigation from '../state/Navigation'
import rfdc from 'rfdc'
const copy = rfdc()

const NavContext = createContext()
export { NavContext }

export default function useNav() {
  return useContext(NavContext)
}

export function useHook() {
  const [projectID, setProjectID] = useState(Navigation.project)
  const [profileID, setProfileID] = useState(Navigation.profile)
  const [browser, setBrowser] = useState(Navigation.browser)
  const [editing, setEditing] = useState(Navigation.editing)
  const [wiki, setWiki] = useState(Navigation.wiki)
  const [compendium, setCompendium] = useState(Navigation.compendium)
  const [mapID, setMapID] = useState(Navigation.map)
  const [sceneID, setSceneID] = useState(Navigation.scene)
  const [hotbar, setHotbar] = useState(Navigation.hotbar)
  const [apps, setApps] = useState(Navigation.apps)
  const [tabs, setTabs] = useState(Navigation.tabs)
  const [trackers, setTrackers] = useState(Navigation.trackers)
  const [tracker, setTracker] = useState(Navigation.tracker)
  const [homepage, setHomePage] = useState(Navigation.homepage)
  const [slideshow, setSlideShow] = useState(Navigation.slideshow)
  const [wizardID, setWizardID] = useState(Navigation.wizard)
  const [settings, setSettings] = useState(Navigation.settings)
  const [focus, setFocus] = useState(Navigation.focus)

  useEffect(() => {
    function change(e) {
      setProjectID(Navigation.project)
      setProfileID(Navigation.profile)
      setBrowser(Navigation.browser)
      setEditing(Navigation.editing)
      setWiki(Navigation.wiki)
      setCompendium(Navigation.compendium)
      setMapID(Navigation.map)
      setSceneID(Navigation.scene)
      setTracker(Navigation.tracker)
      setHomePage(Navigation.homepage)
      setSlideShow(Navigation.slideshow)
      setSettings(Navigation.settings)
      setWizardID(Navigation.wizard)
      setFocus(Navigation.focus)

      if (e === "trackers" || e === true)
        setTrackers(copy(Navigation.trackers))

      if (e === "hotbar" || e === true)
        setHotbar({ ...Navigation.hotbar })
        
      if (e === "apps" || e === true)
        setApps({...Navigation.apps})

      if (e === "tabs" || e === true)
        setTabs({...Navigation.tabs})
    }

    Navigation.on("change", change)
    Navigation.on("ready", change)

    return function () {
      Navigation.off("change", change)
      Navigation.off("ready", change)
    }
  }, [])

  return { focus, projectID, profileID, browser, editing, wiki, compendium, mapID, sceneID, hotbar, apps, tabs, trackers, tracker, homepage, slideshow, wizardID, settings }
}

export function NavProvider({ children }) {
  return <NavContext.Provider value={useHook()} children={children} />
}
