import { openDB, deleteDB } from 'idb'
import * as LZUTF8 from 'lzutf8'

if (navigator.storage && navigator.storage.persist) {
  navigator.storage.persist().then(persistent => {
    if (persistent)
      console.warn("Storage will not be cleared except by explicit user action")
    else
      console.warn("Storage may be cleared by the UA under storage pressure.")
  })
}

// if (window.mobilecheck())
deleteDB("mythic_archive")

const dbPromise = openDB("mythic_archive", 1, {
  upgrade(db, oldVersion, newVersion, transaction) {
    db.createObjectStore("worlds")
    db.createObjectStore("articles")
    db.createObjectStore("projects")
    db.createObjectStore("profiles")
    db.createObjectStore("wizards")
    db.createObjectStore("maps")
    db.createObjectStore("misc")
    db.createObjectStore("nav")
    db.createObjectStore("changes")
    db.createObjectStore("chunks")
    db.createObjectStore("community")
  },
  blocked() {
    window.warning("Unable to create local storage")
  },
  blocking() {
    window.warning("Issue creating local storage")
  }
})

const Worlds = {
  async get(key) {
    return (await dbPromise).get('worlds', key)
  },
  async set(key, val) {
    return (await dbPromise).put('worlds', val, key)
  },
  async delete(key) {
    return (await dbPromise).delete('worlds', key)
  },
  async clear() {
    return (await dbPromise).clear('worlds')
  },
  async keys() {
    return (await dbPromise).getAllKeys('worlds')
  },
}

const Articles = {
  async get(key) {
    return (await dbPromise).get('articles', key)
  },
  async set(key, val) {
    return (await dbPromise).put('articles', val, key)
  },
  async delete(key) {
    return (await dbPromise).delete('articles', key)
  },
  async clear() {
    return (await dbPromise).clear('articles')
  },
  async keys() {
    return (await dbPromise).getAllKeys('articles')
  },
}

const Misc = {
  async get(key) {
    return (await dbPromise).get('misc', key)
  },
  async set(key, val) {
    return (await dbPromise).put('misc', val, key)
  },
  async delete(key) {
    return (await dbPromise).delete('misc', key)
  },
  async clear() {
    return (await dbPromise).clear('misc')
  },
  async keys() {
    return (await dbPromise).getAllKeys('misc')
  },
}

const Nav = {
  async get(key) {
    return (await dbPromise).get('nav', key)
  },
  async set(key, val) {
    return (await dbPromise).put('nav', val, key)
  },
  async delete(key) {
    return (await dbPromise).delete('nav', key)
  },
  async clear() {
    return (await dbPromise).clear('nav')
  },
  async keys() {
    return (await dbPromise).getAllKeys('nav')
  },
}


const Changes = {
  async get(key) {
    return (await dbPromise).get('changes', key)
  },
  async set(key, val) {
    return (await dbPromise).put('changes', val, key)
  },
  async delete(key) {
    return (await dbPromise).delete('changes', key)
  },
  async clear() {
    return (await dbPromise).clear('changes')
  },
  async keys() {
    return (await dbPromise).getAllKeys('changes')
  },
}

const Chunks = {
  async get(key) {
    return (await dbPromise).get('chunks', key)
  },
  async set(key, val) {
    return (await dbPromise).put('chunks', val, key)
  },
  async delete(key) {
    return (await dbPromise).delete('chunks', key)
  },
  async clear() {
    return (await dbPromise).clear('chunks')
  },
  async keys() {
    return (await dbPromise).getAllKeys('chunks')
  },
}

const Projects = {
  async get(key) {
    return (await dbPromise).get('projects', key)
  },
  async set(key, val) {
    return (await dbPromise).put('projects', val, key)
  },
  async delete(key) {
    return (await dbPromise).delete('projects', key)
  },
  async clear() {
    return (await dbPromise).clear('projects')
  },
  async keys() {
    return (await dbPromise).getAllKeys('projects')
  },
}


const Community = {
  async get(key) {
    return (await dbPromise).get('community', key)
  },
  async set(key, val) {
    return (await dbPromise).put('community', val, key)
  },
  async delete(key) {
    return (await dbPromise).delete('community', key)
  },
  async clear() {
    return (await dbPromise).clear('community')
  },
  async keys() {
    return (await dbPromise).getAllKeys('community')
  },
}


const Profiles = {
  async get(key) {
    return (await dbPromise).get('profiles', key)
  },
  async set(key, val) {
    return (await dbPromise).put('profiles', val, key)
  },
  async delete(key) {
    return (await dbPromise).delete('profiles', key)
  },
  async clear() {
    return (await dbPromise).clear('profiles')
  },
  async keys() {
    return (await dbPromise).getAllKeys('profiles')
  },
}


const Maps = {
  async get(key) {
    return (await dbPromise).get('maps', key)
  },
  async set(key, val) {
    return (await dbPromise).put('maps', val, key)
  },
  async delete(key) {
    return (await dbPromise).delete('maps', key)
  },
  async clear() {
    return (await dbPromise).clear('maps')
  },
  async keys() {
    return (await dbPromise).getAllKeys('maps')
  },
}


const Wizards = {
  async get(key) {
    return (await dbPromise).get('wizards', key)
  },
  async set(key, val) {
    return (await dbPromise).put('wizards', val, key)
  },
  async delete(key) {
    return (await dbPromise).delete('wizards', key)
  },
  async clear() {
    return (await dbPromise).clear('wizards')
  },
  async keys() {
    return (await dbPromise).getAllKeys('wizards')
  },
}

window.compress = (msg, opts={outputEncoding: "ByteArray"}) => (LZUTF8.compress(msg, opts))
window.decompress = (msg, opts={inputEncoding: "ByteArray"}) => (LZUTF8.decompress(msg, opts))

export { Articles, Projects, Maps, Profiles, Worlds, Misc, Changes, Chunks, Community, Nav, Wizards }