import React, { useEffect, useRef, useState } from 'react'
import { Row, Column, Button, Scroll, GhostInput } from '../UI'
import { PlusSquareFilled, CloseOutlined, CodeOutlined, DeleteFilled, PictureFilled, QuestionCircleOutlined } from '@ant-design/icons'

import { Card, Select } from 'antd'
import Slate, { SlateContext } from "./slate/Slate"
import useWizard, { getWizard, WizardProvider } from "./hooks/useWizard"
import Media from './Media'
import useProject, { createWizard, getProject, createProfile, createArticle } from "./hooks/useProject"
import { ProfileProvider, getProfile } from './hooks/useProfile'
import Navigation from "./state/Navigation"
import Resources from './state/Resources'
import { Profile } from './Profile'
import Network from "../core/state/Network"
import { applyEvent, createEvent } from "./hooks/useEvent"
import { EventBuilder } from "./Events"

import rfdc from 'rfdc'
const copy = rfdc()

const {event: tempEvent, context: tempContext} = createEvent({uid: "-temp-wizard"})

export function ChoiceCard({script, editing, step, index, choices, stepSelection, selectChoice, selectOption}) {
  const { projectID } = useProject()
  const { steps, wizardID } = useWizard()
  const v = steps[step].choices[index] || {options: [], banner: {}}
  const toolbar = useRef()
  const [enabled, setEnabled] = useState()
  useEffect(()=>{
    if (script && v.cond != null)
      tempEvent.roll(script, {...tempContext, quiet: true}).then(t=>{
        setEnabled(tempEvent.eval(script, {...tempContext, quiet: true}))
      })
    else
      setEnabled(true)
  },[script, v])

  return <Column width="45%" style={{minWidth: "25vw", marginBottom: "4rem"}} className="gaps">
  <Card onClick={!editing ? e=>selectChoice(step, stepSelection[step] === index ? undefined : index) : undefined} hoverable={!editing} cover={v.banner?.src && <Media className="cover" style={{maxHeight: "20vh"}} loop muted src={v.banner?.src} alt=""/>}
    className={`background ${editing ? "" : "clickable"} ${enabled ? "" : "no-click"}`}
    style={{border: stepSelection[step] === index ? "1px solid #34DBDB" : undefined, opacity: enabled ? undefined : "0.5"}}
    extra={
      editing && <Row>
        <Button title="Condition" onClick={e=>window.ask({value: getWizard(wizardID).getChoiceCondition(step, index), title: "Set Condition", desc: "An evaluated comparison that can check the state of the wizard to allow/disallow options (ex. '$warrior === true' will only allow is a previous entry has defined $warrior to be equal to true)"}, res=>getWizard(wizardID).setChoiceCondition(step, index, res))}>
          <QuestionCircleOutlined/>
        </Button>
        <Button title="Remove" onClick={e=>window.ask({type: "confirm", title: "Remove Choice"}, res=>getWizard(wizardID).removeChoice(step, index))}>
          <DeleteFilled/>
        </Button>
      </Row>}
    actions={editing ? [
      <Button title="Event Script" onClick={
        e => window.build({title: "Change Event", width: "85vw", bodyStyle: {padding: 0}, style: {height: "85vh"}, app: EventBuilder, props: {defaultValue: v.event, onFinalize: res=>{
          getWizard(wizardID).setChoiceEvent(step, index, res)
          window.close(true)
        }}})}>
        <CodeOutlined />
      </Button>,
      <Button title="Add Option" onClick={e=>window.ask({title: "Option Name"}, res=>getWizard(wizardID).addOptionChain(step, index, res))}>
        <PlusSquareFilled/>
      </Button>,
      <Button title="Change Banner" onClick={e=>window.ask({title: "Set Banner", type: "file", projectID, profileID: wizardID, filename: true}, res=>getWizard(wizardID).setChoiceBanner(step, index, res))}>
        <PictureFilled/>
      </Button>
    ] : [<Button>Select</Button>]}
    title={editing ? <GhostInput defaultValue={v.name} onConfirm={res=>getWizard(wizardID).setChoiceName(step, index, res || v.name || " ")}><a className={editing ? "card-bg pad-h clickable text-white rounded" : "text-white"}>{v.name}</a></GhostInput> : v.name}
  >
    <Column onClick={e=>e.stopPropagation()}>
      <SlateContext.Provider value={{toolbar, small: true}}>
        <Slate placeholder="Choice Summary" className={editing ? "card-bg rounded" : ""} readOnly={!editing} raw={v.desc || ""} hideAuto={true} onChange={e=>getWizard(wizardID).setChoiceDescription(step, index, e)}/>
      </SlateContext.Provider>
      <div className="column fit-x no-click" ref={toolbar} />
    </Column>
  </Card>
  <Column className="pad-h-l rounded size-small">
    {Object.keys(v.options).map((key,j)=><Row center key={j}>
      <Column grow>
        <Row style={{marginTop: "1.0rem"}}>
          {key}
          <Row grow/>
          {editing && choices[`${step}.${index}.${j}`] != null && <Button mouseEnterDelay={0.5} title="Option Event Script" onClick={
            e => window.build({title: "Change Event", width: "85vw", bodyStyle: {padding: 0}, style: {height: "85vh"}, app: EventBuilder, props: {defaultValue: v.options[key][choices[`${step}.${index}.${j}`]].event, onFinalize: res=>{
              getWizard(wizardID).setOptionEvent(step, index, key, choices[`${step}.${index}.${j}`], res)
              window.close(true)
            }}})}>
             <CodeOutlined/>
          </Button>}
          {editing && <Button title="Add" mouseEnterDelay={0.5} onClick={e=>window.ask({title: "Enter new Selection Name"}, name=>{
            const l = v.options[key].length
            getWizard(wizardID).addOption(step, index, key, {name})
            selectOption(step, index, j, l)
          })}>
            <PlusSquareFilled/>
          </Button>}
          {editing && <Button title="Remove" mouseEnterDelay={0.5} onClick={e=>{
            if (v.options[key].length)
              window.ask({title: `Remove ${v.options[key][choices[`${step}.${index}.${j}`] || 0].name}`, type: "confirm"}, res=>getWizard(wizardID).removeOption(step, index, key, choices[`${step}.${index}.${j}`]))
            else
              getWizard(wizardID).removeOptionChain(step, index, key)

            selectOption(step, index, j)
          }}>
            <DeleteFilled/>
          </Button>}
        </Row>
        <Select allowClear size="small" className="fit-x background" placeholder="Select an Option" value={choices[`${step}.${index}.${j}`]} onClear={e=>selectOption(step, index, j)} onSelect={e=>selectOption(step, index, j, e)}>
          {v.options[key].map(({name, script}, k)=><Select.Option key={k} value={k}>{name}</Select.Option>)}
        </Select>
      </Column>
    </Row>)}
  </Column>
</Column>
}

export function WizardName(opts){
  const {meta} = useWizard()

  return <span {...opts}>{meta.name}</span>
}

export function WizardManager({onSelect, picker}) {
  const {wizards, projectID} = useProject()
  const select = wizardID=>{
    if (onSelect)
      onSelect(wizardID)
  }
  return <Column fit>
    <Scroll>
      {wizards && wizards.map((wizardID, i)=><Row between className={`background clickable`} style={{borderBottom: "1px solid #141414"}} onClick={e=>select(wizardID)}>
        <Row center className="pad">
          <WizardProvider wizardID={wizardID}>
            <WizardName/>
          </WizardProvider>
        </Row>
        <Row center onClick={e=>e.stopPropagation()}>
          {/* <Button onClick={e=>confirm(wizardID, true)}>
            Edit
          </Button> */}
          {!picker && wizards.includes(wizardID) && Network.host && <Button onClick={e=>window.ask({title: "Remove Wizard", desc: "This can't be undone", type: "confirm"}, res=>getProject(projectID).removeWizard(wizardID))}>
            <DeleteFilled/>
          </Button>}
        </Row>
      </Row>)}
    </Scroll>
    {!picker && <Row center>
      <Button className="foreground" onClick={e=>window.ask({title: "Wizard Name"}, res=>{
        const wiz = createWizard()
        wiz.setName(res)
      })}>
        Create Wizard
      </Button>
    </Row>}
  </Column>
}

function SheetPreview({profileID, eventID, back}){
  const create = e=>{
    const template = getProfile(profileID)
    const loading = {}
    const tabs = template.getTabs()

    function confirm(id){
      if (id)
        delete loading[id]

      if (Object.keys(loading).length !== 0)
        return
        
      const backupData = copy(template.pack())
      delete backupData.uid
      delete backupData._id
      
      const profile = createProfile()
      profile.unpack(backupData)
      profile.loaded = false

      const article = createArticle()
      const articleData = Resources.Articles.get(profile.getArticleID()).pack()
      delete articleData.uid
      delete articleData._id
      article.unpack(articleData)
      article.loaded = true
      article.emit("ready")
      article.emit("change")
      profile.setArticleID(article.uid)
      for (const tab of tabs) {
        const article = createArticle()
        const articleData = Resources.Articles.get(profile.getTab(tab).articleID).pack()
        delete articleData.uid
        delete articleData._id
        article.unpack(articleData)
        article.loaded = true
        article.emit("ready")
        article.emit("change")
        profile.updateTab(tab, {articleID: article.uid})
      }
      
      profile.loaded = true
      profile.emit("ready")
      profile.emit("change")

      Navigation.profile = profile.uid
      Navigation.setWiki(true)
      Resources.Events.get(eventID).record()
    }

    const aID = template.getArticleID()
    const article = Resources.Articles.get(aID)
    if (aID && !article.loaded) {
      loading[aID] = true
      article.once("ready", e=>confirm(aID))
    }

    for (const tab of tabs) {
      const { articleID } = template.getTab(tab)
      const article = Resources.Articles.get(articleID)
      if (articleID && !article.loaded) {
        loading[articleID] = true
        article.once("ready", e=>confirm(articleID))
      }
    }
    confirm()
    window.close()
    Navigation.setWizard()
  }

  return <Column fit relative className="card-bg">
    <Scroll>
      <Column fit>
        <ProfileProvider profileID={profileID}>
          <Profile readOnly={true} />
        </ProfileProvider>
      </Column>
    </Scroll>
    <Row className="gaps-h" center absolute bottom fitX>
      <Button className="foreground gaps-h" onClick={back}>
        Back
      </Button>
      <Button className="foreground" onClick={create}>
        Create
      </Button>
    </Row>
  </Column>
}

export default function WizardBuilder(){
  const { steps, meta, wizardID, loaded, formatID } = useWizard()
  const { projectID } = useProject()
  const [step, setStep] = useState(0)
  const [choices, setChoices] = useState({})
  const [editing, setEditing] = useState()
  const [tempID, setTempID] = useState()
  const [eventID, setEventID] = useState()
  const [stepSelection, setStepSelection] = useState({})

  function selectOption(step, index, choice, value) {
    const key = `${step}.${index}.${choice}`
    const newData = {...choices}
    
    if (value === null || value === undefined) 
      delete newData[key]
    else
      newData[key] = parseInt(value)

    setChoices(newData)
  }


  const wiz = getWizard(wizardID)
  let script = wiz.script || ""

  wiz.steps.forEach((step,i)=>{
    if (step && stepSelection[i] != null) {
      const cIdx = stepSelection[i]
      const {options, event} = step.choices[cIdx]
      script += "\n"
      script += event || "" 
      
      let optionIndex = 0
      for (const j in options) {
        options[j].forEach((c, oIdx)=>{
          const index = `${i}.${cIdx}.${optionIndex}`
          if (choices[index] === oIdx) {
            script += "\n"
            script += c.event || ""
          }
        })
        optionIndex++
      }
    }
    script += step.event || ""
    script += "\n"
  })

  function confirm(str){
    const source = getProfile(formatID)

    console.log(source, formatID)

    const wiz = getWizard(wizardID)
    let script = wiz.script || "" 
  
    wiz.steps.forEach((step,i)=>{
      if (step && stepSelection[i] != null) {
        const cIdx = stepSelection[i]
        const {options, event} = step.choices[cIdx]
        script += "\n"
        script += event || "" 
        
        let optionIndex = 0
        for (const j in options) {
          options[j].forEach((c, oIdx)=>{
            const index = `${i}.${cIdx}.${optionIndex}`
            if (choices[index] === oIdx) {
              script += "\n"
              script += c.event || ""
            }
          })
          optionIndex++
        }
      }
      script += step.event || ""
      script += "\n"
    })
  
    
    function loadFromSource(src){
      const iData = src.instance()
      const instance = Resources.Profiles.create(iData.uid)
      instance.unpack(iData)
      instance.loaded = true

      const {event, context} = createEvent({ sourceID: iData.uid })
      event.roll(script, context).then(e=>{
        applyEvent(event, context, {sourceID: iData.uid})
        setTempID(iData.uid)
        setEventID(event.uid)
      })
    }

    if (source.loaded)
      loadFromSource(source)
    else
      source.once("loaded", e=>loadFromSource(source))
  }

  function selectChoice(step, value) {
    const newData = {...stepSelection}
    
    if (value === null || value === undefined) 
      delete newData[step]
    else
      newData[step] = parseInt(value)

    setStepSelection(newData)
    if (value != null && value !== undefined)
      if (step < steps.length-1)
        setStep(step+1)
  }



  if (!loaded)
    return <div/>

  if (tempID)
    return <Column grow className="card-bg">
      <Row className="foreground" between style={{border: "1px solid #141414"}}>
        <Row center>
          {editing ? 
          <GhostInput defaultValue={meta.name} onConfirm={e=>getWizard(wizardID).setName(e || meta.name)}>
            <h3 style={{margin: 0}}>
              <a className="card-bg pad-h clickable text-white rounded">
                {meta.name || "<No Name>"}
              </a>
            </h3>
          </GhostInput>: <h3 style={{margin: 0}}>
            <a className="text-white pad-h">
              {meta.name || "<No Name>"}
            </a>
          </h3>}
        </Row>
        <Row>
          <Button onClick={e=>{
            Navigation.wiki = true
            Navigation.compendium = true
            Navigation.setWizard()
          }}>
            <CloseOutlined />
          </Button>
        </Row>
      </Row>
      <SheetPreview profileID={tempID} eventID={eventID} back={e=>setTempID()}/>
    </Column>

  const setTemplate = e=>window.ask({
    title: "Select Template",
    choices: Object.keys(getProject(projectID).templates).map(value=>({text: (getProject(projectID).templates[value].name || value), value}))
  }, res=>{
    const project = getProject(projectID)
    const wizard = getWizard(wizardID)
    const key = String(res).toLowerCase()
    if (project.templates[key]) {
      project.updateTemplate(key, { wizardID })
      wizard.setFormat(project.templates[key].profileID)
    }
    else
      window.warning("You must use a template from the same project as the wizard")
  })


  return <Column grow className="card-bg" relative>
    <Row className="foreground" between style={{border: "1px solid #141414"}}>
      <Row center>
        {editing ? 
        <GhostInput defaultValue={meta.name} onConfirm={e=>getWizard(wizardID).setName(e || meta.name)}>
          <h3 style={{margin: 0}}>
            <a className="card-bg pad-h clickable text-white rounded">
              {meta.name || "<No Name>"}
            </a>
          </h3>
        </GhostInput>: <h3 style={{margin: 0}}>
          <a className="text-white pad-h">
            {meta.name || "<No Name>"}
          </a>
        </h3>}
      </Row>
      <Row>
        <Button onClick={e=>{
          Navigation.wiki = true
          Navigation.compendium = true
          Navigation.setWizard()
        }}>
          <CloseOutlined />
        </Button>
      </Row>
    </Row>
    <Row className="black" around wrap>
      {(steps.length > 1 || editing) && steps.map((v,i)=> <Column relative onClick={e=>setStep(i)} key={i} grow reverse className="rounded black-tint clickable" height="128px" style={{ minWidth: "20%", boxShadow: "inset 0px 0px 10px black", border: step === i ? "1px solid #34DBDB" : "1px solid #141414"}}>
        <Media src={v.banner?.src} autoPlay muted loop style={{filter: step < i ? "saturate(0%)" : undefined, objectPosition: "center center", width: "100%", maxWidth: "100%", height: "100%" }} className="cover absolute fit-x fit-y" />
        <Column absolute fit style={{boxShadow: "inset 0px 0px 10px black"}}/>
        <Row relative center className="pad-l black-tint">
          {editing && <a className={step < i ? "text-dull" : "text-white"} onClick={e=>window.ask({type: "file", title: "Pick background", projectID, profileID: wizardID, filename: true}, res=>getWizard(wizardID).setStepBanner(i, res))}>
            <PictureFilled className="size-2"/>
          </a>}
          <Row center grow>
            <h2 style={{margin: 0}} onClick={e=>editing && window.ask({title: "Rename Step", value: steps[i].name}, res=>getWizard(wizardID).setStepName(i, res))}><a className={step < i ? "text-dull" : "text-white"}>{v.name}</a></h2>
          </Row>
          {editing && <a className={step < i ? "text-dull" : "text-white"} onClick={e=>window.ask({type: "confirm", title: "Delete Step"}, res=>getWizard(wizardID).removeStep(i))}>
            <DeleteFilled className="size-2"/>
          </a>}
          {!editing && step > i && <Row absolute top center fitX className="size-small text-dull" style={{textShadow: "0px 0px 2px black", paddingTop: "0.25rem", transition: "top 0.2s, font-size 0.2s"}}>{stepSelection[i] != null ? (steps[i].choices[stepSelection[i]].name || v.name) : v.name}</Row>}
        </Row>
      </Column>)}
      {editing && <Column className="pad-l grow clickable" center onClick={e=>window.ask({title: "Step Name"}, res=>getWizard(wizardID).addStep(res))}>
        <PlusSquareFilled className="size-2 gaps pad-h" />
        New Step
      </Column>}
    </Row>
    <Scroll scrollY>
      <Column fitX center style={{minHeight: "100%", boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.7)"}}>
        <Row fitX wrap around className="pad-l">
          {steps[step] && steps[step].choices.map((v,i)=><ChoiceCard script={script} stepSelection={stepSelection} choices={choices} selectChoice={selectChoice} selectOption={selectOption} key={i} step={step} index={i} editing={editing}/>)}
          {editing && steps[step] && step !== null && step !== undefined ? <Column>
            <Column className="pad-l clickable" center onClick={e=>window.ask({title: "Choice Name"}, res=>getWizard(wizardID).addChoice(step, {name: res, art: {src: ""}, options: {}}))}>
              <PlusSquareFilled className="size-2 gaps pad-h" />
              New Choice
            </Column>
          </Column> : undefined}
        </Row>
      </Column>
    </Scroll>
    <Row center absolute bottom fitX>
      {getProject(projectID).isOwner() && getProject(projectID).wizards.includes(wizardID) && <Button className="foreground gaps-h" onClick={e=>setEditing(!editing)}>
        {editing ? "Save" : "Edit"}
      </Button>}
      {editing && <Button className="foreground gaps-h" onClick={setTemplate}>
        Set Template
      </Button>}
      {editing && <Button title="Event Script" className="foreground gaps-h" onClick={
        e => window.build({title: "Change Event", width: "85vw", bodyStyle: {padding: 0}, style: {height: "85vh"}, app: EventBuilder, props: {defaultValue: wiz.script, onFinalize: res=>{
          getWizard(wizardID).setScript(res)
          window.close(true)
        }}})}>
        Set Default Event
      </Button>}
      {!editing && <Button className="foreground gaps-h" onClick={e=>formatID ? confirm(script) : window.warning("No attached template")}>Finalize</Button>}
    </Row>
  </Column>
}