import Context from '../util/Context'

function getActionData(text) {
  const actionData = /(\[!([^\!])*!(=`([^`]+)`)?\])(`([^`]+)`)?/i
  let match = text.match(actionData)
  console.log(match)
  if (match) {
    let label
    let target = (match[1] || "").toLowerCase()
    //let cleanEQ = match[0]

    let split = match[1].split("#")
    if (split.length >= 1) {
      label = split[0].replace(/_/g, " ")
      target = split[1]
      //cleanEQ = match[0].replace(label + "#", "")
    }

    let value
    let content = Context.fill(match[3], {})
    try {
      let solved = Context.solve(content)
      if (typeof solved == "string" || !isNaN(solved))
        value = solved
      else
        value = content
    }
    catch (e) {
      value = content
    }

    return {
      eventID: match[2],
      mask: match[0],
      operation: match[2],
      equation: value,
      label: (label || (match[1].replace("#", "") + match[2] + value))
    }
  }
}

function splitEvents(text, marks) {
  const actions = /(\[![^\!]*!=`[^`]+`?\]`[^`]+`?)/i // /([#\w_\d]*[-+*\\]?[=]\[[^[]+\])/i 
  const actionData = /(\[!([^\!]*)!(=`([^`]+)`)?\])(`([^`]+)`)?/i
  let children = text.split(actions)

  for (const index in children) {
    if (children[index]) {
      let match = children[index].match(actionData)
      if (match) {
        children[index] = {
          eventID: match[2],
          script: (match[4] || "")
          .replace(/\\n/gmi, '\n') // replace evaluated symbols on the nested script
          .replace(/\[d\]/gmi, 'd')
          .replace(/\[#\]/gmi, '#')
          .replace(/\[@\]/gmi, '@')
          .replace(/\[\$\]/gmi, '$')
          .replace(/\[\%\]/gmi, '%'),
          mask: match[6],
          children: [{text: ""}],
          type: "event"
        }
      }
    }
  }
  
  return children
}

function splitData(text, marks) {
  const math = /(`([^`]+)`)/i
  const regex = /([\d_\w]*[#][\w_+\d]+)/i

  let children = text.split(regex)
  for (const index in children) {
    if (children[index]) {
      let match = children[index].match(regex)
      if (match) {
        let split = children[index].split("#")
        let label = split[0].replace(/_/g, " ")
        let key = split[split.length - 1].toLowerCase()
        let context = {}
        let value = key
        if (isNaN(key)) {
          let mathMatch = String(context[key]).match(math)
          if (mathMatch) {
            let content = Context.fill(mathMatch[2], context)
            try {
              let solved = Context.solve(content)
              if (typeof solved == "string" || !isNaN(solved))
                value = solved
              else
                value = content
            }
            catch (e) {
              value = content
            }
          }
          else
            value = context[key]
        }
        children[index] = {
          type: "data",
          mask: label,
          dataKey: key.toLowerCase(),
          data: [{ equation: value }],
          text: ""
        }
      }
    }
  }
  return children
}

function splitMentions(text, marks, mask) {
  const math = /(\[=([-\d\w]+)=\])(`([^`]+)`)?/i
  const regex = mask ? /(\(?\[=[-\d\w]+=\][`[^`]+`]?\)?)/i : /(\(?\[=[-\d\w]+=\]\)?)/i 

  let children = text.split(regex)
  
  for (const index in children) {
    if (children[index]) {
      let match = children[index].match(math)
      if (match) {
        children[index] = {
          profileID: match[2],
          children: [{text: ""}],
          type: "mention",
          mask: match[4]
        }
      }
    }
  }

  return children
}

const strikethrough = /(~{2}([^~]+)~{2})/i
const underline = /(_{2}([^_]+)_{2})/i
const bold = /(\*{2}([^*]+)\*{2})/i
const italic = /(\*([^*]+)\*)/i
const inlinemacro = /({{2}([^{^}]+)}{2})/i // chat events only
const macro = /({([^{^}]+)})/i // chat events only
const roll = /(\[%\[([\d]+)\]%\])/i // chat events only

const serializers = {
  inlineMacro: /({{2}[^{^}]+}{2})/i, // chat events only
  macro: /({[^{^}]+})/i, // chat events only
  roll: /(\[%\[[\d]+\]%\])/i, // chat events only
  strikethrough: /(~{2}[^~]+~{2})/i,
  underline: /(_{2}[^_]+_{2})/i,
  bold: /(\*{2}[^*]+\*{2})/i,
  italic: /(\*[^*]+\*)/i, // order mattters here
}

function processMarks(str, context) {
  // just determines the marks for a specific text node, assumes splitting beforehand
  const marks = {}
  let text = str

  let match = text.match(inlinemacro)
  if (match) {
    text = ""
    marks["equation"] = match[2]
    marks["type"] = "macro"
    marks["inline"] = true
    marks["isBlock"] = true
  }
  
  // match = text.match(inlinemention)
  // if (match) {
  //   marks["profileID"] = match[2]
  //   marks["children"] = [{ text: "" }]
  //   marks["type"] = "mention"
  // }


  match = text.match(macro)
  if (match) {
    text = ""
    marks["equation"] = match[2]
    marks["type"] = "macro"
  }

  match = text.match(roll)
  if (match) {
    text = ""
    marks["index"] = match[2]
    marks["type"] = "roll"
  }

  match = text.match(strikethrough)
  if (match) {
    text = text.replace(match[1], match[2])
    marks["strikethrough"] = true
  }

  match = text.match(underline)
  if (match) {
    text = text.replace(match[1], match[2])
    marks["underline"] = true
  }

  match = text.match(bold)
  if (match) {
    text = text.replace(match[1], match[2])
    marks["bold"] = true
  }

  match = text.match(italic)
  if (match) {
    text = text.replace(match[1], match[2])
    marks["italic"] = true
  }

  return {
    ...marks,
    text: text
  }
}

function splitMarks(str) {
  let children = [str]
  for (const key in serializers) {
    const regex = serializers[key]
    for (const index in children) {
      let child = children[index]
      if (typeof child == "string") {
        child = child.split(regex)
        // console.log(child, key)
        if (child.length > 1)
          for (const childIndex in child)
            child[childIndex] = processMarks(child[childIndex])

        if (child[child.length-1].text === "" && key === "macro" && Object.keys(child[child.length-1]).length)
          child.splice(child.length-1, 1)
      
        if (child[0].text === "" && key === "macro" && Object.keys(child[child.length-1]).length)
          child.splice(0, 1)

        children[index] = child
      }
    }
    children = children.flat()
  }

  for (const index in children)
    if (typeof children[index] == "string")
      children[index] = processMarks(children[index])

  return children
}

function process(text) {
  let nodes = splitEvents(text)
  for (const index in nodes)
    if (typeof nodes[index] === "string")
      nodes[index] = splitData(nodes[index])

  nodes = nodes.flat()
  
  for (const index in nodes)
    if (typeof nodes[index] === "string")
      nodes[index] = splitMentions(nodes[index])

  nodes = nodes.flat()

  for (const index in nodes)
    if (typeof nodes[index] === "string")
      nodes[index] = splitMentions(nodes[index], undefined, true)

  nodes = nodes.flat()


  for (const index in nodes)
    if (typeof nodes[index] === "string")
      nodes[index] = splitMarks(nodes[index])

  nodes = nodes.flat()
  if (nodes.length === 0)
    nodes = [{ text }]

  return nodes
}

function buildNode(str, context) {
  // determine the block type first
  const trimmed = str.trim()
  if (trimmed[0] === "|") {
    // it is a table
    const rows = []
    const seperator = trimmed.substr(1, trimmed.length)
    for (const row of seperator.split(/<br>/im)) {
      const cells = []
      for (const text of row.split("|")) {
        const node = buildNode(text)
        // if (header && node.children && !node.children[0].type && node.children[0].text) {
        //   node.children[0].bold = true
        // }
        cells.push({ type: "cell", noPadding: node.type === "media" ? true : null, children: [node] })
      }
      rows.push({ type: "row", children: cells })
    }
    return { type: "table", children: rows }
  }
  else if (trimmed[0] === "/")
    // GM Only
    return { type: "secret", children: [{ text: "" }] }
  else if (trimmed[0] === "_")
    return { type: "divider", children: [{ text: "" }] }
  else {
    const matchLink = trimmed.match(/(https?:\/\/[^\s]*)/m)
    if (matchLink && matchLink.index === 0)
      return { type: "media", src: matchLink[1], children: [{ text: "" }] }
    else {
      const headerMatch = trimmed.match(/\[h([\d]+)\](.*)/is)
      if (headerMatch) {
        const txt = processMarks(str.replace(`[h${headerMatch[1]}]`, ""))
        txt.bold = true
        if (headerMatch[1] === "1")
          txt.size = 'h1'
        else if (headerMatch[1] === "2")
          txt.size = 'h2'
        else if (headerMatch[1] === "3")
          txt.size = 'h3'
        else
          txt.size = 'sub'

        return {
          type: 'paragraph',
          children: [
            txt
          ]
        }
      }
    }
  }
  return {
    type: 'paragraph',
    children: process(str)
  }
}


export default (text, context) => {
  // Create our initial value...
  if (text instanceof Object) {
    if (Array.isArray(text)) {
      const nodes = []
      if (text.length === 0)
        nodes.push(buildNode("", context))
      else
        for (const txt of text)
          if (txt instanceof Object)
            nodes.push(txt)
          else
            nodes.push(buildNode(String(txt), context))

      return nodes
    }
    if (Object.keys(text).length <= 0)
      return [{ type: "paragraph", children: [{ text: "" }] }]
    else
      return [text]
  }
  return [buildNode(String(text), context)]
}