import React, { useRef, useState, useEffect } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import YouTube from 'react-youtube'

const Img = opts => <img className="fit-x fit-y scale-down" alt="" {...opts} />

const Vid = opts => <video autoPlay loop muted className="fit-x fit-y scale-down" {...opts} />
const Pdf = opts => <iframe className="fit-x fit-y scale-down" {...opts} />
// const Aud = opts => <audio className="fit-x fit-y scale-down" {...opts} />

const UTube = ({ autoPlay, src, height, width }) => {
  // determine the size and fill
  const ref = useRef()
  const [options, setOptions] = useState({})
  const [size, setSize] = useState({height, width})
  // const [size, setSize] = useState({})

  useEffect(() => {
    let videoId = src.match(/youtu.be\/([\d\w]+)/) || src.match(/v=([\d\w]+)/)
    const {width, height} = size
    if (ref) {
      // setSize({ w: ref.current.clientWidth, h: ref.current.clientHeight })
      setOptions({
        height: height ?? ref.current.clientHeight,
        width: width ?? ref.current.clientWidth,
        playerVars: { autoplay: autoPlay ?? 0 },
        videoId: encodeURI(videoId[1])
      })
    }
    else
      setOptions({
        height: height ?? '200',
        width: width ?? '355',
        playerVars: { // https://developers.google.com/youtube/player_parameters
          autoplay: autoPlay ?? 0
        },
        videoId: encodeURI(videoId[1])
      })
  }, [src, autoPlay, size])

  useEffect(()=>{
    if (ref.current && !width && !height) 
      setSize(ref.current.getBoundingClientRect())
    else
      setSize({width, height})
  }, [ref, width, height])

  return <div className="relative fit-x fit-y" ref={ref} >
    {options ? <div className="row center fit-x fit-y" style={{ background: "black" }}>
      <YouTube videoId={options.videoId} opts={options} />
    </div > : <LoadingOutlined />}
  </div >
}

const Link = opts => <a className="white-title" href={opts.src} target="_" {...opts}>{opts.children || opts.src}</a>

export default opts => {
  const src = (opts.src ?? "").toLowerCase()
  let includes = false
  for (let match of [".jpg", ".png", ".jpeg", ".bmp", ".gif", ".svg", ".webp"])
    if (src.match(match)) {
      includes = true
      break
    }

  if (includes)
    return <Img {...opts} />
  else if (src.includes('.mp4') || src.includes('.webm') || src.includes('.ogg'))
    return <Vid {...opts} />
  else if (src.includes(".pdf"))
    return <Pdf {...opts} />
  else if (src.includes("youtube") || src.includes("youtu.be"))
    return <UTube {...opts} />
  else if (src.includes("www") || src.includes("http"))
    return <Link {...opts} />
  return <span {...opts} />
}