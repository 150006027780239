
import React, {useState} from 'react'
import Column from './Column'
import Row from './Row'
import Button from './Button'

export default ({tabs = {}, defaultTab=Object.keys(tabs)[0]}) => {
  const [tab, setTab] = useState(defaultTab  || "")
  
  const CREATE = tabs[tab] && tabs[tab]

  return <Column grow>
  <Row className="black">
    {Object.keys(tabs).map((key,i)=><Button className={key === tab ? "foreground" : "background"}  selected={tab === key} style={{border: "1px solid #141414", marginLeft: i === 0 ? "0.5rem" : "", marginTop: "0.5rem"}} onClick={e=>setTab(key)}>{key}</Button>)}
  </Row>
  {tabs[tab] && <CREATE/>}
</Column> 
}

 