import EventEmitter from 'events'
import Network from '../../core/state/Network'

class SyncMedia extends EventEmitter {
  volume = 0.5
  constructor(ctx) {
    super(ctx)

    // const prepPlaying = msg => {
    //   this.playing = msg.playing
    //   const buffend = e => {
    //     console.log(e)
    //     this.once("bufferend", e => {
    //       console.log(e)
    //       this.playing = msg.playing
    //       this.emit("update")
    //     })
    //   }

    //   const play = e => {
    //     console.log(e)
    //     this.off("buffering", buffend)
    //     this.playing = msg.playing
    //     this.emit("update")
    //   }

    //   this.once("buffering", buffend)
    //   // this.once("play", play)
    // }
    this.setMaxListeners(Infinity)
    this.on("ready", function (e) {
      if (Network.host && this.url) {
        // hack to get the duration before the host has to start the video
        // force cloud resources to start buffering
        if (!(this.url.match(".mp4") || this.url.match(".webm") || this.url.match(".ogg"))) {
          this.playing = true
          this.emit("seek", 0)
          this.playing = false
          this.once("start", e => {
            this.playing = false
            this.emit("seek", 0)
          })
        }
      }
      // this.ready = true
    })

    this.on("sync", function () {
      if (Network.host)
        Network.broadcast("media", JSON.stringify({ ...this.summary, cmd: "sync" }))
    })

    this.on("cmd", function (msg) {
      // receive and handle a command
      if (msg.cmd === "sync" && !Network.host) {
        if (this.url !== msg.url) {
          const start = Date.now()
          this.once("ready", e => {
            if (msg.playing) {
              this.once("buffering", e => {
                this.once("bufferend", e => {
                  const diff = (Date.now() - start) / 1000
                  // add the difference
                  this.progress = msg.progress
                  this.duration = msg.duration
                  this.playing = msg.playing
                  this.emit("update")
                  this.emit("seek", msg.progress.played + (diff / msg.duration))
                })
              })
              this.playing = msg.playing
              this.emit("update")
            }
            else {
              // const diff = (Date.now() - start) / 1000
              this.progress = msg.progress
              this.duration = msg.duration
              this.playing = msg.playing
              e.seekTo(msg.progress.played * msg.duration, "time")
              this.emit("update")
            }

            // if (msg.playing)
            //   prepPlaying(msg)
          })
          this.url = msg.url
          // this.progress = msg.progress
          // this.duration = msg.duration
          this.playing = false
          // this.emit("update")
          this.emit("video")
          // this.emit("seek", msg.progress.played)
        }
        else {
          this.duration = msg.duration
          this.playing = msg.playing
          this.emit("seek", msg.progress ? msg.progress.played : 0)
          // if (msg.playing)
          //   prepPlaying(msg)
        }
      }
    })
  }

  get src() {
    return this.url
  }

  setProgress(p) {
    this.progress = p
    // if (this._poverride) {
    //   this.progress = this._poverride
    //   console.log(this._poverride)
    //   delete this._poverride
    // }
    // console.warn(p)
    this.emit("update")
  }

  setDuration(d) {
    console.log(d)
    this.duration = d
    this.emit("update")
  }

  setVolume(v) {
    this.volume = v
    this.emit("update") // internal for updating the react components
  }

  setURL(url, playing) {
    if (url !== this.url) {
      this.url = url
      this.ready = false
      this.progress = { played: 0 }
      this.playing = false // true for playlists
      this.emit("update")
      this.emit("seek", 0)
      this.emit("video")
      this.emit("sync")
    }
  }

  seekTo(time) {
    this.playing = false
    this.emit("seek", time)
    this.emit("pause")
    this.emit("sync")
  }

  play() {
    this.playing = true
    this.emit("update")
    this.emit("play")
    this.emit("sync")
  }

  pause() {
    this.playing = false
    this.emit("update")
    this.emit("pause")
    this.emit("sync")
  }

  get summary() {
    const { playing, progress, duration, url } = this
    return { playing, progress, duration, url }
  }
}


// class SyncMediaer extends EventEmitter {
//   track = {}

//   parseYoutube(url, options) {
//     // creates a youtube track
//     // parse the URL for video ID
//     const timeRegex = /t=([^&]+)/i
//     if (url.match('youtu.be')) {
//       const vIDsplit = url.split('youtu.be\/').reverse()[0]
//       if (vIDsplit) {
//         const vID = vIDsplit.split(/[?&]/i)[0]
//         const vIDt = timeRegex.exec(url) ? timeRegex.exec(url)[1] : null
//         console.log(vID, vIDt)
//         return [vID, vIDt]
//       }
//     }
//     else if (url.match('youtube.com')) {
//       const vIDRegex = /v=([^&]+)/i
//       const vID = vIDRegex.exec(url) ? vIDRegex.exec(url)[1] : null
//       const vIDt = timeRegex.exec(url) ? timeRegex.exec(url)[1] : null

//       console.log(vID, vIDt)
//       return [vID, vIDt]
//     }
//   }

//   playTrack(uid) {

//   }

//   pauseTrack(uid) {

//   }

//   stopTrack(uid) {

//   }
// }


const state = new SyncMedia()

export default state