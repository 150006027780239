import React from 'react'

import { Column } from '../UI'
import { ArticleProvider } from "./hooks/useArticle"
import { ProfileArticle } from "./Profile"
import useNav from './hooks/useNav'
import Nav from "./state/Navigation"
import { getProfile, ProfileProvider } from "./hooks/useProfile"

export default ({ mobile }) => {
  const { slideshow } = useNav()
  return <Column onClick={e=>Nav.setSlideShow()} className={`${slideshow ? "visible click" : "invisible no-click"} black-tint pointer`} style={{zIndex: 1305}} center absolute fit top left>
    <Column onClick={e=>e.stopPropagation()} width="50vw" style={{maxHeight: "80vh", cursor: "auto"}}>
      <ProfileProvider profileID={slideshow}>
        <ArticleProvider articleID={getProfile(slideshow).getArticleID()}>
          <ProfileArticle readOnly={true} />
        </ArticleProvider>
      </ProfileProvider>
    </Column>
  </Column>
}