import React, { useEffect, useState, useRef, Suspense } from 'react'

import { WikiList } from './Browser'

import { Column, Row, Button } from '../UI'
import { Modal, notification, Select, Input as TextField } from 'antd'
import { ReadFilled } from '@ant-design/icons'
import Network from '../core/state/Network'
import { LoadingOutlined } from '@ant-design/icons'
import Nav from './state/Navigation'
import useNav, { NavProvider } from './hooks/useNav'
import { RadialProvider } from "../editor/hooks/useRadial"

import FileApp from "../core/apps/FileApp"

import Map from './Map'
import RadialMenu from '../editor/RadialMenu'

// import Zone from '../engine/entities/NoteZone'
import CompendiumView, { MainMenu } from './CompendiumView'
import { MapProvider } from "./hooks/useMap"
import Hotbar from "./Hotbar"

import Resources from './state/Resources'

import Logs from './state/Logs'
import Theater from './Theater'
import SyncMedia from './state/SyncMedia'
import { WizardProvider } from "./hooks/useWizard"
import WizardBuilder from "./Wizard"
import { localSocket } from "../Wing"
import Account from '../core/state/Account'
import Inspector from "./Inspector"

if (window.isLocal) {
  Network.on("created", e=>{
    if (Network.host)
      localSocket.emit("host", JSON.stringify({id: Account.uid}))
  })


  Network.channel("transfer", {
    msg: function (msg, channel, from) {
      const data = JSON.parse(msg.data)
      if (data.to === Account.uid)
        localSocket.emit("p2p", data)
      else 
        Network.send("transfer", msg.data, data.to)
    },
    open: function (msg, channel, from) {
      if (!Network.host)
        setTimeout(()=>localSocket.emit("join", JSON.stringify({server: Network.connected[Network.server].playerData.uid, id: Account.uid})), 1000)
    }
  })

  localSocket.on("p2p", msg=>{
    if (msg.to === Account.uid)
      localSocket.emit("p2p", msg)
    else 
      Network.send("transfer", JSON.stringify(msg), msg.to)
  }) // bounce the connection back to the host
}

Network.channel("navigation", {
  msg: function (msg, channel, from) {
    // a proposed change to the scene
    // console.log(msg)
    const { slideshow, project, profile, forceToScreen } = JSON.parse(msg.data)
    if (project !== undefined && project !== Nav.project)
      Nav.setProject(project, true)

    if (profile !== undefined && profile !== Nav.profile)
      Nav.setProfile(profile)

    // if (slideshow !== undefined && slideshow !== Nav.slideshow)
    //   Nav.setSlideshow(slideshow)

    if (forceToScreen) {
      window.blurAll()
      Nav.scene = false
      Nav.map = false
      Nav.compendium = false
      Nav.setWiki()
    }
  },
  open: function (msg, channel, from) {
    // send the scene to the clients if you are the host
    if (Network.host)
      setTimeout(()=>channel.send(JSON.stringify({ project: Nav.project, slideshow: Nav.slideshow })), 1000)
    else
      Nav.loaded = false
  }
})

Network.channel("source", { // Transfer of content from P2P
  msg: function (msg, channel, from) {
    // a proposed change to the scene
    const { uid, _delete, payload, key } = JSON.parse(window.decompress(new Uint8Array(msg.data)))

    if (uid && key) {
      const resource = Resources.lookup(key, uid)
      if (_delete && from === Network.server)
        // only delete from the server
        Resources.delete(key, uid)
      else if (payload) {// its a post request 
        if (!resource.loaded || from === Network.server) { // don't re-load if the host already loaded
          resource.unpack(payload)
          resource.target.emit("change", true) // quiet update
        }
        else if (Network.host) {
          // a player is trying to write to something
          resource.unpack(payload)
          resource.target.emit("change")
        }
      }
      else if (resource.loaded) {// its a get request, only send if you have a loaded copy
        const p = JSON.stringify(resource.pack())
        // if (p.articles)
        //   p.articles = []
        // if (p.profiles)
        //   p.profiles = []
        channel.send(window.compress(p))
      }
    }
  },
  open: function (msg, channel, from) {
    // send the scene to the clients if you are the host
  }
})

Network.channel("theater", {
  msg: function (msg, channel, from) {
    // console.log(msg)
    Logs.state = JSON.parse(msg.data) || {}
    Logs.emit("change")

    if (Network.host)
      Network.broadcast("theater", msg.data)
  },
  open: function (msg, channel, from) {
    // window.info("established")
    if (Network.host)
      channel.send(JSON.stringify(Logs.state))
  }
})

Network.channel("trackers", {
  msg: function (msg, channel, from) {
    Nav.trackers = JSON.parse(msg.data)
    Nav.emit("change", "trackers")
  },
  open: function (msg, channel, from) {
    // send the scene to the clients if you are the host
    if (Network.host)
      channel.send(JSON.stringify(Nav.trackers))
  }
})

Network.channel("chat", {
  msg: function (msg, channel, from) {
    // console.log(msg)
    Logs.process(JSON.parse(msg.data))
    if (Network.host)
      Network.broadcast("chat", msg.data)
  },
  open: function (msg, channel, from) {
    setTimeout(() => !Network.host && from === Network.server && Logs.chat(`_act=Joined!
_class=LOBBY
_classcolor=#141414`), 1000)
  }
})

Network.channel("media", {
  msg: function (msg, channel, from) {
    // console.log(msg)
    SyncMedia.emit("cmd", JSON.parse(msg.data))
  },
  open: function (msg, channel, from) {
    if (Network.host)
      channel.send(JSON.stringify({ ...SyncMedia.summary, cmd: "sync" }))
  }
})

function CompendiumWrap() {
  const { projectID } = useNav()
  return projectID ? <Column fit noClick><CompendiumView /></Column> : <div />
}

function MainMenuWrap() {
  const { projectID } = useNav()
  return !projectID ? <Column fit className={!projectID ? "visible click" : "invisible no-click"}><MainMenu /></Column> : <div className="absolute pad-l">
    <Button className="card-bg 0" title="Open Wiki" mouseEnterDelay={0.5} click onClick={e => {
      window.blurAll()
      Nav.toggleWiki(true)
    }}>
      <ReadFilled />
    </Button>
  </div>
}

const SceneWrap = React.lazy(()=> (import("./Scene")))

function BackgroundApp() {
  const { mapID, sceneID, focus } = useNav()

  if (focus === "map")
    return mapID ? <MapProvider mapID={mapID}>
      <Map />
  </MapProvider> : <Column className="size-2" center fit>No Map Selected</Column>
  else if (focus === "scene")
    return <Suspense fallback={<Column className="size-2" center fit><LoadingOutlined/></Column>}>
      <SceneWrap />
    </Suspense>
  else
    return <Theater />

}

function WizardWrap() {
  const { wizardID } = useNav()
  return  <Column fit center click={wizardID} className={`${wizardID ? "visible" : "invisible"} black-tint pad-l`}>
    {wizardID ? <Column width="85vw" height="85vh" className="background">
      <WizardProvider wizardID={wizardID}>
        <WizardBuilder/>
      </WizardProvider>
    </Column> : <div/>}
  </Column>
}
let lastValue
function Prompt({ prompt, confirm, close }) {
  const { options } = prompt
  const [value, setValue] = useState(options.value)
  const [search, setSearch] = useState()
  const [confirmNext, setConfirmNext] = useState()
  useEffect(()=> {
    if (confirmNext)
      if (value != null || (options.allowCustom || (options.allowEmpty && value == null)))
        confirmDialog(value || search)
  }, [confirmNext, value])
  const ref = useRef()

  useEffect(() => {
    if (ref && ref.current && ref.current.select)
      ref.current.select()

    if (ref && ref.current && ref.current.selectRef)
      ref.current.selectRef.current.focus()
  }, [ref])

  const cancelDialog = value => {
    close()
    setValue()
    if (prompt && prompt.cancel)
      prompt.cancel(value)
  }

  const confirmDialog = value => {
    close()
    setValue()
    if (prompt && prompt.confirm)
      prompt.confirm(value)
  }

  const onKeyUp = e => {
    if (e.key === "Enter") {
      e.preventDefault()
      e.stopPropagation()
      e.nativeEvent.stopPropagation()
    }
  }

  const catchReturn = e => {
    if (e.key === 'Enter' && (e.target.value || options.allowEmpty) && !options.textarea) {
      if (options.choices) 
        setTimeout(()=>setConfirmNext(true), 0)
      else
        confirmDialog(e.target.value)
      e.preventDefault()
    }
  }
  if (options.app) {
    const Element = options.app
    return <Column grow>
      <Element {...options.props} />
    </Column>
  }
  else if (options.type === "file")
    return <FileApp onSelect={(e, file) => confirmDialog(file)} />
  else if (options.type === "confirm")
    return <Column grow>
      {options.subtitle ? <h3>{options.subtitle}</h3> : null}
      {options.desc ? <p>{options.desc}</p> : null}
      <Row reverse grow>
        <Button className="gaps-v foreground" onClick={e => confirmDialog()}>
          {options.yes || "Confirm"}
        </Button>
        <Button className="gaps foreground" primary onClick={e => cancelDialog()}>
          {options.no || "Cancel"}
        </Button>
      </Row>
    </Column>

  return <Column grow>
    {options.subtitle ? <h3>{options.subtitle}</h3> : null}
    {options.desc ? <p>{options.desc}</p> : null}
    {options.textarea && <TextField.TextArea
      className="background"
      style={{boxShadow: "0px 0px 10px rgba(0,0,0,0.7)"}}
      placeholder={options.placeholder}
      value={value ?? ""}
      onChange={e => setValue(e.target.value)}
      onKeyDown={e => catchReturn(e)}
      onKeyUp={onKeyUp}
      rows={options.rows ?? 9}
      ref={ref}
    />}
    {!options.textarea && !options.choices && <TextField
      className="background"
      style={{boxShadow: "0px 0px 10px rgba(0,0,0,0.7)"}}
      placeholder={options.placeholder}
      value={value ?? ""}
      onChange={e => setValue(e.target.value)}
      onKeyDown={e => catchReturn(e)}
      onKeyUp={onKeyUp}
      ref={ref}
    />}
    {options.choices && !options.textarea && <Select
      className="background"
      allowClear
      showSearch
      placeholder={options.placeholder}
      value={value ?? ""}
      onChange={e => setValue(e)}
      onSearch={e => e ? setSearch(e) : null}
      onKeyDown={e => catchReturn(e)}
      onKeyUp={onKeyUp}
      ref={ref}>
      {options.choices.map((v,i)=><Select.Option key={i} value={v.value}>{v.text}</Select.Option>)}
    </Select>}
    <Row reverse>
      <Button className="gaps-v foreground" onClick={e => value || options.allowEmpty ? confirmDialog(value) : cancelDialog(value)}>
        {options.yes || "Confirm"}
      </Button>
      <Button className="gaps foreground" primary onClick={e => cancelDialog(value)}>
        {options.no || "Cancel"}
      </Button>
    </Row>
  </Column>
}


export default opts => {
  const [prompt, setPrompt] = useState()
  const [prompt2, setPrompt2] = useState()

  useEffect(() => {
    window.dismiss = key => notification.close(key)
    window.notify = message => notification.open({
      placement: 'topRight',
      message,
      ...message
    })
    window.info = message => notification.info({
      placement: 'topRight',
      message,
      ...message
    })
    window.success = message => notification.success({
      placement: 'topRight',
      message,
      ...message
    })
    window.warning = message => notification.warning({
      placement: 'topRight',
      message,
      ...message
    })
    window.error = message => notification.error({
      placement: 'topRight',
      message,
      ...message
    })

    window.pick = (onSelect, options = {}) => window.ask({
      title: options.title || "Pick from Folder",
      subtitle: options.subtitle,
      app: WikiList,
      bodyStyle: { borderTopColor: "transparent", margin: 0, padding: 0, ...options.bodyStyle },
      props: {
        compact: true,
        onSelect: args => {
          if (onSelect)
            onSelect(args)
          else
            console.warn(window.info("selected"))
          // if (!onSelect(args))
          window.close()
        },
        ...options.props
      },
      style: { height: "70vh", ...options.style }
    })

    window.pick2 = (onSelect, options = {}) => window.build({
      title: options.title || "Pick from Folder",
      subtitle: options.subtitle,
      app: WikiList,
      bodyStyle: { borderTopColor: "transparent", margin: 0, padding: 0, ...options.bodyStyle },
      props: {
        compact: true,
        onSelect: args => {
          if (onSelect)
            onSelect(args)
          else
            console.warn(window.info("selected"))
          // if (!onSelect(args))
          window.close(true)
        },
        ...options.props
      },
      style: { height: "70vh", ...options.style }
    })

    window.blurAll = function () {
      // console.warn("blur")
      var tmp = document.createElement("input")
      document.body.appendChild(tmp)
      tmp.focus()
      document.body.removeChild(tmp)
    }
    window.build = (options, confirm, cancel) => {
      setPrompt2({ options, confirm, cancel })
    }

    window.ask = (options, confirm, cancel) => {
      if (options && options.type === "file" && window.isLocal) {
        const { projectID, profileID, filename } = options
        const fileInput = document.createElement('input')
        fileInput.setAttribute("type", "file")
        fileInput.setAttribute("accept", ".mp4, .ogg, .webm, .jpg, .jpeg, .png, .webp, .gif, .wav, .mp3")
        fileInput.onchange = function (e) {
          if (this.files.length) {
            const { path, name } = this.files[0]
            const uid = filename ? (profileID || projectID) + String(name).split(".")[0] : (profileID || projectID)
            fetch(`http://127.0.0.1:${window.location.port}/addFile`, {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ path, projectID, uid })
            })
              .then(response => response.json())
              .then(file => confirm(file))
              .catch(e => console.warn(e))
          }
        }
        fileInput.click()
      }
      else
        setPrompt({ options, confirm, cancel })
    }
  }, [])

  useEffect(() => {
    window.close = (second) => {
      if (second) {
        if (prompt2 && prompt2.cancel)
          prompt2.cancel()
        setPrompt2()
      }
      else {
        if (prompt && prompt.cancel)
          prompt.cancel()
        setPrompt()
      }
    }
  }, [prompt, prompt2])

  let PromptDialog
  let PromptDialog2
  if (prompt2) {
    const closePrompt2 = e => {
      setPrompt2()
      if (prompt2.cancel)
        prompt2.cancel()
    }

    PromptDialog2 = <Modal 
      autoFocus={false} 
      centered 
      footer={prompt2.footer || false} 
      onCancel={closePrompt2} 
      width={prompt2.options.width ?? (prompt2.options.type === "file" ? "85vw" : undefined)} 
      visible={prompt2 != null} 
      title={prompt2.options.type === "file" && !prompt2.options.title ? "Files" : prompt2.options.title} 
      bodyClass={prompt2.options.bodyClass}
      bodyStyle={{ background: "#141414", border: "1px solid #333333", padding: prompt2.options.type === "file" ? "0" : undefined, ...(prompt2.options.bodyStyle || {}) }}
    >
      <Column style={prompt2.options.type === "file" ? {height: "85vh", ...prompt2.options.style} : prompt2.options.style}>
        <Prompt prompt={prompt2} close={e => setPrompt2()} />
      </Column>
    </Modal>
  }

  if (prompt) {
    const closePrompt = e => {
      setPrompt()
      if (prompt.cancel)
        prompt.cancel()
    }

    PromptDialog = <Modal 
      autoFocus={false} 
      centered 
      footer={prompt.footer || false} 
      onCancel={closePrompt} 
      width={prompt.options.width ?? (prompt.options.type === "file" ? "85vw" : undefined)} 
      visible={prompt != null} 
      title={prompt.options.type === "file" && !prompt.options.title ? "Files" : prompt.options.title} 
      bodyClass={prompt.options.bodyClass}
      bodyStyle={{ background: "#141414", border: "1px solid #333333", padding: prompt.options.type === "file" ? "0" : undefined, ...(prompt.options.bodyStyle || {}) }}
    >
      <Column style={prompt.options.type === "file" ? {height: "85vh", ...prompt.options.style} : prompt.options.style}>
        <Prompt prompt={prompt} close={e => setPrompt()} />
      </Column>
    </Modal>
  }

  return <Column fit fixed left top>
    {/* <Column fit fixed left top style={{zIndex: -1}}>
      <video autoPlay muted loop src="./vido.mp4" alt="" style={{width: "100vw", maxWidth: "100vw", height: "100vh", objectFit: "cover", objectPosition: "center center"}} />
    </Column> */}
    <NavProvider>
      {/* <SceneWrap />

      <ToolMount />
      <BuildMenuWrap /> */}
      {/* <Inspector /> */}

      <div className="absolute fit-x fit-y" style={{ zIndex: -1 }}>
        <BackgroundApp />
      </div>
      
      <MainMenuWrap />
      <CompendiumWrap />
      <Inspector />
      {PromptDialog}
      {PromptDialog2}

      <div className="absolute pad-l column top right no-click fit-y" style={{ minWidth: "20vw", zIndex: 0 }}>
        <Hotbar />
      </div>

      <RadialProvider>
        <RadialMenu />
      </RadialProvider>
    </NavProvider>
  </Column>
}
