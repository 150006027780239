import React from 'react'
import { Tooltip } from 'antd'
import Column from './Column'
import styled from 'styled-components'

const Button = styled.button`
  color: ${p => p.selected ? "#00e4ec" : "rgba(255,255,255,0.85)"};
  border-radius: 2px;
  border: 1px solid transparent;
  padding: ${p => p.size === "small" ? "0px 8px" : "4px 15px"};
  cursor: pointer;
  transition: color 0.2s, border-color 0.2s;
  &:hover{
    color:  #00e4ec;
    border-color: ${p => p.selected ? "white" : "#00e4ec"};
  }
`

export default opts => {
  const props = { ...opts }
  delete props.title
  delete props.placement
  delete props.children
  delete props.primary
  delete props.secondary
  delete props.selected
  delete props.small
  delete props.large
  delete props.size
  delete props.variant
  delete props.endIcon
  delete props.startIcon
  delete props.disabled
  delete props.interactive
  delete props.arrow
  delete props.mouseLeaveDelay
  delete props.mouseEnterDelay

  const { 
    mouseEnterDelay, 
    mouseLeaveDelay, 
    disabled, 
    childClass, 
    visible,
    trigger, 
    overlayStyle, 
    overlayInnerStyle, 
    primary, 
    secondary, 
    selected, 
    children, 
    small, 
    large, 
    size, 
    title, 
    placement, 
    color, 
    childStyle, 
    interactive, 
    arrow 
  } = opts
  
  return <Column style={{borderRadius: "2px"}} {...props} onClick={disabled ? null : props.onClick}>
    {title ? <Tooltip placement={placement || "top"} title={title} trigger={trigger} overlayStyle={overlayStyle} overlayInnerStyle={overlayInnerStyle} interactive={interactive} arrowPointAtCenter={arrow} mouseEnterDelay={mouseEnterDelay} mouseLeaveDelay={mouseLeaveDelay} visible={visible}>
      <Button
        className={childClass}
        style={childStyle}
        disabled={disabled}
        children={children}
        size={size || (small ? "small" : null) || (large ? "large" : null) || "medium"}
        selected={selected}
        color={color}
      />
    </Tooltip> : <Button
      className={childClass}
      style={childStyle}
      disabled={disabled}
      children={children || ""}
      size={size || (small ? "small" : null) || (large ? "large" : null) || "medium"}
      selected={selected}
      color={color}
    />}
  </Column>
}
