import React, { useEffect, useState, useContext, createContext } from 'react'
import Resources from '../state/Resources'
import Tracker from '../models/Tracker'

import rfdc from 'rfdc'
const copy = rfdc()

const TrackerContext = createContext()

export { TrackerContext }

const art = new Tracker()
const { Trackers } = Resources

export default function useTracker() {
  return useContext(TrackerContext) || {}
}

export function getTracker(trackerID) {
  return trackerID ? Trackers.get(trackerID) : art
}

export function useHook(trackerID) {
  const [categories, setCategories] = useState({})
  const [order, setOrder] = useState([])
  const [loaded, setLoaded] = useState()
  const [meta, setMeta] = useState({})
  useEffect(() => {
    const a = getTracker(trackerID)
    function change() {
      setLoaded(a.loaded)
      setOrder(a.getOrder())
      setCategories(copy(a.getCategories()))
      setMeta(a.meta)
    }
    change()
    a.on("change", change)
    a.on("ready", change)

    return function () {
      a.off("change", change)
      a.off("ready", change)
    }
  }, [trackerID])

  return { loaded, trackerID, categories, meta, order }
}

export function TrackerProvider({ trackerID, children }) {
  return <TrackerContext.Provider value={useHook(trackerID)} children={children} />
}