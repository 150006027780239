import React, { useState, useEffect, memo, useRef, createContext, useContext } from 'react'
import { Scroll, Div, Column, Button, Row } from '../UI'

import { FixedSizeList, areEqual } from "react-window"
import AutoSizer from "react-virtualized-auto-sizer"

import { Dropdown, Input, Menu, } from 'antd'
import { DoubleLeftOutlined, MenuOutlined, CloseOutlined, ReadFilled, SettingFilled, CaretRightFilled, PlusSquareFilled, MoreOutlined, LockFilled, PlusFilled, DeleteFilled, SearchOutlined, CaretDownOutlined, CloseFilled, LoadingOutlined } from '@ant-design/icons'

import useAccount from '../core/hooks/useAccount'
import useEvents from '../core/hooks/useEvents'
import useProject, { getProject, hasRight, createProfile, ProjectProvider, getPermission, createArticle } from './hooks/useProject'
import { ProfileProvider } from './hooks/useProfile'
import useNav from './hooks/useNav'
import Nav from './state/Navigation'
import Resources from './state/Resources'

import { ProfileName, ProfileIcon } from './Profile'

import UniversalDrop from '../core/wrappers/UniversalDrop'
import UniversalDrag from '../core/wrappers/UniversalDrag'

import Push from './util/SearchStack'

import fuzzysearch from 'fuzzysearch'
import QuickSort from 'optimized-quicksort'

import rfdc from 'rfdc'
import ProfileDrop from "./ProfileDrop"

import RightsApp from './Rights'

import { ProjectName, ProjectSettings } from "./Projects"
import Mention from "./slate/inline/Mention"
import { applyEvent, createEvent } from "./hooks/useEvent"

const copy = rfdc()

const SelectContext = createContext()

const { Projects, Profiles } = Resources

const FolderRow = ({ index }) => {
  const { projectID } = useProject()
  const Project = getProject(projectID)
  const WikiState = Project.wiki
  const Root = Project.root

  const [hovering, setHovering] = useState(false)
  const loaded = true
  const [folderData, setFolderData] = useState({})
  const [perms, setPerms] = useState(false)
  const [singlePerm, setSinglePerms] = useState(false)
  const [anchorEl, setAnchorEl] = useState(false)
  const { profileID: profile, projectID: navProjectID } = useNav()
  const selected = folderData?.folder?.data && profile === folderData?.folder?.data
  
  useEffect(() => {
    function refresh() {
      const lookup = WikiState.at(index)
      const f = Root.lookup(lookup)
      setFolderData({ path: lookup, folder: f })
    }
    refresh()

    WikiState.on("rebuild", refresh)
    WikiState.on("change", refresh)
    return function () {
      WikiState.off("rebuild", refresh)
      WikiState.off("change", refresh)
    }
  }, [index, projectID])

  const onSelect = useContext(SelectContext)

  const { path, folder } = folderData
  if (!folder || !path)
    return <Div />

  const hasChildren = (folder.children && folder.children.length) ? true : false
  const open = folder.isOpen()
  const depth = Math.max((path.split("_").length - (WikiState.bookmark ? (WikiState.find(WikiState.bookmark).split("_").length) : 2)), 0)
  const profileID = folder.data

  const onDrop = (p, m, c) => {

    const item = m.getItem()

    if (!Project.isOwner())
      return window.notify("Not permitted")

    if (projectID !== Nav.project)
      return item.path ? window.notify("Can not change a codex") : null


    if (item.profileID) {
      if (item.path) {
        if (path !== item.path) { // don't add to itself
          const child = Root.lookup(item.path)
          const dataIndex = folder.findData(child.data)
          if (dataIndex === -1) {
            folder.add(child)
            Root.parent(item.path).removeData(child.data)
            folder.open()
            WikiState.rebuild(true)
            WikiState.emit("change")
            return true
          }
        }
        else {
          // remove from parent
          const parent = Root.parent(path)
          const relativeRoot = WikiState.lookupFolder(WikiState.bookmark)
          if (parent !== relativeRoot) {
            const container = Root.parent(path, 1)
            container.after(container.findData(parent.data), folder)
            parent.removeData(folder.data)
            WikiState.rebuild(true)
            WikiState.emit("change")
            parent.emit("change")
            return true
          }
        }
      }
    }
  }

  const mouseEnter = e => setHovering(true)
  const mouseLeave = e => setHovering(false)
  const toggleFolder = e => {
    if (hasChildren) {
      folder.toggle()
      WikiState.rebuild()
    }
    else
      onClick(e)
    if (hasChildren)
      e.stopPropagation()
  }

  const top = index === 0 || Root.lookup(WikiState.at(index - 1))?.children?.length
  const onClick = e => {
    if (hasRight(profileID, "SEE") || !Nav.project) {
      if (onSelect)
        if (Profiles.get(profileID).loaded)
          onSelect(profileID, path)
        else
          window.warning("Loading... try again later")
      else {
        if (Nav.compendium) {
          Nav.setProfile(profileID)
          Nav.setBrowser(false)
        }
        else {
          Nav.setProfile(profileID)
          Nav.setBrowser(false)
        }
      }
    }
    else
      window.notify("Not permitted")

    e.stopPropagation()
  }

  const deleteChildren = e => {
    window.ask({ title: "Delete Profile", subtitle: "This operation can't be undone", type: "confirm" }, () => {
      // Nav.removeArticle()
      if (folder.children.length)
        window.ask({ title: "Delete Children", subtitle: "Delete everything in this folder? This operation can't be undone", type: "confirm", no: "Only Parent" }, () => {
          const removeList = []
          for (const child of folder.children)
            removeList.push(child.data)

          for (const profileID of removeList) {
            Project.removeProfile(profileID)
            Profiles.delete(profileID)
          }

          Project.removeProfile(profileID)
          Profiles.delete(profileID)
          Nav.setProfile()
          Nav.setPreview()
        }, () => {
          // move the children to the current folder's parent
          const parent = Root.parent(path)
          for (const child of folder.children)
            parent.add(child)
          Project.removeProfile(profileID)
          Profiles.delete(profileID)
          Nav.setProfile()
          Nav.setPreview()
        })
      else {
        Project.removeProfile(profileID)
        Profiles.delete(profileID)
        Nav.setProfile()
        Nav.setPreview()
      }
    })
  }

  const setIcons = e => {
    const icon = Profiles.get(profileID).getIcon()
    folder.children.forEach(folder => {
      const profile = Profiles.get(folder.data)
      profile.setIcon(icon)
    })
    window.success("Icons copied from parent folder")
    folder.emit("change")
    WikiState.rebuild()
  }

  const permitChildren = e => {
    if (!Project.isOwner())
      return window.notify("Not permitted to affect children")

    const role = "Guests"
    const perms = getPermission(profileID, "profiles")
    const recurse = fdr => {
      for (const child of fdr.children)
        if (hasRight(child.data, "SHARE"))
          Project.permitRole(role, perms, "profiles", child.data)
      // recurse(child)     
    }
    recurse(folder)
    window.success("Permissions assigned from parent folder")
  }

  const sortChildren = icon => {
    folder.children.sort((a, b) => {
      const aa = Profiles.get(a.data)
      const bb = Profiles.get(b.data)
      return aa.getName().toLowerCase().localeCompare(bb.getName().toLowerCase())
    })
    folder.emit("change")
    WikiState.rebuild()
  }

  const menu = <Menu>
    <Menu.Item onClick={setIcons} disabled={!(folder.isOpen())}>
      Set Children Icons
    </Menu.Item>
    <Menu.Item onClick={permitChildren} disabled={!(hasRight(profileID, "SHARE") && folder.isOpen())}>
      Set Children Permissions
    </Menu.Item>
    <Menu.Item onClick={folder.isOpen() ? e => sortChildren() : null} disabled={folder.isOpen() ? false : true}>
      Sort Children
    </Menu.Item>
    <Menu.Item className="text-red" onClick={deleteChildren} disabled={!hasRight(profileID, "OWN")}>
      <b>Delete</b>
    </Menu.Item>
    {/* <Menu.Item onClick={hasRight(profileID, "SHARE") && folder.isOpen() && (e => setPerms(true))} disabled={!hasRight(profileID, "SHARE")}>
      Children Permissions
    </Menu.Item> */}
    {/* <Menu.Item onClick={(owner && canChange && folder.isOpen()) ? e => annotateChildren() : null} disabled={!(owner && canChange && folder.isOpen())}>
      Children to Key Phrases
    </Menu.Item>
    <Menu.Item onClick={(owner && canChange && folder.isOpen()) ? e => formatChildren() : null} disabled={!(owner && canChange && folder.isOpen())}>
      Format Children to self
    </Menu.Item>
    <Menu.Item onClick={(owner && canChange && folder.isOpen()) ? e => sortChildren() : null} disabled={!(owner && canChange && folder.isOpen())}>
      Sort Children
    </Menu.Item> */}
  </Menu>

  return <UniversalDrop className="fit-x" onDrop={onDrop} style={{ paddingLeft: `${depth * 1.5}em`, fontSize: "1.1em" }}>
    <UniversalDrag className={`column fit-x ${selected ? "text-blue" : ""}`} item={{ projectID, profileID, path }} disabled={false}>
      <Column onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} className={`grab clickable ${hovering ? "foreground" : ""}`}>
        {top ? <UniversalDrop className={`wiki-divider wiki-border  ${(index % 2) ? "darker2" : ""}`} dropColor="#34DBDB" onDrop={
          (p, m, c) => {
            if (!Project.isOwner())
              return window.notify("Not permitted")

            if (projectID !== Nav.project)
              return window.notify("Can not change a codex")

            const item = m.getItem()
            if (item.profileID && item.path && path !== item.path) {
              const container = Root.parent(path)
              const parent = Root.parent(item.path)
              const dragObj = Root.lookup(item.path)
              parent.removeData(item.profileID)
              container.before(container.findData(profileID), dragObj)
              WikiState.rebuild(true)
              WikiState.emit("change")
            }
          }} /> : <span className={`wiki-divider wiki-border  ${(index % 2) ? "darker2" : ""}`} />}
        <ProfileProvider profileID={profileID}>
          {perms && <RightsApp close={e => setPerms(false)} open={perms} parent={true} type="profiles" onPermit={permitChildren} />}
          {singlePerm && <RightsApp close={e => setSinglePerms(false)} uid={profileID} open={singlePerm} type="profiles" />}
          <Row className={`wiki-row wiki-border show-hover ${index % 2 ? "darker2" : ""}`} height="30px">
            <Button center size="small" childStyle={{ padding: "0 4px" }} className={`rounded ${hasChildren ? "visible" : "no-click invisible"}`} onClick={toggleFolder}>
              <CaretRightFilled style={{ transition: "transform 0.2s", transform: open ? "rotate(90deg)" : "rotate(0deg)" }} />
            </Button>
            <Row className="pointer" style={{ minWidth: "100px" }} onClick={onClick}>
              <Row center>
                <Mention placement="right" profileID={profileID} item={{projectID, path}} onDrop={onDrop} onClick={true}>
                  <ProfileName maxCharacters={32} showIcon={true} />
                </Mention>
              </Row>
            </Row>
            <Row grow />
            {/* {Project.isOwner() && <Button size="small" className="gaps-h" center onClick={e => { setSinglePerms(true); e.stopPropagation() }}>
              <LockFilled />
            </Button>} */}
            {navProjectID === projectID && getProject(projectID).isOwner() && <Dropdown overlay={menu} trigger="click">
              <Button className="hover-visible invisible" center size="small" style={{marginRight: "4px"}} >
                <SettingFilled />
              </Button>
            </Dropdown>}
          </Row>
        </ProfileProvider>
        {!hasChildren ? <UniversalDrop className={`wiki-divider wiki-border ${(index % 2) ? "darker2" : ""}`} dropColor="#34DBDB" onClick={onClick} onDrop={
          (p, m, c) => {
            if (!Project.isOwner())
              return window.notify("Not permitted")

            if (projectID !== Nav.project)
              return window.notify("Can not change a codex")

            const item = m.getItem()
            if (item.profileID && item.path && path !== item.path) {
              const container = Root.parent(path)
              const parent = Root.parent(item.path)
              const dragObj = Root.lookup(item.path)
              parent.removeData(item.profileID)
              container.after(container.findData(profileID), dragObj)
              WikiState.rebuild(true)
              WikiState.emit("change")
            }
          }} /> : <span className={`wiki-divider wiki-border ${(index % 2) ? "darker2" : ""}`} />}
      </Column>
    </UniversalDrag>
  </UniversalDrop>
}

const WikiRow = memo(({ index, style }) => <div className="row" style={style}>
  <FolderRow index={index} />
</div>, areEqual)

const FolderBrowser = opts => {
  // const { height } = opts
  const { uid: accountID } = useAccount()
  const { projectID, loaded } = useProject()

  const WikiState = getProject(projectID).wiki
  const ref = useRef()
  const [forced, forceUpdate] = useState({ size: WikiState.size() })

  useEffect(() => WikiState.rebuild(), [accountID, projectID])
  useEvents(["rebuild", "change"], () => {
    forceUpdate({ size: WikiState.size() })
  }, WikiState, [projectID, accountID])

  if (!loaded)
    return <Div><LoadingOutlined /></Div>

  return <AutoSizer>
    {({ height, width }) => (
      <FixedSizeList
        ref={ref}
        height={height}
        width={width}
        itemCount={forced.size}
        itemSize={40}
      >
        {WikiRow}
      </FixedSizeList>
    )}
  </AutoSizer>
}

function WikiNav() {
  const { accountID } = useAccount()
  const { projectID } = useProject()
  const Project = getProject(projectID)

  const WikiState = Project.wiki
  const Root = Project.root
  const [tabData, setTabData] = useState({ tabList: [...WikiState.bookmarks], tab: WikiState.bookmark })

  useEffect(() => setTabData({ tabList: [...WikiState.bookmarks], tab: WikiState.bookmark }), [projectID, accountID])

  tabData.tabList = tabData.tabList.filter(bookmark => {
    return WikiState.hasRight(WikiState.lookupBookmark(bookmark), "SEE")
  })

  const { tab, tabList } = tabData

  useEvents(["rebuild", "change"], () => setTabData({ tabList: [...WikiState.bookmarks], tab: WikiState.bookmark }), WikiState, [])

  const catDrop = (p, m, c) => {
    const item = m.getItem()
    if (!Project.isOwner() || Nav.project !== projectID)
      return window.notify("Not permitted")

    if (item && item.profileID && item.path)
      WikiState.pushBookmark(Root.lookup(item.path).uid)
  }
  const catLeave = (p, m, c) => {
    console.log("leave", p)
    if (!Project.isOwner() || Nav.project !== projectID)
      return window.notify("Not permitted")

    const dropResult = m.getDropResult()
    console.log(dropResult)
    if (dropResult == null)
      WikiState.removeBookmark(p.item.bookmark)
  }

  const tabs = []
  for (const bookmark of tabList)
    tabs.push(<Mention key={bookmark} placement="left" style={{display: "block"}} profileID={WikiState.lookupBookmark(bookmark)} item={{ bookmark }} onClick={true}>
      <Button
        childStyle={{padding: "4px"}}
        selected={bookmark === tab}
        key={bookmark}
        onClick={e => {
          selectBookmark(bookmark)
          e.target.scrollIntoView({block: "center", inline: "center"})
        }}
        onClose={e => WikiState.removeBookmark(bookmark)}
        children={<ProfileIcon className="size-2" placement="left" mouseEnterDelay={0.5}/>}
      />
  </Mention>)

  const selectBookmark = value => {
    WikiState.setBookmark(WikiState.bookmark === value ? null : value)
    const folder = WikiState.lookupFolder(value)
    setTabData({ tabList: [...WikiState.bookmarks], tab: WikiState.bookmark })    
    if (folder) {
      if (folder.children.length) {
        folder.open()
        WikiState.rebuild()
      }
      else
        Nav.setProfile(folder.data)
    }
  }

  const onDestroy = (p, m, c) => {
    const item = m.getItem()
    if (item.bookmark) {
      WikiState.removeBookmark(item.bookmark)
    }
  }

  return <UniversalDrop onDrop={catDrop} className="column white-title" backgroundColor="rgba(255,255,255,0.05)">
    <Scroll className="small-scroll">
      {tabs}
      <div className="pad-l"/>
    </Scroll>
    <UniversalDrop onDrop={onDestroy} className={Nav.project === projectID ? undefined : "invisible no-click"}>
      <Button title="Drop to destroy bookmark" secondary placement="right">
        <DeleteFilled />
      </Button>
    </UniversalDrop>
  </UniversalDrop>
}

export default ({ onSelect, compact }) => {
  const { projectID, name, codexes, loaded } = useProject()
  const { projectID: navProjectID } = useNav()

  const [codexID, setCodexID] = useState()
  const [suggestions, setSuggestions] = useState([])
  const [index, setIndex] = useState()
  const [search, setSearch] = useState()
  const [mode, setMode] = useState("Search")
  const searchRef = useRef()

  useEffect(()=>{
    console.log(getProject(projectID).isOwner())
    if (getProject(projectID).isOwner() && getProject(projectID).profiles.length === 0 && navProjectID === projectID) {
      setMode("Create a new...")
      onChange("", "Create a new...")
    }
    else {
      setMode("Search")
      onChange("", "Search")
    }
  }, [projectID, loaded, searchRef])

  const resetSearch = () => {
    setIndex()
    setSearch("")
    setSuggestions([])
  }

  const toggleMode = e => {
    let newMode = (e || mode) === "Search" ? "Create a new..." : "Search"
    if (projectID !== navProjectID)
      newMode = "Search"
    setMode(newMode)
    setSearch("")
    onChange("", newMode, e)
    if (searchRef.current)
      searchRef.current.focus()
  }

  const onChange = (val, buildMode = mode, noDelay) => {
    if (val || buildMode !== "Search") {
      if (!suggestions.length)
        setSuggestions([null])

      const update = () => {
        const Project = getProject(projectID)
        let List = []
        if (buildMode === "Search") {
          for (const uid in Project.codexes) {
            const codex = Projects.get(uid)
            if (codex.loaded)
              List = [...List, ...codex.profiles]
          }
          List = [...List, ...Project.profiles]
          const s = (search || "").toLowerCase()
          List = List.filter(a => {
            let aa = Profiles.get(a)
            return hasRight(a, "READ") && aa.getArticleID() && fuzzysearch(s, aa.getName().toLowerCase())
          })

          QuickSort.sort(List, (a, b) => {
            let aa = Profiles.get(a)
            let bb = Profiles.get(b)
            return aa.getName().toLowerCase().localeCompare(bb.getName().toLowerCase())
          })
          // if (List.length) 
          //   NavState.setPreview({ article: List[0] })
          // else 
          //   NavState.setPreview()
        }
        else if (buildMode === "Create a new...") {
          const bank = Project.getTemplateBank()
          List = Object.keys(bank)
        }
        setIndex(List.length && buildMode === "Search" ? Math.min(List.length, 0) : 0)
        setSuggestions([null, ...List])
      }
      Push("search", update)
      if (noDelay)
        update()
      setSearch(val)
    }
    else
      // NavState.setPreview()
      resetSearch()
  }

  const confirm = (idx = index, clicked) => {
    let list = suggestions
    const Project = getProject(projectID)
    const WikiState = Project.wiki
    const Root = Project.wiki.root
    if (list[idx] != null && mode === "Search") {
      if (hasRight(list[idx], "READ")) {
        // NavState.setPreview()
        if (onSelect)
          onSelect(list[idx])
        else {
          Nav.setProfile(list[idx])
          Nav.setBrowser(false)
        }
      }
      resetSearch()
      searchRef.current.blur()
    }
    else if ((search || mode === "Create a new...") && search[0] !== "/") {
      if (!Project.isOwner())
        return window.warning("You do not own this project")
      if (Nav.profile != null) {
        const f = Root.lookup(WikiState.findProfile(Nav.profile))
        f.open()
      }

      if (list[idx] && mode === "Create a new...") {
        const templateID = list[idx]
        const bank = getProject().getTemplateBank()
        const template = bank[templateID]
        if (template.wizardID && !search) {
          Nav.wiki = true
          Nav.settings = false
          Nav.compendium = true
          Nav.setWizard(template.wizardID)
        }
        else {
          const profile = createProfile()
          if (profile) {
            if (search)
              profile.setName(search)

            if (onSelect)
              onSelect(profile.uid)
            else if (clicked)
              Nav.setProfile(profile.uid)
          }

          // roll the event
          const create = profileID=>{
            const template = Profiles.get(profileID)
            const loading = {}
            const tabs = template.getTabs()
        
            function confirm(id){
              if (id)
                delete loading[id]
        
              if (Object.keys(loading).length !== 0)
                return
                
              const backupData = copy(template.pack())
              delete backupData.uid
              delete backupData._id
              
              profile.unpack(backupData)
              profile.loaded = false
        
              const article = createArticle()
              const articleData = Resources.Articles.get(profile.getArticleID()).pack()
              delete articleData.uid
              delete articleData._id
              article.unpack(articleData)
              article.loaded = true
              article.emit("ready")
              article.emit("change")
              profile.setArticleID(article.uid)
              for (const tab of tabs) {
                const article = createArticle()
                const articleData = Resources.Articles.get(profile.getTab(tab).articleID).pack()
                delete articleData.uid
                delete articleData._id
                article.unpack(articleData)
                article.loaded = true
                article.emit("ready")
                article.emit("change")
                profile.updateTab(tab, {articleID: article.uid})
              }
              
              profile.loaded = true
              profile.emit("ready")
              profile.emit("change")

              if (search)
                profile.setName(search)

              if (bank[templateID].event) {
                const {event, context} = createEvent({sourceID: profile.uid})
                event.roll(bank[templateID].event, context).then(e => {
                  applyEvent(event, context, {sourceID: profile.uid})
                  event.record()
                })
              }
            }
        
            const aID = template.getArticleID()
            const article = Resources.Articles.get(aID)
            if (aID && !article.loaded) {
              loading[aID] = true
              article.once("ready", e=>confirm(aID))
            }
        
            for (const tab of tabs) {
              const { articleID } = template.getTab(tab)
              const article = Resources.Articles.get(articleID)
              if (articleID && !article.loaded) {
                loading[articleID] = true
                article.once("ready", e=>confirm(articleID))
              }
            }
            confirm()
          }
        
          if (template.profileID)
            create(template.profileID)
          
          if (!template.profileID && template.event) {
            const {event, context} = createEvent({sourceID: profile.uid})
            event.roll(template.event, context).then(e => {
              applyEvent(event, context, {sourceID: profile.uid})
              event.record()
            })
          }
        }
      }
      else {
        const profile = createProfile()
        if (profile) {
          if (search)
            profile.setName(search)

          if (onSelect)
            onSelect(profile.uid)
          else if (clicked)
            Nav.setProfile(profile.uid)
        }
        
        const article = createArticle()
        profile.setArticleID(article.uid)
        article.emit("change")
      }

      onChange("", "Search")
      setMode("Search")
      resetSearch()
    }
  }

  const onKeyDown = e => {
    let list = suggestions

    switch (e.key) {
      case "Tab":
        // switch to Create
        if (navProjectID === projectID) {
          setIndex(0)
          const newMode = mode === "Search" ? "Create a new..." : "Search"
          onChange(search, newMode)
          setSuggestions([null])
          setMode(newMode)

          e.preventDefault()
        }
        break
      case "ArrowDown":
        const newSelect = Math.max(((index !== null ? index : -1) + 1) % list.length, 0)
        // if (list.length) 
        //   NavState.setPreview(list[newSelect])

        setIndex(newSelect)
        e.preventDefault()
        break
      case "ArrowUp":
        const newSelec1 = Math.max((index === 0 ? list.length : index - 1), 0)
        // if (list.length) 
        //   NavState.setPreview(list[newSelec1])

        setIndex(newSelec1)
        e.preventDefault()
        break
      case "Escape":
        window.blurAll()
        onChange("", "Search")
        setMode("Search")
        resetSearch()
        break
      case "Enter":
        confirm()
        break
      default:
        break
    }
  }

  let child = <ProjectProvider projectID={codexID || projectID}>
    <Row fit>
      <WikiNav />
      <Div grow style={{boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.7)"}}>
        <FolderBrowser />
      </Div>
    </Row>
  </ProjectProvider>
  if (search || mode !== "Search") {
    let searchResults
    if (mode === "Search")
      searchResults = suggestions.map((profileID, idx) => profileID === null ?
        <Row key="empty" className={(idx === index ? "foreground" : "") + " clickable row text-blue pad-h-l size-large"} onClick={confirm}>
          {`New page '${search}'`}
        </Row> :
        <UniversalDrag item={{ profileID }}
          key={idx}
          className={(idx === index ? "foreground" : "") + " clickable row pad-h-l size-large"}
          margin="none"
          onClick={e => confirm(idx)}
        >
          <Mention profileID={profileID} onClick={true}>
            <Row wrap>
              <Column center style={{minWidth: "2.5rem", paddingRight: "0.5rem"}}>
                <ProfileIcon />
              </Column>
              <ProfileName style={{}} showIcon={false} />
            </Row>
          </Mention>
        </UniversalDrag>)

    else if (mode === "Create a new...") {
      const bank = getProject().getTemplateBank()
      searchResults = suggestions.map((templateID, idx) =>
        <Row key={idx} className={(idx === index ? "foreground" : "") + " clickable row text-blue pad-h-l size-large"} onClick={e => confirm(idx, true)}>
          {bank[templateID] && <Column center style={{minWidth: "2.5rem", paddingRight: "0.5rem"}}>
            <ProfileProvider profileID={bank[templateID].profileID}>
              <ProfileIcon />
            </ProfileProvider>
          </Column>}
          {search ? `${(bank[templateID] ? bank[templateID].name : null) || templateID || "Blank Page"}` : `${(bank[templateID] ? bank[templateID].name : null) || templateID || "Blank Page"}`}
        </Row>)
    }
    child = <Scroll scrollY className="card-bg"  style={{boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.7)"}}>
      <Row className="pad size-large">{mode === "Search" ? "Search results" : "Create a new..."}</Row>
      {searchResults}
    </Scroll>
  }

  if (!loaded)
    return <LoadingOutlined />

  // style={{ marginLeft: "0.5rem" }}

  return <SelectContext.Provider value={onSelect}>
    <Column grow>
      {!compact && <ProjectProvider projectID={codexID || projectID}>
        <Row between className="foreground" style={{border: "1px solid #141414", borderBottomColor: "transparent"}}>
          <Button onClick={e=>Nav.setCompendium(false)}>
            <DoubleLeftOutlined/>
          </Button>
          <Row center>
            <a style={{color: "white", fontWeight: "bold"}} onClick={e => {
              Nav.toggleHomePage(true)
              Nav.setProfile()
            }}>
              <ProjectName className="bold" />
            </a>
            <Dropdown trigger="click" overlay={<Menu>
              <Menu.Item className={codexID ? undefined : "selected"} onClick={e => setCodexID()}>{name}</Menu.Item>
                {codexes.map((v, i) => <Menu.Item className={codexID === v ? "selected" : undefined} key={i} onClick={e => setCodexID(v)}>{getProject(v).getName()}</Menu.Item>)}
              </Menu>}>
              <CaretDownOutlined style={{marginLeft: "4px"}} title="Archives"/>
            </Dropdown>
          </Row>
          <ProjectSettings/>
        </Row>

        {/* <Column center relative style={{overflow: "hidden", height: "10vh"}}>
          <ProjectAvatar />
          <Column absolute fit reverse style={{background: "linear-gradient(180deg,#33333300,#141414)"}}>
            <Row center className="pad-v">
              {!codexID && <ProjectSettings/>}
              <Row className="pad-h" fitX>
                <ProfileProvider>
                  <b className="grow" style={{color: "white", fontSize: "1.4rem"}}><ProfileName onClick={e => Nav.setProfile()} className="pointer" /></b>
                </ProfileProvider>
              </Row>
            </Row>
          </Column>
          <Row absolute top left>
            {archive}
          </Row>
        </Column> */}
      </ProjectProvider>}
      {navProjectID && <Row fitX>
        {/* {compact && archive} */}
        <Row grow className="foreground size-large">
          <Input
            type="text"
            prefix={<Button onClick={e => toggleMode("Create a new...")} primary className="rounded fit-y">
              <SearchOutlined />
            </Button>}
            suffix={!codexID && navProjectID === projectID && <Button placement="right" onClick={e => toggleMode()} title="Create..." primary className="rounded fit-y">
              <PlusSquareFilled />
            </Button>}
            placeholder={!codexID ? "Search or Create" : "Search"}
            className={`${!compact ? "" : ""} search-input`}
            value={search}
            onChange={e => onChange(e.target.value)}
            onKeyDown={onKeyDown}
            ref={searchRef}
          />
        </Row>
      </Row>}
      {child}
    </Column>
  </SelectContext.Provider>
}