import React from 'react'

import { Row, Column, Scroll, Button } from '../UI'
import useNav from './hooks/useNav'
import Nav from './state/Navigation'

import useTracker, {getTracker, TrackerProvider} from './hooks/useTracker'
import useProfile, { getProfile, ProfileProvider } from './hooks/useProfile'
import { getProject, hasRight } from './hooks/useProject'

import Network from '../core/state/Network'
import Resources from './state/Resources'
import UniversalDrop from "../core/wrappers/UniversalDrop"
import UniversalDrag from "../core/wrappers/UniversalDrag"

import { 
  LoadingOutlined,
  DeleteFilled, 
  LockFilled, 
  UnlockFilled, 
  PlusSquareFilled, 
  PlusCircleFilled, 
  WarningFilled,
  CheckSquareFilled,
  CheckSquareOutlined,
  StepForwardOutlined
} from '@ant-design/icons'
import Mention from "./slate/inline/Mention"
import { applyEvent, createEvent } from "./hooks/useEvent"
const { Trackers, Profiles } = Resources

function ProfileText({ tID, category }) {
  const {alias, loaded, name, profileID, banner} = useProfile()
  const text = hasRight(profileID, "SEE") ? name : alias

  if (!loaded)
    return <LoadingOutlined />

  return <Mention profileID={profileID} onClick={true}>
    <UniversalDrag id={`${tID}-${category}-${profileID}-text`} className="row grow" item={{ profileID, trackerID: tID, category }}>
      <Button childStyle={{padding: "0"}} title={text} placement="bottom" onClick={e => hasRight(profileID, "SEE") ? Nav.toggleProfile(profileID) : null}>
        {banner.src ? <img className="cover" height={96} width={64} src={banner.src} /> : <Column className="card-bg" center style={{height: "96px", width: "64px"}}>
          <a className="text-white size-small">{text || "<Hidden>"}</a>
        </Column>}
      </Button>
    </UniversalDrag>
  </Mention>
}

export function Tracker() {
  const {order, categories, loaded, meta, trackerID} = useTracker()

  const removeTracker = e => {
    if (!Network.host)
      return window.notify("Only the host can manage trackers")

    window.ask({ type: "confirm", title: "Delete this tracker", subtitle: "This can not be undone" }, res => {
      Nav.trackers.splice(Nav.trackers.indexOf(trackerID), 1)
      Nav.setTracker()
      Nav.emit("change", "trackers")
      Network.broadcast("trackers", JSON.stringify(Nav.trackers))
    })
  }

  const addProfile = (profileID, category, noGen) => {
    if (!trackerID)
      return window.error("Error, TrackerID missing")

    const tracker = getTracker(trackerID)

    const profile = getProfile(profileID)
    const hasScript = tracker.meta?.enter
    if (noGen) {
      try {
        if (hasScript && category == null) {
          const {event: evt, context: ctx} = createEvent({sourceID: profile.uid})
          const context = {...ctx, quiet: true}
          evt.roll(hasScript, context).then(e=>{
            applyEvent(evt, context)
            if (!profile.temp)
              evt.record()
            const cat = evt.eval(evt.fill(evt.api["category"], context), context)
            tracker.addToCategory(String(cat), profile.uid)
          })
        }
        else {
          const cat = category || profile.getName()
          tracker.addToCategory(String(cat), profile.uid)
        }
      }
      catch (e) {
        window.warning("Something went wrong")
        console.warn(e)
      }
    }
    else
      window.ask({ title: "How many?", subtitle: "Will X number of temporary pages, leave blank to use the original.", allowEmpty: true }, res => {
        if (!res)
          try {
            if (hasScript && category == null) {
              const {event: evt, context: ctx} = createEvent({sourceID: profile.uid})
              const context = {...ctx, quiet: true}
              evt.roll(hasScript, context).then(e=>{
                applyEvent(evt, context)
                if (!profile.temp)
                  evt.record()
                const cat = evt.eval(evt.fill(evt.api["category"], context), context)
                tracker.addToCategory(String(cat), profile.uid)
              })
            }
            else {
              const cat = category || profile.getName()
              tracker.addToCategory(String(cat), profile.uid)
            }
          }
          catch (e) {
            window.warning("Something went wrong")
            console.warn(e)
          }
        else if (isNaN(res))
          window.notify("Please enter a positive integer")
        else if (profile)
          for (let i = 0; i < Math.abs(parseInt(res)); i++) {
            const instance = profile.instance()
            const p = Profiles.create(instance.uid)
            p.load(instance)
            p.setTemplated(true)
            for (const tab of p.getTabs()) 
              p.updateTab(tab, { templated: true })
            
            try {
              if (hasScript && category == null) {
                const {event: evt, context: ctx} = createEvent({sourceID: profile.uid})
                const context = {...ctx, quiet: true}
                evt.roll(hasScript, context).then(e=>{
                  applyEvent(evt, context)
                  if (!profile.temp)
                    evt.record()
                  const cat = evt.eval(evt.fill(evt.api["category"], context), context)
                  tracker.addToCategory(String(cat), p.uid)
                })
              }
              else {
                const cat = category || profile.getName()
                tracker.addToCategory(String(cat), p.uid)
              }
            }
            catch (e) {
              window.warning("Something went wrong")
              console.warn(e)
            }
            p.setName(p.getName() + ` (${i+1})`)
            if (p.getAlias())
              p.setAlias(p.getAlias() + ` (${i+1})`)
          }
      })
    // tracker.addToCategory(category, profile.uid)
  }

  const pickProfile = category => {
    if (!trackerID)
      return window.error("Error, TrackerID missing")

    window.pick(profileID => setTimeout(() => addProfile(profileID, category), 1))
  }

  const addCategory = e => {
    if (!trackerID)
      return

    window.ask({ title: "New Category" }, res => getTracker(trackerID).addCategory(res))
  }

  const toggleReveal = e => {
    const tracker = getTracker(trackerID)
    tracker.meta.revealed = !tracker.meta.revealed
    tracker.emit("change")
    Network.broadcast("trackers", JSON.stringify(Nav.trackers))
  }

  const allActive = e => {
    const tracker = getTracker(trackerID)
    for (const name in tracker.getCategories()) {
      const cat = tracker.getCategory(name)
      for (const key of cat.list) {
        cat.states[key] = false
      }
    }
    tracker.emit("change")
  }

  const allClear = e => {
    const tracker = getTracker(trackerID)
    for (const name in tracker.getCategories()) {
      const cat = tracker.getCategory(name)
      for (const key of cat.list) {
        cat.states[key] = true
      }
    }
    tracker.emit("change")
  }

  const children = []
  for (const name of order) {
    const category = categories[name]
    if (category) {
      const onDrop = (p, m, c) => {
        const tracker = getTracker(trackerID)
        const item = m.getItem()
        if (item.profileID && Network.host)
          if (item.trackerID && item.category) {
            tracker.removeFromCategory(item.category, item.profileID)
            const l = tracker.getCategory(item.category).list.length
            if (l < 1)
              tracker.removeCategory(item.category)
            tracker.addToCategory(name, item.profileID)
          }
          else
            addProfile(item.profileID, name)
      }

      const isNumber = !isNaN(name)

      let c = []
      const col = false ? "#34DBDB" : "#222222"


      c.push(<div className={`${isNumber ? "column" : "row"} between`}>
        <Button small className={isNumber ? "size-2" : undefined} title="Click to add" mouseEnterDelay={0.5} onClick={e => pickProfile(name)}>
          {isNumber ? <b>{name}</b> : name}
        </Button>
        <div className="grow"/>
        {Network.host && <Row reverse>
          <div className={`${isNumber ? "column" : "row"} between`}>
            {/* <Button title="Add" placement={isNumber ? "bottom" : "top"} small onClick={e => pickProfile(name)}>
              <PlusCircleFilled />
            </Button> */}
            <UniversalDrop onDrop={(p,m,c)=>{
              const { profileID } = m.getItem()
              getTracker(trackerID).removeFromCategory(name, profileID)
            }}>
              <Button small mouseEnterDelay={0.5} title="Drop to remove, click to destroy" placement={isNumber ? "bottom" : "top"} onClick={Network.host && (e => window.ask({ title: "Remove group", type: "confirm" }, res => getTracker(trackerID).removeCategory(name)))}>
                <DeleteFilled />
              </Button>
            </UniversalDrop>
          </div>
         </Row>}
        </div>)
      // let rows = category.list.map((pID, i)=><span style={{opacity: category.states[pID] ? "50%" : undefined}} className={category.states[pID] ? "black-tint" : ""} key={i}>
      //   <td style={{ width: "10%" }} className="clickable" onClick={e => hasRight(pID, "CHANGE") ? getTracker(trackerID).toggleState(name, pID) : null}>
      //     {category.states[pID] ? <CheckSquareFilled /> : <CheckSquareFilled className="text-blue" />}
      //   </td>
      //   <td style={{ width: "90%" }} className={category.states[pID] ? "reference" : "embold"} onClick={e => hasRight(pID, "SEE") ? Nav.toggleProfile(pID) : null}>
      //     <ProfileProvider profileID={pID}>
      //       <ProfileText category={name} tID={trackerID} />
      //     </ProfileProvider>
      //   </td>
      // </span>)

      // if (rows.length)
      //   c.push(<table className="background auto editing" style={{ textAlign: "center" }}>
      //     <tbody>
      //       {rows}
      //     </tbody>
      //   </table>)

      let rows = category.list.map((pID, i)=><Column relative style={{opacity: category.states[pID] ? "50%" : undefined}} className="foreground" key={i}>
        <ProfileProvider profileID={pID}>
          <ProfileText category={name} tID={trackerID} />
        </ProfileProvider>
        {hasRight(pID, "CHANGE") && <Button absolute bottom right small className="black" onClick={e => hasRight(pID, "CHANGE") ? getTracker(trackerID).toggleState(name, pID) : null}>
          {category.states[pID] ? <CheckSquareFilled /> : <CheckSquareFilled className="text-blue" />}
        </Button>}
      </Column>)

      if (rows.length)
        c.push(<Row center>
          <Row wrap>
            {rows}
          </Row>
        </Row>)
        
      children.push(<UniversalDrop className={`${isNumber ? "row-reverse" : "column"} card-bg`} style={{marginBottom: "1.0rem", border: `1px solid ${col}`}} onDrop={onDrop} onClick={e=>e.stopPropagation()}>
        {c}
      </UniversalDrop>)
    }
  }

  const onDrop = (p, m, c) => {
    const item = m.getItem()
    if (item.profileID && Network.host)
      if (item.trackerID && item.category) 
        window.ask({title: "Move to..."}, res=>{
          const tracker = getTracker(item.trackerID)
          tracker.removeFromCategory(item.category, item.profileID)
          const l = tracker.getCategory(item.category).list.length
          if (l < 1)
            tracker.removeCategory(item.category)
          addProfile(item.profileID, res, true)
        })
      else
        addProfile(item.profileID, undefined)

    // tracker.addToCategory(name, item.profileID)
  }

  return <Column grow style={{ borderRadius: "4px", overflow: "hidden" }}>
    <UniversalDrop className="column black grow click" onDrop={onDrop}>
      {Network.host ? <Row className="background">
        <Button small title="Add a new category" placement="bottom" onClick={addCategory}><PlusSquareFilled /></Button>
        <Row grow center>
          <Button small title="Reset all to active" placement="bottom" onClick={allActive}>
            <CheckSquareFilled className="text-blue" />
          </Button>
          <Button small title="Reset all to clear" placement="bottom" onClick={allClear}>
            <CheckSquareFilled />
          </Button>
        </Row>
        <Button small title={meta.revealed ? "Revealed" : "GM Only"} onClick={toggleReveal}>
          {meta.revealed ? <UnlockFilled /> : <a className="text-blue"><LockFilled /></a>}
        </Button>
        <Button small title="Remove tracker" onClick={removeTracker}>
          <DeleteFilled color="error" />
        </Button>
      </Row> : null}

      <Scroll hiddenX ScrollY>
        <Column className={Network.host ? "pointer" : undefined} onClick={Network.host ? addCategory : undefined} fit>
          {children.length ? children : <Column className={Network.host ? "clickable" : undefined} center fitY fitX><i className="size-small">No Groups</i></Column>}
        </Column>
      </Scroll>
      {/* {Network.host ? <Row wrap between className="pad-h black">
        <Button variant="text" secondary title="Add a new category" placement="bottom" onClick={addCategory}>Add</Button>
        <Button variant="text" secondary title="Sort Numerically" selected={tracker.meta.sort === "number"} placement="bottom" onClick={sortNumeric}>
          <UnorderedListOutlined />
        </Button>
        <Button variant="text" secondary title="Sort Alphabetically" selected={tracker.meta.sort === "alpha"} placement="bottom" onClick={sortAlpha}>
          <SortAscendingOutlined />
        </Button>
        <Button variant="text" secondary title="Reset all to active" placement="bottom" onClick={allActive}>
          ⬜
      </Button>
        <Button variant="text" secondary title="Reset all to clear" placement="bottom" onClick={allClear}>
          ◻️
      </Button>
      </Row> : null} */}
    </UniversalDrop>
  </Column>
}

export function TrackerAdd(opts) {
  const {trackers, tracker, projectID} = useNav()

  const createTrack = e => {
    if (!Network.host)
      return window.notify("Not Permitted")
    const bank = getProject(projectID).getBank().trackers
    window.ask({ title: "Tracker Type", value: Object.keys(bank)[0], choices: Object.keys(bank).map(value=>({value, text: bank[value].name || value })) }, res => {
      const track = Trackers.create()
      track.meta.revealed = !bank[res].gmOnly
      track.meta.sort = bank[res].sort
      track.meta.ascending = bank[res].ascending
      track.meta.enter = bank[res].enter
      track.meta.exit = bank[res].exit
      track.meta.counter = bank[res].counter
      track.meta.name = (bank[res].name || res).toUpperCase()
      Nav.trackers.push(track.uid)
      Nav.emit("change", "trackers")
      Nav.setTracker(track.uid)
      Network.broadcast("trackers", JSON.stringify(Nav.trackers))
    })
  }
  //<Badge badgeContent={<div className="beta fa fa-flask" title="Lab Feature" />}></Badge>
  return <Row wrap>
    {trackers.map((tID, i) => <Button key={i} small childClass="grow" className="card-bg" style={{ display: !Trackers.get(tID).meta.revealed && !Network.host ? "none" : "" }} selected={tracker === tID} onClick={e => Nav.toggleTracker(tID)}>{Trackers.get(tID).meta.name}</Button>)}
    {Network.host && <Button small className="card-bg" title="Add Tracker" onClick={createTrack}>
      <PlusSquareFilled />
    </Button>}
  </Row>
}

export function TrackerApp(opts) {
  const { tracker} = useNav()

  return tracker ? <TrackerProvider trackerID={tracker}>
    <Tracker />
  </TrackerProvider> : <div/>
}

export default opts => {
  const {trackers, tracker} = useNav()

  const createTrack = e => {
    if (!Network.host)
      return window.notify("Not Permitted")

    window.ask({ title: "Tracker Name" }, res => {
      const track = Trackers.create()
      track.meta.name = res.toUpperCase()
      Nav.trackers.push(track.uid)
      Nav.emit("change", "trackers")
      Nav.setTracker(track.uid)
      Network.broadcast("trackers", JSON.stringify(Nav.trackers))
    })
  }
  //<Badge badgeContent={<div className="beta fa fa-flask" title="Lab Feature" />}></Badge>
  return <Column grow>
    <Row reverse click>
      {Network.host && <Button className="card-bg" title="Add Tracker" onClick={createTrack}>
        <PlusSquareFilled />
      </Button>}
      {trackers.map((tID, i) => <Button key={i} childClass="grow" className="card-bg" style={{ display: !Trackers.get(tID).meta.revealed && !Network.host ? "none" : "" }} selected={tracker === tID} onClick={e => Nav.toggleTracker(tID)}>{Trackers.get(tID).meta.name}</Button>)}
    </Row>
    {tracker && <TrackerProvider trackerID={tracker}>
      <Tracker />
    </TrackerProvider>}
  </Column>
}