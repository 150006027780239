import Serial from '../../core/models/Serial'

export default class Resource extends Serial {
  get instanceID() {
    return String(this.uid).split("-temp-")[0]
  }

  get temp() {
    return String(this.uid).match("-temp-")
  }

  get local() {
    return String(this.uid).match("-local-")
  }
}