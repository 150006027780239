import Serial from '../../core/models/Serial'

export default class List extends Serial {
  constructor(root, hasRight) {
    super()
    this.root = root
    this.list = []
    this.bookmarks = []
    this.hasRight = hasRight
    this.setMaxListeners(Infinity) // potentially large # of folders to be displayed
    this.rebuild()
  }

  remove(id) {
    const scan = folder => {
      const folderIndex = folder.findData(id)
      if (folderIndex >= 0) {
        // const removeFolder = folder.children[folderIndex]
        // loop through the children and add them to me
        folder.removeData(id)
        this.rebuild()
      }
      else
        for (const child of folder.children)
          scan(child)
    }
    scan(this.root)
  }

  size() {
    return this.list.length
  }

  at(index) {
    return this.list[index]
  }

  toggleBookmark(id, quiet) {
    if (this.bookmarks.includes(id))
      this.removeBookmark(id, quiet)
    else
      this.bookmark(id, quiet)
  }

  pushBookmark(id, quiet) {
    this.removeBookmark(id) // prevents duplicates
    this.bookmarks.push(id)
    return quiet ? false : this.emit("change")
  }

  removeBookmark(id, quiet) {
    for (let i in this.bookmarks)
      if (this.bookmarks[i] === id) {
        this.bookmarks.splice(i, 1)
        if (id === this.bookmark) {
          delete this.bookmark
          this.rebuild()
        }
        return quiet ? false : this.emit("change")
      }
  }

  lookupBookmark(uid) {
    return this.lookupFolder(uid).data
  }

  lookupFolder(uid) {
    const search = (path, uid) => {
      var result = false
      const parent = path === "root" ? this.root : this.root.lookup(path)
      for (const index in parent.children) {
        const folder = parent.children[index]
        const childPath = `${path}_${index}`
        if (folder.uid === uid)
          result = folder
        else
          result = search(childPath, uid)
        if (result)
          break
      }
      return result
    }

    return search("root", uid)
  }

  find(uid) {
    const search = (path, uid) => {
      var result = false
      const parent = path === "root" ? this.root : this.root.lookup(path)
      for (const index in parent.children) {
        const folder = parent.children[index]
        const childPath = `${path}_${index}`
        if (folder.uid === uid)
          result = childPath
        else
          result = search(childPath, uid)
        if (result)
          break
      }
      return result
    }

    return search("root", uid)
  }


  findProfile(data) {
    const search = (path, data) => {
      var result = false
      const parent = path === "root" ? this.root : this.root.lookup(path)
      for (const index in parent.children) {
        const folder = parent.children[index]
        const childPath = `${path}_${index}`
        if (folder.data === data)
          result = childPath
        else
          result = search(childPath, data)
        if (result)
          break
      }
      return result
    }

    return search("root", data)
  }

  setBookmark(index) {
    this.bookmark = index
    this.rebuild()
  }

  rebuild(quiet) {
    let list = []

    // find a bookmark
    const search = (path, uid) => {
      var result = false
      const parent = path === "root" ? this.root : this.root.lookup(path)
      // if (parent.isOpen())
      for (const index in parent.children) {
        const folder = parent.children[index]
        const childPath = `${path}_${index}`
        if (folder.uid === uid) {
          list.push(childPath)
          return childPath
        }
        else
          result = search(childPath, uid)

        if (result)
          break
      }
      return result
    }
    const newRoot = (this.bookmark ? search("root", this.bookmark) : null)

    const process = path => {
      const parent = path === "root" ? this.root : this.root.lookup(path)
      if (parent.isOpen() || path === "root")
        parent.children.forEach((folder, index) => {
          const childPath = `${path}_${index}`
          if (!this.hasRight || this.hasRight(folder.data, "SEE")) {
            list.push(childPath)
            process(childPath)
          }
        })
    }
    process(newRoot || "root")
    this.list = list
    if (!quiet)
      this.emit("rebuild")
  }

  pack() {
    return { root: this.root.pack(), bookmarks: this.bookmarks }
  }

  serialize() {
    return JSON.stringify(this.pack())
  }

  load(json) {
    this.bookmarks = json.bookmarks
    this.root.deserialize(JSON.stringify(json.root))
    this.rebuild()
  }

  deserialize(rawJSON) {
    this.load(JSON.parse(rawJSON))
  }
}