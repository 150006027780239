import React, { useState, useEffect, useRef } from 'react'

import { Dropdown, Menu } from 'antd'
import { PictureFilled } from '@ant-design/icons'
import { Column, Button, Div, Row } from '../UI'
import URLDrop from '../core/wrappers/URLDrop'
import Nav from './state/Navigation'
import useAccount from "../core/hooks/useAccount"

import Logs from './state/Logs'
import Network from '../core/state/Network'
import Resources from './state/Resources'

import useEvents from '../core/hooks/useEvents'
import useNav from './hooks/useNav'
import Media from './Media'
import MediaPlayer from './MediaPlayer'
import SyncMedia from './state/SyncMedia'
import UniversalDrop from "../core/wrappers/UniversalDrop"
import { getProject } from "./hooks/useProject"

export default ({ mobile }) => {
  const [menu, setMenu] = useState(true)
  const [newMedia, setNewMedia] = useState()
  const [media, setMedia] = useState()
  const [host, setHost] = useState(Network.host)
  const [refresh, setRefresh] = useState()
  const { loaded, uid: authed } = useAccount()
  const { projectID, slideshow } = useNav()

  const mediaRef = useRef()

  const closeMenu = e => { Nav.setSlideShow(false); e.stopPropagation() }

  const shareMedia = media => {
    // const img = new Image()
    // img.src = media
    // img.onload = function () {
    if (media instanceof Object)
      Logs.state.theater = media
    else
      Logs.state.theater = { src: media }

    Logs.emit("change")
    if (Network.host)
      Network.broadcast("theater", JSON.stringify(Logs.state))
    // }
    // else 
    //   Network.propose("theater", JSON.stringify(Project.state))
  }

  function change() {
    if (Logs.state.theater?.src)
      setNewMedia(Logs.state.theater)
    else {
      setNewMedia(null)
      setMedia()
    }
  }
  useEvents("change", change, Logs)
  useEvents("change", e => setHost(Network?.host), Network)

  const onDrop = (p, m, c) => {
    const item = m.getItem()
    if (Network.host)
      if (item.urls)
        shareMedia(item.urls[0])
      else if (item.files) {
        // console.log(item.files)
        const file = item.files[0]
        if (file.size <= 1024 * 1024 * 0.5) {
          var reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = function () {
            // console.log(this.result)
            shareMedia(this.result)
          }
          reader.onerror = function (error) {
            console.log('Error: ', error)
          }
        }
        else
          window.warning("File too large")
      }
      else
        window.warning("Only the host can share images")
  }

  const onDropUni = (p, m, c) => {
    const item = m.getItem()
    if (Network.host)
      if (item.url)
        shareMedia(item.url)
      else if (item.profileID) {
        shareMedia(Resources.Profiles.get(item.profileID).getBanner().src)
      }
      else
        window.warning("Only the host can share images")
  }

  useEffect(() => {
    // Memory leak, wrap into usecallback
    const onPaste = e => {
      const src = e && e.clipboardData.getData('text')
      const domains = ["youtu", "soundcloud", "twitch.tv", "facebook", "streamable", "wistia", "dailymotion", "mixcloud", "vidyard"]
      if (src && document.activeElement.tagName === "BODY" && Nav.project && (!Nav.wiki || !(Nav.compendium && (Nav.homepage || Nav.profile))))
        shareMedia({src: src.split("&")[0], fit: "contain", sync: domains.map(s=>src.match(s) ? true : false).filter(e=>(e)).length ? true : false})
    }

    window.addEventListener("paste", onPaste)
    return function(){
      window.removeEventListener("paste", onPaste)
    }
  }, [])
  
  useEffect(()=>{
    if (newMedia?.src !== media?.src) {
      setMedia(newMedia)
      setRefresh()
      setTimeout(()=>setRefresh(true), 100) // forces an animation
    }
  }, [media, newMedia])

  if (!projectID)
    return <Column fit/>
    
  if (newMedia?.sync)
    SyncMedia.url = newMedia?.src

  const bg = getProject(projectID).getBackground()

  const hasMedia = media ?? newMedia
  const slide = <Div absolute className={hasMedia ? "visible" : "invisible"} fit style={{transition: refresh ? "bottom 0.25s, left 0.25s, opacity 0.1s" : undefined, bottom: refresh ? "0" : "-100vh"}}>
    {newMedia?.sync ? <Div style={{ width: "75%", height: "75%", marginBottom: "10%" }}><MediaPlayer src={newMedia.src} /></Div> : <Media poster={newMedia?.src.replace(".mp4", ".png")} autoPlay muted loop src={newMedia?.src} alt="" style={{width: "100vw", maxWidth: "100vw", height: "100vh", objectFit: newMedia?.fit, objectPosition: "center center"}} />}
    {/* <video autoPlay muted loop src="./vido.mp4" alt="" style={{width: "100vw", maxWidth: "100vw", height: "100vh", objectFit: "cover", objectPosition: "center center"}} /> */}
  </Div>

  const placeholder = <Div absolute className={media ? "visible" : "invisible"} fit>
    {!media?.sync && <Media muted src={media?.src} alt="" style={{width: "100vw", maxWidth: "100vw", height: "100vh", objectFit: media?.fit, objectPosition: "center center"}} />}
  </Div>

  return <Column fit relative className={`no-click ${menu ? "visible pointer" : "invisible"}`}>
    <URLDrop onDrop={onDrop} className={Network.host ? "grow overflow-hidden" : "grow overflow-hidden"}>
      <UniversalDrop onDrop={onDropUni} className="fit-x fit-y click">
        <Column fit className="text-white">
          {media && media?.src !== newMedia?.src && placeholder}
          {slide}
          {Network.host ? <div className={`${hasMedia ? "row-reverse" : "column"} fit-xy`}/> : null}
        </Column>
      </UniversalDrop>
    </URLDrop>
    {/* </FileDrop> */}
    <Row bottom absolute fitX center onClick={closeMenu}>
      <Row className="pad-v-l">
        {host && menu && <Row>
          <Dropdown trigger="click" overlay={<Menu>
            {/* <Menu.Item onClick={e => window.ask({title: 'Enter URL (Youtube/SoundCloud/Facebook/Twitch)'}, res=>shareMedia({src: res, sync: true}))}>Sync Player</Menu.Item> */}
            <Menu.SubMenu title="Cover">
              <Menu.Item onClick={e => window.ask({ type: "file" }, res => shareMedia({src: res.src, fit: "cover"}))}>File</Menu.Item>
              <Menu.Item onClick={e => window.ask({title: 'Enter URL'}, res=>shareMedia({src: res, fit: "cover"}))}>URL</Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu title="Contain">
              <Menu.Item onClick={e => window.ask({ type: "file" }, res => shareMedia({src: res.src, fit: "contain"}))}>File</Menu.Item>
              <Menu.Item onClick={e => window.ask({title: 'Enter URL'}, res=>shareMedia({src: res, fit: "contain"}))}>URL</Menu.Item>
            </Menu.SubMenu>
            {Network.host && <Menu.Item onClick={e=>{
              getProject(projectID).setBackground(newMedia)
              window.success("Project Wallpaper updated")
            }}>Save as Wallpaper</Menu.Item>}
            {hasMedia && Network.host && <Menu.Item onClick={e => shareMedia()}>Empty</Menu.Item>}
          </Menu>}>
            <Button className="card-bg rounded gaps-h click">
              <PictureFilled/>
            </Button>
          </Dropdown>
          {hasMedia && Network.host && JSON.stringify(bg) !== JSON.stringify(newMedia) && <Button className="card-bg rounded gaps-h click" onClick={e => { shareMedia(getProject(projectID).getBackground()); e.stopPropagation() }}>Clear</Button>}
          {hasMedia && Network.host && <Button className="card-bg rounded gaps-h click" onClick={e => Network.broadcast("navigation", JSON.stringify({forceToScreen: true}))}>Force Watch</Button>}
          {/* <Button style={{ color: 'rgba(255,255,255,0.87)' }} endIcon={<ImageIcon />}>Upload</Button> */}
          {/* <Button className="white-subtitle" endIcon={<Input />}>Drop</Button> */}
        </Row>}
        {/* {host ? <Button className="card-bg rounded gaps-h" onClick={closeMenu} title="Exit"><CloseOutlined/></Button> : null} */}
      </Row>
    </Row>
  </Column>
}