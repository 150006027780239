import React, { useState } from 'react'
import { Editor, Transforms } from 'slate'
import { useSlate, useFocused } from 'slate-react'

import { Dropdown, Menu } from 'antd'
import {
  TableOutlined,
} from '@ant-design/icons'

// import Button from './ToolbarButton'
import { Button, Row } from '../../../UI'

import rfdc from 'rfdc'
import { getProject, hasPermission } from "../../hooks/useProject"
import Context from "../../util/Context"
import Resources from "../../state/Resources"
import useProfile from "../../hooks/useProfile"
import useNav from "../../hooks/useNav"
import Network from "../../../core/state/Network"
import Logs from '../../state/Logs'
import { EventBuilder } from "../../Events"

const copy = rfdc()

const toggleFormat = (editor, format, e) => {
  e.preventDefault()
  const isActive = isFormatActive(editor, format)
  Transforms.setNodes(
    editor,
    { [format]: isActive ? null : true },
    { match: editor.isText, split: true }
  )
}

const isFormatActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n[format] === true,
    mode: 'all',
  })
  return !!match
}

export default ({ menus }) => {
  const editor = useSlate()
  const { profileID, permission } = useProfile()
  const { projectID } = useNav()
  const { table, row, cell } = menus

  const removeColumn = e => {
    const columnIndex = cell[1].reverse()[0]
    if (table) {
      const path = [...table[1]]
      const workingObject = copy(table[0])
      for (const row of workingObject.children)
        row.children.splice(columnIndex, 1)

      Transforms.removeNodes(editor, { at: path })
      Transforms.insertNodes(editor, workingObject, { at: path })
    }
  }

  const addColumn = after => {
    const columnIndex = cell[1].reverse()[0]
    if (table) {
      const path = [...table[1]]
      const workingObject = copy(table[0])
      for (const row of workingObject.children)
        row.children.splice(after ? columnIndex + 1 : columnIndex, 0, { type: "cell", children: [{ type: "paragraph", children: [{ text: "" }] }] })

      Transforms.removeNodes(editor, { at: path })
      Transforms.insertNodes(editor, workingObject, { at: path })
    }
  }

  const addRow = above => {
    const rowIndex = row[1].reverse()[0]
    if (table) {
      const path = [...table[1]]
      const workingObject = copy(table[0])
      const columnCount = workingObject.children[0].children.length

      const row = { type: "row", children: [] }
      for (let j = 0; j < Math.min(12, columnCount); j++)
        row.children.push({ type: "cell", children: [{ type: "paragraph", children: [{ text: "" }] }] })
      workingObject.children.splice(above ? rowIndex : rowIndex + 1, 0, row)

      Transforms.removeNodes(editor, { at: path })
      Transforms.insertNodes(editor, workingObject, { at: path })
    }
  }

  const removeRow = e => {
    const rowIndex = row[1].reverse()[0]
    if (table) {
      const path = [...table[1]]
      const workingObject = copy(table[0])
      workingObject.children.splice(rowIndex, 1)

      Transforms.removeNodes(editor, { at: path })
      Transforms.insertNodes(editor, workingObject, { at: path })
    }
  }

  const setColumns = e => {
    if (table) {
      const path = [...table[1]]
      const workingObject = copy(table[0])
      const len = workingObject.children[0].children.length
      window.ask({ title: "How many columns", value: len }, res => {
        const int = parseInt(res)

        for (const row of workingObject.children) {
          const len = row.children.length
          if (len > int)
            row.children.splice(len - (len - int), len - int)
          else if (len < 12)
            for (let i = 0; i < Math.min(12, int - len); i++)
              row.children.push({ type: "cell", children: [{ type: "paragraph", children: [{ text: "" }] }] })
        }

        Transforms.removeNodes(editor, { at: path })
        Transforms.insertNodes(editor, workingObject, { at: path })
      })
    }
    e.preventDefault()
  }
  
  const setColumnWidth = e => {
    if (table) {
      const path = [...table[1]]
      const workingObject = copy(table[0])
      const cellIndex = cell[1][cell[1].length-1]
      window.ask({ title: "Enter width (in % or pixels)", allowEmpty: true }, res => {
        let rowIndex = 0
        for (const row of workingObject.children)
          Transforms.setNodes(
            editor,
            { width: (res === undefined || res === null) ? "auto" : res },
            { at: [...path, rowIndex++, cellIndex] }
          )
      })
    }
    e.preventDefault()
  }

  const setRows = e => {
    if (table) {
      const path = [...table[1]]
      const workingObject = copy(table[0])
      const len = workingObject.children.length
      window.ask({ title: "How many rows", value: len }, res => {
        const columnCount = workingObject.children[0].children.length
        const int = parseInt(res)
        if (len > int)
          workingObject.children.splice(len - (len - int), len - int)
        else
          for (let i = 0; i < (int - len); i++) {
            const row = { type: "row", children: [] }
            for (let j = 0; j < Math.min(12, columnCount); j++)
              row.children.push({ type: "cell", children: [{ type: "paragraph", children: [{ text: "" }] }] })
            workingObject.children.push(row)
          }

        Transforms.removeNodes(editor, { at: path })
        Transforms.insertNodes(editor, workingObject, { at: path })
      })
    }

    e.preventDefault()
  }

  const deleteTable = e => window.ask({type: "confirm", title: "Delete Table"}, e=>Transforms.delete(editor, { at: table[1] }))


  const element = table ? table[0] : {}
  const rollTable = gm => window.ask({title: "Enter roll equation", value: element.die}, res=>{
    const path = [...table[1]]
    let rowID = 0
    const r = Context.roll(res, {})
    for (const row of element.children) {
      const header = Editor.string(editor, [...path, rowID, 0])
      // if (rowID === 0) {
      //   const dice = /([\d]*)d([\d]+)/i
      //   const die = header.match(dice)
      //   console.log(dice[1])
      // }
      // else {
      const reg = /([\d]+)(-([\d]+))?/i
      const match = header.match(reg)
      if (match) 
        if ((match[1] == Number(r.equation) && (match[3] == null || match[3] == undefined)) || (Number(r.equation) >= match[1] && Number(r.equation) <= match[3])) {
          const l = element.children[rowID].children.length-1
          const data = element.children[rowID].children[l].children

          const event = Resources.Events.create()
          const profile = Resources.Profiles.get(profileID)
          let source = {}
          if (profile) {
            source = profile.getContext()
            event.meta.sourceID = profileID
          }
      
          const context = { source, target: {} }

          event.meta.gmRoll = gm
          event.roll(element.context || "", context).then(e => {
            if (profile) {
              for (const key in event.source)
                profile.changeData(key, event.eval(event.source[key], context))
      
              profile.emit("change")
            }
            event.text = [...event.text, ...data]
            event.record()
          })
          break
        }
      rowID++
    }
  })
  
  const saveRollTable = e=> window.ask({title: "Enter a key for the table (display name can be changed from project settings)"}, res=>{
    const path = [...table[1]]
    let rowID = 0
    const ranges = {}

    for (const row of element.children) {
      const header = Editor.string(editor, [...path, rowID, 0])
      const reg = /([\d]+)(-([\d]+))?/i
      const match = header.match(reg)
      if (match) {
        const key = `${match[1]}${match[3] ? ("-"+match[3]) : ""}`
        const l = element.children[rowID].children.length-1
        const data = element.children[rowID].children[l].children
        ranges[key] = copy(data)
      }
      rowID++
    }
    const key = String(res).toLowerCase()
    const project = getProject(projectID)
    if (project.rollTables[key]) {
      window.close()
      window.ask({title: "Would you like to update the existing table", type: "confirm"}, res=>{
        project.rollTables[key] = { ...(project.rollTables[key] || {}), die: element.die, ranges, script: element.context}
        project.emit("changed")
        window.success("Table Saved")
      })
    }
    else {
      project.rollTables[key] = { ...(project.rollTables[key] || {}), die: element.die, ranges, script: element.context}
      project.emit("changed")
      window.success("Table Saved")
    }
  })

  const makeRollTable = e=> window.ask({title: "Enter die to roll for table"}, res=>{
    const path = [...table[1]]
    Transforms.setNodes(editor, {die: res}, {at: path})
  })

  const unmakeRollTable = e=>{
    const path = [...table[1]]
    Transforms.setNodes(editor, {die: undefined}, {at: path})
  }

  const setContext = e=> window.build({title: "Change Event", width: "85vw", bodyStyle: {padding: 0}, style: {height: "85vh"}, app: EventBuilder, props: {defaultValue: element.context, onFinalize: res=>{
    const path = [...table[1]]
    Transforms.setNodes(editor, {context: res}, {at: path})
    window.close()
  }}})


  return table ? <Row className={`${table ? "visible click" : "invisible no-click"}`} style={{boxShadow: "0px 0px 4px rgba(0,0,0,0.7)"}}>
    <Row center className="black rounded pad">
      <TableOutlined />
    </Row>
    <Button small secondary className="foreground" childClass="grow" style={{boxShadow: "inset 0px 0px 2px black"}} onMouseDown={deleteTable} title="Delete Table">
      Delete
    </Button>
    <Dropdown overlay={<Menu>
      <Menu.Item onMouseDown={e => addColumn()}>Add Left</Menu.Item>
      <Menu.Item onMouseDown={e => removeColumn()}>Delete Column</Menu.Item>
      <Menu.Item onMouseDown={e => addColumn(true)}>Add Right</Menu.Item>
      <Menu.Item onMouseDown={setColumns}>Set Columns</Menu.Item>
      <Menu.Item onMouseDown={setColumnWidth}>Set Column Width</Menu.Item>
    </Menu>
    }>
      <Button small secondary className="foreground" childClass="grow" style={{boxShadow: "inset 0px 0px 2px black"}} color="inherit">
        Columns
      </Button>
    </Dropdown>

    <Dropdown overlay={<Menu>
      <Menu.Item onMouseDown={e => addRow(true)}>Add Above</Menu.Item>
      <Menu.Item onMouseDown={e => removeRow()}>Delete Row</Menu.Item>
      <Menu.Item onMouseDown={e => addRow()}>Add Below</Menu.Item>
      <Menu.Item onMouseDown={setRows}>Set Rows</Menu.Item>
    </Menu>
    }>
      <Button small secondary className="foreground" childClass="grow" style={{boxShadow: "inset 0px 0px 2px black"}} color="inherit">
        Rows
      </Button>
    </Dropdown>
    <Dropdown overlay={<Menu>
      {profileID && hasPermission(permission, "CHANGE") ? <Menu.Item key="rolltable" onMouseDown={makeRollTable}>Make Rollable Table</Menu.Item> : undefined}
      {profileID && element.die && Network.host ? <Menu.Item key="rolltable" onMouseDown={saveRollTable}>Save Rollable Table</Menu.Item> : undefined}
      {profileID && hasPermission(permission, "CHANGE") && element.die ? <Menu.Item key="context" onMouseDown={setContext}>Edit Event</Menu.Item> : undefined}
      {profileID && hasPermission(permission, "CHANGE") && element.die ? <Menu.Item key="normaltbl" onMouseDown={unmakeRollTable}>Make Normal Table</Menu.Item> : undefined}
    </Menu>}>
      <Button small secondary className="foreground" childClass="grow" style={{boxShadow: "inset 0px 0px 2px black"}} color="inherit">
        Roll Table
      </Button>
    </Dropdown>
  </Row> : null
}