import Resource from './Resource'
import Account from '../../core/state/Account'
import { v4 } from 'uuid'

export default class Wizard extends Resource {
  steps = []

  constructor(ctx = {}) {
    super(ctx)

    this._id = ctx._id
    this.uid = ctx.uid || v4()
    this.meta = { name: ctx.name, creator: ctx.creator || Account.uid, created: Date.now() }
     
    this.setMaxListeners(Infinity) // Potentially thousands of references due to wiki
  }

  setFormat(profileID) {
    this.format = profileID
    this.emit("change")
  }

  
  setScript(profileID) {
    this.script = profileID
    this.emit("change")
  }

  addStep(data) {
    this.steps = this.steps || []
    this.steps.push({name: data, choices: []})
    this.emit("change")
  }

  setStepName(step, name) {
    this.steps = this.steps || []
    this.steps[step].name = name
    this.emit("change")
  }

  getStepName(step) {
    this.steps = this.steps || []
    return this.steps[step]
  }

  setStepBanner(step, banner) {
    this.steps = this.steps || []
    this.steps[step].banner = banner
    this.emit("change")
  }

  getStepBanner(step) {
    this.steps = this.steps || []
    return this.steps[step].banner
  }
  
  setStepEvent(step, event) {
    this.steps = this.steps || []
    this.steps[step].event = event
    this.emit("change")
  }

  getStepEvent(step) {
    this.steps = this.steps || []
    return this.steps[step].event
  }
  
  removeStep(step) {
    this.steps = this.steps || []
    this.steps.splice(step,1)
    this.emit("change")
  }

  setChoiceName(step, index, data) {      
    this.steps[step].choices = this.steps[step].choices || []
    this.steps[step].choices[index].name = data
    this.emit("change")
  }

  setChoiceCondition(step, index, data) {      
    this.steps[step].choices = this.steps[step].choices || []
    this.steps[step].choices[index].cond = data
    this.emit("change")
  }

  getChoiceCondition(step, index) {      
    this.steps[step].choices = this.steps[step].choices || []
    return this.steps[step].choices[index].cond
  }

  setChoiceBanner(step, index, data) {      
    this.steps[step].choices = this.steps[step].choices || []
    this.steps[step].choices[index].banner = data
    this.emit("change")
  }

  setChoiceEvent(step, index, data) {      
    this.steps[step].choices = this.steps[step].choices || []
    this.steps[step].choices[index].event = data
    this.emit("change")
  }

  setChoiceDescription(step, index, data) {      
    this.steps[step].choices = this.steps[step].choices || []
    this.steps[step].choices[index].desc = data
    this.emit("change")
  }

  addChoice(step, data) {      
    this.steps[step].choices = this.steps[step].choices || []
    this.steps[step].choices.push(data)
    this.emit("change")
  }

  removeChoice(step, choice) {      
    this.steps[step].choices.splice(choice, 1)
    this.emit("change")
  }

  addOption(step, choice, option, data) {
    this.steps[step].choices[choice].options = this.steps[step].choices[choice].options || {}
    this.steps[step].choices[choice].options[option] = this.steps[step].choices[choice].options[option] || []
    this.steps[step].choices[choice].options[option].push(data)
    this.emit("change")
  }  

  removeOption(step, choice, option, index) {
    this.steps[step].choices[choice].options = this.steps[step].choices[choice].options || {}
    this.steps[step].choices[choice].options[option] = this.steps[step].choices[choice].options[option] || []
    this.steps[step].choices[choice].options[option].splice(index, 1)
    this.emit("change")
  }
  
  setOptionEvent(step, choice, option, index, data) {
    this.steps[step].choices[choice].options = this.steps[step].choices[choice].options || {}
    this.steps[step].choices[choice].options[option] = this.steps[step].choices[choice].options[option] || []
    this.steps[step].choices[choice].options[option][index].event = data
    this.emit("change")
  }

  removeOptionChain(step, choice, option) {
    this.steps[step].choices[choice].options = this.steps[step].choices[choice].options || {}
    delete this.steps[step].choices[choice].options[option]
    this.emit("change")
  }
  

  addOptionChain(step, choice, option) {
    this.steps[step].choices[choice].options = this.steps[step].choices[choice].options || {}
    this.steps[step].choices[choice].options[option] = []
    this.emit("change")
  }

  setName(name) {      
    this.meta.name = name
    this.emit("change")
  }

  pack() {
    return {
      _id: this._id,
      uid: this.uid,
      steps: this.steps,
      format: this.format,
      script: this.script,
      meta: this.meta || {}
    }
  }

  deserialize(json) {
    this.load(JSON.parse(json))
  }

  load(json) {
    this._id = json._id
    this.uid = json.uid || this.uid
    this.steps = json.steps || this.steps
    this.format = json.format || this.format
    this.script = json.script || this.script
    this.meta = json.meta || this.meta
    this.loaded = true
    this.emit("ready")
  }

  serialize() {
    return JSON.stringify(this.pack())
  }
}