import React, { Component } from 'react'
import { NativeTypes } from 'react-dnd-html5-backend'
import { DropTarget } from 'react-dnd'

const source = {
  hover(props, monitor, component) {
    if (props.onHover) {
      props.onHover(props, monitor, component)
    }
  },
  drop(props, monitor, component) {
    if (monitor.didDrop({ shallow: props.shallow })) {
      // somewhere has already handled the drop
      return
    }
    if (props.onDrop) {
      props.onDrop(props, monitor, component)
    }
  },
}

function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    hovered: monitor.isOver(),
    hoveredCurrent: monitor.isOver({ shallow: true }),
    item: monitor.getItem()
  }
}

class URLDrop extends Component {
  componentDidUpdate(prevProps) {
    if (!prevProps.hoveredCurrent && this.props.hoveredCurrent) {
      // You can use this as enter handler
      if (this.props.onEnter) {
        this.props.onEnter(this.props.item, prevProps.ref)
      }
    }

    if (prevProps.hoveredCurrent && !this.props.hoveredCurrent) {
      // You can use this as leave handler
      if (this.props.onLeave) {
        this.props.onLeave(prevProps.item, prevProps.ref)
      }
    }
  }

  render() {
    const { hoveredCurrent, onDrop, dropColor, style, connectDropTarget, className } = this.props

    const background = hoveredCurrent && onDrop ? (dropColor || "lightblue") : "transparent"

    const baseStyle = { ...style, background }

    return connectDropTarget(
      <div className={className} style={baseStyle}>
        {this.props.children}
      </div>
    )
  }
}

export default DropTarget(NativeTypes.URL, source, collect)(URLDrop)
