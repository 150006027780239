import React, { useEffect, useRef, useState } from 'react'
import { Row, Column, Div, Button } from "../UI"

import {
  CommentOutlined,
  FolderAddOutlined,
  EditFilled,
  SettingFilled,
  UserOutlined,
  MenuOutlined,
  MessageFilled,
  SearchOutlined,
  ThunderboltFilled,
  TeamOutlined,
  ProfileFilled,
  CustomerServiceFilled,
  ReadFilled,
  WarningFilled,
  YoutubeFilled
} from '@ant-design/icons'
import Nav from './state/Navigation'
import { ChatEntry, ChatList, DiceRoller } from "./slate/ChatApp"
import useNav from "./hooks/useNav"
import { Dropdown, Menu, Switch } from "antd"
import { PlayerList, LobbyButton } from "./Players"
import Trackers, {TrackerAdd, TrackerApp} from './Trackers'
import SignIn from "../core/SignIn"
import useProject, { getProject, ProjectProvider } from "./hooks/useProject"

import Resources from './state/Resources'
import UniversalDrag from "../core/wrappers/UniversalDrag"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EventBuilder from "./Events"
import { createEvent } from "./hooks/useEvent"
import { ProjectSettings } from "./Projects"
import MediaPlayer, { WrappedVid } from "./MediaPlayer"
import Logs from './state/Logs'
import SyncMedia from './state/SyncMedia'

const { Profiles, Events } = Resources

export function Actions(opts) {
  const {profileID, projectID} = useNav()

  const bank = getProject(projectID).getEventBank()

  let cats = {}
  for (const key in bank) {
    const e = bank[key]
    if (e && e.category) {
      cats[e.category] = cats[e.category] || []
      cats[e.category].push(key)
    }
  }

  const execute = (eventID, prefix, profileID, gm) => {
    const event = Events.create()
    const profile = Profiles.get(profileID)
    let source = {}
    if (profile && profileID) {
      source = profile.getContext()
      event.meta.sourceID = profileID
    }

    const context = { source, target: {} }

    let script = prefix || ""
    if (eventID && bank[eventID])
      script += `\n${bank[eventID].script}`

    event.meta.gmRoll = gm
    event.roll(script, context).then(e => {
      if (profile) {
        for (const key in event.source)
          profile.changeData(key, event.eval(event.source[key], context))

        profile.emit("change")
      }
      event.record()
    })
  }

  const children = []
  let i = 1
  for (const key in cats)
    children.push(<Button key={i} children={key} small />)

  return <Row>
    {Object.keys(cats).map((catKey, i) => <Dropdown overlay={<Menu>
      {cats[catKey].map((v,j)=>  <Menu.SubMenu onTitleClick={e => execute(v, "", profileID)} key={v+j} title={<UniversalDrag style={{display: "inline-block"}} item={{script: bank[v].script, profileID}}>{bank[v].mask || v}</UniversalDrag>}>
          <Menu.Item onClick={e => execute(v, "", profileID, true)}>
            <UniversalDrag style={{display: "inline-block"}} item={{script: bank[v].script, profileID, gmRoll: true}}>
              GM
            </UniversalDrag>
          </Menu.Item>
        </Menu.SubMenu>)}
      </Menu>}>
      <Button small className="card-bg rounded" key={catKey+i} childStyle={{ border: "1px solid #222" }}>
        {(bank[catKey]?.mask || catKey)}
      </Button>
    </Dropdown>)}
  </Row>
}

export function BottomApp(opts) {
  const { sceneID, mapID, hotbar, apps, projectID: navProjectID, editing } = useNav()
  const { projectID } = useProject()
  const [browsing, setBrowsing] = useState()
  const [roller, setRoller] = useState()
  const [builderOpen, setBuilderOpen] = useState()
  const minimized = editing

  const fresh = getProject(projectID).profiles.length === 0 && navProjectID === projectID

  const appLib = {
    // Players: {
    //   i: TeamOutlined,
    //   render: props=> <Row reverse>
    //     <PlayerList />
    //   </Row>
    // },
    Trackers: {
      i: CustomerServiceFilled,
      render: props => <Row grow reverse>
        <Trackers />
      </Row>,
    },
    // Music: {
    //   i: CustomerServiceFilled,
    // },
    
    // Chat: {
    //   i: MessageFilled,
    //   render: props => <Column reverse grow>
    //     <Column grow>
    //       <ChatList browsing={browsing} />
    //       <Row reverse style={{ marginTop: "0.5rem" }}/>
    //       <Row className="card-bg" click>
    //         <Button center childClass="grow" selected={roller} className="card-bg" onClick={e => setRoller(!roller)}>
    //           <FontAwesomeIcon icon={['fas', "dice"]} />
    //         </Button>
    //         {roller ? <Row grow className="card-bg" click><DiceRoller/></Row> : <ChatEntry style={{ border: "1px solid transparent", boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.7)" }} />}
    //         <Button center childClass="grow" onClick={e => setBrowsing(!browsing)}>
    //           <ProfileFilled />
    //         </Button>
    //       </Row>
    //     </Column>
    //   </Column>
    // },
    // Search: {
    //   i: SearchOutlined,
    // },
    // Settings: {
    //   i: SettingFilled,
    // },
    // Trackers: {
    //   i: ProfileFilled,
    // },
  }

  function renderIcon(key) {
    const ICON = appLib[key].i
    return <ICON />
  }

  const dropmenu = <Menu onClick={({ key, domEvent }) => {
    if (key === "Change Project")
      return
  }}>
    <Menu.Item key="Change Project" onClick={e=>{
      Nav.setProject()
      delete Nav.profile 
      delete Nav.map
      delete Nav.scene
      delete Nav.focus
      Nav.setBrowser(true)
      e.domEvent.stopPropagation()
    }}>Change Project</Menu.Item>
    <Menu.Item>
      <a href="https://discord.gg/ZfFRfKPqcg" target="_" style={{ color: "inherit" }}>Discord</a>
    </Menu.Item>
    {editing && <Menu.Item key="Stop Editing" onClick={e=>{
      Nav.toggleEditing(true)
    }}>Stop Editing</Menu.Item>}
  </Menu>

  useEffect(()=>{
    window.$sleek=[]
    window.SLEEK_PRODUCT_ID=438857337
    var d,s
    (function(){
      d=document
      s=d.createElement("script")
      s.src="https://client.sleekplan.com/sdk/e.js"
      s.async=1
      d.getElementsByTagName("head")[0].appendChild(s)
    })()
  }, [])

  const onFinalize = script=>{
    const {event, context} = createEvent({})
    event.roll(script, context).then(e=>event.record())
    setBuilderOpen()
  }

  return <ProjectProvider projectID={projectID}>
    <Column grow>
      {!fresh && apps && Object.keys(appLib).map(k=> (apps[k] && appLib[k].render) ? appLib[k].render() : <div />)}
      <Column relative grow>
        <Column center>
          <WrappedVid height="200px" />
        </Column>
        {!minimized &&<Column grow relative style={{zIndex: -1}}>
          <Column fit absolute>
            <ChatList browsing={browsing} />
          </Column>
          <Column fit absolute>
            <TrackerApp />
          </Column>
        </Column>}
        <Row reverse style={{ marginTop: "0.5rem" }}>
          <Row click>
            {projectID && !minimized && <TrackerAdd />}
          </Row>
        </Row>
        <Row reverse>
          <Column grow={projectID && !minimized ? 1 : undefined} className="card-bg rounded">
            {projectID && !minimized && !fresh && <Row click>
              {roller ? <DiceRoller/> : <ChatEntry style={{ border: "1px solid transparent", boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.7)" }} />}
              <Row>
                <Button small center childClass="grow" title="Sync Player" mouseEnterDelay={0.5} onClick={e=>window.ask({title: 'Enter URL (Youtube/SoundCloud/Facebook/Twitch)'}, res=>SyncMedia.setURL(res))}>
                  <YoutubeFilled/>
                </Button>
                <Button small center childClass="grow" selected={roller} title="Dice Roller" mouseEnterDelay={0.5} className="card-bg" onClick={e => setRoller(!roller)}>
                  <FontAwesomeIcon icon={['fas', "dice"]} />
                </Button>
                <Button small childClass="grow" title="Custom Event" mouseEnterDelay={0.5} onClick={e=>setBuilderOpen(true)}>
                  <WarningFilled />
                </Button>
                <Button small center title="Show Chat Logs" childClass="grow" mouseEnterDelay={0.5} onClick={e => setBrowsing(!browsing)}>
                  <ProfileFilled />
                </Button>
              </Row>
            </Row>}
            </Column>
          </Row>
        </Column>
      {projectID && <EventBuilder visible={builderOpen} onClose={e=>setBuilderOpen()} onFinalize={onFinalize}/>}
    </Column>
  </ProjectProvider>
}

function LeftApp(opts) {
  const dropmenu = <Menu onClick={({ key, domEvent }) => {
    if (key === "Change Project")
      return
  }}>
    <Menu.Item key="Change Project" onClick={e=>{
      Nav.setProject()
      delete Nav.profile 
      delete Nav.map
      delete Nav.scene
      Nav.setBrowser(true)
      e.domEvent.stopPropagation()
    }}>Change Project</Menu.Item>
    <Menu.Item>
      <a href="https://discord.gg/ZfFRfKPqcg" target="_" style={{ color: "inherit" }}>Discord</a>
    </Menu.Item>
    <Menu.Item key="Toggle Editing" onClick={e=>{
      Nav.toggleEditing(true)
    }}>Toggle Editing</Menu.Item>
  </Menu>


  return <Column grow>
    <Dropdown placement="bottomRight" trigger="click" overlay={dropmenu}>
      <Button>
        <MenuOutlined />
      </Button>
    </Dropdown>
    <Button onClick={e=>{
      if (!Nav.wiki)
        Nav.compendium = true
      Nav.toggleWiki(true)
    }} placement="right" mouseEnterDelay={0.5} title="Open Project">
      <ReadFilled />
    </Button>
    <Button onClick={e=>Nav.setFocus("theater")} placement="right" mouseEnterDelay={0.5} title="Open Theater">
      <FontAwesomeIcon icon={['fas', "theater-masks"]} />
    </Button>
    <Button onClick={e=>Nav.setFocus("map")} placement="right" mouseEnterDelay={0.5} title="Open Map">
      <FontAwesomeIcon icon={['fas', "map"]} />
    </Button>
    {window.show3D && <Button onClick={e=>delete Nav.wiki && Nav.setFocus("scene")} placement="right" mouseEnterDelay={0.5} title="Open Scene">
      <FontAwesomeIcon icon={['fas', "street-view"]} />
    </Button>}
    <Column grow/>
    <PlayerList className="column"/>
    {/* <Button>
      <CommentOutlined />
    </Button> */}
    <LobbyButton />
    <SignIn/>
  </Column>
}

export function HotMenu(){
  const { projectID } = useNav()
  return <ProjectProvider projectID={projectID}>
    <LeftApp/>
  </ProjectProvider>
}

export default opts => {
  const { projectID } = useNav()
  return <ProjectProvider projectID={projectID}>
    <BottomApp/>
  </ProjectProvider>
}