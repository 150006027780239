import React from 'react'
import { Switch, Checkbox } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { Column, Row, Scroll, Button } from '../UI'
import useNav from './hooks/useNav'
import Nav from './state/Navigation'
import useProject, {getProject, ProjectProvider} from './hooks/useProject'
import useAccount from "../core/hooks/useAccount"
import {ProjectAvatar, ProjectName} from './Projects'
import useContent from "./hooks/useContent"
import Community from './Community'
import { DeleteFilled } from '@ant-design/icons'

const FavoriteItem = ({ selected }) => {
  const { loaded, name, src, targetID: codexID } = useContent()
  const { projectID, meta, codexes } = useProject()

  if (!loaded)
    return <Row><LoadingOutlined/></Row>

  const attach = uid => {
    // if (Object.keys(codexes).length && !Account.isMember()) {
    //   window.notify("Become a Supporter and add multiple codexes to your projects")
    //   return window.login(true)
    // }

    getProject(projectID).attachCodex(uid)
    Nav.loadCodex(uid)
  }

  return <Row between className={`${selected ? "foreground" : "background"} pad-h`} style={{borderBottom: "1px solid #141414"}}>
    <ProjectProvider projectID={codexID}>
      <Row center>
        <ProjectAvatar height={25}/>
        <Row className="pad">
          <ProjectName/>
        </Row>
      </Row>
    </ProjectProvider>
    <Row grow/>
    <Row center className={`${codexes.includes(codexID) ? "visible click" : "no-click invisible"} pad-h`}>
      <Checkbox checked={meta.gmOnly && meta.gmOnly[codexID]} onChange={e => (meta.gmOnly && meta.gmOnly[codexID]) ? getProject(projectID).hideCodex(codexID) : getProject(projectID).revealCodex(codexID)}/>
      <Row center className="pad-h">GM Only</Row>
    </Row>
    <Column center>
      <Switch checked={codexes.includes(codexID)} onChange={e => codexes.includes(codexID) ? getProject(projectID).detachCodex(codexID) : attach(codexID)}/>
    </Column>
  </Row>
}

const CodexApp = ({ style }) => {
  const { uid, loaded: accLoaded, details } = useAccount()
  const { meta, codexes, loaded: projectLoaded} = useProject()
  const { projectID } = useNav()

  const loaded = accLoaded && projectLoaded
  // const [projects, setProjects] = useState([])
  // const [tab, setTab] = useState("projects")

  // useEffect(() => {
  //   const list = [...codexes]
  //   for (const dex of codexes) 
  //     for (const p of projects) 
  //       if (p.uid === dex) {
  //         list.splice(list.indexOf(dex), 1)
  //         break
  //       }
  // }, [codexes, projects])

  // useEffect(()=>{
  //   Account.getAccountDetails()
  //   Nav.getProjects().then(e => {
  //     //TODO: wayyy too many loads
  //     // if (e.data && e.data.length)
  //       // console.log(e.data.map(d=>getProject(d.uid)))
  //     if (e.data.length !== projects.length)
  //       setProjects(e.data.map(d=>(d.uid || d)))
  //   })
  // }, [loaded])

  const attach = uid => {
    // if (Object.keys(getProject(projectID).codexes).length && !Account.isMember()) {
    //   window.notify("Become a Supporter and add multiple codexes to your projects")
    //   return window.login(true)
    // }
    if (uid === projectID)
      return window.error("Can't attach a project to itself")
    getProject(projectID).attachCodex(uid)
    Nav.loadCodex(uid)
  }

  console.log(meta)

  return <Column grow>
    {/* <Row className="black">
      <Button className={tab === "projects" ? "foreground" : "background"} selected={tab === "projects"} style={{border: "1px solid #141414", marginLeft: "0.5rem", marginTop: "0.5rem"}} onClick={e=>setTab("projects")}>Your Projects</Button>
      {!window.isLocal && <Button className={tab === "compendium" ? "foreground" : "background"}  selected={tab === "compendium"} style={{border: "1px solid #141414", marginTop: "0.5rem"}} onClick={e=>setTab("compendium")}>Subscriptions</Button>}
    </Row>
    {tab === "projects" ?
      <Scroll scrollY>
        {projects.map((tile, index) => projectID !== tile ? <Row between className={`${tile.uid === uid ? "foreground" : "background"} pad-h`} style={{borderBottom: "1px solid #141414"}} key={index}>
          <ProjectProvider projectID={tile}>
            <Row center>
              <ProjectAvatar height={25}/>
              <Row className="pad">
                <ProjectName/>
              </Row>
            </Row>
          </ProjectProvider>
          <Row grow/>
          <Row center className={`${codexes.includes(tile) ? "visible click" : "no-click invisible"} pad-h`}>
            <Checkbox checked={meta.gmOnly && meta.gmOnly[tile]} onChange={e => (meta.gmOnly && meta.gmOnly[tile]) ? getProject(projectID).hideCodex(tile) : getProject(projectID).revealCodex(tile)}/>
            <Row center className="pad-h">GM Only</Row>
          </Row>
          <Column center>
            {console.log(codexes)}
            <Switch checked={codexes.includes(tile)} onChange={e => codexes.includes(tile) ? getProject(projectID).detachCodex(tile) : attach(tile)}/>
          </Column>
        </Row> : <div/>
        )}
        {manualDexs.map((dex, index) => <Row between key={index}>
          <ProjectProvider projectID={dex}>
            <Row center>
              <ProjectAvatar/>
              <Row className="pad">
                <ProjectName/>
              </Row>
            </Row>
          </ProjectProvider>
          <Button className="foreground" onClick={e => getProject(projectID).detachCodex(dex)}>Remove</Button>
        </Row>)}
      </Scroll> : null}
    {tab === "compendium" && !window.isLocal && <Column grow>
      <Scroll scrollY>
        {details && details.favs && details.favs.map((tile, index) => <ContentProvider contentID={tile}>
          <FavoriteItem key={index} />
        </ContentProvider>)}
      </Scroll>
      <Row center>
        <Button className="foreground" onClick={e => window.ask({ title: "Compendium", app: Community, width: "90vw", bodyStyle: { padding: 0 }, style: {height: "90vh"} })} childStyle={{width: "200px"}}>
          Compendium
        </Button>
      </Row>
    </Column>} */}
      <Scroll>
        {codexes.map((tile, index) => <Row between className="background pad-h" style={{borderBottom: "1px solid #141414"}} key={index}>
          <ProjectProvider projectID={tile}>
            <Row center>
              <ProjectAvatar height={25}/>
              <Row className="pad">
                <ProjectName/>
              </Row>
            </Row>
          </ProjectProvider>
          <Row grow/>
          <Row center className={`${codexes.includes(tile) ? "visible click" : "no-click invisible"} pad-h`}>
            <Checkbox checked={meta.gmOnly && meta.gmOnly[tile]} onChange={e => (meta.gmOnly && meta.gmOnly[tile]) ? getProject(projectID).hideCodex(tile) : getProject(projectID).revealCodex(tile)}/>
            <Row center className="pad-h">GM Only</Row>
          </Row>
          <Column center>
            <Button onClick={e => getProject(projectID).detachCodex(tile)}>
              <DeleteFilled/>
            </Button>
          </Column>
        </Row>)}
      </Scroll>

    <Row center>
      <Button className="foreground" onClick={e => window.ask({ title: "Compendium", app: Community, width: "90vw", bodyStyle: { padding: 0 }, style: {height: "90vh"}, props: {
        onSelect: (e,id)=>{
          window.close()
          if (codexes.includes(id.meta.target))
            return window.error("Already included")
          attach(id.meta.target)
        }, 
        projectSystem: meta.descriptors?.system ? projectID : meta.projectSystem
      } })} childStyle={{width: "200px"}}>
        Add Extra Content
      </Button>
    </Row>
  </Column>
}

export default ({style, projectID})=><ProjectProvider projectID={projectID}>
  <CodexApp style={style}/>
</ProjectProvider>