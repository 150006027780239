import Navigation from '../models/Navigation'
import { Misc } from '../../core/state/Database'

// import Simulation from '../../engine/Simulation'

const navigation = new Navigation()
// load the last nav state
navigation.on("change", e => {
  if (navigation.loaded)
    Misc.set("nav", navigation.serialize())
})


// navigation.on("ready", e => {
//   window.history.replaceState({ profile: null }, null, "")
// })

// navigation.on("change", e => {
//   console.log(window.history, window.history.state, document.referrer)
//   window.history.pushState({ profile: navigation.profile }, '')
//   // history.push(navigation.profile)
// })

window.onpopstate = function (e) {
  if (e.state) {
    if (e.state.noLeave)
      window.history.pushState({ noLeave: true }, '')

    navigation.setProfile(e.state.profile)
  }
  else
    e.preventDefault()
}

window.history.pushState({ noLeave: true }, null, "")
// window.onbeforeunload = function () { return "Do you want to leave campaign studio?" }

Misc.get("nav").then(e => {
  // console.log(e)
  if (e)
    navigation.deserialize(e)
}).catch(e => {
  console.log(e)
  navigation.loaded = true
  navigation.emit("ready")
})


export default navigation