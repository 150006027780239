import Serial from '../../core/models/Serial'
import Network from '../../core/state/Network'
import Account from '../../core/state/Account'
import { Changes } from '../../core/state/Database'
import { v4 } from 'uuid'
import { localSocket } from "../../Wing"

const saveDelay = 1000 // ms
let canChange = Date.now()
const save = {}

setInterval(() => {
  if (canChange < Date.now() && Object.keys(save).length)
    for (const key in save) {
      save[key]()
      delete save[key]
    }
}, saveDelay / 2)


const pushChange = (key, fn) => {
  save[key] = fn
  canChange = Date.now() + saveDelay
}

const creating = {} // a cache so you don't duplicate entries

class Destination extends Serial {
  constructor({ uid, Model, compressed, serialized, service, explicit, storage, key, offline, time = 1000, timeout = 20000 }) {
    super()
    this.target = new Model({ uid })
    this.storage = storage
    // if no service, then don't save online
    this.service = service
    // if no storage, then don't save offline
    this.storage = storage
    this.offline = offline
    this.compressed = compressed
    this.serialized = serialized
    this.explicit = explicit // will not create things unless directly ordered too
    this.key = key
    this.timeout = timeout
    this.time = time
    this.uid = uid
    this.target.on("ready", e => {
      this.loaded = true
      this.emit("ready")
    })
    this.on("ready", e=>{
      if (Network.host && Network.lobby) 
        Network.broadcast("source", window.compress(JSON.stringify(this.pack())))
    })
    this.target.on("change", local => local ? undefined : this.change())
    this.target.on("save", local => local ? undefined : this.change())
  }

  saveLocal() {
    const { uid, target, key } = this

    const opts = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        route: key,
        uid,
        data: target.pack()
      })
    }

    return fetch(`http://127.0.0.1:${window.location.port}/resources/save`, opts)
  }

  saveService() {
    const { service, target, uid, storage, key } = this
    // console.log(target)
    if (service.connection.disconnected && storage) {
      // stash the change for later usage
      delete creating[uid]
      return pushChange(`${key}-${uid}-storage`, () => this.saveStorage(true))
    }

    if (target._id) {
      // update
      service.patch(target._id, target.pack()).then(e => {
        console.log("service successful")
        // console.log(e)
      }).catch(e => {
        if (storage)
          pushChange(`${key}-${uid}-storage`, () => this.saveStorage(true))
        console.warn(e)
      })
    }
    else if (!creating[uid]) {
      // create
      creating[uid] = true
      service.create(target.pack()).then(e => {
        console.log("service successful")
        target._id = e._id
        delete creating[uid]
      }).catch(e => {
        if (storage)
          pushChange(`${key}-${uid}-storage`, () => this.saveStorage(true))
        console.warn(e)
        delete creating[uid]
      })
    }
  }

  saveStorage(record) {
    if (window.isLocal)
      return
    const { storage, uid, target, key } = this
    // console.log(uid, target.serialize())
    storage.set(uid, target.serialize()).then(e => {
      console.log("storage successful")
      if (record) // its a change coming from a cloud service
        Changes.keys().then(keys => { // create it if it doesn't exist
          if (!keys.includes(key))
            Changes.set(key, JSON.stringify([uid])).then(e => {
              console.warn("Database services are unavailable, storing changes")
            }).catch(e => window.error("Stop working immediately, an unexpected error has occured"))
          else
            Changes.get(key).then(changeStr => {
              const changes = JSON.parse(changeStr)
              if (!changes.includes(uid))
                changes.push(uid)
              Changes.set(key, JSON.stringify(changes)).then(e => {
                console.warn("Database services are unavailable, storing changes")
              }).catch(e => window.error("Stop working immediately, an unexpected error has occured"))
            })
        }).catch(e => window.error("Unable to record offline changes, stop editing immediately"))
    }).catch(e => console.warn(e))
  }

  change(e) {
    // stack up the changes so you don't blast out a ton of messages for frequent changes
    const { service, storage, key, uid, target, offline } = this
    if (target.local && Network.host)
      pushChange(`${key}-${uid}-local`, () => this.saveLocal())

    if (target.loaded)
      if (Network.server) // I am not the host, so I'll send my updates to the host
        Network.send("source", window.compress(JSON.stringify(this.pack())))
      else {
        // send Changes to all players
        if (Network.host && Network.lobby) 
          Network.broadcast("source", window.compress(JSON.stringify(this.pack())))

        if (!window.isLocal)
          if (!target.temp && !(target.local && Network.host)) { // I am the server, so I need to start prepping the changes for either cloud or local
            if (service && Account.uid && !offline() && !target.local) // Server is available, write to it, making sure you are hosting
              pushChange(`${key}-${uid}-service`, () => this.saveService())
            // if no server record the changes
            if (storage) // Redundant write to storage, tracking commands if not connected
              pushChange(`${key}-${uid}-storage`, () => this.saveStorage())
        }
      }
  }

  pack() {
    // prepare for peer transfer
    const { key, uid, target } = this
    return { uid, key, payload: target.pack() }
  }

  unpack(payload) {
    // load from peer transfer
    const { target } = this
    target.load(payload)
    // if (serialized)
    //   target.load(payload)
    // if (compressed)
    //   target.load(payload)
  }

  loadService() {
    const { service, target, uid } = this
  
    if (service.connection.disconnected)
      return false

    if (target._id)
      return new Promise(res => service.get(target._id).then(e => {
        target.load(e)
        res(true)
        // console.log(e)
      }).catch(e => {
        res(false)
        console.warn(e)
      }))
    else
      return new Promise(res => service.find({ query: { uid } }).then(e => {
        if (e.data.length) {
          target.load(e.data[0])
          res(true)
        }
        else 
          res(false)
        
      }).catch(e => {
        res(false)
        console.warn(e)
      }))
  }

  loadLocal() {
    const { storage, uid, target, key } = this

    const opts = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        route: key,
        uid
      })
    }

    return new Promise(res => fetch(`http://127.0.0.1:${window.location.port}/resources/load`, opts).then(r => r.json()).then(e => {
      console.log(e)
      if (e) {
        console.log(uid + " loaded")
        target.load(e)
        res(true)
      }
      else
        res(false)
    }).catch(e => {
      console.warn(e, uid)
      res(false)
    }))
  }

  loadStorage() {
    const { storage, uid, target } = this
    console.log(uid)
    return new Promise(res => storage.get(uid).then(e => {
      if (e) {
        console.warn(uid + " pulled from storage")
        console.log(e)
        target.deserialize(e)
        res(true)
      }
      else
        res(false)
    }).catch(e => {
      console.warn(e, uid)
      res(false)
    }))
  }

  loadPeer() {
    const { uid, key, timeout, target } = this
    // send out a message to prompt the transfer of this content
    
    Network.broadcast("source", window.compress(JSON.stringify({ uid, key }))) // asks the entire network
    return new Promise((resolve, error) => {
      setTimeout(() => {
        if (target.loaded) // the content reached the target successfully
          resolve(true)
        else
          resolve(false)
      }, timeout)
    })
  }

  async load() {
    // attempts to load
    const { service, storage, key, uid, target, offline, offlineFirst } = this
    let success = false
    if (!success && Network.server) // connected to a server (Peer), attempt to retreive info here
      success = await this.loadPeer()

    if (!target.temp) {
      if (offlineFirst) {
        if (!success && target.local && localSocket.connected) // Check the local machine
          success = await this.loadLocal()

        if (!success && storage && !window.isLocal) // Check the local machine
          success = await this.loadStorage()

        if (!success && service && !offline() && uid !== "local" && !window.isLocal) // Check the cloud
          success = await this.loadService()
      }
      else {
        if (!success && target.local && localSocket.connected) // Check the local machine
          success = await this.loadLocal()

        if (!success && service && !offline() && uid !== "local" && !window.isLocal) // Check the cloud
          success = await this.loadService()

        if (!success && storage && !window.isLocal) // Check the local machine
          success = await this.loadStorage()
      }
    }

    if (success) {
      this.loaded = success
      this.emit("ready")
    }
    else if (!target.temp)
      console.warn("Unable to load", key, uid)
  }

  deletePeer() {
    const { key, uid } = this
    if (Network.host) {
      console.log("SREE ", uid, key)
      Network.broadcast("source", window.compress(JSON.stringify({ uid, key, _delete: true })))
    }
  }

  deleteService() {
    const { target, service } = this
    if (target._id)
      service.remove(target._id).then(e => console.log("Successfully deleted")).catch(e => console.warn(e))
  }

  deleteStorage() {
    const { target, storage } = this
    if (target.uid)
      storage.delete(target.uid).then(e => console.log("Successfully deleted")).catch(e => console.warn(e))
  }

  async delete() {
    const { target, service, storage } = this

    this.loaded = false

    target.loaded = false
    target.emit("change")

    // send out the delete commands if we are the host
    if (Network.host)
      this.deletePeer()

    if (service)
      this.deleteService()

    if (storage)
      this.deleteStorage()
  }
}

export default class SourcedList extends Serial {
  constructor({ key, Model, service, storage, compressed, serialized, explicit, offline }) {
    super()
    this.Model = Model
    // if no service, then don't save online
    this.service = service
    // if no storage, then don't save offline
    this.storage = storage
    this.offline = offline
    this.compressed = compressed
    this.serialized = serialized
    this.explicit = explicit // will not create things unless directly ordered too
    this.key = key
    this.cache = {}
  }

  initialize(uid, offlineFirst) {
    const { cache, service, storage, key, Model, offline } = this
    if (cache[uid])
      return

    cache[uid] = new Destination({ Model, service, storage, key, uid, offline })
    cache[uid].target.uid = uid
    cache[uid].offlineFirst = offlineFirst
    cache[uid].load()
  }

  create(uid = v4()) {
    const { cache, service, storage, key, Model, offline } = this
    if (cache[uid])
      return cache[uid].target
    this.cache[uid] = new Destination({ Model, service, storage, key, uid, offline }) // doesn't load because you created it
    this.cache[uid].target.uid = uid
    this.cache[uid].target.loaded = true
    this.cache[uid].loaded = true
    this.cache[uid].target.emit("ready")

    return this.cache[uid].target
  }

  get(uid, offlineFirst) {
    if (uid)
      return this.find(uid, offlineFirst).target
  }

  find(uid, offlineFirst) {
    const { cache } = this
    const id = uid
    if (!cache[id] && id)
      this.initialize(id, offlineFirst)
    return this.cache[id]
  }

  exists(uid) {
    return this.cache[uid]
  }

  delete(uid) {
    const { cache } = this
    const id = uid
    if (cache[id])
      cache[id].delete()
  }

  clean(uid) { 
    const { cache } = this
    const id = uid
    if (cache[id])
      delete cache[id]
  }
}