import React, { useEffect } from 'react'
import L from 'leaflet'
import UniversalDrop from '../core/wrappers/UniversalDrop'

import 'leaflet-editable'
import 'leaflet.path.drag'
import Nav from './state/Navigation'
import { getProfile } from "./hooks/useProfile"
import useMap, { getMap } from "./hooks/useMap"

import FileDrop from "../core/wrappers/FileDrop"
import { notification } from "antd"
import { v4 } from "uuid"

import Radial from '../editor/state/Radial'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { LoadingOutlined } from '@ant-design/icons'
import { ColorPalete } from '../editor/Color'
import { hasRight } from "./hooks/useProject"

L.LatLng.prototype.distanceTo = function (other) { // hack to fix the circles being huge
  var dx = other.lng - this.lng
  var dy = other.lat - this.lat
  return Math.sqrt(dx * dx + dy * dy)
}

const colors = ["pink", "red", "orange", "olive", "green", "#00e4ec", "#3388ff", "#8832ff", "#964B00", "grey", "#222222"]

export async function convertFile(path, mapID) {
  return fetch(`http://127.0.0.1:${window.location.port}/convert`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ path, uid: mapID })
  })
    .then(response => response.json())
    .then(e => getMap(mapID).setBackground(`map/${e.name}`))
    .catch(e => console.warn(e))
}

export function uploadMap(mapID) {
  const fileInput = document.createElement('input')
  fileInput.setAttribute("type", "file")
  fileInput.setAttribute("accept", ".jpg, .jpeg, .png")
  fileInput.onchange = function (e) {
    if (this.files.length) {
      const { path } = this.files[0]
      window.notify({ message: "Converting map, please wait...", key: "uploading", duration: 0 })
      convertFile(path, mapID).then(e => {
        notification.close("uploading")
        window.success("Map converted successfully")
      }).catch(e=>notification.warn("Error creating map"))
    }
  }
  fileInput.click()
}

export function LeafletMap({ markerPosition }) {
  // create map
  const mapRef = React.useRef(null)
  const { mapID, loaded } = useMap()


  useEffect(() => {
    const map = L.map('map', {
      attributionControl: false,
      center: [0, 0],
      editable: true,
      inertiaDeceleration: 5000,
      easeLinearity: 0.1,
      crs: L.CRS.Simple,
      zoomControl: false,
      minZoom: 2,
      maxZoom: 6,
      zoom: 4
    })

    Object.defineProperty(map, "color", {
      get: () => (map._color),
      set: newColor => {
        map._color = newColor
        if (map.editing) {
          if (map.editing.setColor)
            map.editing.setColor(newColor)

          if (map.editing.save)
            map.editing.save()
        }
      }
    })

    Object.defineProperty(map, "editing", {
      get: () => (map._editing),
      set: newEditing => {
        map._editing = newEditing
      }
    })

    Object.defineProperty(map, "entities", {
      get: () => (map._entities),
      set: newEntities => {
        map._entities = newEntities
      }
    })

    map._background = L.tileLayer(`./${getMap(mapID).src}/{z}/{x}/{y}.png`, {
      noWrap: true,
      attribution: '<b>Mythic Archive/b>'
    }).addTo(map)

    map.color = "#00e4ec"
    map._entities = {}

    L.control.zoom({ position: 'topright' }).addTo(map)

    mapRef.current = map
  }, [])

  useEffect(() => {
    const map = mapRef.current
    if (!map)
      return

    // const b = map.getBounds()
    // const width = Math.abs(b.getWest() - b.getEast())
    // const height = Math.abs(b.getNorth() - b.getSouth())
    // console.log(width, height)
    // console.log(map.unproject(L.point(0, 0)))
    // console.log(map.unproject(L.point(width, height)))
    // map.setMaxBounds(new L.LatLngBounds(new L.LatLng(-height, 0), new L.LatLng(0, width)))
    // console.log(background)


    // console.log(background)
    // console.log(background._tileCoordsToBounds())

    map.update = function () {
      for (const key in this.entities)
        if (!getMap(mapID).lookup(key)) {
          if (map.editing && map.editing._uid === key)
            map.editing = undefined
          this.removeLayer(this.entities[key])
          delete this.entities[key]
        }

      const {layers} = getMap(mapID)
      console.log(layers)
      const dupes = {}
      const deleteList = []
      for (const uid in layers)
        if (!map.entities[uid]) {
          const { type } = layers[uid]
          if (!layers[uid].uid)
            layers[uid].uid = uid
          if (type) {
            if (type === "marker") {
              console.log(layers[uid].latlng)
              if (!dupes[JSON.stringify(layers[uid].latlng)]) {
                map.addMarker(layers[uid])
                dupes[JSON.stringify(layers[uid].latlng)] = uid
              }
              else
                deleteList.push(uid)
            }
            else {
              let shape
              if (type === "circle")
                shape = map.editTools.startCircle()

              if (type === "polygon")
                shape = map.editTools.startPolygon()

              if (type === "line")
                shape = map.editTools.startPolyline()

              if (type === "rectangle") {
                // console.log(getMap(mapID).layers[uid], uid)
                // const ne = new L.LatLng(bounds[0][0], bounds[0][1])
                // const sw = new L.LatLng(bounds[1][0], bounds[1][1])
                shape = map.editTools.startRectangle()
              }

              shape._uid = uid
              shape._type = type
              map.wrapShape(shape)
              shape.addTo(map)
              if (shape.disableEdit)
                shape.disableEdit()
              shape.dragging.disable()
              shape.update()

              map.entities[uid] = shape
            }
          }
        }
      map._background.setUrl(`${getMap(mapID).src}/{z}/{x}/{y}.png`)
      for (const uid of deleteList) {
        delete layers[uid]
        getMap(mapID).emit("change")
      }
    }


    map.wrapShape = function (shape) {
      shape.setColor = newColor => {
        shape.setStyle({ color: newColor })
        shape._color = newColor
      }
      shape.save = function () {
        const options = { uid: this._uid, color: this._color }
        if (this._type === "circle") {
          options.latlng = [this._latlng.lat, this._latlng.lng]
          options.radius = this._mRadius
          options.type = "circle"
        }

        if (this._type === "rectangle") {
          const ne = this._bounds._northEast
          const sw = this._bounds._southWest
          options.bounds = [[ne.lat, ne.lng], [sw.lat, sw.lng]]
          options.type = "rectangle"
        }

        if (this._type === "polygon") {
          options.points = this._latlngs[0].map(data => ([data.lat, data.lng]))
          options.type = "polygon"
        }

        if (this._type === "line") {
          options.points = this._latlngs.map(data => ([data.lat, data.lng]))
          options.type = "line"
        }

        getMap(mapID).update(options)
        this.update()
      }

      shape.update = function () {
        const { type, bounds, points, radius, latlng, color } = getMap(mapID).lookup(this._uid)
        if (type === "circle" && latlng && radius) {
          this.setRadius(radius)
          this.setLatLng(new L.LatLng(latlng[0], latlng[1]))
        }

        if (type === "rectangle" && bounds) {
          const ne = new L.LatLng(bounds[0][0], bounds[0][1])
          const sw = new L.LatLng(bounds[1][0], bounds[1][1])
          this.setBounds(new L.LatLngBounds(ne, sw))
        }

        if ((type === "polygon" || type === "line") && points) 
          this.setLatLngs(points.map(d => (new L.LatLng(d[0], d[1]))))

        if (color)
          this.setColor(color)
      }

      shape.on('contextmenu', ev => {
        if (map.editing && map.editing._uid === shape._uid) {
          shape.save()
          const {profileID} = getMap(mapID).lookup(shape._uid)
          const o = [{title: "Attach Page", icon: <FontAwesomeIcon icon={['fas', "book"]} />, onSelect: e=>{
            window.pick(profileID=>{
              getMap(mapID).update({uid: shape._uid, profileID})
              delete Nav.wiki
              Nav.profileID = profileID 
              Nav.setBrowser(true)
            })
          }}]
          if (profileID)
            o.push({title: "Open Page", icon: <FontAwesomeIcon icon={['fas', "book-open"]} />, onSelect: e=>{
              if (profileID && hasRight(profileID, "SEE")) {
                Nav.wiki = true
                Nav.profileID = profileID 
                Nav.setBrowser(false)
              }
              else
                window.warning("Not permitted")
            }})
      
          Radial.open({x: ev.originalEvent.clientX, y: ev.originalEvent.clientY, options: {
            "root": [
              [
                ...o,
                {title: "Delete", icon: <FontAwesomeIcon icon={['fas', "trash"]} />, onSelect: e=>{getMap(mapID).remove(shape._uid)}}
              ],
              [...colors.map(bg=>({
                title: bg,
                icon: <FontAwesomeIcon style={{color: bg}} icon={['fas', "square"]} />,
                onSelect: e=>{
                  map.color = bg
                }
              })),
              {title: "Custom", icon: <FontAwesomeIcon icon={['fas', "palette"]} />, onSelect: e=>{
                window.ask({
                  title: "Pick a custom color",
                  app: ColorPalete, 
                  props: {
                    defaultColor: map.color,
                    defaults: colors,
                    onChange: e=>map.color = `#${e.getHexString()}`
                  }
                })
                return true
              }
              }],
            ]
          }, menu: "root" })
        }
        else
          map.startEditing(shape)

        // ev.originalEvent.preventDefault()
        ev.originalEvent.view.L.DomEvent.stopPropagation(ev) // lol
      })

      // shape.on('mouseover', ev => {
      //   const { profileID } = getMap(mapID).lookup(shape._uid)
      //   if (profileID)
      //     shape.bindPopup(getProfile(profileID).getName())
      //   else
      //     shape.unbindPopup()
      // })

      shape.on('click', ev => {
        const { profileID } = getMap(mapID).lookup(shape._uid)
        if (profileID && hasRight(profileID, "SEE")) {
          if (Nav.profile === profileID || !Nav.wiki)
            Nav.toggleWiki(true)
          Nav.setBrowser(false)
          Nav.setProfile(profileID)
        }
        else
          window.warning("Not permitted")
        
      })

      map.entities[shape._uid] = shape
      return shape
    }

    map.addMarker = function (options) {
      // attach the proper handles
      const { uid, color, latlng, profileID, style } = options

      const marker = L.marker(latlng, { autoPan: true }).addTo(map)

      marker._uid = uid ?? getMap(mapID).add(options, true)
      map.entities[marker._uid] = marker
      const classes = style || ""
      const icon = L.divIcon({
        className: 'custom-div-icon',
        html: `<div class='scale-anchor'>
          <div class='scale-shadow'>
            <div class='scale-pulse' style='display: none; ${color ? `background: ${color};` : ""}' id='pulse-${marker._leaflet_id}'></div>
          </div>
          <div class='scale-correction'>
            <div class='marker-pin ${classes}'>
              <div class='marker-body ${classes}' id='body-${marker._leaflet_id}' ${color ? `style='background: ${color};'` : ""}>
                <div class="marker-icon ${classes}">
                  <i id='icon-${marker._leaflet_id}' class="fa fa-${getProfile(profileID).getIcon()}" style="transform: translate(0%,-70%); font-size:20px;"></i>
                </div>
              </div>
            </div>
          </div>
          <span class="marker-tooltip" id='tooltip-${marker._leaflet_id}'></span>
        </div>`,
        iconSize: [51, 51],
        iconAnchor: [25, 34]
      })
      marker.setColor = function (color) {
        const pulse = document.getElementById(`pulse-${marker._leaflet_id}`)
        pulse.setAttribute("style", `display: ${marker.dragging._enabled ? "auto" : "none"}; ${color ? `box-shadow: 0 0 1px 4px ${color};` : ""}`)

        const body = document.getElementById(`body-${marker._leaflet_id}`)
        body.setAttribute("style", `${color ? `background: ${color};` : ""}`)

        this._color = color
      }
      marker.setIcon(icon)
      marker.setColor(color)

      // mapRef.current.on('zoomend', function (e) {
      //   const geo = mapRef.current.getCenter()
      //   if (mapRef.current.getZoom() > 3) {
      //     marker.setLatLng(geo)
      //     marker.addTo(mapRef.current)
      //   }
      //   else
      //     marker.remove()
      // })

      marker.save = function () {
        //pushes the latest changes
        const options = { uid: this._uid, color: this._color, latlng: [this._latlng.lat, this._latlng.lng] }
        getMap(mapID).update(options)
      }
      marker.update = function () {
        // pulls the latest changes
        const { color, latlng } = getMap(mapID).lookup(this._uid)
        marker.setColor(color)
        marker.setLatLng(new L.LatLng(latlng[0], latlng[1]))
      }

      marker.on('mouseover', ev => {
        const { profileID } = getMap(mapID).lookup(marker._uid)

        const i = document.getElementById(`icon-${marker._leaflet_id}`)
        i.setAttribute("class", `fa fa-${getProfile(profileID).getIcon()}`)

        const m = document.getElementById(`tooltip-${marker._leaflet_id}`)
        m.innerHTML = `<div class='background pad'>${getProfile(profileID).getName()}</div>`
        ev.target.openPopup()
      })

      marker.on("contextmenu", ev => {
        if (map.editing === marker) {
          marker.save()
          const {profileID} = getMap(mapID).lookup(marker._uid)
          const o = [{title: "Attach Page", icon: <FontAwesomeIcon icon={['fas', "book"]} />, onSelect: e=>{
            window.pick(profileID=>{
              getMap(mapID).update({uid: marker._uid, profileID})
              delete Nav.wiki
              Nav.profileID = profileID 
              Nav.setBrowser(true)
            })
          }}]
          if (profileID)
            o.push({title: "Open Page", icon: <FontAwesomeIcon icon={['fas', "book-open"]} />, onSelect: e=>{
              if (profileID && hasRight(profileID, "SEE")) {
                Nav.wiki = true
                Nav.profile = profileID 
                Nav.setBrowser(false)
              }
              else
                window.warning("Not permitted")
            }})
      
          Radial.open({x: ev.originalEvent.clientX, y: ev.originalEvent.clientY, options: {
            "root": [
              [
                ...o,
                {title: "Delete", icon: <FontAwesomeIcon icon={['fas', "trash"]} />, onSelect: e=>{getMap(mapID).remove(marker._uid)}}
              ],
              [...colors.map(bg=>({
                title: bg,
                icon: <FontAwesomeIcon style={{color: bg}} icon={['fas', "square"]} />,
                onSelect: e=>{
                  map.color = bg
                }
              })),
              {title: "Custom", icon: <FontAwesomeIcon icon={['fas', "palette"]} />, onSelect: e=>{
                window.ask({
                  title: "Pick a custom color",
                  app: ColorPalete, 
                  props: {
                    defaultColor: map.color,
                    defaults: colors,
                    onChange: e=>map.color = `#${e.getHexString()}`
                  }
                })
              }}
              ]
            ]
          }, menu: "root" })
        }
        else
          map.startEditing(marker)
        ev.originalEvent.view.L.DomEvent.stopPropagation(ev)
      })

      marker.on('click', ev => {
        const { profileID } = getMap(mapID).lookup(marker._uid)
        if (profileID && hasRight(profileID, "SEE")) {
          if (Nav.profile === profileID || !Nav.wiki)
            Nav.toggleWiki(true)
          Nav.setBrowser(false)
          Nav.setProfile(profileID)
        }
        else
          window.warning("Not permitted")
      })

      return marker
    }

    map.startEditing = function (poly = map.editing) {
      if (map.editing && map.editing !== poly)
        map.stopEditing()

      if (poly.enableEdit)
        poly.enableEdit()

      if (poly && poly._uid) {
        const lookup = getMap(mapID).lookup(poly._uid)
        if (lookup && lookup.type === "marker") {
          const { color } = lookup
          const pulse = document.getElementById(`pulse-${poly._leaflet_id}`)
          poly.dragging.enable()
          map.editing = poly
          pulse.setAttribute("style", `display: auto; ${color ? `box-shadow: 0 0 1px 4px ${color};` : ""}`)
        }
      }

      map.editing = poly
    }

    map.stopEditing = function (poly = map.editing) {
      if (map.editing)
        if (map.editing.editor) {
          // save changes
          map.editing.disableEdit()
        }

      if (poly && poly.disableEdit)
        poly.disableEdit()

      if (poly && poly._uid && getMap(mapID).lookup(poly._uid)) {
        const lookup = getMap(mapID).lookup(poly._uid)
        if (lookup && lookup.type === "marker") {
          const { color } = lookup
          const pulse = document.getElementById(`pulse-${poly._leaflet_id}`)
          poly.dragging.disable()
          pulse.setAttribute("style", `display: none; ${color ? `box-shadow: 0 0 1px 4px ${color};` : ""}`)
        }
      }

      if (poly && poly.save)
        poly.save()
      map.editing = undefined
      map.editTools.stopDrawing()
 
      getMap(mapID).emit("change")
    }

    map.on("contextmenu", function (e) {
      if (map.editing)
        map.stopEditing()
      else {
        function openMenu() {
          const actions = [
            [
              {title: "Create Area", icon: <FontAwesomeIcon style={{color: map.color}} icon={['fas', "square"]} />, onSelect: e=>{
                const shape = map.editTools.startRectangle(undefined, { color: map.color })
                shape._uid = v4()
                shape._type = "rectangle"
                shape._color = map.color
                map.wrapShape(shape)
                map.startEditing(shape)
              }},
              {title: "Create Circle", icon: <FontAwesomeIcon style={{color: map.color}} icon={['fas', "circle"]} />, onSelect: e=>{
                const shape = map.editTools.startCircle(undefined, { color: map.color })
                shape._uid = v4()
                shape._type = "circle"
                shape._color = map.color
                map.wrapShape(shape)
                map.startEditing(shape)
              }},
              {title: "Create Marker", icon: <div className="relative" style={{height: "20px", width: "20px"}}>
                <div style={{transform: "translate(-15px, -6px)"}}>
                  <div class='scale-anchor no-click' style={{transform: "scale(0.4)"}}>
                    <div class='scale-correction'>
                      <div class={`marker-pin ${map.markerStyle || ""}`}>
                        <div class={`marker-body ${map.markerStyle || ""}`} style={map.color ? {background: map.color} : undefined}>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>,
              onSelect: ()=> window.pick(profileID => {
                  const latlng = e.latlng
                  const options = { latlng, profileID, color: map.color, type: "marker", style: map.markerStyle }
                  const uid = getMap(mapID).add(options, true)
                  map.startEditing(map.addMarker({ ...options, uid }))
                })
              },
              {title: "Create Path", icon: <FontAwesomeIcon style={{color: map.color}} icon={['fas', "wave-square"]} />, onSelect: e=>{
                const shape = map.editTools.startPolyline(undefined, { color: map.color })
                shape._uid = v4()
                shape._type = "line"
                shape._color = map.color
                map.wrapShape(shape)
                map.startEditing(shape)
              }},
              {title: "Create Polygon", icon: <FontAwesomeIcon style={{color: map.color}} icon={['fas', "vector-square"]} />, onSelect: e=>{
                const shape = map.editTools.startPolygon(undefined, { color: map.color })
                shape._uid = v4()
                shape._type = "polygon"
                shape._color = map.color
                map.wrapShape(shape)
                map.startEditing(shape)
              }},

            ],
            [
              {title: "Set Background", onSelect: ev=>{
                uploadMap(mapID)
                ev.stopPropagation()
              }, icon: <FontAwesomeIcon icon={['fas', "map"]} />},
              {title: "Set Color", icon: <FontAwesomeIcon style={{color: map.color}} icon={['fas', "palette"]} />, onSelect: "root/colors"},
              {title: "Set Marker", icon: <div className="relative" style={{height: "20px", width: "20px"}}>
                <div style={{transform: "translate(-15px, -6px)"}}>
                  <div class='scale-anchor no-click' style={{transform: "scale(0.4)"}}>
                    <div class='scale-correction'>
                      <div class={`marker-pin ${map.markerStyle || ""}`}>
                        <div class={`marker-body ${map.markerStyle || ""}`} style={map.color ? {background: map.color} : undefined}>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>, onSelect: "root/styles"},
              {title: "Toggle Wiki", icon: <FontAwesomeIcon icon={['fas', "book"]} />, onSelect: e=>{
                Nav.toggleWiki(true)
              }},
              // {title: "Set Marker Style", icon: <FontAwesomeIcon style={{color: map.color}} icon={['fas', "map-marker"]} />},
            ]
          ]
          Radial.open({x: e.originalEvent.clientX, y: e.originalEvent.clientY, options: {
            "root" : actions,
            "root/styles": ["diamond", "square", "grave", "circle", "pin"].map(className=>({
              title: className,
              icon: <div className="relative" style={{height: "20px", width: "20px"}}>
                <div style={{transform: "translate(-15px, -6px)"}}>
                  <div class='scale-anchor no-click' style={{transform: "scale(0.4)"}}>
                    <div class='scale-correction'>
                      <div class={`marker-pin ${className || ""}`}>
                        <div class={`marker-body ${className || ""}`} style={map.color ? {background: map.color} : undefined}>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>,
              onSelect: e=>{
                map.markerStyle = className
                openMenu()
                return true
              }
            })),
            "root/colors": [...colors.map(bg=>({
              title: bg,
              icon: <FontAwesomeIcon style={{color: bg}} icon={['fas', "square"]} />,
              onSelect: e=>{
                map.color = bg
                openMenu()
                return true
              }
            })), 
            {title: "Custom", icon: <FontAwesomeIcon icon={['fas', "palette"]} />, onSelect: e=>{
              window.ask({
                title: "Pick a custom color",
                app: ColorPalete, 
                props: {
                  defaultColor: map.color,
                  defaults: colors,
                  onChange: e=>map.color = `#${e.getHexString()}`
                }
              })
            }}
          ],
          }, menu: "root" })
        }
        openMenu()
      }
    })
    const keyUp = function (e) {
      if (e.key === "Delete" && map.editing && (!document.activeElement || document.activeElement.tagName === "DIV"))
        getMap(mapID).remove(map.editing._uid)
    }
    window.addEventListener("keyup", keyUp)

    function getCurrentCity() {
      return fetch(`${getMap(mapID).src}/tilemapresource.xml`)
        .then(response => response.text())
        .then(data => {
          console.log(data)
          const min = [data.match(/minx=\"([-\.\d]+)\"/)[1], data.match(/miny=\"([-\.\d]+)\"/)[1]].map(t => Number(t))
          const max = [data.match(/maxx=\"([-\.\d]+)\"/)[1], data.match(/maxy=\"([-\.\d]+)\"/)[1]].map(t => Number(t))
          console.log(min, max)
          const sw = map.unproject([-max[0] * 0.25, max[1]], 4)
          const ne = map.unproject([max[0], -max[1] * 0.25], 4)
          const layerbounds = new L.LatLngBounds(sw, ne)
          map.setMaxBounds(layerbounds)
          map.panTo(new L.LatLng(sw.lat / 4, ne.lng / 4))
        })
    }
    // getCurrentCity()

    map.update()

    return function () {
      window.removeEventListener("keyup", keyUp)
    }
  }, [mapID, loaded])

  useEffect(() => {
    if (!mapRef.current)
      return

    const map = mapRef.current

    function change(uid, options) {
      if (uid) { // specifically a marker has changed
        // if (!map.entities[uid])
        //   map.entities[uid] = 

        const marker = map.entities[uid]
        if (!marker) {
          // intialize shape
        }
      }
      else  // a map setting has changed or multiple markers
        map.update(getMap(mapID)) // will pull from current map
    }
    getMap(mapID).on("change", change)

    map.update()
    return function () {
      // cleanup handles
      getMap(mapID).off("change", change)
    }
  }, [mapRef, mapID, loaded])

  // add marker
  // const markerRef = React.useRef(null)
  // React.useEffect(
  //   () => {
  //     if (markerRef.current) {
  //       markerRef.current.setLatLng(markerPosition)
  //     } else {
  //       markerRef.current = L.marker(markerPosition).addTo(mapRef.current)
  //     }
  //   },
  //   [markerPosition]
  // )

  const onDrop = (p, m, c) => {
    const item = m.getItem()
    if (item.profileID) {
      window.notify("Dropped ProfileID")
      const map = mapRef.current
      const lookup = map.editing && map.editing._uid && getMap(mapID).lookup(map.editing._uid)
      const uid = map.editing && map.editing._uid ? map.editing._uid : undefined
      map.stopEditing()
      if (lookup && lookup.type !== "marker")
        getMap(mapID).update({ ...lookup, uid, profileID: item.profileID })
      else {
        const { x, y } = m.getClientOffset()
        const latlng = map.layerPointToLatLng(map.containerPointToLayerPoint(L.point(x, y)))
        const options = { latlng, profileID: item.profileID, color: map.color, type: "marker", style: map.markerStyle }
        const uid = getMap(mapID).add(options, true)
        map.startEditing(map.addMarker({ ...options, uid, }))
      }
    }
  }

  return <UniversalDrop className="fit-x fit-y" onDrop={onDrop}>
    <FileDrop className="fit-x fit-y" onDrop={(p, m, c) => {
      window.notify({ message: "Converting map, please wait...", key: "uploading", duration: 0 })
      convertFile(m.getItem().files[0].path, mapID).then(e => {
        notification.close("uploading")
        window.success("Map converted successfully")
      }).catch(e=>notification.warn("Error creating map"))
    }}>
      <div id="map" className="fit-x fit-y black" />
    </FileDrop>
  </UniversalDrop>
}

let warned = false
export default function Map(opts) {
  const { loaded, background, layers } = useMap()
  if (!loaded)
    return <div className="column fit-x fit-y center"><LoadingOutlined/></div>

  if (!background && (!layers || Object.keys(layers).length === 0) && !warned) {
    window.info({message: "Right click or a drag a .png map file to get started", duration: 0})
    warned = true
  }

  return <LeafletMap {...opts}/>
}