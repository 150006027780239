const saveDelay = 2000 // ms
let canChange = Date.now()
const save = {}

setInterval(() => {
  if (canChange < Date.now() && Object.keys(save).length) {
    for (const key in save) {
      save[key]()
      delete save[key]
    }
  }
}, saveDelay / 2)


export default (key, fn) => {
  save[key] = fn
  canChange = Date.now() + saveDelay
}