import React, { useState } from 'react'
import useProject, { hasRight } from '../../hooks/useProject'
import { useHook, getProfile, ProfileProvider } from '../../hooks/useProfile'
import { getArticle, ArticleProvider } from '../../hooks/useArticle'
import Nav from '../../state/Navigation'
import UniversalDrag from '../../../core/wrappers/UniversalDrag'
import { Column, Row, Button } from '../../../UI'
import { CloseOutlined } from '@ant-design/icons'
import SlateApp from '../Slate'

import ProfileNav from '../ProfileNav'

import { Tooltip } from 'antd'
import ProfileDrop from "../../ProfileDrop"

export default opts => {
  const { mask, phrases, term, children } = opts
  
  const {phrase, profileID} = phrases[term]

  const [clicked, setClicked] = useState(false)
  const { projectID } = useProject()


  const { loaded } = useHook(profileID)
  const profile = getProfile(profileID)
  const text = profileID ? (mask || (hasRight(profileID, "SEE") ? profile.getName() : profile.getAlias())) : "<Deleted>"

  const onOpen = e => {
    if (!hasRight(profileID, "SEE"))
      return window.notify("Not Permitted")

    if (!projectID)
      return window.notify("Not Permitted")

    window.blurAll()
    Nav.setProfile(profileID)
  }

  const articleID = profile.getArticleID()
  const article = getArticle(articleID)

  const style = {
    width: "100%",
    maxHeight: "30vh",
    overflowY: "auto",
  }

  let body
  if (phrase)
    for (let i = 0; i < article.body.length; i++)
      if (article.body[i].uid === phrase) {
        body = body || []
        body.push(article.body[i])
      }
      else if (((article.body[i].uid && article.body[i].uid !== phrase) || article.body[i].type === "divider") && body)
        break
      else if (body)
        body.push(article.body[i])


  if (!articleID)
    return <span className="mention">{children}</span>

  const child = <div style={{ fontSize: "0.8rem", lineHeight: "150%", color: 'white' }}>
    <Row between className="text-white click">
      <Row center className="reference pad-h" onClick={onOpen}>
        <span className="mention">{text}</span>
      </Row>
      <Button size="small" onClick={e => setClicked(false)} color="inherit">
        <CloseOutlined />
      </Button>
    </Row>
    {/* <NavBar ctx={{ ...ctx, parentprofileID: profileID, profileID, articleID, preview: true, seed }} conditional={true} /> */}

    <ProfileDrop placement="bottom">
      <ArticleProvider articleID={articleID}>
        <ProfileNav />
      </ArticleProvider>
    </ProfileDrop>

    <Column className="pad-l background-tint" style={style}>
      {article ? <SlateApp ctx={{ articleID, preview: true }} readOnly={true} raw={article.body} /> : <Column grow center>No Article</Column>}
    </Column>
  </div>

  return <UniversalDrag style={{ display: "inline" }} item={{ profileID }}>
    <ProfileProvider profileID={profileID} style={{ display: "inline" }}>
      <Tooltip title={child} trigger="click" overlayInnerStyle={{ padding: "0", border: "1px solid #333333", background: "#333333" }} overlayStyle={{ minWidth: "30vw", maxWidth: "30vw" }} visible={clicked} onVisibleChange={e => setClicked(e)}>
        <ProfileDrop placement="bottom" style={{ display: "inline" }}>
          <span className="mention">
            {children}
          </span>
        </ProfileDrop>
      </Tooltip>
    </ProfileProvider>
  </UniversalDrag>
}