import Resource from './Resource'
import Account from '../../core/state/Account'
import { v4 } from 'uuid'

export default class Content extends Resource {
  constructor(ctx = {}) {
    super(ctx)
    this.uid = ctx.uid || v4()
    this.meta = { creator: ctx.creator || Account.uid, created: Date.now() }

    this.setMaxListeners(Infinity) // Potentially thousands of references due to wiki
  }

  getName() {
    return this.meta.name
  }

  setName(name) {
    this.meta.name = name
    this.emit("change")
  }

  getImage() {
    return this.meta.src
  }

  setImage(img) {
    this.meta.src = img
    this.emit("change")
  }

  getTarget() {
    return this.meta.target
  }

  setTarget(target) {
    this.meta.target = target
    this.emit("change")
  }

  getPublic() {
    return this.meta.public
  }

  setTags(tags) {
    this.meta.tags = tags
    this.emit("change")
  }

  setPublic(newPublic) {
    this.meta.public = newPublic
    this.emit("change")
  }

  getArticleID() {
    return this.meta.articleID
  }

  setArticleID(articleID) {
    this.meta.articleID = articleID
    this.emit("change")
  }

  getType() {
    return this.meta.type
  }

  setType(type) {
    this.meta.type = type
    this.emit("change")
  }

  getSystem() {
    return this.meta.system
  }
  
  setSystem(system) {
    this.meta.system = system
    this.emit("change")
  }

  pack() {
    return {
      _id: this._id,
      uid: this.uid,
      meta: this.meta,
    }
  }

  serialize() {
    return JSON.stringify(this.pack())
  }

  load(json) {
    this._id = json._id
    this.uid = json.uid || this.uid
    this.meta = json.meta || this.meta
    this.loaded = true
    this.emit("ready")
  }

  deserialize(rawJSON) {
    this.load(JSON.parse(rawJSON))
  }
}