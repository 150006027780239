import Network from '../../core/Network'
import Account from '../../core/state/Account'

const network = new Network()
network.channel("authority", { // negotiates who is the host
  msg: function (msg, channel, from) {
    // console.log(msg)
    // console.log("from: ", from)
    // console.log("me: ", socket.id)
    // console.log("____")

    if (!network.host) {
      // I've gotten my host, now I need to annouce myself as a new peer
      network.server = msg.data
      // window.info("announcing ", network.host)
      channel.send("authority", Account.id)
      network.emit("joined", msg.data)
    }
    else
      // new peer, send it out to all my connected players
      network.broadcast("peers", JSON.stringify([from]))
  },
  open: function (msg, channel, from) {
    if (network.host)
      // window.warning("announced")
      channel.send(Account.id)

    network.emit("change", msg, channel, from) // updates the player list
  },
})

network.channel("metadata", { // announces player meta data
  msg: function (msg, channel, from) {
    // console.log(msg)
    const playerData = JSON.parse(msg.data)
    // console.log(from, playerData)
    network.connected[from].playerData = playerData
    network.emit("change", msg, channel, from)
  },
  open: function (msg, channel, from) {
    // window.info("established")
    channel.send(JSON.stringify({ name: Account.name || "NO NAME", uid: Account.uid }))
  }
})

network.channel("peers", { // hands out the peers
  msg: function (msg, channel, from) {
    // console.log(msg)
    if (!network.host) {
      let peers = JSON.parse(msg.data)
      console.log(peers, Account.id)
      for (const id of peers)
        if (id !== Account.id && id !== from)
          // console.log(id, socket.id)
          // window.warning(`connecting ${id}`)
          network.connect(id)
    }
  }
})

export default network