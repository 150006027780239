import React, { useContext, useState } from 'react'
import UniversalDrag from '../../../core/wrappers/UniversalDrag'
import Resources from '../../state/Resources'
import Logs from '../../state/Logs'
import useProfile, { getProfile } from '../../hooks/useProfile'
import useProject, { getProject, hasPermission } from '../../hooks/useProject'
import { applyEvent, createEvent } from "../../hooks/useEvent"
import { Tooltip, Input, Dropdown, Menu } from "antd"
import { Transforms } from 'slate'
import { ReactEditor, useSlate } from 'slate-react'
import { SlateContext } from "../Slate"
import { EventBuilder } from "../../Events"
import EventModel from '../../models/Event'
import UniversalDrop from "../../../core/wrappers/UniversalDrop"

const tempEvent = new EventModel()
export default ({ attributes, style, mask, prefix, eventID }) => {
  const { projectID } = useProject()
  const { profileID, permission } = useProfile()

  const [open, setOpen] = useState()

  const editor = useSlate()
  const project = getProject(projectID)
  const bank = project.getEventBank()

  const evt = bank[eventID]
  let script = prefix || ""

  const execute = gmRoll => {
    const {event, context} = createEvent({sourceID: profileID, gmRoll})
    event.roll(script, context).then(e => {
      applyEvent(event, context, {sourceID: profileID})
      event.record()
    })
  }

  const onDrop = (p,m,c)=>{
    const item = m.getItem()
    if (item.profileID) {
      const {event, context} = createEvent({sourceID: item.profileID, componentID: item.componentID, gmRoll: item.gmRoll})
      event.roll(script, context).then(e => {
        applyEvent(event, context, {sourceID: item.profileID, componentID: item.componentID, gmRoll: item.gmRoll})
        event.record()
      })
    }
  }

  function startEditing(e) {
    const { ref } = attributes
    if (!ref || !ref.current)
      return window.error("Unable to resolve document")

    const path = ReactEditor.findPath(editor, ReactEditor.toSlateNode(editor, ref.current))

    if (hasPermission(permission, "CHANGE"))
      window.build({title: "Change Event", width: "85vw", bodyStyle: {padding: 0}, style: {height: "85vh"}, app: EventBuilder, props: {defaultValue: script, onFinalize: res=>{
        Transforms.setNodes(editor, { script: res }, { at: path })
        window.close(true)
      }}})
  }

    
  function setMask(e) {
    const { ref } = attributes
    if (!ref || !ref.current)
      return window.error("Unable to resolve document")

    const path = ReactEditor.findPath(editor, ReactEditor.toSlateNode(editor, ref.current))

    if (hasPermission(permission, "CHANGE"))
      window.ask({title: "Set Button Label", allowEmpty: true, value: attributes.mask }, res=> {
        Transforms.setNodes(editor, { mask: res }, { at: path })
      })
  }



  const context = {source: {}, target: {}}
  context.source = {...getProfile(profileID).getContext()}

  const res = tempEvent.eval(mask || (evt ? (evt.mask || eventID) : (eventID)), context) ?? mask

  return <span onContextMenu={e=>e.stopPropagation()}>
    <Dropdown trigger="contextMenu" arrow={true} overlayStyle={{zIndex: 1550}} overlay={<Menu>
      <UniversalDrag onClick={e=>execute()} item={{ script, profileID }}>
        <Menu.Item>Execute</Menu.Item>
      </UniversalDrag>
      <UniversalDrag onClick={e=>execute(true)} item={{ script, profileID, gmRoll: true }}>
        <Menu.Item>GM Execute</Menu.Item>
      </UniversalDrag>
      {!editor._readOnly && hasPermission(permission, "WRITE") && <Menu.Item onClick={e=>setMask()}>Edit Label</Menu.Item>}
      {!editor._readOnly && hasPermission(permission, "WRITE") && <Menu.Item onClick={e=>startEditing()}>Edit Event</Menu.Item>}
    </Menu>}>
      <span>
        <UniversalDrop onClick={e=>editor._readOnly ? execute() : startEditing()} onDrop={onDrop} className="event" style={{ ...style, display: "inline" }} >
          <UniversalDrag style={{ display: "inline" }} item={{ script, profileID }}>
            {res}
          </UniversalDrag>
        </UniversalDrop>
      </span>
    </Dropdown>
  </span>
}