import Resource from './Resource'
import Account from '../../core/state/Account'
import { v4 } from 'uuid'

export default class Article extends Resource {
  constructor(ctx = {}) {
    super(ctx)

    this._id = ctx._id
    this.uid = ctx.uid || v4()
    this.meta = { name: ctx.name, creator: ctx.creator || Account.uid, created: Date.now() }
    this.body = []
    this.content = []
    this.tabs = []

    this.setMaxListeners(Infinity) // Potentially thousands of references due to wiki
  }
  
  // destroy(key, object) {
  //   delete this[key][object]
  //   this.emit("change", `${key}`)
  // }

  // rename(key, object, hint) {
  //   // need to preserve order
  //   let newTabs = {}
  //   for (let k in this[key][object])
  //     if (k === object)
  //       newTabs[hint] = this[key][object]
  //     else
  //       newTabs[k] = this[key][object]

  //   this[object] = newTabs
  //   this.emit("change", `${key}.${object}`)
  // }

  // list(key, object) {
  //   console.log(key, object, this)
  //   if (object == null)
  //     return Object.keys(this[key])
  //   return Object.keys(this[key][object])
  // }

  // set(key, object, data) {
  //   this[key][object] = data
  //   this.emit("change", `${key}.${object}`)
  // }

  // get(key, object) {g74
  //   return this[key][object]
  // }

  // update(key, object, data) {
  //   const workingObj = this[key][object]

  //   if (!workingObj || !data)
  //     workingObj = data
  //   else
  //     for (const k in data)
  //       workingObj[k] = data[k]

  //   this.emit("change", `${key}.${object}`)
  // }

  getTags() {
    const list = {}
    const content = this.content
    for (let i = 0; i < content.length; i++)
      if (content[i] && content[i].tag)
        list[i] = content[i].tag
    return list
  }

  getContent(state = this.state) {
    return this.content
  }

  createSection(section = { apps: [{}] }, index) {
    if (index != null)
      this.content.splice(parseInt(index), 0, section)
    else
      this.content.push(section)

    this.emit("change")//)//, `content`)
    this.emit("rebuild")
  }

  insertSection(index, section = { apps: [{}] }) {
    if (index != null)
      this.content.splice(parseInt(index), 0, section)
    else
      this.content.push(section)

    this.emit("change")//, `content`)
    this.emit("rebuild")
  }

  swapSections(startIndex, swapIndex) {
    const dupe = { ...this.content[startIndex] }
    this.content[startIndex] = this.content[swapIndex]
    this.content[swapIndex] = dupe
    this.emit("change")//, `content`)
    this.emit("rebuild")
  }

  removeSection(index) {
    this.content.splice(index, 1)
    this.emit("change")//, `content`)
    this.emit("rebuild")
  }

  setSection(index, data) {
    this.content[index] = data
    this.emit("change")// `content.${index}`)
    this.emit("rebuild")
  }

  setSectionApp(index, app, data) {
    this.content[index].apps[app] = data
    this.emit("change")// `content.${index}.apps.${app}`)
    this.emit("rebuild")
  }


  getSectionApp(index, app) {
    return this.content[index].apps[app]
  }

  updateSectionApp(index, app, data) {
    if (!this.content[index].apps[app] || !data)
      this.content[index].apps[app] = data
    else
      for (const key in data)
        this.content[index].apps[app][key] = data[key]

    this.emit("change")//`content.${index}.apps.${app}`)
    this.emit("rebuild")
  }

  updateSection(index, data) {
    if (!this.content[index] || !data)
      this.content[index] = data
    else
      for (const key in data)
        this.content[index][key] = data[key]
    this.emit("change")//`content.${index}`)
    this.emit("rebuild")
  }

  getBody() {
    return this.body
  }

  setBody(data) {
    this.body = data
    this.emit("change")
    this.emit("rebuild")
  }

  pack() {
    return {
      _id: this._id,
      uid: this.uid,
      body: this.body || [],
      // content: this.content || [],
      // tabs: this.tabs || [],
      meta: this.meta || {}
    }
  }

  deserialize(json) {
    this.load(JSON.parse(json))
  }

  load(json) {
    this._id = json._id
    this.uid = json.uid || this.uid
    this.body = json.body || []
    this.content = (json.content || []).filter(value => { return value != null })
    this.tabs = this.tabs || []
    if (!json.tabs && json.content && !this.body.length) {
      //convert to tabs
      for (const section of json.content)
        for (const { data, app } of section.apps)
          if (app === "text" || app === "table")
            for (const dat of data)
              this.body.push(dat)
    }

    this.meta = json.meta || this.meta
    this.loaded = true
    this.emit("ready")
    this.emit("rebuild")
  }

  serialize() {
    return JSON.stringify(this.pack())
  }
}