import React, { useState } from 'react'
import useProfile from '../hooks/useProfile'
import { Column, Button } from '../../UI'
import { ProfileOutlined } from '@ant-design/icons'
import useArticle from "../hooks/useArticle"

export default ({ conditional }) => {
  const { profileID } = useProfile()
  const { articleID, tabs } = useArticle()
  const [open, setOpen] = useState()
  if (conditional || !Object.keys(tabs).length)
    return <span />

  const overlay = <Column>
    Bookmarks
    {Object.keys(tabs).map((tab, i) => <a className="text-dull pad-h" children={tabs[tab]} key={tab} title="Jump to bookmark" onClick={e => {
      const d = document.getElementById(`${articleID}-${tab}`)
      if (d)
        d.scrollIntoView({ behavior: "smooth", inline: "center", block: 'start' })
      
      setOpen()
    }} />)}
  </Column>

  return <Button onClick={e=>setOpen(!open)} visible={open} small childClass="grow" className="background" style={{ marginRight: "0.5rem" }} title={overlay}>
    <ProfileOutlined/>
  </Button>
}