import EventEmitter from 'events'

export default class Serial extends EventEmitter {
  unpack(json) {
    // virtual method
    for (const key in json)
      if (!(json[key] instanceof Function))
        this[key] = json[key]

    this.emit("ready")
  }

  pack() {
    // virtual method
    const o = {}
    for (const key in this)
      if (!(this[key] instanceof Function))
        o[key] = this[key]
    return o
  }

  toString() {
    return JSON.stringify(this.pack())
  }

  fromString(str) {
    this.load(JSON.parse(str))
  }

  serialize() {
    return [] // array of bytes
  }

  deserialize(byteArray) {
    // translate bytes to JSON
  }
}