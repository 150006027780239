import React, { useContext, useState } from 'react'
import useProject, { getProject, hasPermission, hasRight } from '../../hooks/useProject'
import { useHook, getProfile, ProfileProvider } from '../../hooks/useProfile'
import { getArticle, ArticleProvider } from '../../hooks/useArticle'
import Nav from '../../state/Navigation'
import UniversalDrag from '../../../core/wrappers/UniversalDrag'
import { Column, Row, Button } from '../../../UI'
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons'
import Slate, { SlateContext } from '../Slate'

import ProfileNav from '../ProfileNav'

import { Tooltip, Tag } from 'antd'
import ProfileDrop from "../../ProfileDrop"

import { v4 } from 'uuid'
import { CaretRightOutlined, CaretLeftOutlined } from '@ant-design/icons'
import { ProfileName } from "../../Profile"
import { applyEvent, createEvent } from "../../hooks/useEvent"

import Context from "../../util/Context"

export function ActionsMenu({profileID, componentID: cID, onSelect}){
  const { projectID } = useProject()
  const [path, setPath] = useState()
  const [componentID, setComponentID] = useState(cID)
  const [subCategory, setSubCategory] = useState()
  const [tables, setTables] = useState()

  const {events, rollTables} = getProject(projectID).getBank()
  function execute(eventID, gmRoll, prefix) {
    const {event, context} = createEvent({sourceID: profileID, componentID, gmRoll})

    let script = prefix || ""
    if (eventID && events[eventID])
      script += `\n${events[eventID].script}`

    event.meta.gmRoll = gmRoll
    event.roll(script, context).then(e => {
      applyEvent(event, context, {sourceID: profileID, componentID})
      event.record()
    })
  }

  function executeTable(tableID, gmRoll, prefix) {
    window.ask({title: "Table roll result (Equation or Number)", value: rollTables[tableID].die}, res=>{
      const {event, context} = createEvent({sourceID: profileID, componentID, gmRoll})

      const r = Context.roll(res, {})
      event.meta.gmRoll = gmRoll
      event.roll(`%🎲=${Number(r.equation)}\n${rollTables[tableID].script}`, context).then(e => {
        for (const key in rollTables[tableID].ranges) {
          const reg = /([\d]+)(-([\d]+))?/i
          const match = key.match(reg)
          if (match) 
            if ((match[1] === Number(r.equation) && (match[3] === null || match[3] === undefined)) || (Number(r.equation) >= match[1] && Number(r.equation) <= match[3])) {
              event.text = [...rollTables[tableID].ranges[key]]
              break
            }
        }
        event.record()
      })
    })
  }

  const reset = e=>{
    setSubCategory()
    setPath()
    setTables()
  }

  function buildMenu(pID, submenu){
    const list = []
    const categories = {}
    const componentCats = {}
    const profile = getProfile(pID)
    const {components, rollTables} = getProject(projectID)
    const mention = /(\[=([-\d\w]+)=\])(`([^`]+)`)?/i
    
    if (!submenu && !subCategory)
      for (const key in components) {
        if (components[key].hotbar) {
          const cTable = profile.getComponentTable() || {}
          if (cTable[key] && Object.keys(cTable[key]).length) {
            componentCats[key] = componentCats[key] || {}
            for (const id in cTable[key]) {
              const match = String(id).match(mention)
              if (match) 
                componentCats[key][id] = match[2]
            }
          }
        }
      }

    for (const key in events) {
      const {name, hotbar, mask, tags, script} = events[key]
      const hotkey = String(hotbar).toLowerCase()
      if (hotbar) {
        categories[hotkey] = categories[hotkey] || []
        let shouldContinue = true
        if (tags && tags.length) {
          shouldContinue = false
          const tList = [...tags]
          for (const tag of tags) 
            if (profile.meta && profile.meta.tags && profile.meta.tags.includes(tag)) {
              tList.splice(tList.indexOf(tag), 1)
              if (tList.length <= 0) {
                shouldContinue = true
                break
              }
            }
        }
        if (shouldContinue)
          categories[hotkey].push(<UniversalDrag key={v4()} className="row clickable between" onClick={e=>{
            execute(key)
            if (onSelect)
              onSelect(key)
            e.stopPropagation()
          }} item={{ script, componentID, profileID }}>
            <Row center className="pad-h">
              {name || mask || key}
            </Row>

            <UniversalDrag className="row center effect" onClick={e=>{
              execute(key, true)
              if (onSelect)
                onSelect(key)
              e.stopPropagation()
            }} item={{ script, eventID: key, componentID, profileID, gmRoll: true }}>
              GM
            </UniversalDrag>
          </UniversalDrag>)
      }
      else if (!submenu) {
        let shouldContinue = true
        if (tags && tags.length) {
          shouldContinue = false
          const tList = [...tags]
          for (const tag of tags) 
            if (profile.meta && profile.meta.tags && profile.meta.tags.includes(tag)) {
              tList.splice(tList.indexOf(tag), 1)
              if (tList.length <= 0) {
                shouldContinue = true
                break
              }
            }
        }
        if (shouldContinue)
          list.push(<UniversalDrag key={v4()} className="row clickable between" onClick={e=>{
              execute(key)
              if (onSelect)
                onSelect(key)
              e.stopPropagation()
            }} item={{ script, componentID, profileID }}>
              <Row center className="pad-h">
                {name || mask || key}
              </Row>

              <UniversalDrag className="row center effect" onClick={e=>{
                execute(key, true)
                if (onSelect)
                  onSelect(key)
                e.stopPropagation()
              }} item={{ script, eventID: key, componentID, profileID, gmRoll: true }}>
                GM
              </UniversalDrag>
            </UniversalDrag>)
      }
    }

    if (path)
      return [
        <Row className="clickable" onClick={reset}><Row center className="pad-h"><CaretLeftOutlined/>Back</Row></Row>, 
        ...Object.keys(componentCats[path]).map(k=><Row key={v4()} between className="clickable" onClick={e=>{reset(); setComponentID(componentCats[path][k])}}>
          <Row center className="pad-h">
            <CaretRightOutlined/>
            <ProfileProvider profileID={componentCats[path][k]}>
              <ProfileName/>
            </ProfileProvider>
          </Row>
        </Row>)
      ]

    if (subCategory)
      return [<Row className="clickable" onClick={reset}><Row center className="pad-h"><CaretLeftOutlined/>Back</Row></Row>,...categories[subCategory]]


    const newList = []

    if (tables) {
      for (const key in rollTables) 
        newList.push(<Row key={v4()} between className="clickable" onClick={e=>{
          executeTable(key)
          if (onSelect)
            onSelect(key)
          e.stopPropagation()
        }}>
          <Row center className="pad-h">
            {rollTables[key].name || key}
          </Row>

          <Row center className="effect" onClick={e=>{
            executeTable(key, true)
            if (onSelect)
              onSelect(key)
            e.stopPropagation()
          }}>
            GM
          </Row>
        </Row>)

      return [<Row className="clickable" onClick={reset}><Row center className="pad-h"><CaretLeftOutlined/>Back</Row></Row>,...newList]
    }

    for (const k in componentCats)
      newList.push(<Row key={v4()} between className="clickable" onClick={e=>{reset(); setPath(k);}}>
        <Row center className="pad-h">
          <CaretRightOutlined/>
          {components[k].name || k}
        </Row>
      </Row>)

    for (const k in categories)
      if (categories[k].length)
        newList.push(<Row key={v4()} between className="clickable" onClick={e=>{reset(); setSubCategory(k);}}>
          <Row center className="pad-h">
            <CaretRightOutlined/>
            {k}
          </Row>
        </Row>)
        
    if (Object.keys(rollTables).length)
      newList.push(<Row key={v4()} between className="clickable" onClick={e=>{reset(); setTables(true);}}>
      <Row center className="pad-h">
        <CaretRightOutlined/>
        Roll Tables
      </Row>
    </Row>)

    return [...newList, ...list]
  }

  return <Column className="black size-large">
    {/* <Input size="small" placeholder="Search for Events" className="search-input" prefix={<Button small><SearchOutlined/></Button>}/> */}
    <Column style={{border: "1px solid #333", overflowY: "auto", maxHeight: "300px"}}>
      {componentID && <Row>
        <Row center className="pad-h size-small"><i>Using</i></Row> 
        <Tag closable onClose={e=>{reset(); setComponentID()}}>
          <ProfileProvider profileID={componentID}>
            <ProfileName/>
          </ProfileProvider>
        </Tag>
      </Row>}
      {buildMenu(componentID || profileID)}
    </Column>
  </Column>
}

export default opts => {
  const { profileID, mask, selected, item, rootID, componentID } = opts
  const [clicked, setClicked] = useState(false)
  const [contextClicked, setContextClicked] = useState(false)
  const ctx = useContext(SlateContext)
  const { projectID } = useProject()
  const { loaded, permission } = useHook(profileID)
  const profile = getProfile(profileID)
  const text = profileID ? (mask || (hasRight(profileID, "SEE") ? profile.getName() : profile.getAlias())) : "<Deleted>"

  const onOpen = e => {
    if (!hasPermission(permission, "SEE"))
      return window.notify("Not Permitted")

    if (!projectID)
      return window.notify("Not Permitted")

    window.blurAll()
    Nav.setProfile(profileID)
    setClicked(false)
  }

  if (!loaded)
    return <span><LoadingOutlined /></span>

  const articleID = profile.getArticleID()
  const article = getArticle(articleID)

  const style = {
    width: "100%",
    maxHeight: "30vh",
    overflowY: "auto",
    fontSize: "1.1em",
    lineHeight: "150%",
  }
  // const seed = v4()
  // const fallback = hasRight(profileID, "SEE") && !hasRight(profileID, "READ") ? <TitleBar noOptions ctx={{ ...ctx, parentprofileID: profileID, profileID, articleID, preview: true, seed }} /> : null
  const child = <div style={{ fontSize: "0.8rem", color: 'white' }}>
    <Row between className="text-white">
      <Row center className="reference pad-h" onClick={onOpen}>
        <span className="mention">{text}</span>
      </Row>
      <Button size="small" onClick={e => setClicked(false)} color="inherit">
        <CloseOutlined />
      </Button>
    </Row>
    {/* <NavBar ctx={{ ...ctx, parentprofileID: profileID, profileID, articleID, preview: true, seed }} conditional={true} /> */}

    <ProfileDrop placement="bottom">
      <ArticleProvider articleID={articleID}>
        <ProfileNav />
      </ArticleProvider>
    </ProfileDrop>

    <Column className="pad-l background-tint" style={style}>
      {article ? <SlateContext.Provider value={{...ctx, flatten: true}}>
        <Slate ctx={{ articleID }} readOnly={true} raw={article.body} />
      </SlateContext.Provider> : <Column grow center>No Article</Column>}
    </Column>
  </div>

  function changeVisiblity(e) {
    if (hasPermission(permission, "CHANGE") || permission == null || (!getProject().profiles.includes(profileID) && !profileID.match("-temp-")))
      setClicked(e)
    else {
      window.warning("Permission Denied")
      setClicked(false)
    }
  }

  function onChangeContext(e) {
    if (hasPermission(permission, "CHANGE") || permission == null || (!getProject().profiles.includes(profileID) && !profileID.match("-temp-")))
      setContextClicked(e)
    else {
      window.warning("Permission Denied")
      setContextClicked(false)
    }
  }



  // function buildMenu(profileID, submenu){
  //   const list = []
  //   const categories = {}
  //   const componentcats = {}
  //   const profile = getProfile(profileID)
  //   const {components} = getProject(projectID)
  //   const mention = /(\[=([-\d\w]+)=\])(`([^`]+)`)?/i
  //   if (!submenu)
  //     for (const key in components) {
  //       // if (components[key].hotbar) {
  //         componentcats[key] = componentcats[key] || {}
  //         for (const id in profile.getComponentTable()[key]) {
  //           const match = String(id).match(mention)
  //           if (match) {
  //             componentcats[key][id] = buildMenu(match[2], true)
  //           }
  //         }
  //       // }
  //     }

  //   for (const key in events) {
  //     const {name, hotbar, mask, tags, script} = events[key]
  //     if (hotbar) {
  //       categories[String(hotbar).toLowerCase()] = categories[String(hotbar).toLowerCase()] || []
  //       let shouldContinue = true
  //       if (tags && tags.length) {
  //         shouldContinue = false
  //         for (const tag of tags) 
  //           if (profile.meta && profile.meta.tags && profile.meta.tags.includes(tag)) {
  //             shouldContinue = true
  //             break
  //           }
  //       }
  //       if (shouldContinue)
  //         categories[String(hotbar).toLowerCase()].push(<Menu.SubMenu onTitleClick={e=>{
  //           execute(key)
  //           setContextClicked(false)
  //           e.domEvent.stopPropagation()
  //         }} key={v4()} title={<UniversalDrag style={{display: "inline"}} onClick={e=>{
  //           execute(key)
  //           setContextClicked(false)
  //           e.stopPropagation()
  //         }} item={{ script, rootID, profileID }}>
  //           {name || mask || key}
  //         </UniversalDrag>}>
  //           <UniversalDrag style={{display: "inline"}} onClick={e=>{
  //             execute(key, true)
  //             setContextClicked(false)
  //             e.stopPropagation()
  //           }} item={{ script, eventID: key, rootID, profileID, gmRoll: true }}>
  //             <Button>
  //               GM
  //             </Button>
  //           </UniversalDrag>
  //         </Menu.SubMenu>)
  //     }
  //     else if (!submenu) {
  //       let shouldContinue = true
  //       if (tags && tags.length) {
  //         shouldContinue = false
  //         for (const tag of tags) 
  //           if (profile.meta && profile.meta.tags.includes(tag)) {
  //             shouldContinue = true
  //             break
  //           }
  //       }
  //       if (shouldContinue)
  //         list.push(<Menu.SubMenu key={v4()} onTitleClick={e=>{
  //           execute(key)
  //           setContextClicked(false)
  //           e.domEvent.stopPropagation()
  //         }} title={<UniversalDrag style={{display: "inline"}} onClick={e=>{
  //           execute(key)
  //           setContextClicked(false)
  //           e.stopPropagation()
  //           }} item={{ script, rootID, profileID }}>
  //             {name || mask || key}
  //           </UniversalDrag>}>
  //           <UniversalDrag style={{display: "inline"}} onClick={e=>{
  //             execute(key, true)
  //             setContextClicked(false)
  //             e.stopPropagation()
  //           }} item={{ script, eventID: key, rootID, profileID, gmRoll: true }}>
  //             <Button>
  //               GM
  //             </Button>
  //           </UniversalDrag>
  //         </Menu.SubMenu>)
  //     }
  //   }

  //   const newList = []
  //   for (const k in componentcats)
  //     newList.push(<Menu.SubMenu onTitleClick={e=>e.domEvent.stopPropagation()} key={v4()} title={k}>
  //       {Object.keys(componentcats[k]).map(id=><Menu.SubMenu onTitleClick={e=>e.domEvent.stopPropagation()} key={v4()} title={id}>{componentcats[k][id]}</Menu.SubMenu>)}
  //     </Menu.SubMenu>)

  //   for (const k in categories)
  //     if (categories[k].length)
  //       newList.push(<Menu.SubMenu onTitleClick={e=>e.domEvent.stopPropagation()} key={k} title={k}>{categories[k]}</Menu.SubMenu>)

  //   return [...newList, ...list]
  // }

  // const menu = <Menu onClick={e=>e.domEvent.stopPropagation()}>
  //   {contextClicked && buildMenu(profileID)} 
  // </Menu>
  
  const display = opts.children ? opts.children : <span className="mention" style={{textDecoration: selected ? "underline" : undefined}}>
    {text || profile.getName()}
  </span>

  const inline = opts.style ? opts.style : { display: "inline" } 
  return <UniversalDrag className={opts.className} style={inline} item={{ profileID, ...item }}>
    <ProfileProvider profileID={profileID} style={inline}>
      <span onContextMenu={e=>e.stopPropagation()}>
        <ProfileDrop placement="bottom" style={inline} onDrop={opts.onDrop}>
          <Tooltip trigger="contextMenu" placement="bottom" visible={contextClicked} onVisibleChange={onChangeContext} overlay={<Column onClick={e=>e.stopPropagation()}>{contextClicked && <ActionsMenu componentID={componentID} profileID={rootID || profileID} onSelect={e=>setContextClicked(false)}/>}</Column>} overlayInnerStyle={{ background: "transparent", padding: 0, minHeight: 0, minWidth: "200px"}}>
            {opts.onClick ? display : <Tooltip placement={opts.placement} title={child} visible={clicked} onVisibleChange={changeVisiblity} trigger="click" overlayInnerStyle={{ padding: "0", border: "1px solid #333333", background: "#333333" }} overlayStyle={{ maxWidth: "30vw" }}>
              {display}
            </Tooltip>}
          </Tooltip>
        </ProfileDrop>
      </span>
    </ProfileProvider>
  </UniversalDrag>
}