import React, { useState } from 'react'
import useRadial from './hooks/useRadial'
import Radial from './state/Radial'
import {Column} from '../UI'


export default opts => {
  const { x, y, opened, options, menu = "root", hideClose } = useRadial()
  const [text, setText] = useState()
  const popMenu = e => {
    const split = menu.split("/")
    if (split.length > 1) {
      split.splice(split.length - 1, 1)
      let m = ""
      for (const key of split)
        m += key + "/"

      m = m.substring(0, m.length - 1)
      Radial.setMenu(m)
    }
    else
      Radial.close()

    e.stopPropagation()
  }

  const { min, max } = Math

  const size = 100
  if (!menu || !options || !options[menu])
    return <div/>

  let theta = options[menu].length === 3 ? -5 * Math.PI / 6 : Math.PI / -2
  const dist = 125
  const increment = 2 * Math.PI / options[menu].length
  const xPos =  min(max(x, size), window.innerWidth - size)
  const yPos = min(max(y, size), window.innerHeight - size)
  const root = <div className="absolute" style={{
    transform: "translate(-50%, -50%)",
    left: xPos + "px",
    top: yPos + "px"
  }}>
    {/* <Column className="black round pad-l clickable" onClick={popMenu}>
      {(menu && menu.split("/").length > 1) ? <CaretLeftOutlined /> : <CloseOutlined />}
    </Column> */}
     
    {text && <Column className="black-tint rounded pad-h bold size-2 clickable text-white">
      {text}
    </Column>}
  </div>

  return <div className={`${opened ? "visible" : "invisible no-click"} fit-x fit-y fixed`} style={{ zIndex: 1500 }} onClick={e => Radial.close()} onContextMenu={e => {
    Radial.close()
    e.preventDefault()
    e.stopPropagation()
    return false
  }}>
    {root}
    {options[menu].map((v, i) => {
      theta += increment
      if (Array.isArray(v)) {
        let tempTheta = theta - increment/2
        return v.map((opt,i)=>{
          tempTheta += increment/(v.length+1)
          const { title, icon, onSelect } = opt
          const transform = "translate(-50%, -50%)"
          const left = (xPos + Math.cos(tempTheta) * dist) + "px"
          const top = (yPos + Math.sin(tempTheta) * dist) + "px"
          
          return <Column className="fixed black round pad-l clickable" onMouseLeave={e=>setText()} onMouseEnter={e=>setText(title)} style={{transform, left, top}} key={i} onClick={e => {
            if (onSelect instanceof Function) {
              if (onSelect(e) !== true)
                Radial.close()
            }
            else if (onSelect)
              Radial.setMenu(onSelect)
            else
              return popMenu(e)
    
            e.stopPropagation()
          }}>
            {icon || title}
          </Column>
        })
      }
      
      const { title, icon, onSelect } = v
      const transform = "translate(-50%, -50%)"
      const left = (xPos + Math.cos(theta) * dist) + "px"
      const top = (yPos + Math.sin(theta) * dist) + "px"
      return <Column className="fixed black round pad-l clickable" onMouseLeave={e=>setText()} onMouseEnter={e=>setText(title)} style={{transform, left, top}} key={i} onClick={e => {
        if (onSelect instanceof Function) {
          if (onSelect(e) !== true)
            Radial.close()
        }
        else if (onSelect)
          Radial.setMenu(onSelect)
        else
          return popMenu(e)

        e.stopPropagation()
      }}>
        {icon || title}
      </Column>
    })}
    {/* <ThemeProvider theme={theme}>
      <PieMenu
        radius={`${size}px`}
        centerRadius='30px'
        centerX={min(max(x, size), window.innerWidth - size) + "px"}
        centerY={min(max(y, size), window.innerHeight - size) + "px"}
        Center={Center}
        opened={opened}
        onClick={e=>e.stopPropagation()} 
      >
        {options && options[menu].map((v, i) => {
          const { title, onSelect } = v
          return <Slice key={i} onSelect={e => {
            if (onSelect instanceof Function) {
              if (onSelect(e) !== true)
                Radial.close()
            }
            else if (onSelect)
              Radial.setMenu(onSelect)
            else
              return popMenu(e)

            e.stopPropagation()
          }}>
            <Column center>
              {title}
            </Column>
          </Slice>
        })}
      </PieMenu>
    </ThemeProvider> */}
  </div >
}