import React, { useEffect, useState, useContext, createContext } from 'react'
import Resources from '../state/Resources'
import Content from '../models/Content'
import Nav from '../state/Navigation'
import clone from 'rfdc'

const copy = clone()
const ContentContext = createContext()

export { ContentContext }

const content = new Content()
const { Community } = Resources

export function getContent(contentID) {
  return Community.get(contentID) || content
}

export default function useContent() {
  return useContext(ContentContext)
}

export function useHook(contentID) {
  const [loaded, setLoaded] = useState()
  const [meta, setMeta] = useState()
  const [pub, setPublic] = useState()
  const [targetID, setTargetID] = useState()

  useEffect(() => {
    const content = getContent(contentID)
    function change() {
      setPublic(content.getPublic())
      setMeta({...content.meta})
      setLoaded(content.loaded)
      setTargetID(content.getTarget())
    }
    change()

    content.on("change", change)
    content.on("ready", change)

    return function () {
      content.off("change", change)
      content.off("ready", change)
    }
  }, [contentID])

  return { contentID, targetID, loaded, meta, published: pub }
}

export function ContentProvider({ contentID, children }) {
  return <ContentContext.Provider value={useHook(contentID)} children={children} />
}
