import Serial from '../../core/models/Serial'
export class Radial extends Serial {
  open({ x, y, options, hideClose, menu }) {
    this.opened = true
    this.x = x
    this.y = y
    this.options = options
    this.hideClose = hideClose
    this.menu = menu
    this.emit("change")
  }

  setPosition(x,y) {
    this.x = x
    this.y = y
    this.emit("change")
  }

  setMenu(newMenu) {
    this.menu = newMenu
    this.emit("change")
  }

  close() {
    this.opened = false
    this.emit("change")
  }
}

const state = new Radial()
export default state