import React, { useState, useEffect, useRef } from 'react'

import CommandField from './CommandField'
import Context from '../util/Context'

import Logs from '../state/Logs'
import useEvent, { getEvent, EventProvider, createEvent } from '../hooks/useEvent'
import { getProject, hasRight } from '../hooks/useProject'
import Slate from './Slate'

import { Scroll, Row, Column, Button, Div } from '../../UI'
import useEvents from '../../core/hooks/useEvents'

import { Tooltip } from 'antd'
import { ExperimentOutlined, CloseOutlined, CaretRightFilled, ArrowRightOutlined } from '@ant-design/icons'

import UniversalDrag from "../../core/wrappers/UniversalDrag"
import UniversalDrop from "../../core/wrappers/UniversalDrop"

import {Color} from 'three'
import DayJS from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { ProfileName, ProfileChip } from "../Profile"
import Navigation from '../state/Navigation'
import Mention from '../slate/inline/Mention'

import { dicePool } from '../models/Event'
import rfdc from "rfdc"

const copy = rfdc()

DayJS.extend(relativeTime)

const roll = (c, i, search) => {
  const eq = search.replace("roll", "").trim()
  Logs.chat(`{${eq}}`)
}

const commands = {
  "=": search => {
    return [
      {
        name: "Execute Equation",
        desc: Context.resolve(search, {}) || "Enter in an equation, ex. d20+3",
        icon: <ExperimentOutlined />,
        result: c => roll(c, null, search)
      },
    ]
  },
  "/": search => {
    // do global filtering in here, regex /roll etc etc
    return [
      {
        name: "Roll dice, known as /roll in other tools",
        desc: "Enter in an equation, ex. d20+3",
        icon: <ExperimentOutlined />,
        result: c => roll(c, null, search)
      },
    ]
  }
  // "=": {
  //   menu: search => {
  //     return [
  //       {
  //         name: "Macro '='",
  //         desc: Context.resolve(search, {}) || "Enter in an equation, ex. d20+3",
  //         icon: <Functions />,
  //         result: {
  //           chat: cb => {
  //             Context.exec(search || `ask("Enter in an equation")`, {}, eq => {
  //               cb(eq)
  //             })
  //           }
  //         }
  //       },
  //     ]
  //   }
  // },
  // "!": {
  //   menu: search => {
  //     let mentions = []
  //     for (let key in Context.getScripts()) {
  //       let script = Context.getScripts()[key]
  //       if (script.default) {
  //         mentions.push({
  //           name: script.name || key,
  //           desc: script.desc,
  //           result: { chat: cb => { Context.execute(script.default, {}, res => { console.log(res); cb(res.result) }) } }
  //         })
  //       }
  //     }
  //     if (search) {
  //       mentions = mentions.filter(a => {
  //         return a.name.toLowerCase().indexOf(search.toLowerCase()) > -1
  //       })
  //     }

  //     return mentions
  //   }
  // },
  // "@": {
  //   menu: (search) => {
  //     return [
  //       { name: "New Article '@'", desc: "Create a new Article", icon: <NoteAdd />, result: { type: "mention", text: "", mask: "Mentioned" } },
  //     ]
  //   }
  // },
  // "/": {
  //   menu: (search) => {
  //     return [
  //       {
  //         name: "Act", desc: "", icon: <Link />, result: {
  //           chat: (cb, search, cmd) => {
  //             cb([`[h3]${search}`])
  //           }
  //         }
  //       },
  //       {
  //         name: "Prompt", desc: "", icon: <Link />, result: {
  //           chat: (cb, search, cmd) => {
  //             cb([`[h1]${search}`])
  //           }
  //         }
  //       },
  //       {
  //         name: "Yell", desc: "", icon: <Link />, result: {
  //           chat: (cb, search, cmd) => {
  //             cb([`[h1]${search}`])
  //           }
  //         }
  //       },
  //       {
  //         name: "Whisper", desc: "", icon: <Link />,
  //         result: {
  //           chat: (cb, search, cmd) => {
  //             cb([`[h1]${search}`])
  //           }
  //         }
  //       },
  //     ].filter(a => {
  //       if (!search) {
  //         return true
  //       }
  //       return a.name.toLowerCase().indexOf(search.toLowerCase()) > -1
  //     })
  //   },
  // },
  // "/": {
  //   menu: search => {
  //     return [
  //       { name: "Macro '='", desc: "Roll an Equation", icon: <Functions />, result: { text: "=" } },
  //       { name: "Script '!'", desc: "Run a Script", icon: <Code />, result: { text: "!" } },
  //       // { name: "Mention '@'", desc: "Mention an Article", icon: <Description />, result: { text: "@" } },
  //     ].filter(a => {
  //       if (!search) {
  //         return true
  //       }
  //       return a.name.toLowerCase().indexOf(search.toLowerCase()) > -1
  //     })
  //   }
  // }
}

const getContrastYIQ = function(color) {
  var hex = '#'
  var r,g,b
  if (color.indexOf(hex) > -1) {
    r = parseInt(color.substr(1,2),16)
    g = parseInt(color.substr(3,2),16)
    b = parseInt(color.substr(5,2),16)
  }
  else {
    color = color.match(/\d+/g)
    r = color[0]
    g = color[1]
    b = color[2]
  }

  var yiq = ((r*299)+(g*587)+(b*114))/1000
  return (yiq >= 128) ? 'black' : 'white'
}

export const DiceRoller = opts => {
  const [open, setOpen] = useState(false)
  const [mode, setMode] = useState(false)
  const [pool, setPool] = useState({})
  const [normalPool, setNormalPool] = useState({})

  const addDice = (dice, normal) => {
    const target = normal ? normalPool : pool
    const d = copy(target)
    d[dice] = d[dice] || 0
    d[dice]++
    if (normal)
      setNormalPool(d)
    else
      setPool(d)
  }

  const removeDice = (dice, normal) => {
    const target = normal ? normalPool : pool
    const d = copy(target)
    d[dice] = d[dice] || 0
    d[dice]--
    if (d[dice] <= 0)
      delete d[dice]    
    if (normal)
      setNormalPool(d)
    else
      setPool(d)
  }

  const normalDie = {
    "d4": "/dice/d4.svg",
    "d6": "/dice/d6.svg",
    "d8": "/dice/d8.svg", 
    "d10": "/dice/d10.svg",
    "d12": "/dice/d12.svg",
    "d20": "/dice/d20.svg",
    "d100": "/dice/d10.svg",
  }

  const rollDice = (clear, gmRoll) => {
    const {event, context} = createEvent({gmRoll})
    let equation = ""
    if (Object.keys(pool).length) {
      equation += "_swffg="
      for (const die in dicePool.pool)
        if (pool[die])
          equation += `${die}=${pool[die]}|`

      equation += "\n"
    }

    if (Object.keys(normalPool).length) {
      equation += ">{"
      
      for (const die in normalDie)
        if (normalPool[die])
          equation += `${normalPool[die] || 1}${die} + `

      equation = equation.substring(0, equation.length-3)
      equation += "}"
    }

    event.roll(equation, context).then(e=>event.record())

    setOpen()
    if (clear) {
      setPool({})
      setNormalPool({})
    }
  }

  return <Row grow between className="black" style={{ boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.7)" }} onMouseDown={e => e.preventDefault()}>
    <Column grow center>
      <Row wrap center>
        {([...Object.keys(dicePool.pool)]).map((key, i) => {
          const color = getContrastYIQ(("#"+new Color(dicePool.pool[key].display.background).getHexString()))
          return <Button mouseEnterDelay={0.5} title={dicePool.pool[key].name || key} key={i} childStyle={dicePool.pool[key] ? {...dicePool.pool[key].display, color, fontWeight: "bold"} : undefined} small 
            onClick={e=>addDice(key)} 
            onContextMenu={e => {
              removeDice(key)
              e.preventDefault()
            }}>
            {pool[key] || 0}
          </Button>
        })}
      </Row>
      <Row wrap center>
        {Object.keys(normalDie).map((key, i) => <Button mouseEnterDelay={0.5} title={key} key={i} className="relative" small
          onClick={e=>addDice(key, true)} 
          onContextMenu={e => {
            removeDice(key, true)
            e.preventDefault()
          }}>
          <Div absolute fit top left>
            <img src={normalDie[key]} className="contain no-click fit-y" alt="" style={{filter: "invert(100%)", opacity: "50%", objectPosition: "center"}}/>
          </Div>
          <b style={{fontWeight: "bold", textShadow: "0px 0px 4px black"}}>{normalPool[key] || 0}</b>
        </Button>)}
      </Row>
    </Column>
    <Button small childClass="grow" className="background" onClick={e => rollDice()} onContextMenu={e=>{rollDice(undefined, true); e.preventDefault()}}>
      Roll
    </Button>
  </Row>
}



const fade = 120 * 1000 // chat hang time
const watching = {}
export const ChatBubble = ({ browsing, index }) => {
  const { loaded, meta, text, script, tags, eventID, api, defines, rolls } = useEvent()
  const ref = useRef()
  const evt = getEvent(eventID)

  const { name, created, gmRoll } = loaded ? meta : { created: Date.now() }
  const timeLeft = (created + fade) - Date.now()

  const [expired, setExpired] = useState((timeLeft || !loaded) ? false : true)
  const [minimized, setMinimized] = useState(evt.eval(evt.api["mini"]))
  const [force, setForce] = useState(watching[eventID] ? {} : undefined)
  
  useEffect(() => {
    const evt = getEvent(eventID)
    setMinimized(evt.eval(evt.api["mini"]))
    if (loaded && evt.meta) {
      const { created } = evt.meta
      const timeLeft = (created + fade) - Date.now()
      console.log(timeLeft)
      setExpired(timeLeft <= 0)
    }
    if (loaded) {
      watching[eventID] = true
      setForce({})
    }
  }, [loaded, eventID, index, browsing])
  
  if (!loaded)
    return <div className="invisible" ref={ref} style={{ transition: "opacity 0.15s, transform 0.25s", transform: "translate(100%, 0%)", marginTop: index == null ? undefined : "1px", borderRadius: "4px" }}/>

  if (gmRoll && !getProject().isOwner())
    return <span />


  if (!force)
    setTimeout(()=> {
      const evt = getEvent(eventID)
      setMinimized(evt.eval(evt.api["mini"]))
      if (loaded && evt.meta) {
        const { created } = evt.meta
        const timeLeft = (created + fade) - Date.now()
        console.log(timeLeft)
        setExpired(timeLeft <= 0)
      }
      setForce({})
    }, Math.max(timeLeft, 0))
  // const onEntered = () => {
  //   // console.log(browsing)
  //   if (ref.current && index === 0 && !browsing)
  //     ref.current.scrollIntoView({ inline: "end", block: "end", behavior: "smooth" })
  // }
  let effectButton = false && script ? <UniversalDrag className="effect grab row center" style={{ marginLeft: "4px" }} item={{ script }}>
    <b>FOLLOWUP</b>
  </UniversalDrag> : null

  let tagList = []
  let i = 0

  const isMobile = false//window.mobilecheck()

  for (const tag in tags) {
    const value = evt.eval(tags[tag])
    if (value === true)
      tagList.push(<Row center key={i} className="text-white black rounded size-small" style={{ marginLeft: "4px", padding: "2px 8px" }}><b>{String(tag).toUpperCase()}</b></Row>)
    // else if (isNaN(value))
    //   tags.push(<Row key={i} style={{ marginLeft: "4px" }}>{tag}{value}</Row>)
    else if (value != null && value !== false)
      tagList.push(<Row center key={i} className="text-white black rounded size-small" style={{ marginLeft: "4px", padding: "2px 8px" }}><b>{String(tag).toUpperCase()}&nbsp;{value}</b></Row>)
    i++
  }
  
  const b = api["classcolor"] || "black"
  const c = getContrastYIQ(("#"+new Color(b).getHexString()))

  tagList = tagList.reverse()
  const col = gmRoll ? "#34DBDB77" : "#222222"

  let finalText = text

  const scrambled = getEvent(eventID).eval(api["scramble"])
  if (scrambled) 
    finalText = meta.scrambled
  
  console.log(getEvent(eventID))
  const canBeToggled = (text != null && (!Array.isArray(text) || text.length)) 
  const dImg = api["swffg"] ? "swimg" : "img"

  return <div ref={ref} className={`click ${(force == null || !(expired && !browsing)) ? "visible" : "invisible"}`} style={{ marginTop: index !== null ? "1.0rem" : undefined, transition: "opacity 0.15s, transform 0.25s", transform: (force) ? "translate(0%, 0%)" : "translate(100%, 0%)" }}>
    <Row>
      <Column grow style={{maxWidth: "100%"}}>
        {/* <a className="text-dull" title={name}>
          {meta.sourceID ? <Mention profileID={meta.sourceID} onClick={true}>
            <Row>
              <Row center>
                <Row width="32px" height="32px" className="rounded black clickable" style={{border: "2px solid white"}}/>
                <Row center className="pad-h" style={{textShadow: "4px 4px 4px black"}}>
                  <ProfileName className="size-2" onClick={e=>hasRight(meta.targetID, "READ") ? Navigation.setProfile(meta.sourceID) : undefined}/>
                </Row>
              </Row>
            </Row>
          </Mention> : name}
        </a> */}
        <Column grow className="card-bg" style={{ borderRadius: "4px", border: `1px solid ${col}`, boxShadow: "0px 0px 4px black" }}>
          <Column grow style={{ background: col }}>
            <Column grow className={canBeToggled ? "clickable" : ""} style={{ boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.7)" }} onClick={canBeToggled ? e=>setMinimized(!minimized) : e=>{}}>
              <Row>
                {meta.sourceID && <Column onClick={e=>e.stopPropagation()}>
                  <Row className="rounded black" style={{padding: "2px"}}>
                    <Mention profileID={meta.sourceID} onClick={true}>
                      <ProfileChip width="32px" height="auto" className="clickable rounded" style={{border: "2px solid rgba(255,255,255,0.87)"}} onClick={e=>hasRight(meta.targetID, "READ") ? Navigation.setProfile(meta.sourceID) : undefined}/>
                    </Mention>
                  </Row>
                </Column>}
                <Row center onClick={e=>e.stopPropagation()}>
                  <a className="text-dull size-large pad-h" title={name}>
                    {meta.sourceID ? <Mention profileID={meta.sourceID} onClick={true}>
                      <ProfileName maxCharacters={26} onClick={e=>hasRight(meta.targetID, "READ") ? Navigation.setProfile(meta.sourceID) : undefined}/>
                    </Mention> : name}
                  </a>
                </Row>
                <Row grow reverse wrap>
                  {!browsing && index != null && <Button small className="pointer" onClick={e => {setExpired(true); e.stopPropagation()}}>
                    <CloseOutlined />
                  </Button>}
                </Row>
              </Row>
              <Row wrap relative>
                {/* {(text != null && (!Array.isArray(text) || text.length)) ? <Button center small childStyle={{ padding: "0 4px" }} onClick={e=>setMinimized(!minimized)}>
                  <CaretRightFilled style={{ transition: "transform 0.2s", transform: minimized ? "rotate(0deg)" : "rotate(90deg)" }} />
                </Button> : null}
                {api["icon"] && <Column center>
                  <img src={api["icon"]} alt="" className="cover" height={(api.class || api.act || tagList.length) ? 48 : 24} />
                </Column>} */}   
                <Row grow between>
                  <Row center title={name} className="pad-h">
                    <b style={{color: api["actcolor"], textShadow: "1px 1px 1px black"}}>
                      {api["act"] || ""}
                    </b>
                  </Row>
                </Row>       
                {tagList}
                {effectButton}
              </Row>
              {(api["genesis"] || api["swffg"]) && <Column center wrap>
                <Row wrap className="pad-v">
                  {rolls.filter(rollData=>(rollData.diceKey)).map(rollData=>(dicePool.pool[rollData.diceKey] && <Row wrap className="pad-h-4px">
                    {rollData.rolled.map(()=><Row center><Row className="pad rounded" style={dicePool.pool[rollData.diceKey].display} /></Row>)}
                  </Row>))}
                </Row>
                <Row wrap>
                  {defines["_triumph"] && <Row center className="pad-v-4px" title="Triumph">
                    <img style={{filter: "invert(100%)"}} src={dicePool.keys["tri"][dImg]} alt="" height={24}/>
                    <b className="pad-h">{defines["_triumph"]}</b>
                  </Row>}
                  {defines["_success"] && <Row center className="pad-v-4px" title="Success">
                    <img style={{filter: "invert(100%)"}} src={dicePool.keys["s"][dImg]} alt="" height={24}/>
                    <b className="pad-h">{defines["_success"]}</b>
                  </Row>}
                  {defines["_advantage"] && <Row center className="pad-v-4px" title="Advantage">
                    <img style={{filter: "invert(100%)"}} src={dicePool.keys["a"][dImg]} alt="" height={24}/>
                    <b className="pad-h">{defines["_advantage"]}</b>
                  </Row>}
                  {defines["_despair"] && <Row center className="pad-v-4px" title="Despair">
                    <img style={{filter: "invert(100%)"}} src={dicePool.keys["des"][dImg]} alt="" height={24}/>
                    <b className="pad-h">{defines["_despair"]}</b>
                  </Row>}
                  {defines["_failure"] && <Row center className="pad-v-4px" title="Failure">
                    <img style={{filter: "invert(100%)"}} src={dicePool.keys["f"][dImg]} alt="" height={24}/>
                    <b className="pad-h">{defines["_failure"]}</b>
                  </Row>} 
                  {defines["_threat"] && <Row center className="pad-v-4px" title="Threat">
                    <img style={{filter: "invert(100%)"}} src={dicePool.keys["t"][dImg]} alt="" height={24}/>
                    <b className="pad-h">{defines["_threat"]}</b>
                  </Row>}
                  {defines["_lightside"] && <Row center className="pad-v-4px" title="Lightside">
                    <img style={{filter: "invert(100%)"}} src={dicePool.keys["light"][dImg]} alt="" height={24}/><b className="pad-h">{defines["_lightside"]}</b>
                  </Row>} 
                  {defines["_darkside"] && <Row center className="pad-v-4px" title="Darkside">
                    <img style={{filter: "invert(100%)"}} src={dicePool.keys["dark"][dImg]} alt="" height={24}/><b className="pad-h">{defines["_darkside"]}</b>
                  </Row>}
                </Row>
              </Column>}
            </Column>
          </Column>
          {(finalText != null && (!Array.isArray(finalText) || finalText.length) && !minimized) ? 
            <Slate raw={finalText} readOnly={true} style={{ boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.7)", padding: isMobile ? "8px 16px" : "0.5rem", fontSize: isMobile ? "0.8rem" : null }} />
          : <div/>}
        </Column>
        <Row between>
          <Row wrap center>
            {/* <a className="text-dull" title={name}>
              {meta.sourceID ? <Mention profileID={meta.sourceID} onClick={true}>
                <ProfileName onClick={e=>hasRight(meta.targetID, "READ") ? Navigation.setProfile(meta.sourceID) : undefined}/>
              </Mention> : name}
            </a> */}

            {meta.sourceID !== meta.targetID && meta.targetID && <Row>
              <Row className="pad-h black-tint rounded" style={{borderRadiusTopLeft: "0px", borderRadiusTopRight: "0px"}}>
                <Mention profileID={meta.targetID}>
                  <span className="size-small" style={{paddingRight: "0.5rem"}}><ArrowRightOutlined /></span>
                  <a className="text-blue size-small">
                    <ProfileName />
                  </a>
                </Mention>
              </Row>
            </Row>}
          </Row>
          <Row center>
            <Row>
              <Row center style={{borderRadiusTopLeft: "0px", borderRadiusTopRight: "0px",}} className="size-small text-dull pad-h black-tint rounded" title={new Date(created)}>
                {DayJS(created).fromNow()}
              </Row>
            </Row>
            {api["class"] && <Row className="rounded pad-h text-dull size-small" center style={{background: api["classcolor"] ? b : "transparent"}}>
              <b style={{color: api["classcolor"] ? c : undefined}}>
                {String(api["class"] || "").toUpperCase()}
              </b>
            </Row>}
          </Row>
        </Row>
      </Column>
    </Row>
  </div>
}


export function ChatList({ browsing }) {
  const [chats, setChats] = useState([])

  function fn(e) {
    const newChats = []
    const len = Logs.history.length
    if (len)
      for (let i = 0; i < 40; i++)
        if (len - 1 - i >= 0)
          newChats.push(len - 1 - i)

    setChats(newChats)
  }

  useEffect(() => {
    const el = document.getElementById("chat-bottom")
    if (el && !browsing)
      el.scrollIntoView({ inline: "start", block: "start" })
  }, [chats, browsing])

  useEvents("change", fn, Logs)
  const isMobile = false
  return <Scroll
    click={browsing}
    fit
    style={{ display: "flex", flexFlow: "column-reverse", overflowX: "visible", overflowY: (isMobile || browsing) ? "scroll" : "visible", borderRadius: "4px"}}
  >
    <div id="chat-bottom" />
    <Column reverse style={{maxWidth: "100%"}} fitX>
      {chats.map((index, i) => <EventProvider key={Logs.history[index]} eventID={Logs.history[index]}><ChatBubble browsing={browsing} index={index} chats={chats} /></EventProvider>)}
    </Column>
  </Scroll>
}

export function ChatEntry({ ref, onFocusCallback, style }) {
  useEffect(() => {
    let activeEl = false
    function enterWatch(e) {
      if (e.key === "Enter") {
        const el = document.getElementById("chatentry")
        if (el && activeEl)
          el.focus()
      }
    }

    function capture(e) {
      const ae = document.activeElement
      if (ae && ae.tagName !== "INPUT" && ae.tagName !== "TEXTAREA" && !activeEl.contentEditable)
        activeEl = true
      else
        activeEl = false
    }

    window.addEventListener("keyup", enterWatch)
    window.addEventListener("keydown", capture)
    return function () {
      window.removeEventListener("keydown", capture)
      window.removeEventListener("keyup", enterWatch)
    }
  })

  const onDrop = (p, m, c) => {
    const i = m.getItem()
    if (i.url)
      Logs.chat(i.url)
  }

  return <UniversalDrop className="black-tint grow" onDrop={onDrop}>
    <CommandField
      id="chatentry"
      commands={commands}
      multiline={true}
      focusCallback={onFocusCallback}
      // size="small"
      disableUnderline={true}
      rows={1}
      placeholder="Chat"
      baseCommand={(c, i, value) => value.trim().length ? Logs.chat(value.trim()) : null}
      style={style}
    />
  </UniversalDrop>
}