import React from 'react'
import { LockOutlined, TeamOutlined, EyeOutlined, ReadOutlined, ProfileOutlined, EditOutlined, DeleteFilled, ApartmentOutlined, LoadingOutlined } from '@ant-design/icons'
import {Modal} from 'antd'

import { Column, Row, Button } from '../UI'
import useProject, {getProject} from './hooks/useProject'

export default ({ type, uid, open, close, parent, onPermit }) => {
  const {projectID, access, loaded} = useProject()

  if (!loaded)
    return <Modal centered visible={open && true} onCancel={close}  footer={null}>
      <LoadingOutlined />
    </Modal>

  const { users, roles } = access 

  const permissions = [
    ["Visible by Handout Only", <LockOutlined />],
    ["See", <EyeOutlined />],
    ["Read", <ReadOutlined />],
    ["Change", <ProfileOutlined />],
    ["Write", <EditOutlined />]
  ]

  return <Modal centered visible={open && true} onCancel={close} footer={null}>
      Permissions
      {parent ? <p>Permissions will be applied to all children</p> : null}
      <Column>
        <Column>
          <b>Roles</b><br/>
          {Object.keys(roles).map((role, idx) => <Row key={idx}>
              {role === "Guests" ? <Row center><TeamOutlined /></Row> : <Button onClick={e => window.ask({ type: "confirm", title: "Remove user from project", subtitle: "This will remove the user from the project and clear all permissions for the user" }, res => {
                getProject(projectID).removeRole(role)
              })}><DeleteFilled /></Button>}
            <Row center className="pad-h">
              <b children={roles[role].config.name || role} />
            </Row>
            <Row grow/>
            <Row center>
              {permissions.map(([value, icon], i) => <Button
                small
                key={i}
                primary
                title={value}
                placement="top"
                selected={roles[role][type][uid] != null && roles[role][type][uid] >= i * 10}
                onClick={e => {
                  getProject(projectID).permitRole(role, i * 10, type, uid)
                  if (onPermit)
                    onPermit(role, i * 10, type, uid)
                }}>
                {icon}
              </Button>)}
              <Button
                small
                primary
                title="Inherit Permissions"
                placement="top"
                selected={roles[role][type][uid] == null}
                onClick={e => {
                  getProject(projectID).permitRole(role, null, type, uid)
                  if (onPermit)
                    onPermit(role, null, type, uid)
                }}>
                <ApartmentOutlined />
              </Button>
            </Row>
          </Row>)}
        </Column>
        <b>Users</b><br/>
          {Object.keys(users).map((userID, idx) => <Row key={idx}>
            <Button color="secondary" onClick={e => window.ask({ type: "confirm", title: "Remove user from project", subtitle: "This will remove the user from the project and clear all permissions for the user" }, res => {
              getProject(projectID).removeUser(userID)
            })}>
            <DeleteFilled />
          </Button>
          <Row center className="pad-h">
            <b children={users[userID].config.name || userID} />
          </Row>
          <Row grow/>
          <Row center>
            {permissions.map(([value, icon], i) => <Button
                small
                primary
                title={value}
                placement="top"
                selected={users[userID][type][uid] != null && users[userID][type][uid] >= i * 10}
                onClick={e => {
                  getProject(projectID).permitUser(userID, i * 10, type, uid)
                  if (onPermit)
                    onPermit(userID, i * 10, type, uid)
                }}>
                {icon}
              </Button>)}
            <Button
              small
              primary
              title="Inherit Permissions"
              placement="top"
              selected={users[userID][type][uid] == null}
              onClick={e => {
                getProject(projectID).permitUser(userID, null, type, uid)
                if (onPermit)
                  onPermit(userID, null, type, uid)
              }}>
              <ApartmentOutlined />
            </Button>
          </Row>
        </Row>)}
      </Column>
  </Modal>
}