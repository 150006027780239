import styled from 'styled-components'

// const margin = p => {
//   return p.margin
// }

// const padding = p => {
//   return p.padding
// }

export default styled.div`
  position: ${p => p.relative ? "relative" : (p.absolute ? "absolute" : (p.fixed ? "fixed" : "static"))};
  left: ${p => p.left ? "0" : "auto"};
  right: ${p => p.right ? "0" : "auto"};
  top: ${p => p.top ? "0" : "auto"};
  bottom: ${p => p.bottom ? "0" : "auto"};
  display: ${p => (p.flex || p.row || p.column) ? "flex" : "initial"};
  flex-direction: ${p => p.row ? (p.reverse ? "row-reverse" : "row") : (p.reverse ? "column-reverse" : "column")};
  flex-wrap: ${p => p.wrap ? (p.wrap === true ? "wrap" : p.wrap || "wrap") : "nowrap"};
  flex-grow: ${p => p.grow && p.grow === true ? "1" : p.grow || "auto"};
  width: ${p => (p.fitX === true || (p.fit && !p.fitX && !p.fitY)) ? (p.fitX === true || p.fit === true ? "100%" : p.fitX || "100%") : p.width || "auto"};
  height: ${p => (p.fitY === true || (p.fit && !p.fitX && !p.fitY)) ? (p.fitY === true || p.fit === true ? "100%" : p.fitY || "100%") : p.height || "auto"};
  justify-content: ${p => p.around ? "space-around" : (p.between ? "space-between" : (p.center ? "center" : "flex-start"))};
  align-items: ${p => p.center ? "center" : "initial"};
  overflow-x: ${p => p.scrollX ? (p.scrollX === true ? "scroll" : p.scrollX) : (p.hiddenX ? "hidden" : (p.visibleX ? "visible" : "initial"))};
  overflow-y: ${p => p.scrollY ? (p.scrollY === true ? "scroll" : p.scrollY) : (p.hiddenY ? "hidden" : (p.visibleY ? "visible" : "initial"))};
  ${p => p.click ? "pointer-events:auto;" : p.noClick ? "pointer-events:none;" : null}
  `

// export default opts => <Div {...opts} cons={console.log(opts.style)} />