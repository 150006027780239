import React, { useEffect, useState } from 'react'
import { Tag, Select, Card, Avatar, Checkbox } from 'antd'
import { HeartFilled, HeartOutlined, ArrowLeftOutlined, DeleteFilled, LoadingOutlined } from '@ant-design/icons'
import { Row, Column, Scroll, Button } from '../UI'

import { Wing } from '../Wing'
import useArticle, { ArticleProvider, getArticle } from './hooks/useArticle'
import Account from '../core/state/Account'
import Slate from './slate/Slate'
import Wiki from './Wiki'

// import HeaderBar from './worldbuilding/profile/HeaderBar'
// import ArticleSection from './worldbuilding/profile/ArticleSection'
import { getProject, ProjectProvider } from "./hooks/useProject"
import { ProfileProvider } from "./hooks/useProfile"
import { Profile } from "./Profile"
import useAccount from "../core/hooks/useAccount"
import systems from "./util/Systems.json"


const community = Wing.service("community")

function queryContent() {
  const opts = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      route: "projects",
    })
  }

  if (window.isLocal)
    return fetch(`http://127.0.0.1:${window.location.port}/resources/content`, opts)
    .then(r => r.json())
    .then(list => {
      const data = []
      for (const pack of list)
        if (pack.meta)
          data.push({ 
            isSystem: pack.meta?.descriptors?.system, 
            projectSystem: pack.meta?.projectSystem,
            meta: { 
              target: pack.uid, 
              system: pack.meta?.projectSystem, 
              name: pack?.profile?.states[pack?.profile?.state].name, 
              src: pack?.profile?.states[pack?.profile?.state].media[0]?.src
            } 
          })
      return { data }
    })
    .catch(e=>console.warn(e))
  else
    return community.find({
      query: { $limit: 50, "meta.type": "project", "meta.public": true },
      // paginate: {
      //   default: 50,
      //   max: 50
      // }
    })
}

const Article = opts => {
  const {loaded, articleID} = useArticle()

  if (!articleID)
    return <Column fitX fitY center>Nothing Written</Column>

  if (!loaded)
    return <Column fitX fitY center><LoadingOutlined color="secondary" size={window.innerHeight * 0.1} /></Column>

  return <Column className="pad-l">
    <Slate readOnly={true} raw={getArticle(articleID).getBody()} />
  </Column>
}

const VoteCard = ({ index, startData, onClick, selected }) => {
  const data = startData

  // const vote = (map, vote) => {
  //   community.patch(map._id, { ...map, vote }).then(e => {
  //     setData(e)
  //   }).catch(e => console.warn(e))
  // }

  // const up = { ...data.up }
  // const upVoted = up[Account.uid] > 0
  // const down = { ...data.down }
  // const downVoted = down[Account.uid] > 0

  return <Card
    className="gaps clickable"
    style={{ maxWidth: "240px", border: selected ? "1px solid red" : "1px solid transparent" }} 
    cover={<img className="background-tint cover fit-x" height={140} width={240} src={data.meta.src || "/assets/icons/png_128/icon_app/icon_app_106.png"} alt={data.meta.name} />} 
    onClick={e => onClick(e, data, index)}
  //  actions={[
  //   <Button variant="text" secondary selected={upVoted ? true : undefined} onClick={e => vote(data, upVoted ? "clear" : "up")} title="Like this content" small>
  //     <ThumbUp />
  //   </Button>,
  //   <Button variant="text" secondary selected={downVoted} onClick={e => vote(data, "down")} title="Vote down" small>
  //     <ArrowDownward color={downVoted ? "error" : undefined} />
  //   </Button>,
  //   <Row center className="gaps-h">
  //     <Typography variant="subtitle1" align="center">
  //       {data.votes}
  //     </Typography>
  //   </Row>
  //  ]}
  >
    <Card.Meta title={data.meta.name} description={data.author} avatar={<Avatar src="/assets/icons/png_128/icon_game/icon_game_100.png"/>} />
  </Card>
}

export function EntryRow({ small, index, startData, onClick, selected, onRemove, onBrowse }) {
  const data = startData
  const { uid } = useAccount()

  // const [voteData, setVoteData] = useState()

  // const vote = (map, vote) => {
  //   community.patch(map._id, { ...map, vote }).then(e => {
  //     setVoteData(e)
  //   }).catch(e => console.warn(e))
  // }

  // const up = { ...voteData.up }
  // const upVoted = up[uid] > 0
  // const down = { ...voteData.down }
  // const downVoted = down[uid] > 0

  return <Row between className={`${selected ? "background" : "background"} rounded clickable`} style={{ borderBottom: "1px solid #141414" }} onClick={e => onClick(e, data, index)}>
    <Row>
      <Column width={small ? "64px" : "192px"} style={{backgroundImage: `url('${data.meta.src || "/assets/icons/png_128/icon_app/icon_app_106.png"}')`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat"}}/>
      <Column className={small ? "pad" : "pad-l"}>
        <a className={`${small ? "size-large" :  "size-2"} text-white`}>{data.meta.name}</a>
        <a className="text-dull" title="Author/Publisher">{data.meta.author}</a>
      </Column>
    </Row>
    {!small && <Column between reverse>
      <Row>
        {(data.meta.tags ?? []).map((v,i)=><Tag key={i}>{v}</Tag>)}
      </Row>
      <Row reverse>
        {/* <Button title="Like this content" style={{width: "100px"}}>
          <LikeOutlined />
          <b className="pad-h">{data.votes ?? (Math.floor(Math.random() * 100))}</b>
        </Button> */}
        {data.meta.system && systems[data.meta.system] && <a className="text-dull row center pad">
          <Avatar src={systems[data.meta.system].img} size="small"/>&nbsp;
          {systems[data.meta.system].name}
        </a>}
      </Row>
    </Column>}
    {onBrowse && <Row center><Button small={small} className="black" onClick={e=>onBrowse(e, data, index)}>Browse</Button></Row>}
    {onRemove && <Row center><Button small={small} className="black" onClick={e=>onRemove(e, data, index)}><DeleteFilled/></Button></Row>}
  </Row>
}

export default ({ style, projectSystem, onBack, systemSelect, onSelect }) => {
  const {details} = useAccount()
  const [offset, setOffset] = useState(0)
  const [library, setLibrary] = useState([])
  const [selected, setSelected] = useState()
  const [preview, setPreview] = useState()
  const [profileID, setProfileID] = useState()
  const [system, setSystem] = useState()
  const [noFiltering, setNoFiltering] = useState()

  const { favs } = details || {favs: []}

  useEffect(()=>{
    queryContent(projectSystem).then(e => {
      setLibrary(e.data)
    }).catch(e => console.warn(e))
  },[])

  const onClick = (e, map, i) => {
    setPreview()
    setSelected(i === selected ? null : i)
    setProfileID()
    if (onSelect)
      onSelect(e, map)

    e.stopPropagation()
  }

  const onBrowse = (e, data, i) => {
    if (data && data.meta)
      setPreview(preview === data.meta.target ? null : data.meta.target)
    else
      setPreview()
    setProfileID()

    e.stopPropagation()
  }

  const selectProfile = pID => setProfileID(profileID === pID ? undefined : pID)

  const favorite = e => {
    const old = { ...Account.details }
    const favs = [...(old.favs || [])]
    if (!favs.includes(library[selected].uid))
      favs.push(library[selected].uid)
    else
      favs.splice(favs.indexOf(library[selected].uid), 1)

    Account.updateAccountDetails({ favs })
  }
  const favorited = selected ? favs && favs.includes(library[selected].uid) : false

  if (preview != null)
    return <Row grow className="rounded card-bg" style={{ height: "75vh", ...style }}>
      <Column grow style={{ minWidth: "20vw", maxWidth: "20vw" }}>
        <Column grow>
          <Row className="foreground" between>
            <Button className="foreground" title="Back" onClick={e => {
              setPreview()
              setSelected()
              // Nav.setBookmark()
            }}>
              <ArrowLeftOutlined />
            </Button>
           </Row>

          {/* <CardHeader
              // actionIcon={<IconButton small><HeartOutlined fontSize="small" /></IconButton>}
              title={map.meta.name}
              titleTypographyProps={{ noWrap: true }}
              subtitle={map.author}
              subheaderTypographyProps={{ noWrap: true }}
            /> */}
          <Column grow>
            <ProjectProvider projectID={preview}>
              <Wiki onSelect={selectProfile} compact={true} />
            </ProjectProvider>
          </Column>

          {/* <Row center fitX>
            <Button variant="text" secondary title="Vote up" secondary small>
              <ThumbUp color="secondary" />
            </Button>
            <Button variant="text" secondary title="Vote down" secondary small>
              <ArrowDownward color="error" />
            </Button>
            <Row center className="gaps-h">
              <Typography variant="subtitle1" align="center">
                {Object.keys(library[selected].up || {}).length - Object.keys(library[selected].down || {}).length}
              </Typography>
            </Row>
          </Row> */}
        </Column>
      </Column>
        
      <Column grow>
        {/* <EntryRow index={selected} selected={true} onClick={onClick} startData={library[selected]} /> */}
        {/* <Row className="black pad-l">
          <h1 style={{ margin: "0" }}>{library[selected].meta.name}</h1>
          <h4 style={{ margin: "0" }}>{library[selected].meta.author}</h4>
        </Row> */}
        <Scroll scrollClassName="column" scrollY>
          <Column fit>
            {profileID ? 
              <ProfileProvider profileID={profileID}>
                <Profile/>
              </ProfileProvider> : 
              <ArticleProvider articleID={getProject(profileID).profile.getArticleID()}>
                <Article />
              </ArticleProvider>}
          </Column>
        </Scroll>
        {/* <Row className="black" between>
          {library[selected].meta.creator !== Account.uid && Account.uid ? <Button onClick={favorite} className="foreground" fitX selected={favorited}>
            {favorited ? ["Unsubscribe ", <HeartFilled />] : ["Subscribe ", <HeartOutlined />]}
          </Button> : <Button grow center noClick>Your Project</Button>}
        </Row> */}
      </Column>
        {/* <Scroll scrollClassName="column" scrollY>
          <WikiApp projectID={projectID} />
        </Scroll> */}
    </Row>

  let filtered = library
  if (projectSystem !== true && !noFiltering)
    if (projectSystem)
      filtered = [...library].filter(v=>(v.projectSystem === projectSystem))
    else
      filtered = [...library].filter(v=>(v.isSystem))

  
  // library.filter(v=>(!system || v.meta.system === system))
  // .filter(v=>(projectSystem && (projectSystem === v.meta.target || v.projectSystem !== projectSystem)))
  // .filter(v=>(projectSystem && v.isSystem))
  
  return <Row grow className="rounded">
    <Column grow style={{ minWidth: "20vw", maxWidth: "20vw" }}>
      {onBack && <Row className="foreground"><Button onClick={onBack}><ArrowLeftOutlined/>&nbsp;Back</Button></Row>}
      <Scroll className="black" style={{boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.7)"}}>
        <Column fit>
          <Column fitX center relative height="256px" style={{overflow: "hidden"}}>
            {system && systems[system] && <img className="contain" height="256px" src={systems[system].img} alt="" />}
            {system && systems[system] && <Column className="pad-l black-tint" fitX absolute bottom>
              <a className="size-2 text-white">{systems[system].name}</a>
              <a className="text-dull" title="Author/Publisher">{systems[system].author}</a>
            </Column>}
          </Column>
          <Column grow={2} className="pad-l foreground" style={{ boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.7)" }}>
            <Column grow className="pad-h-l">
              <Column className="pad-v-l">
                Game Filter:
                <Select showSearch className="background text-white" allowClear placeholder="Game Filter" onSelect={e=>setSystem(e)} onClear={e=>setSystem()}>
                  {Object.keys(systems).map((key,i)=><Select.Option key={key} children={key}/>)}
                </Select>
              </Column>

              <Column className="pad-v-l">
                <Row>
                  <Checkbox
                    checked={!noFiltering}
                    name="codexes"
                    color="secondary"
                    className="gaps-h"
                    onClick={e=>setNoFiltering(!noFiltering)}
                  /><Row center>Filter Compatible</Row>
                </Row>
                {/* <Row>
                  <Checkbox
                    checked={true}
                    name="codexes"
                    color="secondary"
                    className="gaps-h"
                  /><Row center>Official</Row>
                </Row>
                <Row>
                  <Checkbox
                    checked={true}
                    name="codexes"
                    color="secondary"
                    className="gaps-h"
                  /><Row center>Homebrew</Row>
                </Row>
              </Column>
              <Column grow>
                <Row>
                  <Checkbox
                    checked={true}
                    name="codexes"
                    color="secondary"
                    className="gaps-h"
                  /><Row center>Adventure</Row>
                </Row>

                <Row>
                  <Checkbox
                    checked={true}
                    name="codexes"
                    color="secondary"
                    className="gaps-h"
                  /><Row center>Content</Row>
                </Row>

                <Row>
                  <Checkbox
                    checked={true}
                    name="codexes"
                    color="secondary"
                    className="gaps-h"
                  /><Row center>Fluff</Row>
                </Row>

                <Row>
                  <Checkbox
                    checked={true}
                    name="codexes"
                    color="secondary"
                    className="gaps-h"
                  /><Row center>System</Row>
                </Row>*/}
              </Column>
              {/*<Column className="pad-v-l">
                Sort By:
                <Select className="background text-white" value="Rating">
                  <Select.Option key="Popular">Popular</Select.Option>
                  <Select.Option key="Rating">Rating</Select.Option>
                  <Select.Option key="Created">Created</Select.Option>
                </Select>
              </Column> */}
            </Column>
          </Column>
        </Column>
      </Scroll>

      {/* <Divider />
      <SearchBar
        placeholder="Search for content to add to your project..."
        variant="filled"
        disabled
        InputLabelProps={{ className: "white-title" }}
        inputProps={{ className: "white-title" }}
        value={""}
        fullWidth
      /> */}
    </Column>
    
    <Column grow className="card-bg" style={{ overflow: "hidden" }}>
      <Column grow className="rounded" style={{ boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.7)" }}>
        {filtered.length ? <Scroll>
          {filtered.map((v, i) => <EntryRow index={i} selected={selected === i} onClick={onClick} onBrowse={onBrowse} key={i} startData={v} />)}
        </Scroll> : <Column grow center>Add Content from the Workshop!</Column>}
      </Column>
      {/* <Scroll>
        <Row even wrap>
          {library.map((map, i) => <VoteCard index={i} selected={selected === i} onClick={onClick} key={i} startData={map} />)}
        </Row>
        <Row grow />
      </Scroll> */}
      {/* <Row reverse>
        <Button className="foreground" onClick={e => setOffset(offset + 10)}>
          <ArrowRightOutlined />
        </Button>
        <Button className="foreground" onClick={e => setOffset(Math.max(offset - 10, 0))}>
          <ArrowLeftOutlined />
        </Button>
      </Row> */}
    </Column>
  </Row>
}