import io from 'socket.io-client'
import feathers from '@feathersjs/client'
import EventEmitter from 'events'
const pingInterval = 100
const pingTimeout = 500
let localSocket = window.location.href.includes("local=1") ? io(`http://127.0.0.1:${window.location.port}`, {
  transports: ['websocket'],
  forceNew: true,
  pingInterval: pingInterval,
  pingTimeout: pingTimeout,
  rejectUnauthorized: false
}) : new EventEmitter()
window.isLocal = window.location.href.includes("local=1")
window.isDemo = window.location.href.includes("demo=1")
window.show3D = window.location.href.includes("preview=1")
let socket = new EventEmitter()

const Wing = feathers()

if (!localSocket.connected) {
  let port = window.location.protocol === 'http:' ? ':3001' : ''
  socket = io(window.location.protocol + '//' + window.location.hostname + port, {
    transports: ['websocket'],
    forceNew: true,
    pingInterval: pingInterval,
    pingTimeout: pingTimeout,
    rejectUnauthorized: false
  })

  function attemptReconnect() {
    if (socket.disconnected) {
      socket.open()
      setTimeout(attemptReconnect, pingTimeout * 10)
    }
  }

  socket.on("disconnect", function () {
    window.warning("No connection, storing what we can offline", { dismiss: true, preventDuplicate: true })
    attemptReconnect()
  })

  Wing.configure(feathers.socketio(socket, {timeout: 15000}))
  Wing.configure(feathers.authentication({
    storage: window.localStorage,
  }))
}


export { Wing, socket, localSocket }
