import Resource from './Resource'
import Article from './Article'
import Profile from './Profile'
import Folder from './Folder'
import Wiki from './Wiki'
import Resources from '../state/Resources'
import Network from '../../core/state/Network'
import Nav from '../state/Navigation'
import Account from '../../core/state/Account'
import { v4 } from 'uuid'
import clone from 'rfdc'
import { Wing } from '../../Wing'

const copy = clone()

const perms = {
  "INHERIT": null,
  "NONE": 0,
  "SEE": 10,
  "READ": 20,
  "CHANGE": 30,
  "WRITE": 40,
  "SHARE": 50,
  "REMOVE": 60,
  "OWN": 70
}

const defaultRole = {
  config: {},
  folders: {},
  articles: {},
  annotations: {},
  profiles: {},
  worlds: {},
  wizards: {}
}

export default class Project extends Resource {
  uid = v4()
  access = {
    roles: {
      "Guests": { ...defaultRole }
    },
    users: {},
  } // control who can access what
  articles = [] // list of article IDs created by this project
  maps = []
  profiles = []
  wizards = []
  entities = {} // a temporary database of in-map articles
  codexes = {}
  state = {}
  users = {}
  keyed = {} // map of keyed phrases
  templates = {}
  components = {}
  rollTables = {}
  trackers = {}
  background = {src: "/bg.jpg", fit: "cover"}
  root = new Folder({ opened: true })
  wiki = new Wiki(this.root, (uid, right) => {
    return this.getPermission(Account.uid, "profiles", uid) >= perms[right]
  })

  loaded = false

  constructor(ctx = {}) {
    super(ctx)
    this.description = new Article({ creator: ctx.creator })
    this.profile = new Profile({ creator: ctx.creator })
    this.profile.setName(ctx.name)
    this.profile.setAlias("Project Overview")
    this.profile.on("change", e => this.emit("save")) // trigger saving 
    // this.description.on("change", e => this.emit("change")) // trigger saving
    this.wiki.on("change", e => this.emit("save")) // trigger saving
    this.meta = {
      creator: ctx.creator || Account.uid,
      created: Date.now(),
      // security: {
      //   roles: {
      //     "Guests": { ...defaultRole }
      //   },
      //   users: {},
      // }
    }
    this.setMaxListeners(Infinity)
  }


  getName() {
    return this.profile.getName()
  }

  setName(name) {
    this.profile.setName(name)
  }

  getBackground() {
    return this.background
  }

  setBackground(value) {
    this.background = value
    this.emit("change")
  }

  isOwner(userID = Account.uid) {

    console.log(Network.host, userID, Network.server, this.meta.creator)

    if (Network.host)
      return true

    return this.meta.creator === userID
  }

  getPrivate() {
    return this.meta.private
  }

  setPrivate(newPrivate) {
    this.meta.private = newPrivate
    this.emit("change")
  }

  getCommunityID() {
    return this.meta.communityID
  }

  setCommunityID(communityID) {
    if (communityID !== this.meta.communityID) {
      this.meta.communityID = communityID
      this.emit("change")
    }
  }

  removeComponent(key) {
    delete this.components[key]
    this.emit("change")
  }

  setComponentOptions(key, value) {
    this.components[key] = value
    this.emit("change")
  }
  
  getComponentOptions(key) {
    return this.components[key]
  }

  removeTracker(key) {
    delete this.trackers[key]
    this.emit("change")
  }

  setTrackerOptions(key, value) {
    this.trackers[key] = value
    this.emit("change")
  }
  
  getTrackerOptions(key) {
    return this.trackers[key]
  }


  pack() {
    // console.log({
    //   _id: this._id,
    //   uid: this.uid,
    //   articles: this.articles,
    //   description: this.description.pack(),
    //   wiki: this.wiki.pack(),
    //   users: this.users,
    //   state: this.state,
    //   meta: this.meta
    // })
    return {
      _id: this._id,
      uid: this.uid,
      access: this.access,
      keyed: this.keyed,
      profiles: this.profiles,
      maps: this.maps,
      articles: this.articles,
      wizards: this.wizards,
      codexes: this.codexes,
      templates: this.templates,
      phrases: {},// have to do this because patch doesn't let you delete fields
      components: this.components,
      rollTables: this.rollTables,
      trackers: this.trackers,
      // description: this.description.pack(),
      profile: this.profile.pack(),
      wiki: this.wiki.pack(),
      users: this.users,
      state: this.state,
      background: this.background,
      meta: this.meta
    }
  }

  serialize() {
    return JSON.stringify(this.pack())
  }

  deserialize(json) {
    this.load(JSON.parse(json))
  }

  load(json) {
    this.loaded = false
    this._id = json._id
    this.uid = json.uid || this.uid
    this.profiles = json.profiles || []
    this.articles = json.articles || []
    this.maps = json.maps || []
    this.wizards = json.wizards || []
    this.state = json.state || {}
    this.rollTables = json.rollTables || {}
    this.components = json.components || {}
    this.trackers = json.trackers || {}
    this.access = json.access || { roles: { "Guests": { ...defaultRole } }, users: {} }
    for (const key in this.access.users) {
      // clean up temporary stuff
      for (const uid in this.access.users[key].profiles)
        if (uid && String(uid).match("-temp-"))
          delete this.access.users[key].profiles[uid]

      for (const uid in this.access.users[key].articles)
        if (uid && String(uid).match("-temp-"))
          delete this.access.users[key].articles[uid]

      for (const uid in this.access.users[key].wizards)
        if (uid && String(uid).match("-temp-"))
          delete this.access.users[key].wizards[uid]
    }

    this.keyed = json.keyed || {}
    if (json.phrases && Object.keys(json.phrases).length)
      for (const key in json.phrases) {
        this.keyed[key] = json.phrases[key]

        if (json.phrases[key].articleID)
          this.keyed[key].profileID = json.phrases[key].articleID
      }

    this.codexes = json.codexes || {}
    this.templates = json.templates || {}
      
    if (json.meta.security) {
      this.access = json.meta.security
      delete json.meta.security
      console.log(this.access)
    }
    this.meta = json.meta || this.meta
    this.users = json.users || {}
    this.wiki.load(json.wiki)
    // this.description.load(json.description)
    this.background = json.background
    this.profile.load(json.profile || json.profile)
    this.loaded = true
    this.emit("ready")
  }

  rebuildFolders() {
    this.root.children = this.articles.map((id, index) => {
      return new Folder({
        article: id,
        path: `${this.path}_${index}`,
        root: this.root,
        children: []
      })
    })
    this.wiki.rebuild()
    this.emit("change")
  }

  addToWiki(parent, uid, quiet) {
    const { profiles, wiki, root } = this
    if (!uid.match("-temp-") || this.temp) {
      profiles.push(uid)
      if (parent)
        root.lookup(wiki.findProfile(parent)).addData(uid)
      else
        root.push(uid)

      if (!quiet) {
        wiki.rebuild()
        this.emit("change")
      }
    }
  }

  createProfile(parent) { // really "register" profile
    const uid = this.createUID()
    const { profiles, wiki, root } = this
    if (!uid.match("-temp-") || this.temp) {
      profiles.push(uid)
      if (parent)
        root.lookup(wiki.findProfile(parent)).addData(uid)
      else
        root.push(uid)
      wiki.rebuild()
      this.emit("change")
    }

    return uid
  }

  removeProfile(uid) {
    const index = this.profiles.indexOf(uid)
    if (index >= 0)
      this.profiles.splice(index, 1)

    for (const bookmark of this.wiki.bookmarks)
      if (this.wiki.lookupBookmark(bookmark) === uid) {
        this.wiki.removeBookmark(bookmark)
        break
      }

    this.wiki.remove(uid)
    this.wiki.rebuild()

    this.emit("change")
  }

  createUID(id, temp) {
    let rID = (id || v4())
    if (this.temp || temp)
      return "-temp-" + rID

    if (this.local)
      return "-local-" + rID

    return rID
  }

  createMap(id) { // really "register article"
    const uid = this.createUID(id)
    if (!uid.match("-temp-")) {
      const { maps } = this
      maps.push(uid)
      this.emit("change")
    }

    return uid
  }

  removeMap(uid) {
    const index = this.maps.indexOf(uid)
    if (index >= 0)
      this.maps.splice(index, 1)

    this.emit("change")
  }

  createArticle(id) { // really "register article"
    const uid = this.createUID(id)
    if (!uid.match("-temp-")) {
      const { articles } = this
      articles.push(uid)
      this.emit("change")
    }
    return uid
  }

  createWizard(id) { 
    const uid = this.createUID(id)
    if (!uid.match("-temp-")) {
      const { wizards } = this
      wizards.push(uid)
      this.emit("change")
    }
    return uid
  }

  removeArticle(uid) {
    const index = this.articles.indexOf(uid)
    if (index >= 0)
      this.articles.splice(index, 1)

    this.emit("change")
  }

  removeWizard(uid) {
    const index = this.wizards.indexOf(uid)
    if (index >= 0)
      this.wizards.splice(index, 1)

    this.emit("change")
  }

  getPermission(userID, type, uid) { // will default to a role if not found
    // Inherit is null
    const { creator } = this.meta
    
    if (Nav.project !== this.uid || !Nav.project)
      return perms["READ"]

    if (uid && !this.profiles.includes(uid) && !uid.match("-temp-"))
      return perms["READ"]

    if (userID && creator === userID)
      return perms["OWN"]

    if (this.local && Network.host)
      return perms["OWN"]

    if (creator === userID && (!uid || uid === this.profile.uid))
      return perms["OWN"]
      
    if (userID && (userID === "local-user" || creator === "local-user") && Network.host)
      return perms["OWN"]

    const { access } = this
    const { roles, users } = access
    // console.log(roles["Guests"][type][uid])
    if (users[userID]) {
      users[userID][type] = users[userID][type] || {}
      if (users[userID][type][uid])
        return users[userID][type][uid]
    }

    // No registered user present, default to roles
    const role = "Guests"
    if (roles[userID]) {
      roles[userID][type] = roles[userID][type] || {}
      if (roles[userID][type][uid] !== null)
        return roles[userID][type][uid]
    }

    roles[role][type] = roles[role][type] || {}
    return roles[role][type][uid]
  }

  configureRole(role, config) {
    const { roles } = this.access
    roles[role] = roles[role] || { ...defaultRole }
    roles[role].config = config
    this.emit("change")
  }

  removeRole(role) {
    const { roles } = this.access
    if (role !== "Guests" && this.isOwner())
      delete roles[role]
    this.emit("change")
  }

  permitRole(role, value, type, uid) {
    const { roles } = this.access
    roles[role][type][uid] = value
    this.emit("change")
  }

  getRole(role) {
    const { roles } = this.access
    return roles[role]
  }

  configureUser(userID, config) {
    // permits a user to use the project at any point, and also gives them a role
    const { users } = this.access
    users[userID] = users[userID] || { ...defaultRole }
    users[userID].config = config

    const newUsers = this.getUsers()
    // Update every article/profile and this project to reflect the new author

    if (!window.isLocal) {
      const articles = Wing.service("articles")
      const profiles = Wing.service("profiles")
      articles.patch(null, { "meta.authors": newUsers }, { query: { uid: { $in: this.articles }, $limit: 1000 } }).then(e => {
        window.success("UPDATED ARTICLES")
        console.log(e)
      }).catch(e => {
        window.error("UNABLE TO UPDATE ARTICLES")
        console.warn(e)
      })

      profiles.patch(null, { "meta.authors": newUsers }, { query: { uid: { $in: this.profiles }, $limit: 1000 } }).then(e => {
        window.success("UPDATED PAGES")
        console.log(e)
      }).catch(e => {
        window.error("UNABLE TO UPDATE PAGES")
        console.warn(e)
      })
    }

    this.emit("change")
  }

  permitUser(userID, value, type, uid) {
    const { users } = this.access
    users[userID][type][uid] = value
    this.emit("change")
  }

  removeUser(uid) {
    const { users } = this.access
    if (this.isOwner())
      delete users[uid]

    // Update every article/profile and this project to reflect the new author


    this.emit("change")
  }

  getUsers() {
    const { users } = this.access

    let result = {}
    for (const uid in users)
      result[uid] = true

    return result
  }

  // roles: {
  //   config, // configuration of the role
  //   name,
  //   articles: {} // what this role has access to
  //   folders: {} // what this role has access to
  // }
  // users: {
  //   uid: {
  //     name // saved name
  //     roles: [],
  //     articles: {} // what this role has access too
  //     folders: {} // what 
  //   }
  // }

  getTemplates() {
    return this.templates
  }

  getTemplate(key) {
    return this.templates[key]
  }

  setTemplate(key, data) {
    this.templates[key] = data
    this.emit("change")
  }

  getSystem() {
    return this.meta.system
  }

  setSystem(data) {
    this.meta.system = data
    this.emit("change")
  }

  revealCodex(uid){
    this.meta.gmOnly = this.meta.gmOnly || {}
    this.meta.gmOnly[uid] = true
    this.emit("change")
  }

  hideCodex(uid){
    this.meta.gmOnly = this.meta.gmOnly || {}
    delete this.meta.gmOnly[uid]
    this.emit("change")
  }

  deleteTemplate(key) {
    delete this.templates[key]
    this.emit("change")
  }

  updateTemplate(key, data) {
    if (!this.templates[key])
      this.templates[key] = data
    else
      for (const k in data)
        this.templates[key][k] = data[k]
    this.emit("change")
  }

  setPhrase(key, data = {}) {
    this.keyed[key.toLowerCase()] = data || {}
    this.emit("change")
  }

  updatePhrase(key, update = {}) {
    this.keyed[key.toLowerCase()] = this.keyed[key.toLowerCase()] || {}
    for (const k in update)
      this.keyed[key.toLowerCase()][k] = update[k]
    this.emit("change")
  }

  deletePhrase(key) {
    delete this.keyed[key.toLowerCase()]
    this.emit("change")
  }

  renamePhrase(old, key) {
    this.keyed[key.toLowerCase()] = { ...this.keyed[old.toLowerCase()] }
    delete this.keyed[old.toLowerCase()]
    this.emit("change")
  }

  attachCodex(projectID) {
    if (projectID === this.uid)
      return window.error("Can't attach a project to itself")
    this.codexes[projectID] = {}
    this.emit("change")
  }

  detachCodex(projectID) {
    delete this.codexes[projectID]
    this.emit("change")
  }

  getCodexes() {
    return Object.keys(this.codexes) || []
  }

  getPhrases() {
    return this.keyed
  }

  getPhraseBank() {
    // Merges with codex annotations
    const { Projects } = Resources
    let result = {}

    for (const codex in this.codexes) {
      const project = Projects.get(codex)
      if (project.loaded)
        result = { ...result, ...copy(project.keyed) }
    }

    result = { ...result, ...copy(this.keyed) }
    return result
  }

  getTemplateBank() {
    // Merges with codex Effects
    const { Projects } = Resources
    let result = {}

    for (const codex in this.codexes) {
      console.log(codex)
      const project = Projects.get(codex)
      if (project.loaded)
        result = { ...result, ...copy(project.templates) }
    }

    result = { ...result, ...copy(this.templates) }
    return result
  }

  getEventBank() {
    // Merges with codex Events
    const { Projects } = Resources
    let result = {}

    for (const codex in this.codexes) {
      const project = Projects.get(codex)
      if (project.loaded)
        result = { ...result, ...copy(project.profile.getEventsObject()) }
    }

    result = { ...result, ...copy(this.profile.getEventsObject()) }

    return result
  }

  getProfileBank() {
    // Merges with codex Events
    const { Projects } = Resources
    let result = [...this.profiles]

    for (const codex in this.codexes) {
      const project = Projects.get(codex)
      if (project.loaded)
        result = [...result, ...project.profiles]
    }
    return result
  }

  getEvents(){
    return this.profile.getEventsObject()
  }

  getBank() {
    // Merges with codex Events
    const { Projects } = Resources
    const result = {
      phrases: {},
      templates: {},
      components: {},
      rollTables: {},
      trackers: {},
      events: {},
    }

    for (const codex in this.codexes) {
      const project = Projects.get(codex)
      if (project.loaded) {
        result.components = { ...result.components, ...copy(project.components) }
        result.rollTables = { ...result.rollTables, ...copy(project.rollTables) }
        result.templates = { ...result.templates, ...copy(project.templates) }
        result.trackers = { ...result.trackers, ...copy(project.trackers) }
        result.events = { ...result.events, ...copy(project.profile.getEventsObject()) }
      }
    }

    // result.events = { ...result.events, ...copy(project.profile.getEventsObject()) }
    result.components = { ...result.components, ...copy(this.components) }
    result.rollTables = { ...result.rollTables, ...copy(this.rollTables) }
    result.trackers = { ...result.trackers, ...copy(this.trackers) }
    result.templates = { ...result.templates, ...copy(this.templates) }
    result.events = { ...result.events, ...copy(this.profile.getEventsObject()) }

    return result
  }
}