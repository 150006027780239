import React, { Component } from 'react'

import { DropTarget } from 'react-dnd'

const source = {
  hover(props, monitor, component) {
    if (props.onHover) {
      props.onHover(props, monitor, component)
    }
  },
  drop(props, monitor, component) {
    if (monitor.didDrop({ shallow: props.shallow })) {
      // somewhere has already handled the drop
      return
    }

    if (props.onDrop) {
      props.onDrop(props, monitor, component)
    }
  },
}

function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    hovered: monitor.isOver(),
    hoveredCurrent: monitor.isOver({ shallow: true }),
    item: monitor.getItem()
  }
}

class UniversalDrop extends Component {
  componentDidUpdate(prevProps) {
    if (!prevProps.hoveredCurrent && this.props.hoveredCurrent) {
      // You can use this as enter handler
      if (this.props.onEnter)
        this.props.onEnter(this.props.item, prevProps.ref)
    }

    if (prevProps.hoveredCurrent && !this.props.hoveredCurrent) {
      // You can use this as leave handler
      if (this.props.onLeave)
        this.props.onLeave(prevProps.item, prevProps.ref)
    }
  }

  render() {
    const { hoveredCurrent, onClick, onDrop, dropColor, style, connectDropTarget, className, backgroundColor } = this.props
    const background = hoveredCurrent && onDrop ? (dropColor || "#34DBDB88") : (backgroundColor ?? null)
    const baseStyle = { ...style, background, transition: "background 0.25s" }

    return connectDropTarget(
      <div className={className} style={baseStyle} onClick={onClick}>
        {this.props.children}
      </div>
    )
  }
}

export default DropTarget("universal", source, collect)(UniversalDrop)
