import React from 'react'
import { Button } from '../../../UI'

import { useFocused } from 'slate-react'

export default ({ className, small, placement, title, color, onClick, children, selected }) => <Button
  onMouseDown={e => {
    if (onClick)
      onClick(e)
    e.preventDefault()
  }}
  onClick={e => e.preventDefault()}
  color={color}
  selected={selected}
  title={title}
  placement={placement || "top"}
  secondary
  className={className || "foreground"}
  childClass="grow"
  style={{boxShadow: "inset 0px 0px 2px black"}}
  mouseEnterDelay={0.8}
  small={small}
  click={useFocused()}
>
  {children}
</Button>


