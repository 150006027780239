import React, { useEffect, useState, useContext, createContext } from 'react'
import Nav from '../state/Navigation'
import Resources from '../state/Resources'
import Account from '../../core/state/Account'
import Project from '../state/Project'
import rfdc from 'rfdc'

const copy = rfdc()
const { Projects, Profiles, Articles, Maps, Wizards } = Resources

const ProjectContext = createContext()
export { ProjectContext }

export default function useProject() {
  return useContext(ProjectContext) || {}
}

export function useHook(projectID=Nav.project) {
  const [name, setName] = useState()
  const [profiles, setProfiles] = useState()
  const [codexes, setCodexes] = useState([])
  const [loaded, setLoaded] = useState()
  const [access, setAccess] = useState()
  const [phrases, setPhrases] = useState()
  const [templates, setTemplates] = useState()
  const [meta, setMeta] = useState({})
  const [wizards, setWizards] = useState([])
  const [events, setEvents] = useState({})
  const [components, setComponents] = useState({})
  const [rollTables, setRollTables] = useState({})
  const [trackers, setTrackers] = useState({})

  useEffect(() => {
    const p = Projects.get(projectID)
    let change
    if (p) 
      change = function() {
        setName(p.getName())
        setLoaded(p.loaded)
        setCodexes([...p.getCodexes()])
        setAccess({...p.access})
        setPhrases({...p.getPhrases()})
        setTemplates({...p.getTemplates()})
        setProfiles([...p.profiles])
        setMeta({...p.meta})
        setWizards([...p.wizards])
        setEvents({...p.getEvents()})
        setComponents({...p.components})
        setRollTables({...p.rollTables})
        setTrackers({...p.trackers})
      }

    if (change)
      change()

    if (p) {
      p.on("change", change)
      p.on("ready", change)
    }

    return function () {
      if (p) {
        p.off("change", change)
        p.off("ready", change)
      }
    }
  }, [projectID])

  return { loaded, projectID, name, codexes, access, phrases, templates, profiles, meta, wizards, rollTables, components, events, trackers}
}

export function ProjectProvider({ projectID, children }) {
  return <ProjectContext.Provider value={useHook(projectID)} children={children} />
}

export function addToWiki(projectID = Nav.project, profileID, quiet) {
  const project = getProject(projectID) || Project
  project.addToWiki(Nav.profile ?? project.wiki.lookupBookmark(project.wiki.bookmark), profileID, quiet)
}

export function createProfile(projectID = Nav.project, templateID, cb) {
  const project = getProject(projectID) || Project
  if (!project.loaded) {
    window.warning("Project still loading")
    return
  }

  if (!project.isOwner()) {
    window.warning("Invalid permissions")
    return
  }

  // if (Resources.offline && Project.profiles.length >= 200)
  //   window.warning("Local Project has exceeded recommended storage, data deletion may occur due to browser constraints. Continue using at your own risk.")

  const uid = cb ? project.createUID(undefined, true) : project.createProfile(Nav.profile ?? project.wiki.lookupBookmark(project.wiki.bookmark))
  const profile = Profiles.create(uid)

  const bank = project.getTemplateBank()
  if (templateID && bank[templateID] && bank[templateID].profileID) {
    const create = profileID=>{
      const template = Profiles.get(profileID)
      const loading = {}
      const tabs = template.getTabs()

      function confirm(id){
        if (id)
          delete loading[id]

        if (Object.keys(loading).length !== 0)
          return
        const template = Profiles.get(profileID)
        const tabs = template.getTabs()

        const backupData = copy(template.pack())
        delete backupData.uid
        delete backupData._id
        
        profile.unpack(backupData)
        profile.loaded = false

        const article = createArticle()
        const articlePack = Resources.Articles.get(profile.getArticleID())

        if (articlePack && articlePack.loaded) {
          const articleData = articlePack.pack()
          delete articleData.uid
          delete articleData._id
          article.unpack(articleData)
          article.loaded = true
          article.emit("ready")
          article.emit("change")
        }

        profile.setArticleID(article.uid)

        for (const tab of tabs) {
          const article = createArticle()
          const articlePack = Resources.Articles.get(profile.getTab(tab).articleID)
          
          if (articlePack && articlePack.loaded) {
            const articleData = articlePack.pack()
            delete articleData.uid
            delete articleData._id
            article.unpack(articleData)
            article.loaded = true
          }

          article.emit("ready")
          article.emit("change")
          profile.updateTab(tab, {articleID: article.uid})
        }
        profile.loaded = true
        profile.emit("ready")
        profile.emit("change")
        if (cb)
          cb(profile)
      }

      const aID = template.getArticleID()
      const article = Resources.Articles.get(aID)
      if (aID && !article.loaded) {
        loading[aID] = true
        article.once("ready", e=>confirm(aID))
      }

      for (const tab of tabs) {
        const { articleID } = template.getTab(tab)
        const article = Resources.Articles.get(articleID)
        if (articleID && !article.loaded) {
          loading[articleID] = true
          article.once("ready", e=>confirm(articleID))
        }
      }
      confirm()
    }
    create(bank[templateID].profileID)
  }
  else if (cb) 
    cb(profile)

  profile.meta.owner = project.meta.creator
  profile.meta.ownedBy = project.uid
  profile.meta.authors = project.getUsers()
  return profile
}


export function createMap(projectID = Nav.project) {
  const project = getProject(projectID) || Project
  if (!project.loaded) {
    window.warning("Project still loading")
    return
  }

  if (!project.isOwner()) {
    window.warning("Invalid permissions")
    return
  }

  if (Resources.offline && Project.maps.length >= 200)
    window.warning("Local Project has exceeded recommended storage, data deletion may occur due to browser constraints. Continue using at your own risk.")


  const uid = project.createMap()
  const map = Maps.create(uid)
  map.meta.owner = project.meta.creator
  map.meta.ownedBy = project.uid
  map.meta.authors = project.getUsers()
  return map
}

// export function createContent(projectID = Nav.project) {

// }

export function createArticle(projectID = Nav.project) {
  const project = getProject(projectID) || Project
  if (!project.loaded) {
    window.warning("Project still loading")
    return
  }

  if (!project.isOwner()) {
    window.warning("Invalid permissions")
    return
  }

  // if (Resources.offline && Project.articles.length >= 1) {
  //   window.warning("Can't create, local storage is full")
  //   return
  // }

  const uid = project.createArticle()
  const article = Articles.create(uid)
  article.meta.owner = project.meta.creator
  article.meta.ownedBy = project.uid
  article.meta.authors = project.getUsers()
  return article
}

export function createWizard(projectID = Nav.project) {
  const project = getProject(projectID) || Project
  if (!project.loaded) {
    window.warning("Project still loading")
    return
  }

  if (!project.isOwner()) {
    window.warning("Invalid permissions")
    return
  }

  // if (Resources.offline && Project.articles.length >= 1) {
  //   window.warning("Can't create, local storage is full")
  //   return
  // }

  const uid = project.createWizard()
  const wizard = Wizards.create(uid)
  wizard.meta.owner = project.meta.creator
  wizard.meta.ownedBy = project.uid
  wizard.meta.authors = project.getUsers()
  return wizard
}


export function getProject(projectID = Nav.project) {
  return Projects.get(projectID) || Project
}

export function getPermission(uid, type = "profiles", userID = Account.uid, projectID = Nav.project) {
  const project = getProject(projectID)
  if (!project.loaded)
    return 0

  return project.getPermission(userID, type, uid)
}

export function hasPermission(perm, right) {
  const perms = {
    "INHERIT": null,
    "NONE": 0,
    "SEE": 10,
    "READ": 20,
    "CHANGE": 30,
    "WRITE": 40,
    "SHARE": 50,
    "REMOVE": 60,
    "OWN": 70
  }
  return perm >= perms[right]
}

export function hasRight(uid, right, type = "profiles", userID = Account.uid, projectID = Nav.project) {
  return hasPermission(getPermission(uid, type, userID, projectID), right)
}
