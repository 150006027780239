import EventEmitter from 'events'
import Resources from '../state/Resources'
import { v4 } from 'uuid'
import Network from "../../core/state/Network"

export default class ChatLog extends EventEmitter {
  history = []
  state = {}
  chat(eq, gm) {
    const { Events } = Resources
    let equation = eq
    if (equation.split("\n").length === 1 && equation[0] !== ">")
      equation = ">" + equation
    const uid = v4()
    const event = Events.create(uid)
    event.meta.gmRoll = gm
    event.roll(equation).then(e => {
      this.history = this.history || []
      if (Network.host) {
        this.history.push(uid)
        this.emit("change")
        Network.broadcast("chat", event.serialize())
      }
      else
        Network.propose("chat", event.serialize())
    })
  }

  process(data) {
    const { Events } = Resources
    // literally a text history
    if (!Events.exists(data.uid)) {
      const e = Events.create(data.uid)
      e.load(data)
    }
    this.history.push(data.uid)
    this.emit("change")
  }

  record(uid) {
    const { Events } = Resources
    const event = Events.get(uid)
    if (event.loaded) 
      if (Network.host) {
        this.history = this.history || []
        this.history.push(uid)
        this.emit("change")
        Network.broadcast("chat", event.serialize())
      }
      else
        Network.propose("chat", event.serialize())
  }
}