import React, { useEffect, useState, useContext, createContext } from 'react'
import Resources from '../state/Resources'
import Map from '../models/Map'
import Nav from '../state/Navigation'

const MapContext = createContext()

export { MapContext }

const map = new Map()
const { Maps, Projects } = Resources

export function getMap(mapID) {
  return mapID ? Maps.get(mapID) : (Nav.project ? Projects.get(Nav.project).profile.getMapID() : map)
}

export default function useMap() {
  return useContext(MapContext)
}

export function useHook(mapID, project = Nav.project) {
  const [loaded, setLoaded] = useState()
  const [meta, setMeta] = useState()
  const [background, setBackground] = useState()
  const [layers, setLayers] = useState()

  useEffect(() => {
    const m = getMap(mapID)
    function change() {
      setLoaded(m.loaded)
      setBackground(m.src)
      setMeta(m.meta)
      setLayers(m.layers)
    }
    change()

    m.on("change", change)
    m.on("ready", change)

    return function () {
      m.off("change", change)
      m.off("ready", change)
    }
  }, [mapID, project])

  return { mapID, loaded, meta, background, layers }
}

export function MapProvider({ mapID, children }) {
  return <MapContext.Provider value={useHook(mapID)} children={children} />
}
