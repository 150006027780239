// import withFirebaseAuth from 'react-with-firebase-auth'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/database'
import 'firebase/functions'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyB__QQa5vaWXU-pR4qwCE1FQdZtzkbk9VE",
  authDomain: "mythicarchive.firebaseapp.com",
  databaseURL: "https://spyroll-90c31.firebaseio.com",
  projectId: "spyroll-90c31",
  storageBucket: "spyroll-90c31.appspot.com",
  messagingSenderId: "921070474702",
  appId: "1:921070474702:web:3b0bf8abc785ac7d"
}

const app = firebase.initializeApp(firebaseConfig)

const auth = app.auth()
const database = app.database()
// const firestore = app.firestore()
const functions = app.functions()
const storage = app.storage()
const analytics = app.analytics()

const providers = { googleAuthProvider: new firebase.auth.GoogleAuthProvider() }
export { app, auth, database, functions, storage, providers, analytics }