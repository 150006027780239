import React, { useEffect, useState } from 'react'
import Input from '../core/Input'
import useAccount from '../core/hooks/useAccount'
import { Column, Button, Row, GhostInput, Scroll } from '../UI'
import useProject, { createProfile, getProject, ProjectProvider, addToWiki } from "./hooks/useProject"
import { LoadingOutlined, SettingFilled, CloseOutlined, DeleteFilled, PlusSquareFilled } from '@ant-design/icons'
import useNav from "./hooks/useNav"
import { Modal, Switch, Input as InputEntry, Tag, Avatar, Select, Checkbox } from 'antd'

import Events from './EventList'
import Templates from './TemplateList'
import Archives from "./Archives"
import { WizardManager } from './Wizard'

import systems from "./util/Systems.json"

import { ProfileMeta, ProjectCodexButton } from './Browser'
import Navigation from "./state/Navigation"
import { EventBuilder } from "./Events"
import Community, { EntryRow } from "./Community"
import { applyEvent, createEvent } from "./hooks/useEvent"
import Resources from "./state/Resources"
import CreateValue from "./slate/CreateValue"

import { v4 } from 'uuid'

export function ProjectAvatar(opts) {
  const { projectID, loaded } = useProject()
  return loaded ? <img alt="" src={getProject(projectID).profile.getBanner().src || "/assets/icons/png_128/icon_app/icon_app_106.png"} {...opts} /> : <div />
}

export function ProjectImageCard(opts) {
  const { projectID, loaded } = useProject()
  return loaded ? <Column top left fit absolute style={{ backgroundSize: "cover", backgroundPosition: "center", backgroundImage: `url('${getProject(projectID).profile.getBanner().src || "/assets/icons/png_128/icon_app/icon_app_106.png"}')` }} /> : <div />
}

export function ProjectTags(opts) {
  const { loaded, meta } = useProject()
  return loaded ? (meta.tags || []).map((v, i) => <Column center index={i}><Tag className="background">{v}</Tag></Column>) : <div />
}


export function ProjectSystem(opts) {
  const { loaded, meta } = useProject()
  return loaded && meta.system && systems[meta.system] ? <a className="text-dull row center pad-h">
    <Avatar src={systems[meta.system].img} size="small" />&nbsp;
    {systems[meta.system].name}
  </a> : <div />
}

export function ProjectName(opts) {
  const { projectID, loaded } = useProject()
  return loaded ? <span {...opts}>{getProject(projectID).profile.getName()}</span> : <LoadingOutlined />
}

export function ProjectAuthor() {
  const { projectID, loaded } = useProject()
  return loaded ? <a>{getProject(projectID).meta.author}</a> : <LoadingOutlined />
}

export function ProjectEventApp({ target, projectID }) {
  const evt = getProject(projectID).profile.getEvent(target) || {}
  const [tags, setTags] = useState(evt.tags || [])
  const [name, setName] = useState(evt.name)
  const [mask, setMask] = useState(evt.mask)
  const [hotbar, setHotbar] = useState(evt.hotbar)
  const [choices, setChoices] = useState(evt.choices || [])
  const [choice, setChoice] = useState()
  const [args, setArgs] = useState(evt.args || "")

  const addOption = e => {
    const newChoices = [...choices]
    newChoices.push({ mask: e, script: "" })
    setChoice(newChoices.length - 1)
    setChoices(newChoices)
  }

  const removeOption = e => {
    const newChoices = [...choices]
    newChoices.splice(e, 1)
    setChoice()
    setChoices(newChoices)
  }

  const updateOptionScript = (option, text) => {
    const newChoices = [...choices]
    newChoices[option].script = text
    setChoices(newChoices)
  }

  const confirm = e => {
    getProject(projectID).profile.updateEvent(target, { ...evt, tags, name, hotbar, mask, choices, args })
    window.close()
  }

  return <Column>
    <Row className="size-small">Display Name</Row>
    <InputEntry
      defaultValue={name}
      onChange={e => setName(e.target.value)}
      className="background size-small"
      placeholder={target}
    />
    <Row>
      <InputEntry
        className="background size-small"
        placeholder="Hotbar Category"
        onChange={e => setHotbar(e.target.value)}
        defaultValue={hotbar || ""}
      />
      <InputEntry
        className="background size-small"
        placeholder="Default Text"
        onChange={e => setMask(e.target.value)}
        defaultValue={mask}
      />
    </Row>
    {/* <br/>
    <InputEntry.TextArea
      value={args}
      rows={3}
      onChange={e=>setArgs(e.target.value)}
      className="background size-small" 
      placeholder="Default Context (Added before script if event invoked through UI)"
    /> */}
    <br />
    <Row between>
      <Row center>
        Contexts
        <GhostInput placeholder="Display name for context" size="small" onConfirm={e => addOption(e)}>
          <Button small title="Add Context">
            <PlusSquareFilled />
          </Button>
        </GhostInput>
      </Row>
    </Row>
    <Row between>
      <Select className="grow background" size="small" placeholder="Choices" value={choice} onSelect={e => setChoice(e)}>
        {choices.map((v, i) => <Select.Option key={i} value={i}>{v.mask}</Select.Option>)}
      </Select>
      {choice != null && <Button small title="Remove Choice" onClick={e => removeOption(choice)}>
        <DeleteFilled />
      </Button>}
    </Row>
    {choice != null && <InputEntry.TextArea
      value={choices[choice].script}
      rows={3}
      onChange={e => updateOptionScript(choice, e.target.value)}
      className="background size-small"
      placeholder="Choice Context (added to the top of the below script, replaceable depending on context)"
    />}
    <br />
    <Row>
      Filter tags
      <GhostInput title="Add Tag" size="small" onConfirm={e => tags.includes(String(e).toLowerCase()) ? undefined : setTags([...tags, String(e).toLowerCase()])} placeholder="Enter New Tag">
        <Button center small>
          <PlusSquareFilled />
        </Button>
      </GhostInput>
    </Row>
    <Row wrap>
      {tags.map(t => <Tag key={t} closable onClose={e => {
        const newTags = [...tags]
        newTags.splice(tags.indexOf(t), 1)
        setTags(newTags)
      }}>{t}</Tag>)}
    </Row>
    <br />
    {/* <Row>
      <Row center>
        Allow Custom&nbsp;<Switch value={custom} onChange={e=>setCustom(!e)}/>
      </Row>
    </Row> */}
    {/* <Row>
      <Row center>
        Display on Avatar&nbsp;<Switch checked={custom} onChange={e=>setCustom(!e)}/>
      </Row>
    </Row> */}
    <Row between>
      <Row center><i className="black-tint pad-h">!{target}</i></Row>
      <Row>
        <Button className="foreground gaps-h" onClick={e => window.close()}>Cancel</Button>
        <Button className="foreground" onClick={confirm}>Confirm</Button>
      </Row>
    </Row>
  </Column>
}


export function ProjectComponentApp({ target, projectID }) {
  const component = getProject(projectID).getComponentOptions(target) || {}
  const [tags, setTags] = useState(component.tags || [])
  const [name, setName] = useState(component.name)
  const [custom, setCustom] = useState(component.custom)
  const [selected, setSelected] = useState()
  const [map, setMap] = useState(component.map)
  const [hotbar, setHotbar] = useState(component.hotbar)

  const addOption = k => {
    const newChoices = { ...map }
    if (k.length < 1)
      return
    const m = k.match(/[\w\d_]+/i)
    if (!m || m.index !== 0 || m[0].length !== k.length)
      return window.error("Invalid key, may only contain numbers, characters and _")
    if (m[0] === "_")
      return window.error("Key can't start with _, reserved for system")

    const key = k.toLowerCase()
    newChoices[key] = {}

    setSelected(key)
    setMap(newChoices)
  }

  const removeOption = key => {
    const newChoices = { ...map }
    delete newChoices[key]
    setSelected()
    setMap(newChoices)
  }

  const updateOptionPage = (option, text) => {
    const newChoices = { ...map }
    newChoices[option] = newChoices[option] || {}
    newChoices[option].profileID = text
    setMap(newChoices)
  }

  const updateOptionName = (option, text) => {
    const newChoices = { ...map }
    newChoices[option] = newChoices[option] || {}
    newChoices[option].name = text
    setMap(newChoices)
  }

  const confirm = e => {
    getProject(projectID).setComponentOptions(target, { ...component, tags, custom, name, hotbar, map })
    window.close()
  }

  return <Column>
    <i>[{target}]</i>
    <br />
    <Row className="size-small">Display Name</Row>
    <InputEntry
      defaultValue={name}
      onChange={e => setName(e.target.value)}
      className="background size-small"
      placeholder={target}
    />
    <br />
    <Row between>
      <Row center>
        Known Keys
        <GhostInput placeholder="Display name for context" size="small" onConfirm={e => addOption(e || "")}>
          <Button small title="Add Key">
            <PlusSquareFilled />
          </Button>
        </GhostInput>
      </Row>
    </Row>
    <Row between>
      <Select className="grow background" size="small" placeholder={`Shorthand keys for page codes #${target}(<key here>)`} value={selected} onSelect={e => setSelected(e)}>
        {Object.keys(map || {}).map((v, i) => <Select.Option key={v} value={v}>{v}</Select.Option>)}
      </Select>
      {selected != null && <Button small title="Remove Association" onClick={e => removeOption(selected)}>
        <DeleteFilled />
      </Button>}
    </Row>
    <br />
    {selected && <Column className="pad">
      <InputEntry
        defaultValue={map[selected].name}
        onChange={e => updateOptionName(selected, e.target.value)}
        className="background size-small"
        placeholder="Known Key Display Name"
      />
      <Row>
        <InputEntry
          value={map[selected].profileID}
          onChange={e => updateOptionPage(selected, e.target.value)}
          className="background size-small"
          placeholder="Known Key Page Code (creates a link)"
        />
        <Button className="foreground" onClick={e => window.pick2(profileID => updateOptionPage(selected, `[=${profileID}=]`))}>
          Pick
        </Button>
      </Row>
      <br />
    </Column>}
    <Row>
      Filter tags
      <GhostInput title="Add Tag" size="small" onConfirm={e => tags.includes(String(e).toLowerCase()) ? undefined : setTags([...tags, String(e).toLowerCase()])} placeholder="Enter New Tag">
        <Button center small>
          <PlusSquareFilled />
        </Button>
      </GhostInput>
    </Row>
    <Row wrap>
      {tags.map(t => <Tag key={t} closable onClose={e => {
        const newTags = [...tags]
        newTags.splice(tags.indexOf(t), 1)
        setTags(newTags)
      }}>{t}</Tag>)}
    </Row>
    <br />
    {/* <Row>
      <Row center>
        Allow Custom&nbsp;<Switch checked={custom} onChange={e=>setCustom(!e)}/>
      </Row>
    </Row> */}
    <Row>
      <Row center>
        Display in Actions&nbsp;<Switch checked={hotbar} onChange={e => setHotbar(!hotbar)} />
      </Row>
    </Row>
    <Row reverse>
      <Button className="foreground" onClick={confirm}>Confirm</Button>
      <Button className="foreground gaps-h" onClick={e => window.close()}>Cancel</Button>
    </Row>
  </Column>
}

function ProjectEvents(opts) {
  const { events, projectID } = useProject()
  const configure = target => window.ask({
    title: `Change ${events[target].name || target} Options`,
    app: ProjectEventApp,
    props: {
      projectID,
      target
    }
  })

  const remove = (target, e) => {
    e.stopPropagation()
    window.ask({ title: `Remove ${events[target].name || target}`, type: "confirm" }, res => {
      getProject(projectID).profile.deleteEvent(target)
      getProject(projectID).emit("change")
    })
  }

  const keys = Object.keys(events)

  const newEvent = e => window.ask({ title: "Enter Unique Key" }, res => {
    const m = res.match(/[\w\d_]+/i)
    if (!m || m.index !== 0 || m[0].length !== res.length)
      return window.error("Invalid key, may only contain numbers, characters and _")
    if (m[0] === "_")
      return window.error("Key can't start with _, reserved for system")

    if (events[res])
      return window.error("Duplicate key detected")

    getProject(projectID).profile.setEvent(res.toLowerCase(), {})
    getProject(projectID).emit("change")
  })

  return <Column grow>
    <Scroll>
      {keys.map(uid => <Column key={uid}>
        <Row between className="clickable background" style={{ borderBottom: "1px solid #141414" }} onClick={e => configure(uid)}>
          <Row className="pad-h" center><i className="pad-h">[{uid}]</i>{events[uid].name || ""}</Row>
          <Row onClick={e => e.stopPropagation()}>
            <Button className="foreground" onClick={
              e => window.build({
                title: "Change Event", width: "85vw", bodyStyle: { padding: 0 }, style: { height: "85vh" }, app: EventBuilder, props: {
                  defaultValue: getProject(projectID).profile.getEvent(uid).script, onFinalize: res => {
                    getProject(projectID).profile.updateEvent(uid, { script: res })
                    window.close(true)
                  }
                }
              })}>
              Event Script
            </Button>
            <Button onClick={e => remove(uid, e)}>
              <DeleteFilled />
            </Button>
          </Row>
        </Row>
      </Column>)}
    </Scroll>
    <Row center>
      <Button className="foreground" onClick={newEvent}>
        New Event&nbsp;<PlusSquareFilled />
      </Button>
    </Row>
  </Column>
}


function ProjectComponents(opts) {
  const { components, projectID } = useProject()
  const configure = target => window.ask({
    title: `Change ${components[target].name || target} Options`,
    app: ProjectComponentApp,
    props: {
      projectID,
      target
    }
  })

  const remove = (target, e) => {
    e.stopPropagation()
    window.ask({ title: `Remove ${components[target].name || target}`, type: "confirm" }, res => {
      getProject(projectID).removeComponent(target)
    })
  }

  const newComponent = e => window.ask({ title: "Enter Unique Key" }, res => {
    const m = res.match(/[\w\d_]+/i)
    if (!m || m.index !== 0 || m[0].length !== res.length)
      return window.error("Invalid key, may only contain numbers, characters and _")
    if (m[0] === "_")
      return window.error("Key can't start with _, reserved for system")

    if (components[res.toLowerCase()])
      return window.error("Duplicate key detected")

    getProject(projectID).setComponentOptions(res.toLowerCase(), {})
  })

  return <Column grow>
    <Scroll>
      {Object.keys(components).map(uid => <Column key={uid}>
        <Row between className="clickable background" style={{ borderBottom: "1px solid #141414" }} onClick={e => configure(uid)}>
          <Row className="pad-h" center><i className="pad-h">[{uid}]</i>{components[uid].name || ""}</Row>
          <Row onClick={e => e.stopPropagation()}>
            <Button className="foreground" onClick={
              e => window.build({
                title: "Change Event", width: "85vw", bodyStyle: { padding: 0 }, style: { height: "85vh" }, app: EventBuilder, props: {
                  defaultValue: getProject(projectID).getComponentOptions(uid).enter, onFinalize: res => {
                    getProject(projectID).setComponentOptions(uid, { ...getProject(projectID).getComponentOptions(uid), enter: res })
                    window.close(true)
                  }
                }
              })}>
              Enter Script
            </Button>
            <Button className="foreground" onClick={
              e => window.build({
                title: "Change Event", width: "85vw", bodyStyle: { padding: 0 }, style: { height: "85vh" }, app: EventBuilder, props: {
                  defaultValue: getProject(projectID).getComponentOptions(uid).exit, onFinalize: res => {
                    getProject(projectID).setComponentOptions(uid, { ...getProject(projectID).getComponentOptions(uid), exit: res })
                    window.close(true)
                  }
                }
              })}>
              Exit Script
            </Button>
            <Button onClick={e => remove(uid, e)}>
              <DeleteFilled />
            </Button>
          </Row>
        </Row>
      </Column>)}
    </Scroll>
    <Row center>
      <Button className="foreground" onClick={newComponent}>
        New Component&nbsp;<PlusSquareFilled />
      </Button>
    </Row>
  </Column>
}

function ProjectTables(opts) {
  const { rollTables, projectID } = useProject()
  const configure = target => window.ask({
    title: `Change ${rollTables[target].name || target} Display Name`,
    value: rollTables[target].name
    // app: ProjectComponentApp,
    // props: {
    //   projectID,
    //   target
    // }
  }, res => {
    getProject(projectID).rollTables[target].name = res
    getProject(projectID).emit("change")
  })

  const remove = (target, e) => {
    delete getProject(projectID).rollTables[target]
    getProject(projectID).emit("change")
    e.stopPropagation()
  }

  return <Column grow>
    <Scroll>
      {Object.keys(rollTables).map(uid => <Column key={uid}>
        <Row between className="clickable background" style={{ borderBottom: "1px solid #141414" }} onClick={e => configure(uid)}>
          <Row className="pad-h" center><i className="pad-h">[{uid}]</i>{rollTables[uid].name || ""}</Row>
          <Button onClick={e => remove(uid, e)}>
            <DeleteFilled />
          </Button>
        </Row>
      </Column>)}
    </Scroll>
    <Row center>
      <i>Add a new table using a roll table in a page</i>
    </Row>
  </Column>
}


export function ProjectTrackerApp({ target, projectID }) {
  const tracker = getProject(projectID).getTrackerOptions(target) || {}
  const [tags, setTags] = useState(tracker.tags || [])
  const [name, setName] = useState(tracker.name)
  const [gmOnly, setGMOnly] = useState(tracker.gmOnly)
  const [sort, setSort] = useState(tracker.sort)
  const [ascending, setAscending] = useState(tracker.ascending)

  const confirm = e => {
    getProject(projectID).setTrackerOptions(target, { ...tracker, tags, name, ascending, sort })
    window.close()
  }

  return <Column>
    <Row className="size-small">Display Name</Row>
    <InputEntry
      defaultValue={name}
      onChange={e => setName(e.target.value)}
      className="background size-small"
      placeholder={target}
    />
    <br />
    <Column>
      <Row>
        <Row center>
          GM Only&nbsp;<Switch size="small" checked={gmOnly} onChange={e => setGMOnly(!gmOnly)} />
        </Row>
      </Row>
      <Row>
        <Row center>
          Sort Alphabetically&nbsp;<Switch size="small" checked={sort === "alpha" && !ascending} onChange={e => { setSort("alpha"); setAscending(false) }} />
        </Row>
      </Row>
      <Row>
        <Row center>
          Sort Reverse Alphabetically&nbsp;<Switch size="small" checked={sort === "alpha" && ascending} onChange={e => { setSort("alpha"); setAscending(true) }} />
        </Row>
      </Row>
      <Row>
        <Row center>
          Sort Numerically (Ascending) &nbsp;<Switch size="small" checked={sort === "number" && ascending} onChange={e => { setSort("number"); setAscending(true) }} />
        </Row>
      </Row>
      <Row>
        <Row center>
          Sort Numerically (Descending) &nbsp;<Switch size="small" checked={sort === "number" && !ascending} onChange={e => { setSort("number"); setAscending(false) }} />
        </Row>
      </Row>
      {/* <Row>
        <Row center>
          Sort Numerically (Descending) &nbsp;<Switch size="small" checked={gmOnly} onChange={e=>setGMOnly(!gmOnly)}/>
        </Row>
      </Row> */}
    </Column>
    <br />
    <Row reverse>
      <Button className="foreground" onClick={confirm}>Confirm</Button>
      <Button className="foreground gaps-h" onClick={e => window.close()}>Cancel</Button>
    </Row>
  </Column>
}

function ProjectTrackers(opts) {
  const { trackers, projectID } = useProject()
  const configure = target => window.ask({
    title: `Change ${trackers[target].name || target} Tracker`,
    app: ProjectTrackerApp,
    props: {
      projectID,
      target
    }
  }, res => {
    getProject(projectID).trackers[target].name = res
    getProject(projectID).emit("change")
  })

  const remove = (target, e) => {
    delete getProject(projectID).trackers[target]
    getProject(projectID).emit("change")
    e.stopPropagation()
  }

  const newTracker = e => window.ask({ title: "Enter Unique Key" }, res => {
    const m = res.match(/[\w\d_]+/i)
    if (!m || m.index !== 0 || m[0].length !== res.length)
      return window.error("Invalid key, may only contain numbers, characters and _")
    if (m[0] === "_")
      return window.error("Key can't start with _, reserved for system")

    if (trackers[res.toLowerCase()])
      return window.error("Duplicate key detected")

    getProject(projectID).setTrackerOptions(res.toLowerCase(), {})
  })

  return <Column grow>
    <Scroll>
      {Object.keys(trackers).map(uid => <Column key={uid}>
        <Row between className="clickable background" style={{ borderBottom: "1px solid #141414" }} onClick={e => configure(uid)}>
          <Row className="pad-h" center><i className="pad-h">[{uid}]</i>{trackers[uid].name || ""}</Row>
          <Row onClick={e => e.stopPropagation()}>
            <Button className="foreground" onClick={
              e => window.build({
                title: "Change Event", width: "85vw", bodyStyle: { padding: 0 }, style: { height: "85vh" }, app: EventBuilder, props: {
                  defaultValue: getProject(projectID).getTrackerOptions(uid).enter, onFinalize: res => {
                    getProject(projectID).setTrackerOptions(uid, { ...getProject(projectID).getTrackerOptions(uid), enter: res })
                    window.close(true)
                  }
                }
              })}>
              Enter Script
            </Button>
            {/* <Button className="foreground" onClick={
                e => window.build({title: "Change Event", width: "85vw", bodyStyle: {padding: 0}, style: {height: "85vh"}, app: EventBuilder, props: {defaultValue: getProject(projectID).getTrackerOptions(uid).exit, onFinalize: res=>{
                  getProject(projectID).setTrackerOptions(uid, { ...getProject(projectID).getTrackerOptions(uid), exit: res })
                  window.close()
                }}})}>
              Exit Script
            </Button> */}
            <Button onClick={e => remove(uid, e)}>
              <DeleteFilled />
            </Button>
          </Row>
        </Row>
      </Column>)}
    </Scroll>
    <Row center>
      <Button className="foreground" onClick={newTracker}>
        New Tracker&nbsp;<PlusSquareFilled />
      </Button>
    </Row>
  </Column>
}

function ProjectGameMechanics() {
  const [tab, setTab] = useState("automation")
  return <Column grow>
    <Row className="black">
      <Button className={tab === "components" ? "foreground" : "background"} selected={tab === "components"} style={{ border: "1px solid #141414", marginLeft: "0.5rem", marginTop: "0.5rem" }} onClick={e => setTab("components")}>Components</Button>
      <Button className={tab === "automation" ? "foreground" : "background"} selected={tab === "automation"} style={{ border: "1px solid #141414", marginTop: "0.5rem" }} onClick={e => setTab("automation")}>Events</Button>
      <Button className={tab === "tables" ? "foreground" : "background"} selected={tab === "tables"} style={{ border: "1px solid #141414", marginTop: "0.5rem" }} onClick={e => setTab("tables")}>Roll Tables</Button>
      <Button className={tab === "trackers" ? "foreground" : "background"} selected={tab === "trackers"} style={{ border: "1px solid #141414", marginTop: "0.5rem" }} onClick={e => setTab("trackers")}>Trackers</Button>
    </Row>
    {tab === "automation" && <ProjectEvents />}
    {tab === "components" && <ProjectComponents />}
    {tab === "tables" && <ProjectTables />}
    {tab === "trackers" && <ProjectTrackers />}
  </Column>
}

function ProjectContentCreation() {
  const [tab, setTab] = useState("templates")
  return <Column grow>
    <Row className="black">
      <Button className={tab === "wizards" ? "foreground" : "background"} selected={tab === "wizards"} style={{ border: "1px solid #141414", marginLeft: "0.5rem", marginTop: "0.5rem" }} onClick={e => setTab("wizards")}>Creation Wizards</Button>
      <Button className={tab === "templates" ? "foreground" : "background"} selected={tab === "templates"} style={{ border: "1px solid #141414", marginTop: "0.5rem" }} onClick={e => setTab("templates")}>Templates</Button>
    </Row>
    {tab === "templates" && <Templates />}
    {tab === "wizards" && <WizardManager onSelect={wizardID => {
      Navigation.wiki = true
      Navigation.settings = false
      Navigation.setWizard(wizardID)
    }} />}
  </Column>
}

let tutorial = true
export function ProjectSettings(opts) {
  const { projectID, meta } = useProject()
  const { projectID: navProjectID } = useNav()
  const [menu, setMenu] = useState()

  const openImporter = e => {
    if (tutorial) {
      window.warn({ message: "Import content that has been formatted. Good for exporting from ", duration: 0 })
      tutorial = false
    }
  }
  const { system } = meta

  const toggleDescriptor = name => {
    const descriptors = getProject(projectID).meta.descriptors || {}
    if (descriptors[name])
      delete descriptors[name]
    else
      descriptors[name] = true

    getProject(projectID).meta.descriptors = descriptors
    getProject(projectID).emit("change")
  }

  return <Column className={navProjectID !== projectID ? "no-click invisible" : "visible"}>
    {menu === "Archives" && <Modal visible={true} centered footer={null} width="80vw" title={menu} onCancel={e => setMenu()} bodyStyle={{ padding: 0 }}>
      <Column fit className="card-bg">
        <Archives projectID={projectID} style={{ height: "80vh" }} />
      </Column>
    </Modal>}
    {menu === "Templates" && <Modal visible={true} centered footer={null} width="80vw" title={menu} onCancel={e => setMenu()}><Templates projectID={projectID} style={{ height: "80vh" }} /></Modal>}
    {menu === "Wizards" && <Modal visible={true} centered footer={null} width="80vw" title={menu} onCancel={e => setMenu()} bodyStyle={{ padding: 0, height: "80vh" }}>
      <WizardManager onSelect={wizardID => {
        Navigation.wiki = true
        Navigation.setWizard(wizardID)
        setMenu()
      }} />
    </Modal>}
    {menu === "Events" && <Modal visible={true} centered footer={null} width="80vw" title={menu} onCancel={e => setMenu()}><Events projectID={projectID} style={{ height: "80vh" }} /></Modal>}
    {menu === "Systems" && <Modal visible={true} centered footer={null} width="80vw" title="Project Details" onCancel={e => setMenu()} bodyStyle={{ padding: 0 }}>
      <Column fit className="card-bg">
        <Column relative center fitX className="black-tint rounded pad-l" style={{ overflow: "hidden" }}>
          {system && systems[system] && <img className="cover" height="256px" src={systems[system].img} alt="" />}
          {system && systems[system] && <Column className="pad-l black-tint" fitX absolute bottom>
            <a className="size-2 text-white">{systems[system].name}</a>
            <a className="text-dull" title="Author/Publisher">{systems[system].author}</a>
          </Column>}
        </Column>
        <Column>
          <Select showSearch className="background text-white" allowClear placeholder="Pick a System" value={system} onSelect={e => getProject(projectID).setSystem(e)} onClear={e => getProject(projectID).setSystem()}>
            {Object.keys(systems).map((key, i) => <Select.Option key={key} children={key} />)}
          </Select>
        </Column>
        <Column grow className="pad-l">
          <Column className="pad-v-l">
            Includes
            <Row>
              <Checkbox
                checked={(meta.descriptors || {}).adventure}
                onChange={e => toggleDescriptor("adventure")}
                name="codexes"
                color="secondary"
                className="gaps-h"
              /><Row center>Adventure</Row>
            </Row>
            <Row>
              <Checkbox
                checked={(meta.descriptors || {}).content}
                onChange={e => toggleDescriptor("content")}
                name="codexes"
                color="secondary"
                className="gaps-h"
              /><Row center>Content</Row>
            </Row>
            <Row>
              <Checkbox
                checked={(meta.descriptors || {}).fluff}
                onChange={e => toggleDescriptor("fluff")}
                name="codexes"
                color="secondary"
                className="gaps-h"
              /><Row center>Fluff</Row>
            </Row>
            <Row>
              <Checkbox
                checked={(meta.descriptors || {}).system}
                onChange={e => toggleDescriptor("system")}
                name="codexes"
                color="secondary"
                className="gaps-h"
              /><Row center>System</Row>
            </Row>
          </Column>
        </Column>
        {!window.isLocal && <Row center>
          <Row center className="pad-h">Publish to Compendium</Row>
          <ProjectCodexButton />
        </Row>}
      </Column>
    </Modal>}

    {/* <Dropdown trigger="click" overlay={<Menu>
      <Menu.Item onClick={e=>setMenu("Archives")}>Manage Archives</Menu.Item>
      <Menu.Item onClick={e=>setMenu("Events")}>Manage Automation</Menu.Item>
      <Menu.Item onClick={e=>setMenu("Templates")}>Manage Templates</Menu.Item>
      <Menu.Item onClick={e=>setMenu("Wizards")}>Manage Wizards</Menu.Item>
      <Menu.Item onClick={e=>setMenu("Systems")}>Project Details</Menu.Item>
    </Menu>}> */}
    <Button onClick={e => { Navigation.setProfile(); Navigation.toggleSettings(true) }} childClass="grow" className="rounded foreground" title="Project Settings" placement="right" mouseEnterDelay={0.5}>
      <SettingFilled />
    </Button>
    {/* </Dropdown> */}
  </Column>
}


function WizardType({ onSelect, startSelecting }) {
  const [selecting, setSelecting] = useState(startSelecting)
  const [archives, setArchives] = useState([])
  const [system, setSystem] = useState()
  const [type, setType] = useState()

  if (selecting)
    return <Community projectSystem={system} onBack={e => setSelecting()} onSelect={(e, id) => {
      setSelecting()
      if (selecting === "content") {
        for (const uid of archives)
          if (uid === id.uid)
            return window.warning("Already included")

        const p = getProject(id.meta.target)

        if (!p.loaded)
          p.once("ready", r=>setArchives([...archives, id.meta.target]))
        else
          setArchives([...archives, id.meta.target])
      }
      else {

        const p = getProject(id.meta.target)
        if (!p.loaded)
          p.once("ready", r=>setArchives([...archives, ...Object.keys(p.codexes)]))
        else
          setArchives([...archives, ...Object.keys(p.codexes)])

        setTimeout(()=>setSystem(id.meta.target), 10)
        setType()
      }
    }} />

  const types = {
    "Blank": {
      desc: "This project is completely empty, waiting for you to build a system, and expand in it",
      icon: "/assets/icons/png_128/icon_tool/icon_tool_77.png"
    },
    "RPG Skeleton - d20": {
      desc: "This project contains some basic automation for d20 RPGs",
      icon: "/dice/d20.svg",
      invert: true
    },
    "RPG Skeleton - d100": {
      desc: "This project contains some basic automation for d100 RPGs",
      icon: "/dice/d10.svg",
      invert: true
    },
    "Adventure": {
      desc: "This project is an adventure framework that you can share or use in your campaigns. Basically an Adventure Book",
      icon: "/assets/icons/png_128/icon_game/icon_game_121.png"
    },
    "Campaign": {
      desc: "This project is for actively running a campaign, keeping your party's progression, exploration and knowledge of the world seperate. You should make one per group you play with",
      icon: "/assets/icons/png_128/icon_app/icon_app_18.png"
    },
    "Content": {
      desc: "This project is a collection of content that can be used during a campaign, or shared for other people to enjoy in the Archives. Basically an Expansion pack",
      icon: "/assets/icons/png_128/icon_game/icon_game_64.png"
    },
    "Fluff": {
      desc: "This project is worldbuilding content for the universe, it should contain interesting locations, backgrounds, lore and other non-gameplay related content",
      icon: "/assets/icons/png_128/icon_tool/icon_tool_77.png"
    },
  }

  if (system) {
    delete types["Blank"]
    delete types["RPG Skeleton - d20"]
    delete types["RPG Skeleton - d100"]
  }
  else
    for (const key in types)
      if (key !== "Blank" && key !== "RPG Skeleton - d100" && key !== "RPG Skeleton - d20")
        delete types[key]

  function getCommunityData(projectID) {
    const project = getProject(projectID)
    return {
      isSystem: project.meta?.descriptors?.system, 
      projectSystem: project.meta?.projectSystem,
      meta: { 
        target: project.uid, 
        system: project.meta?.projectSystem, 
        name: project?.profile?.states[project?.profile?.state].name, 
        src: project?.profile?.states[project?.profile?.state].media[0]?.src
      } 
    }
  }

  return <Column relative grow>
    <Column grow style={{ border: "1px solid #141414", borderBottom: "none" }}>
      <Row grow>
        <Row grow>
          <Scroll>
            {Object.keys(types).map(key => <Row className={`pad-l clickable ${key === type ? "foreground text-blue" : "background"}`} style={{ borderBottom: "1px solid #141414" }} key={key} onClick={e => setType(key)}>
              <Column className="pad-h">
                <img height={64} width={64} src={types[key].icon} style={types[key].invert ? { filter: "invert(100%)" } : undefined} alt="" />
              </Column>
              <Column grow className="pad">
                <h3 style={{ margin: 0, color: "inherit" }}>{key}</h3>
                <span>{types[key].desc}</span>
              </Column>
            </Row>)}
          </Scroll>
        </Row>

        <Column width="50%">
          <Column grow className="black-tint rounded">
            <Column grow relative>
              {system && <ProjectProvider projectID={system}>
                <Column absolute fit reverse>
                  <ProjectImageCard />
                  <Column className="pad-l black-tint" fitX absolute bottom>
                    <a className="size-2 text-white"><ProjectName /></a>
                    <a className="text-dull" title="Author/Publisher"><ProjectAuthor /></a>
                  </Column>
                </Column>
              </ProjectProvider>}
              {!system && <Column fit absolute center className="text-dull">
                <Row center>
                  <Button className="foreground" onClick={e => { setSelecting(true); setSystem() }}>Pick A System</Button>
                </Row>
              </Column>}
            </Column>
          </Column>
          <Column relative grow>
            <Scroll>
              {archives.map((data, i) => <EntryRow small startData={getCommunityData(data)} onRemove={e => {
                const newArchives = [...archives]
                newArchives.splice(i, 1)
                setArchives(newArchives)
              }} onClick={e => ("")} />)}
            </Scroll>
            <Row absolute bottom fitX center noClick>
              <Button center className="foreground gaps-h" click onClick={e => setSelecting("content")}>Add Extra Content&nbsp;<PlusSquareFilled /></Button>
            </Row>
          </Column>
        </Column>
      </Row>
      {type && <Row absolute noClick bottom fitX center>
        <Button click className="foreground" childClass="size-2" onClick={e => onSelect({ system, type, archives: system ? [...archives, system, ...Object.keys(getProject(system).codexes)] : archives, wallpaper: system ? getProject(system).getBackground() : undefined })}>
          Confirm
        </Button>
      </Row>}
    </Column>
  </Column>
}

export function StartProjectWizard() {
  return new Promise((res, rej) => {
    const ask = startSelecting=>window.ask({
      title: "Select Project Type",
      app: WizardType,
      width: "90vw",
      style: { height: "90vh" },
      bodyStyle: { padding: 0 },
      props: {
        startSelecting,
        onSelect: opts => {
          window.ask({
            title: "Enter Project Details",
            placeholder: "Enter Project Name"
          },
            name => res({ ...opts, name }), rej)
        }
      }
    }, null, rej)
    window.ask({
      title: "Would you like to use an existing system",
      type: "confirm",
      yes: "yes",
      no: "no"
    },e=>ask(true),e=>ask(false))
  })
}

export function ProjectOverview() {
  const { projectID, meta, loaded } = useProject()
  const [tab, setTab] = useState("overview")

  const { system } = meta

  const toggleDescriptor = name => {
    const descriptors = getProject(projectID).meta.descriptors || {}
    if (descriptors[name])
      delete descriptors[name]
    else
      descriptors[name] = true
    getProject(projectID).meta.descriptors = descriptors
    getProject(projectID).emit("change")
  }

  const pickSystem = e => {
    const onSelect = (e, data, index) => {
      window.close()
      getProject(projectID).meta.projectSystem = data.meta.target
      getProject(projectID).emit("change")
    }
    window.ask({ title: "Select a System", app: Community, style: { height: "90vh" }, bodyStyle: { padding: 0 }, width: "90vw", props: { onSelect, systemSelect: true } })
  }

  const clearSystem = e => {
    delete getProject(projectID).meta.projectSystem
    getProject(projectID).emit("change")
  }

  return <Column grow>
    <Row>
      <Button className={tab === "overview" ? "foreground" : "background"} selected={tab === "overview"} style={{ border: "1px solid #141414", marginLeft: "0.5rem" }} onClick={e => setTab("overview")}>Meta Data</Button>
      <Button className={tab === "archives" ? "foreground" : "background"} selected={tab === "archives"} style={{ border: "1px solid #141414" }} onClick={e => setTab("archives")}>Archives</Button>
      <Button className={tab === "creation" ? "foreground" : "background"} selected={tab === "creation"} style={{ border: "1px solid #141414" }} onClick={e => setTab("creation")}>Content Creation</Button>
      <Button className={tab === "mechanics" ? "foreground" : "background"} selected={tab === "mechanics"} style={{ border: "1px solid #141414" }} onClick={e => setTab("mechanics")}>System Mechanics</Button>
      <Row grow />
      <Button childClass="grow row center" className="background" onClick={e => Navigation.setSettings()}><CloseOutlined /></Button>
    </Row>
    <Column grow className="card-bg">
      {tab === "overview" && <Column grow>
        <Column grow>
          <Row>
            <Column width="33%" height="300px" className="black-tint rounded">
              <Column grow relative>
                {meta.projectSystem && <ProjectProvider projectID={meta.projectSystem}>
                  <Column absolute fit reverse>
                    <ProjectImageCard />
                    <Column className="pad-l black-tint" fitX absolute bottom>
                      <a className="size-2 text-white"><ProjectName /></a>
                      <a className="text-dull" title="Author/Publisher"><ProjectAuthor /></a>
                    </Column>
                  </Column>
                </ProjectProvider>}
                <Column fit absolute center className="text-dull">
                  <Column center>
                    <Button className="foreground" onClick={pickSystem}>Associate with a System</Button>
                    {meta.projectSystem ? <Button className="foreground" onClick={clearSystem}>Reset System</Button> : null}
                  </Column>
                </Column>
              </Column>
            </Column>
            <Column width="33%" height="300px">
              <Column relative center fitX height="300px" className="black-tint rounded pad-l" style={{ overflow: "hidden" }}>
                <span className="pad size-large absolute top left black-tint">
                  Game
                </span>
                {system && systems[system] && <img className="cover" height="300px" src={systems[system].img} alt="" />}
                <Column className="pad-l black-tint" fitX absolute bottom>
                  {system && systems[system] && <a className="size-2 text-white">{systems[system].name}</a>}
                  {system && systems[system] && <a className="text-dull" title="Author/Publisher">{systems[system].author}</a>}
                  <Column>
                    <Select showSearch className="background text-white" allowClear placeholder="Associate with a Game" value={system} onSelect={e => getProject(projectID).setSystem(e)} onClear={e => getProject(projectID).setSystem()}>
                      {Object.keys(systems).map((key, i) => <Select.Option key={key} children={key} />)}
                    </Select>
                  </Column>
                  <Row center className="pad size-small text-dull">
                    <i>Don't see a game you're playing? Suggest it on Discord!</i>
                  </Row>
                </Column>
              </Column>
            </Column>
            <Column grow className="pad-l">
            <Column>
                {window.isLocal && <Button className="foreground" onClick={e => {
                    if (window.isDemo)
                      return window.info("Requires Host License")
                    window.info("Creating package...")
                    const opts = {
                      method: "POST",
                      headers: {
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({
                        projectID
                      })
                    }
                    fetch(`/package`, opts).then(e => window.success("Finished Creating package")).catch(err => console.warn(err))
                  }}>Make Workshop Package</Button>}
                {window.isLocal && <Button className="foreground" onClick={e => {
                    if (window.isDemo)
                      return window.info("Requires Host License")
                    const input = document.createElement("input")
                    input.type = "file"
                    input.accept = "text/*"
                    input.multiple = true
                    input.onchange = e => {
                      let stack = []
                      function IMPORT() {
                        if (!stack.length)
                          return
                        const { list: files, msg } = stack.pop()
                        const profiles = []
                        window.info(msg)
                        function confirm() {
                          for (const p of profiles)
                            if (!p)
                              return

                          for (const p of profiles) {
                            Resources.Profiles.clean(p.uid)
                            const data = p.pack()
                            delete data.uid
                            delete data._id
                            const profile = Resources.Profiles.create(getProject(projectID).createUID())
                            profile.unpack(data)
                            addToWiki(projectID, profile.uid, true)
                            profile.emit("change")
                          }
                          getProject(projectID).wiki.rebuild()
                          getProject(projectID).emit("change")
                          window.success("Import Complete!")
                          if (stack.length < 1)
                            window.warning("You should refresh (f5) after every import")
                          IMPORT()
                        }

                        files.forEach((file, i) => {
                          var reader = new FileReader()
                          profiles.push(false)
                          reader.onload = function (e) {
                            var content = this.result
                            const { event, context } = createEvent({ uid: `-temp-${v4()}` })
                            event.roll(content, context).then(event => {
                              const templateID = event.eval(event.api["template"], context)
                              createProfile(projectID, String(templateID).toLowerCase().trim(), profile => {
                                applyEvent(event, context, { sourceID: profile.uid })
                                profile.setName(event.eval(event.api["name"], context))
                                profile.setAlias(event.eval(event.api["alias"], context))
                                if (event.api["tab"]) {
                                  const t = String(event.eval(event.api["tab"], context)).trim()
                                  const tData = profile.getTab(t)
                                  if (tData != null) {
                                    const { articleID, templated } = profile.getTab(t)
                                    if (!templated) {
                                      const article = Resources.Articles.get(articleID)
                                      for (const text of event.text)
                                        article.body = article.body.concat(CreateValue(event.eval(text, context)))

                                      article.emit("change")
                                    }
                                  }
                                }
                                else if (!profile.getTemplated()) {
                                  const article = Resources.Articles.get(profile.getArticleID())
                                  for (const text of event.text)
                                    article.body = article.body.concat(CreateValue(event.eval(text, context)))
                                  article.emit("change")
                                }
                                profile.emit("ready")
                                profile.emit("change")

                                profiles[i] = profile
                                confirm()
                                Resources.Events.clean(event.uid)
                              })
                            })
                          }
                          reader.readAsText(file)
                        })
                      }
                      if (e.target.files.length <= 500) {
                        const list = [...e.target.files]
                        const l = list.length
                        const chunk = Math.ceil(l / 10)
                        for (let i = 0; i < chunk; i++)
                          if (list.length)
                            stack.push({ msg: `Importing... (${list.length} Remaining)`, list: list.splice(0, Math.min(20, list.length)) })

                        stack.reverse()
                        IMPORT()
                      }
                      else {
                        window.warning("Can't Import more than 500 entries at a time")
                      }
                    }
                    input.click()
                  }}>
                    Page Importer (Event Script)
                  </Button>}
                {!window.isLocal ? <Row center>
                  <Row center className="pad">Publish to Compendium</Row>
                  <ProjectCodexButton />
                </Row> : <Button className="foreground" onClick={e => {
                  if (window.isDemo)
                    return window.info("Requires Host License")
                  const opts = {
                    method: "POST",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ path: projectID })
                  }
                  fetch(`/getFiles`, opts).catch(err => console.warn(err))
                }}>Open in Explorer</Button>}
              </Column>
              <Column grow className="pad">
                Descriptors
                <Row>
                  <Checkbox
                    checked={(meta.descriptors || {}).adventure}
                    onChange={e => toggleDescriptor("adventure")}
                    name="codexes"
                    color="secondary"
                    className="gaps-h"
                  /><Row center>Adventure</Row>
                </Row>
                <Row>
                  <Checkbox
                    checked={(meta.descriptors || {}).content}
                    onChange={e => toggleDescriptor("content")}
                    name="codexes"
                    color="secondary"
                    className="gaps-h"
                  /><Row center>Content</Row>
                </Row>
                <Row>
                  <Checkbox
                    checked={(meta.descriptors || {}).fluff}
                    onChange={e => toggleDescriptor("fluff")}
                    name="codexes"
                    color="secondary"
                    className="gaps-h"
                  /><Row center>Fluff</Row>
                </Row>
                <Row>
                  <Checkbox
                    checked={(meta.descriptors || {}).system}
                    onChange={e => toggleDescriptor("system")}
                    name="codexes"
                    color="secondary"
                    className="gaps-h"
                  /><Row center>System</Row>
                </Row>
              </Column>
            </Column>
          </Row>
          <Column grow>
            <ProfileMeta />
          </Column>
        </Column>
      </Column>}
      {tab === "mechanics" && <ProjectGameMechanics />}
      {tab === "archives" && <Archives />}
      {tab === "creation" && <ProjectContentCreation />}
    </Column>
  </Column>
}

let open
export default ({ key }) => {
  const [opacity, setOpacity] = useState(0)
  const [left, setLeft] = useState(-window.innerWidth / 2 + "px")

  const { name, id, uid, avatar, rank } = useAccount()

  function leave() {
    setOpacity(0)
    setLeft(-window.innerWidth / 2 + "px")
    open = false
  }
  function enter() {
    setOpacity(1)
    setLeft(0)
    open = true
  }

  useEffect(() => {
    enter()
    Input.on("key_down", ({ e }) => {
      if (e.key === key)
        if (!open)
          enter()
        else
          leave()
    })
  }, [])

  return <Column noClick fitY top absolute style={{ zIndex: 1299, padding: "3.0rem 1.0rem", transition: "left 0.1s, opacity 0.1s", opacity, left }}>
    <Column fit relative click style={{ minWidth: "30vw", maxWidth: "30vw" }}>
      {name}<br />
      {id}<br />
      {uid}<br />
      {avatar}<br />
      {rank}<br />
    </Column>
  </Column>
}

