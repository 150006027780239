import React, { useState, useRef, useEffect } from 'react'
import useEvents from '../core/hooks/useEvents'
import Account from '../core/state/Account'
import Network from '../core/state/Network'

import { Row, Column, Button, Div } from '../UI'
import { ClusterOutlined, TeamOutlined, LoginOutlined } from '@ant-design/icons'
import { v4 } from 'uuid'
import { getProject } from "./hooks/useProject"
import useAccount from "../core/hooks/useAccount"
import { Dropdown, Menu } from "antd"
import UniversalDrop from "../core/wrappers/UniversalDrop"

Account.on("meta", res => {
  if (Network.lobby)
    Network.broadcast("metadata", JSON.stringify({ name: Account.name, uid: Account.uid }))
})

const PeerPlate = ({ peerID, data }) => {
  const mediaRef = useRef()
  const { accountID } = useAccount()
  const { stream, name, video, audio, uid } = data

  useEffect(() => {
    if (stream && mediaRef && mediaRef.current) {
      if (audio)
        stream.addTrack(audio)

      if (video)
        stream.addTrack(video)

      mediaRef.current.srcObject = stream
      mediaRef.current.addEventListener("loadeddata", function (evt) {
        // console.log(evt)
        mediaRef.current.play()
      })
    }
  }, [data, audio, stream, video])


  const onDrop = (p, m, c) => {
    const item = m.getItem()
    const project = getProject()
    if (item.profileID && project.meta.creator === accountID) {
      Network.send("navigation", JSON.stringify({ project: project.uid, profile: item.profileID }), peerID)
      window.info("Sent handout")
    }
    else
      window.info("Only the host can send handouts")
  }

  const permitted = getProject() && getProject().isOwner() && !getProject().getRole(uid)
  
  const addToProject = (uid, name) => {
    const p = getProject()
    // if (p.uid === "local" || !Account.isMember()) {
    //   window.info("Support Mythic Archive to add users to a project")
    //   return
    // }
    if (window.isDemo)
      return window.info("Requires Host License")
      
    p.configureUser(uid, { name })
  }
  
  let text = name || "NO NAME"
  if (true)
    text = text.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()

  function hash(s){
    return String(s).split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);
  }
  const cols = ["pink", "red", "orange", "olive", "green", "#00e4ec", "#3388ff", "#8832ff", "#964B00", "grey", "#222222"]
  const col = hash(uid || peerID) % cols.length

  return <UniversalDrop className="column card-bg" onDrop={onDrop}>
    {/* <video width={opts.width ?? 180} height={audio && video ? opts.height ?? 120 : 0} ref={mediaRef} /> */}
    <Column>
      <Dropdown trigger="click" overlay={<Menu>
        {permitted && uid && <Menu.Item onClick={e => addToProject(uid, name)}>Add User to Project</Menu.Item>}
        {permitted && <Menu.Item onClick={e => window.pick(profileID=>Network.send("navigation", JSON.stringify({ project: getProject().uid, profile: profileID }), peerID))}>
          Send handout
        </Menu.Item>}
      </Menu>}>
        <Column click title={name} className="black-tint rounded pointer" style={{padding: "2px"}}>
          <Row center style={{backgroundColor: cols[col], border: "2px solid rgba(255,255,255,0.87)"}} >
            {text}
          </Row>
        </Column>
      </Dropdown>
      {/* {permitted ? <Button variant="contained" small title="Add User to Project" placement="top"><PersonAdd fontSize="small" /></Button> : null} */}
    </Column>
  </UniversalDrop>
}

export const copySocket = uid => {
  let temp = document.createElement("input")
  temp.type = "text"
  temp.value = uid
  document.body.appendChild(temp)
  temp.focus()
  temp.select()
  document.execCommand("copy")
  document.body.removeChild(temp)
  window.info("Copied join code!")
}

export function LobbyButton(opts){
  const [lobby, setLobby] = useState()

  // const [mic, setMic] = useState(false)
  // const [camera, setCamera] = useState(false)

  useEffect(() => {
    function force() {
      setLobby(Network.host || (Network.host !== true && Network.server))
    }
    Network.on("change", force)
    return () => {
      Network.off("change", force)
    }
  }, [])


  // if (lobby)
  if (!Network.host)
    return <div/>

  return lobby ? <Button click className="card-bg" title="Lobby code" mouseEnterDelay={0.5} onClick={e => window.notify("Lobby code : " + Network.lobby)}><TeamOutlined/></Button>      
    : <Button click title="Host Lobby" mouseEnterDelay={0.5} className="card-bg" placement="right" onClick={e => {
      // if (Account.uid) {
      let lobbyCode = v4()
      Network.createLobby(lobbyCode).then(v => { // host a lobby
        Network.prepare(Account.id)
        copySocket(lobbyCode)
        setLobby(lobbyCode)
      }).catch(e => window.warning("Could not create lobby, try again later"))
      // }
      // else
      //   window.warning("You must be logged in and have an open project to create a lobby (Your players don't though)")
    }}><ClusterOutlined /></Button>
}

export function PlayerList({className}){
  const [players, setPlayers] = useState()

  // const [mic, setMic] = useState(false)
  // const [camera, setCamera] = useState(false)

  useEffect(() => {
    function force() {
      setPlayers({...Network.peers()})
    }
    Network.on("change", force)
    return () => {
      Network.off("change", force)
    }
  }, [])

  const playerChips = []
  // if (!window.mobilecheck())
  for (const peer in players)
    playerChips.push(<PeerPlate key={peer} peerID={peer} data={players[peer]} />)

  return <Div className={className}>
    {playerChips}
  </Div>
}

export default opts => {
  const [lobby, setLobby] = useState()
  const [players, setPlayers] = useState()

  // const [mic, setMic] = useState(false)
  // const [camera, setCamera] = useState(false)

  const [name, setName] = useState(Account.name)
  useEvents(["loaded", "change"], () => setName(Account.name), Account)

  useEffect(() => {
    function force() {
      setPlayers(Network.peers())
      setLobby(Network.host || (Network.host !== true && Network.server))
    }
    Network.on("change", force)
    return () => {
      Network.off("change", force)
    }
  })


  const playerChips = []
  // if (!window.mobilecheck())
  for (const peer in players)
    playerChips.push(<PeerPlate key={peer} peerID={peer} data={players[peer]} />)

  // if (lobby)
  return <Row reverse wrap="wrap-reverse">
    <Row reverse>
      {lobby ? <Button click small className="card-bg" title="Lobby code" onClick={e => window.notify("Lobby code : " + Network.lobby)}><ClusterOutlined/></Button>      
      : <Button click title="Host Lobby" className="card-bg" small onClick={e => {
        // if (Account.uid) {
        let lobbyCode = v4()
        Network.createLobby(lobbyCode).then(v => { // host a lobby
          Network.prepare(Account.id)
          copySocket(lobbyCode)
          setLobby(lobbyCode)
        }).catch(e => window.warning("Could not create lobby, try again later"))
        // }
        // else
        //   window.warning("You must be logged in and have an open project to create a lobby (Your players don't though)")
      }
      }><ClusterOutlined /></Button>}
    </Row>

    {/* <video width={opts.width ?? 180} height={audio && video ? opts.height ?? 120 : 0} ref={mediaRef} /> */}

    {/* <Row reverse>
      <Button
        small
        className="card-bg"
        onClick={e => window.ask({title: "Enter New Temporary Username", value: name}, username=>Account.setName(username))}
      >
        {name}
      </Button>
    </Row> */}
    {playerChips}
  </Row>
  // return <Box />
}