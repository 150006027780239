import React, { useEffect, useState, useContext, createContext } from 'react'
import Resources from '../state/Resources'
import Profile from '../models/Profile'
import Nav from '../state/Navigation'

import useProject, { getPermission, getProject } from "./useProject"
import useAccount from "../../core/hooks/useAccount"

const ProfileContext = createContext()

export { ProfileContext }

const profile = new Profile()
const { Profiles, Projects } = Resources

export function getProfile(profileID) {
  return profileID ? Profiles.get(profileID) : (Nav.project ? Projects.get(Nav.project).profile : profile)
}

export default function useProfile() {
  return useContext(ProfileContext) || {}
}

export function useHook(profileID) {
  const { projectID } = useProject()
  const { accountID } = useAccount()

  const [name, setName] = useState()
  const [alias, setAlias] = useState()
  const [banner, setBanner] = useState()
  const [loaded, setLoaded] = useState()
  const [icon, setIcon] = useState()
  const [meta, setMeta] = useState()
  const [tags, setTags] = useState([])
  const [articleID, setArticleID] = useState()
  const [mapID, setMapID] = useState()
  const [data, setData] = useState()
  const [formulas, setFormulas] = useState()
  const [events, setEvents] = useState()
  const [templated, setTemplated] = useState()
  const [tabs, setTabs] = useState({})
  const [temp, setTemp] = useState()
  const [permission, setPermission] = useState()

  useEffect(() => {
    const p = getProfile(profileID)
    function change() {
      setName(p.getName())
      setAlias(p.getAlias())
      setBanner(p.getBanner())
      setLoaded(p.loaded)
      setMeta(p.meta)
      setTags([...p.meta.tags || []])
      setIcon(p.getIcon())
      setArticleID(p.getArticleID())
      setMapID(p.getMapID())
      setData({ ...p.getDataTable() })
      setFormulas({ ...p.getFormulaTable() })
      setEvents([ ...p.getEvents() ])
      setTemplated(p.getTemplated())
      setTabs({...p.getTabsObject()})
      setTemp(p.temp)
    }
    change()

    p.on("change", change)
    p.on("ready", change)

    return function () {
      p.off("change", change)
      p.off("ready", change)
    }
  }, [profileID])

  useEffect(()=>{
    const p = getProject(projectID)
    function change() {
      setPermission(getPermission(profileID, "profiles", accountID, projectID))
    }
    change()

    p.on("change", change)
    p.on("ready", change)

    return function () {
      p.off("change", change)
      p.off("ready", change)
    }
  }, [profileID, projectID, accountID])

  return { articleID, profileID, loaded, banner, alias, name, icon, meta, tags, mapID, data, events, templated, tabs, temp, permission, formulas }
}

export function ProfileProvider({ profileID, children }) {
  return <ProfileContext.Provider value={useHook(profileID)} children={children} />
}
