import React, { useState, useEffect } from 'react'
import { Column, Row } from '../UI'
import { Color } from 'three'
import { Tooltip, Input } from 'antd'
import { EditFilled } from '@ant-design/icons'

export function ColorBlock(opts) {
  return <Column {...opts} className={`${opts.className || ""}  rounded pointer color`} style={{ padding: "1em 0.5em", background: opts.color }} onClick={e => opts.setColor(opts.color)} />
}

export function ColorPalete({ onChange, defaultColor, defaults }) {
  const colors = defaults || ["white", "red", "orange", "yellow", "green", "cyan", "blue", "purple", "rgb(55,55,55)"]

  const [show, setShow] = useState(0)
  const [color, setColor] = useState(defaultColor || colors)
  const [{ h, s, l }, setHSL] = useState({})

  const workingColor = new Color()

  useEffect(() => {
    const HSL = {}
    workingColor.set(color)
    workingColor.getHSL(HSL)
    setHSL(HSL)
    if (onChange)
      onChange(workingColor)
  }, [color])

  return <Column center click>
    <Column>
      <Row center>
        <Tooltip placement="left" visible={show} title={<Input className="background" placeholder="Hex or RGB" onPressEnter={e => setColor(e.target.value)} />}>
          <Column className="rounded pad pointer color" style={{ background: `hsl(${Math.floor(h * 360)}, ${s * 100}%, ${l * 100}%)`, color: `hsl(${Math.floor(h * 360)}, ${s * 100}%, ${l * 100}%)`, transition: "background 0.5s" }} onClick={e => setShow((show + 1) % 2)}>
            <EditFilled style={{filter: "invert(100%)"}} />
          </Column>
        </Tooltip>

        <ColorBlock className="pad rounded pointer color" color={`hsl(${Math.floor(h * 360)}, ${s * 100}%, ${Math.floor(100 * (1 / 6))}%)`} setColor={setColor} />
        <ColorBlock className="pad rounded pointer color" color={`hsl(${Math.floor(h * 360)}, ${s * 100}%, ${Math.floor(100 * (2 / 6))}%)`} setColor={setColor} />
        <ColorBlock className="pad rounded pointer color" color={`hsl(${Math.floor(h * 360)}, ${s * 100}%, ${Math.floor(100 * (3 / 6))}%)`} setColor={setColor} />
        <ColorBlock className="pad rounded pointer color" color={`hsl(${Math.floor(h * 360)}, ${s * 100}%, ${Math.floor(100 * (4 / 6))}%)`} setColor={setColor} />
        <ColorBlock className="pad rounded pointer color" color={`hsl(${Math.floor(h * 360)}, ${s * 100}%, ${Math.floor(100 * (5 / 6))}%)`} setColor={setColor} />

        <Row className="gaps-h" />
        <Row grow>
          {colors.map(color => <ColorBlock click className="pad rounded pointer color" color={color} setColor={setColor} />)}
        </Row>

        {/* <Button small primary className="background"><AppstoreFilled /></Button> */}

      </Row>
      {/* <Row between>
        <Input size="small" style={{ width: "50px" }} className="background" type="number" max={360} value={hue} />
        <Input size="small" style={{ width: "50px" }} className="background" type="number" max={100} value={lum} />
        <Input size="small" style={{ width: "50px" }} className="background" type="number" max={100} value={hue} />
      </Row> */}

    </Column>
  </Column>
}
