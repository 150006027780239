import React from 'react'
import { LogoutOutlined } from '@ant-design/icons'

import { Button, Row } from '../UI'
import LoginApp from './apps/LoginApp'
import Account from './state/Account'
import useAccount from './hooks/useAccount'

export default opts => {
  const { uid } = useAccount()
  // if (uid === "local-user")
  //   return <div/>

  if (window.isLocal)
    return <div/>

  return (uid && uid !== "local-user") ? <Row center>
    <Button selected primary title="Sign Out" onClick={e => Account.signOut()} mouseEnterDelay={0.5}>
      <LogoutOutlined />
    </Button>
  </Row> : <LoginApp />
}