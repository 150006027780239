import React, { useState } from 'react'
import { Column, Button } from '../../UI'
import { Progress } from 'antd'
import EventEmitter from 'events'

import Account from './Account'
import { database, storage } from '../Firebase'
import { v4 } from "uuid"

const LoadProgress = ({ label, task, onCancel }) => {
  const [progress, setProgress] = useState(0)
  task.on("state_changed", snapshot => setProgress(snapshot.bytesTransferred / snapshot.totalBytes))

  return <Column fitX>
    {label}
    <Progress status="active" style={{ height: "6px", width: "100%", borderRadius: "2px", margin: "4px" }} percent={progress * 100} />
  </Column>
}

const freeTier = 1024 * 1024 * 100 // 100MB
const memberTier = 4 * 1024 * 1024 * 1024 // 3GB 

class FileManager extends EventEmitter {
  files = []
  loaded = false
  _currentSize

  rebuildFiles() {
    const fileRef = storage.ref(Account.uid)
    this.files = []
    this.loaded = false
    const files = fileRef.listAll().then(list => {
      this.loaded = true
      this.emit("ready")

      const getURL = file => {
        const name = file.fullPath
        file.getDownloadURL().then(src => {
          this.files.push({ src, title: name.split("/")[1], name })
          this.files = this.files.sort((a, b) => {
            var textA = a.title.toUpperCase()
            var textB = b.title.toUpperCase()
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
          })
          this.emit("change")
        }).catch(err => {
          console.warn(err)
          window.error(`Failed to get download link ${name}`)
        })
      }

      for (const file of list.items)
        getURL(file)

      if (list.items.length === 0)
        this.emit("change")

    }).catch(err => console.warn(err))
    return files
  }

  rebuildFilesLocal() {
    this.localFiles = []

    const process = node => {
      if (node instanceof Object) {
        for (const child of node.c) {
          process(child)
        }
      }
      else {
        this.localFiles.push({ src: `/${node}` })
        this.emit("change")
      }
    }

    var xhttp = new XMLHttpRequest()
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200)
        // Typical action to be performed when the document is ready:
        process(JSON.parse(this.responseText))
    }
    xhttp.open("GET", "http://localhost:30000/files", true)
    xhttp.send()
  }


  delete(fullPath, complete = () => this.rebuildFiles()) {
    if (Account.uid) {
      const name = fullPath.split("/")[1]
      const fileRef = storage.ref(Account.uid)
      const file = fileRef.child(name)
      file.getMetadata().then(metadata => {
        file.delete().then(snap => {
          window.warning(`Deleted ${name}`)
          // record the changes
          const stored = database.ref(`users/${Account.uid}/stored`)
          stored.once("value").then(snap => {
            const val = snap.val()
            stored.set(Math.max((val ?? 0) - metadata.size, 0))
          })
          complete()
        }).catch(e => window.error(`Couldn't Delete ${name}`))
      }).catch(e => window.error(`${name} doesn't exist`))
    }
    else
      window.notify("Sign in to delete files")
  }

  uploadFile(blob, complete = () => this.rebuildFiles()) {
    if (blob.type && !blob.type.match("image"))
      return window.notify("Only image files are supported right now")

    const upload = (upload, url) => {
      const fileRef = storage.ref(Account.uid).child(upload.name)
      fileRef.getMetadata().then(metadata => window.notify(`${upload.name} already exists`)).catch(e => {
        // file doesn't exist
        const task = fileRef.put(upload)
        const key = v4()
        window.notify({ title: `Uploading ${upload.name}`, key, action: <Button color="secondary" onClick={e => task.cancel()}>Cancel</Button> })
        task.then(snapshot => {
          const stored = database.ref(`users/${Account.uid}/stored`)
          stored.once("value").then(snap => {
            const val = snap.val()
            stored.set(Math.max((val ?? 0) + snapshot.totalBytes, 0))
            this._currentSize = Math.max((val ?? 0) + snapshot.totalBytes, 0)
          })
          window.dismiss(key)
          window.success(`Uploaded ${upload.name}`)
          if (url)
            URL.revokeObjectURL(url)

          fileRef.getDownloadURL().then(d => complete(snapshot, d))
        }).catch(e => {
          console.warn(e)
          window.dismiss(key)
          window.error("Uploading " + upload.name + " failed")
          if (url)
            URL.revokeObjectURL(url)
        })
      })
    }
    upload(blob)
  }

  upload(blob, complete = () => this.rebuildFiles()) {
    if (!Account.uid)
      return window.notify("Must be signed in to upload files")

    if (!Account.emailVerified)
      return window.notify("Must have a verified email to upload files", { action: <Button secondary variant="text" onClick={e => Account.verifyEmail()}>Verify</Button> })

    const limit = Account.isMember() ? memberTier : freeTier

    if (this._currentSize == null) {
      const stored = database.ref(`users/${Account.uid}/stored`)
      stored.once("value").then(snap => {
        const val = snap.val()
        this._currentSize = val || 0
        this.upload(blob, complete)
      }).catch(e => {
        console.warn("Something went wrong")
        window.warning("Something went wrong")
      })
    }
    else {
      window.notify("Preparing to upload")
      if (blob.type.match("image")) {
        if (blob.type.match("image/webp")) {
          if (blob.size + this._currentSize > limit)
            return window.error(`Not enough storage space (${Math.max(limit - (blob.size + this._currentSize), 0) / 1024 / 1024}MB remains)`)
          this.uploadFile(blob, complete)
        }
        else {
          window.webp.encodeBlob(blob).then(webp => {
            // console.log(this._currentSize, webp.blob.size)
            if (blob.size + this._currentSize > limit)
              return window.error(`Not enough storage space (${Math.max(limit - (blob.size + this._currentSize), 0) / 1024 / 1024}MB remains)`)
            webp.blob.name = blob.name.split(".")[0] + ".webp"
            this.uploadFile(webp.blob, complete)
          }).catch(e => {
            if (blob.size + this._currentSize > limit)
              return window.error(`Not enough storage space (${Math.max(limit - (blob.size + this._currentSize), 0) / 1024 / 1024}MB remains)`)
            this.uploadFile(blob, complete)
          })
        }
      }
      else {
        window.error("File isn't an Image")
      }
    }
  }
}

const manager = new FileManager()
export default manager


// import React from 'react'
// import { Divider } from '@material-ui/core'
// import { Search } from '@material-ui/icons'
// import SearchBar from 'material-ui-search-bar'
// import { Row, Div, Column, Scroll, Button, IconButton } from '../UI'

// export default ({ style }) => {
//   return <Row grow className="background" style={style}>
//     <Column grow className="border-right" style={{ maxWidth: "20vw" }}>
//       <Row>
//         <Button variant="text" secondary>
//           Search Options
//         </Button>
//       </Row>
//       <Scroll>

//       </Scroll>
//       <Divider />
//       <SearchBar
//         placeholder="Search for Systems, Topics, Types..."
//         variant="filled"
//         InputLabelProps={{ className: "white-title" }}
//         inputProps={{ className: "white-title" }}
//         value={""}
//         fullWidth
//       />
//     </Column>
//     <Column grow style={{ width: "850px" }}>
//       {/* <Row>
//         <Button variant="text" secondary>Results</Button>
//       </Row> */}
//       <Column grow>
//         <Column grow center>
//           Nothing Found
//         </Column>
//       </Column>
//     </Column>
//   </Row>
// }