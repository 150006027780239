import { EventDispatcher } from "three"

var emitter = new EventDispatcher()

// Inputs map mobile/desktop/gamepad inputs to events
// These events go to Controls to be applied as needed

class Input {
  static clickDistance = 1.0 // tight distance because crap with tokens
  static holdDelay = 250
  static dblClickDelay = 200

  static mouseDown = false
  static mouseHeld = false
  static lastHeld = false
  static mouseType = false
  static moveX = 0
  static moveY = 0
  static deltaMX = 0
  static deltaMY = 0
  static lastMX = 0
  static lastMY = 0

  static pointerX = 0 // accumlated pointer position
  static pointerY = 0
  static pointerSensitivity = 0.008

  static mouseX = 0
  static mouseY = 0
  static lastX = 0
  static lastY = 0
  static deltaX = 0
  static deltaY = 0

  static locked = false
  static down = {}
  static mountEl = null

  static event() {
    const {
      mouseDown,
      mouseType,
      moveX,
      moveY,
      deltaMX,
      deltaMY,
      lastMX,
      lastMY,
      mouseX,
      mouseY,
      lastX,
      lastY,
      deltaX,
      deltaY,
      locked,
      wheelDelta,
      down,
      pointerSensitivity,
      pointerX,
      pointerY,
      mountEl,
    } = Input
    return {
      mouseDown,
      mouseType,
      moveX,
      moveY,
      deltaMX,
      deltaMY,
      lastMX,
      lastMY,
      mouseX,
      mouseY,
      lastX,
      lastY,
      deltaX,
      deltaY,
      locked,
      wheelDelta,
      down,
      mountEl,
      changeX: lastX - mouseX,
      changeY: lastY - mouseY,
      pointerSensitivity,
      pointerX,
      pointerY,
    }
  }

  static mount(canvas) {
    canvas.addEventListener('mousedown', Input.onMouseDown, false)
    canvas.addEventListener('contextmenu', Input.onContextMenu, false)
    canvas.addEventListener('wheel', Input.onMouseWheel, { passive: true })
    canvas.addEventListener('touchstart', Input.onTouchStart, { passive: true })
    canvas.addEventListener('touchend', Input.onTouchEnd, false)
    canvas.addEventListener('touchmove', Input.onTouchMove, { passive: true })
    canvas.addEventListener('dblclick', Input.onDblClick, false)
    canvas.addEventListener('click', Input.onClick, false)

    canvas.addEventListener('pointerdown', Input.onPointerDown, false)
    canvas.addEventListener('pointerup', Input.onPointerUp, false)


    document.addEventListener('mouseup', Input.onMouseUp)
    document.addEventListener('mousemove', Input.onMouseMove, false)
    document.addEventListener('pointerlockchange', Input.onPointerlockChange, false)
    document.addEventListener('pointerlockerror', Input.onPointerlockError, false)

    window.addEventListener('keydown', Input.onKeyDown, false)
    window.addEventListener('keyup', Input.onKeyUp, false)
    canvas.classList.add("cursor1")
    console.log("mounted")
    Input.mountEl = canvas
  }

  static unmount(canvas) {
    canvas.removeEventListener('contextmenu', Input.onContextMenu, false)
    canvas.removeEventListener('mousedown', Input.onMouseDown, false)
    canvas.removeEventListener('wheel', Input.onMouseWheel, { passive: true })
    canvas.removeEventListener('touchstart', Input.onTouchStart, { passive: true })
    canvas.removeEventListener('touchend', Input.onTouchEnd, false)
    canvas.removeEventListener('touchmove', Input.onTouchMove, { passive: true })
    canvas.removeEventListener('dblclick', Input.onDblClick, false)
    canvas.removeEventListener('click', Input.onClick, false)

    canvas.removeEventListener('pointerdown', Input.onPointerDown, false)
    canvas.removeEventListener('pointerup', Input.onPointerUp, false)

    document.removeEventListener('mouseup', Input.onMouseUp, false)
    document.removeEventListener('mousemove', Input.onMouseMove, false)
    document.removeEventListener('pointerlockchange', Input.onPointerlockChange, false)
    document.removeEventListener('pointerlockerror', Input.onPointerlockError, false)

    window.removeEventListener('keydown', Input.onKeyDown, false)
    window.removeEventListener('keyup', Input.onKeyUp, false)
    console.log("unmounted")
    if (Input.mountEl === canvas) {
      Input.mountEl = null
    }
  }

  static touchDist(e) {
    if (e.touches.length === 2) {
      return Math.hypot(
        e.touches[0].clientX - e.touches[1].clientX,
        e.touches[0].clientY - e.touches[1].clientY)
    }
    return 0
  }

  static moveMouse(x, y, mX, mY) {
    Input.deltaX = -(Input.mouseX - x)
    Input.mouseX = x

    Input.deltaY = Input.mouseY - y
    Input.mouseY = y

    Input.moveX = mX
    Input.deltaMX = -(Input.lastMX - Input.moveX)
    Input.lastMX = mX
    Input.moveY = mY
    Input.deltaMY = -(Input.lastMY - Input.moveY)
    Input.lastMY = mY

    if (Input.locked) {
      Input.pointerX += mX * -Input.pointerSensitivity
      Input.pointerY += mY * -Input.pointerSensitivity
    }
  }

  static onMouseDown(e) {
    const start = Date.now()
    Input.mouseType = e.which
    Input.mouseDown = true
    Input.doubleClicked = false
    Input.mouseHeld = start
    Input.lastHeld = start
    Input.lastX = e.clientX
    Input.lastY = e.clientY

    Input.lastMX = e.movementX || e.mozMovementX || e.webkitMovementX || 0
    Input.lastMY = e.movementY || e.mozMovementY || e.webkitMovementY || 0

    Input.moveMouse(e.clientX, e.clientY, Input.lastMX, Input.lastMY)
    const { mouseX, mouseY } = Input.event()
    // console.log(e)
    setTimeout(() => {
      if (Input.mouseHeld === start && Math.abs(mouseX - Input.mouseX) < Input.clickDistance && Math.abs(mouseY - Input.mouseY) < Input.clickDistance) {
        emitter.dispatchEvent({ type: 'mouse_press', original: e })
        // console.log("press", e)
      }
    }, Input.holdDelay)
    emitter.dispatchEvent({ type: 'mouse_down' })
  }

  static onContextMenu(e) {
    Input.mouseHeld = false
    Input.onClick(e)
  }

  static onClick(e) {
    if ((!Input.mouseHeld || Date.now() - Input.mouseHeld < Input.holdDelay) || (Math.abs(Input.moveX) < Input.clickDistance && Math.abs(Input.moveY) < Input.clickDistance)) {
      const evt = Input.event()
      setTimeout(e => {
        if (!Input.doubleClicked && (Math.abs(evt.lastX - Input.mouseX) < Input.clickDistance && Math.abs(evt.lastY - Input.mouseY) < Input.clickDistance))
          emitter.dispatchEvent({ type: 'mouse_single_click', original: evt })
      }, Input.dblClickDelay)
      emitter.dispatchEvent({ type: 'mouse_click' })
    }
    Input.mouseType = e.which
    Input.mouseDown = false
    Input.mouseHeld = false
  }

  static onDblClick(e) {
    Input.mouseType = e.which
    Input.mouseDown = false
    Input.mouseHeld = false
    Input.doubleClicked = true
    emitter.dispatchEvent({ type: 'mouse_double_click', lastHeld: Input.lastHeld })
  }

  static onMouseUp(e) {
    Input.mouseType = e.which
    Input.mouseDown = false
    emitter.dispatchEvent({ type: 'mouse_up' })
  }

  static onMouseMove(e) {
    Input.shiftKey = e.shiftKey
    Input.ctrlKey = e.ctrlKey
    Input.moveMouse(e.clientX, e.clientY, e.movementX || e.mozMovementX || e.webkitMovementX || 0, e.movementY || e.mozMovementY || e.webkitMovementY || 0)
    emitter.dispatchEvent({ type: 'mouse_move' })
  }

  static onMouseWheel(e) {
    // console.log(e)
    Input.wheelDelta = e.deltaY
    emitter.dispatchEvent({ type: 'mouse_wheel', e })
  }

  static onTouchStart(e) {
    const now = Date.now()
    Input.mouseDown = now
    if (e.touches.length === 1) {
      Input.lastX = e.touches[0].clientX
      Input.lastY = e.touches[0].clientY
      const { mouseX, mouseY } = Input
      setTimeout(() => {
        if (Input.mouseDown === now && Math.abs(mouseX - Input.mouseX) < Input.clickDistance && Math.abs(mouseY - Input.mouseY) < Input.clickDistance)
          emitter.dispatchEvent({ type: 'mouse_press' })
      }, Input.holdDelay)
      Input.moveMouse(Input.lastX, Input.lastY)
      emitter.dispatchEvent({ type: 'mouse_down' })
    }
    else if (e.touches.length === 2) {
      Input.scaling = true
      Input.scale = Input.touchDist(e)
    }
  }

  static onTouchEnd(e) {
    if (Input.mouseDown && Math.abs(Input.moveX) < Input.clickDistance && Math.abs(Input.moveY) < Input.clickDistance)
      emitter.dispatchEvent({ type: 'mouse_click' })

    Input.mouseType = e.which
    Input.mouseDown = false
    emitter.dispatchEvent({ type: 'mouse_up' })
  }

  static onTouchMove(e) {
    Input.scaling = e.touches.length === 2
    if (Input.scaling) {
      let scale = Input.touchDist(e)
      Input.deltaScale = scale - Input.scale
      Input.scale = scale
      emitter.dispatchEvent({ type: 'scale', e })
    } else if (e.touches.length > 0) {
      Input.moveMouse(e.touches[0].clientX, e.touches[0].clientY, Input.lastX - e.touches[0].clientX, Input.lastY - e.touches[0].clientY)
      emitter.dispatchEvent({ type: 'mouse_move' })
    }
  }

  static onPointerDown(e) {
    emitter.dispatchEvent({ type: 'pointer_down' })
  }

  static onPointerUp(e) {
    emitter.dispatchEvent({ type: 'pointer_up' })
  }

  static onPointerlockEnter(e) {
    Input.locked = true
    emitter.dispatchEvent({ type: 'pointer_enter' })
  }

  static onPointerlockLeave(e) {
    Input.locked = false
    emitter.dispatchEvent({ type: 'pointer_leave' })
  }

  static onPointerlockChange(e) {
    Input.locked = !Input.locked
    if (Input.locked) {
      Input.onPointerlockEnter(e)
    }
    else {
      Input.onPointerlockLeave(e)
    }
    emitter.dispatchEvent({ type: 'pointer_change' })
  }

  static onPointerlockError(e) {
    Input.locked = false
    Input.onPointerlockLeave(e)
    emitter.dispatchEvent({ type: 'pointer_error' })
  }

  static onKeyDown(e) {
    Input.down[e.key.toLowerCase()] = true
    emitter.dispatchEvent({ type: 'key_down', e })
  }

  static onKeyUp(e) {
    const key = e.key.toLowerCase()
    Input.down[key] = false
    emitter.dispatchEvent({ type: 'key_up', e })
  }

  static on(event, cb) {
    return emitter.addEventListener(event, cb)
  }
  
  static off(event, cb) {
    return emitter.removeEventListener(event, cb)
  }
}
export default Input