import React from 'react'
import { Transforms } from 'slate'
import { useSlate } from 'slate-react'
import { SplitCellsOutlined } from '@ant-design/icons'

import { Button, Row } from '../../../UI'
import { Dropdown, Menu } from "antd"

export default ({ menus }) => {
  const editor = useSlate()

  const { layout, column } = menus
  const deleteLayout = e => window.ask({ type: "confirm", title: "Delete this layout?" }, res => Transforms.delete(editor, { at: layout[1] }))

  const setColumns = (e, count) => {
    // const [cl, p] = column
    const [ly, path] = layout
    const len = ly.children.length

    if (ly.children.length > count)
      Transforms.delete(editor, { at: [...path, len - 1] })
    else if (ly.children.length < count)
      Transforms.insertNodes(editor, {
        type: "column", children: [
          { type: "paragraph", children: [{ text: "" }] }
        ]
      }, { at: [...path, len] })

    Transforms.setNodes(editor, { width: Math.round(1 / count * 100) + "%" }, { at: path, match: e => e.type === "column" })
    e.preventDefault()
  }

  const setSidebar = (e, right) => {
    const count = 3
    // console.log(column, layout)
    // const [cl, p] = column
    const [ly, path] = layout
    const len = ly.children.length

    if (ly.children.length > count)
      Transforms.delete(editor, { at: [...path, len - 1] })

    if (right) {
      Transforms.setNodes(editor, { width: "25%" }, { at: [...path, 0], match: e => e.type === "column" })
      Transforms.setNodes(editor, { width: "75%" }, { at: [...path, 1], match: e => e.type === "column" })
    }
    else {
      Transforms.setNodes(editor, { width: "75%" }, { at: [...path, 0], match: e => e.type === "column" })
      Transforms.setNodes(editor, { width: "25%" }, { at: [...path, 1], match: e => e.type === "column" })
    }

    e.preventDefault()
  }

  const setMiddle = e => {
    // const [cl, p] = column
    const [ly, path] = layout
    const len = ly.children.length
    const count = 3

    if (ly.children.length < count)
      Transforms.insertNodes(editor, {
        type: "column", children: [
          { type: "paragraph", children: [{ text: "" }] }
        ]
      }, { at: [...path, len] })

    Transforms.setNodes(editor, { width: "25%" }, { at: [...path, 0], match: e => e.type === "column" })
    Transforms.setNodes(editor, { width: "50%" }, { at: [...path, 1], match: e => e.type === "column" })
    Transforms.setNodes(editor, { width: "25%" }, { at: [...path, 2], match: e => e.type === "column" })
    e.preventDefault()
  }

  return layout ? <Row className={`${layout ? "visible click" : "invisible no-click"}`} style={{boxShadow: "0px 0px 4px rgba(0,0,0,0.7)"}}>
    {/* <Button color="inherit" onMouseDown={e => { setEditing(isEditing ? null : path); e.preventDefault() }} small>
      {isEditing ? <Done fontSize="small" /> : <Edit fontSize="small" />}
    </Button> */}
    <Row center className="black rounded pad">
      <SplitCellsOutlined />
    </Row>
    <Dropdown placement="topLeft" overlay={<Menu>
      <Menu.Item onMouseDown={e => setColumns(e, 2)}>
        Sidebar (2 Columns)
      </Menu.Item>
      <Menu.Item onMouseDown={e => setColumns(e, 3)}>
        Sidebar (3 Columns)
      </Menu.Item>
      <Menu.Item onMouseDown={e => setMiddle(e)}>
        Sidebar (Left and Right)
      </Menu.Item>
      <Menu.Item onMouseDown={e => setSidebar(e, true)}>
        Sidebar (Left)
      </Menu.Item>
      <Menu.Item onMouseDown={e => setSidebar(e)}>
        Sidebar (Right)
      </Menu.Item>
      <Menu.Item onMouseDown={deleteLayout}>
        Delete Layout
      </Menu.Item>
    </Menu>}>
      <Button small secondary className="foreground" childClass="grow" style={{boxShadow: "inset 0px 0px 2px black"}} color="inherit">Layout</Button>
    </Dropdown>
  </Row> : null
}