import Resource from './Resource'
import Account from '../../core/state/Account'
import { v4 } from 'uuid'

export default class Tracker extends Resource {
  constructor(ctx = {}) {
    super(ctx)

    this._id = ctx._id
    this.uid = ctx.uid || v4()
    this.meta = { name: ctx.name, creator: ctx.creator || Account.uid, created: Date.now() }
    this.data = { categories: {} }
    this.setMaxListeners(Infinity) // Potentially thousands of references due to wiki
  }

  get temp() {
    return String(this.uid).match("-temp-")
  }

  get local() {
    return String(this.uid).match("-local-")
  }

  sortCategories() {
    return this.data.categories
  }

  addToCategory(name, profileID) {
    if (!this.data.categories[name])
      this.data.categories[name] = { list: [], states: {} }

    const { list } = this.data.categories[name]
    if (!list.includes(profileID))
      list.push(profileID)

    this.emit("change")
  }

  removeFromCategory(name, profileID) {
    const { list } = this.data.categories[name]
    if (list.includes(profileID))
      list.splice(list.indexOf(profileID), 1)

    this.emit("change")
  }

  toggleState(name, profileID) {
    const { states } = this.data.categories[name]
    states[profileID] = !states[profileID]

    this.emit("change")
  }

  removeCategory(category) {
    delete this.data.categories[category]
    this.emit("change")
  }

  addCategory(name, data = { list: [], states: {} }) {
    this.data.categories[name] = data
    this.emit("change")
  }

  getCategory(name) {
    return this.data.categories[name]
  }

  getCategories() {
    return this.data.categories
  }

  getData() {
    return this.data
  }

  setData(data) {
    this.data = data
    this.emit("change")
    this.emit("rebuild")
  }

  getOrder() {
    let keys = Object.keys(this.data.categories)
    if (this.meta.sort)
      if (this.meta.sort === "number") {
        let res = keys.sort((a, b) => {
          const num = /[\d.]+/ig
          const intA = String(a).match(num)
          const intB = String(b).match(num)
          return parseInt(intB ? intB[0] : a) - parseInt(intA ? intA[0] : a)
        })
        if (!this.meta.ascending)
          return res
        else
          return res.reverse()
      }
      else if (this.meta.sort === "alpha") {
        let res = keys.sort()
        if (!this.meta.ascending)
          return res
        else
          return res.reverse()
      }

    return keys
  }

  pack() {
    return {
      _id: this._id,
      uid: this.uid,
      data: this.data || [],
      meta: this.meta || {}
    }
  }

  deserialize(json) {
    this.load(JSON.parse(json))
  }

  load(json) {
    this._id = json._id
    this.uid = json.uid || this.uid
    this.data = json.data || this.data
    this.meta = json.meta || this.meta
    this.loaded = true
    this.emit("ready")
    this.emit("rebuild")
  }

  serialize() {
    return JSON.stringify(this.pack())
  }
}