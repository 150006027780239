import React, { useEffect, useState } from 'react'
import { Row, Column, Button, InputMask, GhostInput, Scroll } from '../UI'
import { 
  CompassFilled,
  CopyFilled,
  PlusOutlined, 
  EditFilled, 
  DeleteFilled, 
  LockFilled, 
  EyeInvisibleFilled, 
  FileTextFilled, 
  PictureFilled, 
  HomeFilled, 
  LinkOutlined, 
  LoadingOutlined,
  WarningFilled,
  FormOutlined,
  SettingFilled
} from '@ant-design/icons'

import { Tag, Tooltip } from 'antd'
import useAccount from '../core/hooks/useAccount'
import useProject, { hasPermission, createMap, createArticle, getProject, ProjectProvider } from './hooks/useProject'
import useProfile, { getProfile } from "./hooks/useProfile"
import useArticle, { ArticleProvider, getArticle } from "./hooks/useArticle"
import Slate from './slate/Slate'
import IconPicker, { Icon } from './Icon'
import ProfileNav from "./slate/ProfileNav"
import Nav from "./state/Navigation"
import Div from "../components/Div"
import { Slider } from 'antd'
import Resources from "./state/Resources"
import useNav from "./hooks/useNav"
import Permissions from "./Permissions"
import Network from "../core/Network"
import rfdc from 'rfdc'
import Mention from "./slate/inline/Mention"
import { uploadMap } from "./Map"

const copy = rfdc()

export function ProfileName(opts) {
  const { uid } = useAccount()
  const { projectID } = useProject()
  const { permission, name, alias, icon, loaded } = useProfile()
  if (!loaded)
    return <span {...opts}><LoadingOutlined/></span>

  const textName = hasPermission(permission, "SEE", "profiles", uid, projectID) ? name : alias
  let text = textName
  if (opts.initials)
    text = text.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()
  
  return <span style={{ whiteSpace: "nowrap" }} title={textName} {...opts}>
    {opts.showIcon ? <Icon className="gaps-h" i={(icon || "folder").replace("fa fa-", "")} /> : false}
    {(opts.maxCharacters && String(text).length >= opts.maxCharacters) ? (String(text).substring(0, opts.maxCharacters) + "...") : text}
  </span>
}
const cols = ["pink", "red", "orange", "olive", "green", "#00e4ec", "#3388ff", "#8832ff", "#964B00", "grey", "#222222"]
export function ProfileChip(opts) {
  const { uid } = useAccount()
  const { projectID } = useProject()
  const { banner, permission, profileID } = useProfile()
  
  const hasBanner = hasPermission(permission, "SEE", "profiles", uid, projectID) && (banner && banner.src)
  function hash(s){
    return s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);
  }
  const col = hash(profileID) % cols.length
  return <Tooltip title={<ProfileName />}>
    <Row center width="64px" height="64px" grow {...opts} style={{...opts.style, backgroundColor: hasBanner ? undefined : cols[col], backgroundImage: hasBanner ? `url('${banner.src}')` : undefined, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
      {!hasBanner && <ProfileName className="size-large" initials={true} />}
    </Row>
  </Tooltip>

}


export function ProfileIcon(opts) {
  const { uid } = useAccount()
  const { projectID } = useProject()
  const { permission, name, alias, icon, loaded } = useProfile()
  if (!loaded)
    return <span {...opts}><LoadingOutlined/></span>

  const text = hasPermission(permission, "SEE", "profiles", uid, projectID) ? name : alias

  return <Tooltip {...opts} title={text}>
    <Icon i={(icon || "folder").replace("fa fa-", "")} />
  </Tooltip>
}

export function ProfileArticle({readOnly, compact}) {
  const { articleID, loaded, body } = useArticle()
  const onChange = e => {
    if (articleID)
      getArticle(articleID).setBody(e)
  }
  return loaded ? <Slate raw={body} onChange={onChange} readOnly={readOnly} compact={compact} /> : <div><LoadingOutlined /></div>
}

export function ProfileContents({currentTab, readOnly: rOnly}) {
  const { settings } = useNav()
  const { articleID: defaultArticle, templated: defaultTemplated, profileID, tabs, loaded, permission } = useProfile()
  if (!loaded)
    return <LoadingOutlined />

  const canWrite = hasPermission(permission, "WRITE")
  const articleID = tabs[currentTab] ? tabs[currentTab].articleID : defaultArticle
  const readOnly = tabs[currentTab] ? tabs[currentTab].templated : defaultTemplated

  if (!articleID) {
    const create = e => {
      const article = createArticle()
      if (article) {
        // article.uid = profileID
        article.setBody([])
        const profile = getProfile(profileID)
        if (tabs[currentTab])
          profile.updateTab(currentTab, {articleID: article.uid})
        else
          profile.setArticleID(article.uid)
      }
    }

    const pick = e=>window.pick(pID=>{
      const profile = getProfile(profileID)
      if (tabs[currentTab])
        profile.updateTab(currentTab, {articleID: getProfile(pID).getArticleID(), templated: true})
      else {
        profile.setArticleID(getProfile(pID).getArticleID())
        profile.setTemplated(true)
      }
    })

    const interact = <Column>
      <Button className="foreground gaps" primary onClick={create}>New Article</Button>
      {/* <Button className="foreground gaps" primary onClick={pick}>Copy Article</Button> */}
    </Column>

    return <Column fit center style={{ padding: "0 5%", minHeight: "50vh" }}>
      {canWrite ? interact : <Row className="text-dull" center>Nothing Written</Row>}
    </Column>
  }
  
  return <Column grow style={{ padding: "0 5%", lineHeight: "175%", fontSize: "1.1em"}}>
    {(permission === 0 || hasPermission(permission, "READ")) && !settings && <ArticleProvider articleID={articleID}>
      <ProfileArticle readOnly={rOnly || readOnly || !hasPermission(permission, "WRITE")} />
    </ArticleProvider>}
    {(permission === 0 || hasPermission(permission, "WRITE")) && settings && <ProfileDataView/>}
    {!settings && <div style={{ minHeight: "25vh" }} />}
  </Column>
}


export function ProfileData(opts) {
  const { projectID } = useProject()
  const { profileID, loaded, temp, alias, articleID: defaultArticle, templated: defaultTemplated, tabs, permission } = useProfile()

  return <Column grow>
    {hasPermission(permission, "CHANGE") && <Scroll className="card-bg s">
      <ProfileDataView />
    </Scroll>}
  </Column>
}

export function ProfileTags(){
  const { editing, settings } = useNav()
  const { profileID, loaded, tags, permission } = useProfile()

  if (!loaded)
    return <LoadingOutlined/>

  const addTag = e => {
    if (e && !tags.includes(String(e).toLowerCase()))
      getProfile(profileID).addTag(e)
  }

  const removeTag = e => {
    if (e)
      getProfile(profileID).removeTag(e)
  }
  
  return <Column grow className="text-dull pad-v">
    <Column className="pad-v">
      <Row>
        <span>System Tags</span>
        {hasPermission(permission, "WRITE") && (editing || settings) && <Column center className="pad-h">
          <InputMask inputClass="foreground" small primary canEdit={() => (true)} placeholder="Add Tag" onConfirm={addTag}>
            <PlusOutlined />
          </InputMask>
        </Column>}
      </Row>
      <Row wrap>
        {tags.map((t, i) => <Column center key={i}>
          <Tag className="pointer" closable={(editing || settings)} onClose={e => removeTag(t)}>
            {t}
          </Tag>
        </Column>)}
      </Row>
    </Column>
  </Column>
}

export function PermWrap(opts){
  return <ProjectProvider projectID={opts.projectID}>
    <Permissions {...opts}/>
  </ProjectProvider>
}


export function ProfileDataView(opts) {
  const { profileID, formulas, data, loaded } = useProfile()

  if (!loaded)
    return <LoadingOutlined/>

  return <Column>
    <Column className="size-small">
      Formulas (Don't nest these)
    </Column>
    <Column>
      {Object.keys(formulas).sort().map(key=><Row key={key} between className="background">
        <Row className="black-tint pad-h size-small" style={{minWidth: "250px"}}>#{key} = {data[key]}</Row>
        <Row grow={2}>
          <Row grow className="data size-small" center title="Change Equation" onClick={e=>window.ask({title: "Set Formula", value: formulas[key]}, res=>getProfile(profileID).setFormula(key, res))}>
            {formulas[key]}
          </Row>
        </Row>
        <Button small className="foreground" title="Return To Stat" onClick={e=>getProfile(profileID).setFormula(key, null)}>
          <DeleteFilled/>
        </Button>
      </Row>)}
    </Column>
    <br/>
    <Column className="size-small">
      Stats
    </Column>
    <Column>
      {Object.keys(data).filter(v=>(formulas[v] == null)).sort().map(key=><Row key={key} between className="background">
        <Row className="black-tint pad-h size-small" style={{minWidth: "200px"}}>#{key}</Row>
        <Row grow className="data size-small" center title="Change Stat" onClick={e=>window.ask({title: "Set Stat", value: data[key]}, res=>getProfile(profileID).setData(key, res))}>
          {data[key]}
        </Row>
        <Button small className="foreground" title="Make Formula" onClick={e=>getProfile(profileID).setFormula(key, "")}>
          <SettingFilled />
        </Button>
        <Button small className="foreground" title="Delete Stat" onClick={e=>getProfile(profileID).setData(key, null)}>
          <DeleteFilled/>
        </Button>
      </Row>)}
      <Button className="foreground" onClick={e=>window.ask({ title: "Enter Stat Key" }, res => {
        const m = res.match(/[\w\d_]+/i)
        if (!m || m.index !== 0 || m[0].length !== res.length)
          return window.error("Invalid key, may only contain numbers, characters and _")
        if (m[0] === "_")
          return window.error("Key can't start with _, reserved for system")
        getProfile(profileID).setData(res.toLowerCase(), "")
      })} small>Add</Button>
    </Column>
  </Column>
}

export function ProfileMetaData(opts) {
  const { projectID } = useProject()
  const { profileID, loaded, alias, articleID: defaultArticle, banner, mapID, templated: defaultTemplated, tabs, permission, temp } = useProfile()

  const navState = useNav()
  const currentTab = navState.tabs[profileID]

  const templated = tabs[currentTab] ? tabs[currentTab].templated : defaultTemplated
  const articleID = tabs[currentTab] ? tabs[currentTab].articleID : defaultArticle
  
  if (!loaded)
    return <LoadingOutlined/>

  const addMap = e => {
    if (!window.isLocal || window.isDemo)
      return window.warning("Requires a Host License")
    const p = getProfile(profileID)
    const map = createMap()
    if (map) {

      p.setMapID(map.uid)
      delete Nav.compendium
      Nav.setFocus("map")
      Nav.setMap(p.getMapID())
      uploadMap(map.uid)
    }
  }

  const changeImage = e => window.ask({ title: "Select Image", type: "file" }, v => getProfile(profileID).setBanner({ src: v.src }))
  const changeImageSrc = e => window.ask({ title: "Select Image" }, v => getProfile(profileID).setBanner({ src: v }))
  const changeImageLocal = e => {
    // if (!window.isLocal)
      // return window.warning("The web version doesn't support custom files yet")
      
    window.ask({type: "file", profileID, projectID}, ({src})=>getProfile(profileID).setBanner({ src }))
  }

  const removeMap = e => {
    window.ask({ type: "confirm", title: "Remove map?", subtitle: "This will remove the map completely, and can not be reversed" }, res => {
      if (getProfile(profileID).getMapID() === Nav.map)
        Nav.setMap()
      getProfile(profileID).setMapID()
    })
  }

  const changeAlias = e => window.ask({ title: "Enter new Alias", value: alias, allowEmpty: true }, v => getProfile(profileID).setAlias(v))
  
  const addTab = e=> window.ask({title: "Enter Tab Name"}, name=>{
    const p = getProfile(profileID)
    p.setTab(name, {rights: {}})
    Nav.setTab(profileID, name)
  })

  const removeTab = e=> window.ask({ type: "confirm", title: "Remove tab?", subtitle: "This will remove the tab completely, and can not be reversed" }, res => {
    const p = getProfile(profileID)
    // TODO CLEANUP ARTICLE ID      
    p.deleteTab(currentTab)
    Nav.setTab(profileID)
  })
  
  const renameTab = e=>window.ask({title: "Enter Tab Name", value: currentTab}, name=>{
    const p = getProfile(profileID)
    p.renameTab(currentTab, name)
    Nav.setTab(profileID, name)
  })

  const copyCode = e=>{
    let temp = document.createElement("input")
    temp.type = "text"
    temp.value = `[=${profileID}=]`
    document.body.appendChild(temp)
    temp.focus()
    temp.select()
    document.execCommand("copy")
    document.body.removeChild(temp)
    window.info("Copied logging code!")
  }

  const detach = e => {
    if (!getProject(projectID).isOwner())
      return window.notify("Not permitted")

    const pArticle = getArticle(articleID)
    const parent = getProfile(profileID)
    window.ask({ type: "confirm", title: "Disconnect this article from template", subtitle: "This will disconnect the article from the original article, allowing you to edit it. This can't be reversed" }, res => {
      if (pArticle.loaded) {
        const article = parent.temp ? Resources.Articles.create("-temp-" + pArticle.uid) : createArticle()
        if (article) {
          const articleContents = pArticle.pack()
          articleContents.uid = article.uid

          delete articleContents._id
          delete articleContents.meta

          article.load(JSON.parse(JSON.stringify(articleContents)))
          article.emit("change")
          if (currentTab)
            parent.updateTab(currentTab, {articleID: article.uid, templated: false})
          else {
            parent.setArticleID(article.uid)
            parent.setTemplated(false)
          }
        }
      }
      else
        window.warning("Article isn't loaded, please try again later")
    })
  }

  const reformat = e => window.pick(templateID=>{
    const template = getProfile(templateID)
    const tabs = template.getTabsObject()
    setTimeout(()=>window.ask({title: "Replace current tab with...", value: template.getArticleID(), choices: [{value: template.getArticleID(), text: "Home Tab"}, ...Object.keys(tabs).map(v=>({ value: tabs[v].articleID, text: v }))]}, val=>{
      const templateArticle = getArticle(val)
      setTimeout(()=>window.ask({type: "confirm", title: "Are you sure?", desc: "The current layout will be lost (stats and other data will remain)"}, res=>{
          const curArticle = getArticle(articleID)
          if (!templateArticle.loaded)
            templateArticle.once("ready", e=>curArticle.setBody(copy(templateArticle.getBody())))
          else
            curArticle.setBody(copy(templateArticle.getBody()))
        })
      )
    }))
  })

  const permit = e=> window.ask({
    title: "Manage Permissions",
    app: PermWrap,
    props: {
      onPermit: args => {
        window.close()
      },
      type: "profiles",
      uid: profileID,
      projectID
    },
    style: { height: "50vh" }
  })


  const overrides = e=> window.ask({
    title: "Manage Overrides",
    app: PermWrap,
    props: {
      onPermit: args => {
        window.close()
      },
      type: "profiles",
      uid: profileID,
      projectID
    },
    style: { height: "50vh" }
  })

  return <Column grow className="pad-h">
    <Column grow/>
    <Column grow className={`${(navState.editing || navState.settings || !profileID) ? "visible" : "invisible no-click"}`}>
      {profileID && <Row className="gaps-v">
        <Button small onClick={copyCode}>
          Page Code
        </Button>
      </Row>}

      <Column className="gaps-v">
        {hasPermission(permission, "WRITE") && <Row>
          <Button small onClick={reformat}>
            <CopyFilled />&nbsp;
            Reformat
          </Button>
        </Row>}

        {/* {hasPermission(permission, "SHARE") && profileID && <Row>
          <Button small onClick={permit}>
            <LockFilled />&nbsp;
            Manage Permissions
          </Button>
        </Row>} */}
      </Column>

      <Column className="gaps-v">
        {!alias && hasPermission(permission, "CHANGE") && <Row>
          <Button small onClick={changeAlias}>
            <EyeInvisibleFilled />&nbsp;
            Add Alias
          </Button>
        </Row>}
        {hasPermission(permission, "CHANGE") && <Row>
          <Button small onClick={changeImageLocal}>
            <PictureFilled />&nbsp;
            {(banner && banner.src) ? " Set Banner" : " Add Banner"}
          </Button>
        </Row>}
        {!mapID && !temp && hasPermission(permission, "WRITE") && <Row>
          <Button small onClick={addMap}>
            <CompassFilled />&nbsp;
            Add Map
          </Button>
        </Row>}
        {mapID && hasPermission(permission, "WRITE") && <Row>
            <Button small onClick={removeMap}>
              <DeleteFilled/>&nbsp;Remove Map
            </Button>
          </Row>}

        {/* {hasPermission(permission, "WRITE") && <Row className="pad-v">
          <Button primary small onClick={overrides}>
            <WarningFilled />&nbsp;
            Manage Event Overrides
          </Button>
        </Row>} */}
      </Column>

      <Column className="gaps-v">
        {hasPermission(permission, "WRITE") && <Row>
          <Button small onClick={addTab}>
            <FileTextFilled />&nbsp;
            Add Tab
          </Button>
        </Row>}
        <Column className="pad-h">
          <i className="text-dull">{currentTab}</i>
          {currentTab && hasPermission(permission, "WRITE") && <Row>
            <Button small onClick={renameTab}>
              <EditFilled/>&nbsp;Rename Tab
            </Button>
          </Row>}
          {currentTab && hasPermission(permission, "WRITE") && <Row>
            <Button small onClick={removeTab}>
              <DeleteFilled/>&nbsp;Remove Tab
            </Button>
          </Row>}
        </Column>
        {templated && hasPermission(permission, "SHARE") && <Row className="pad-v">
          <Button small onClick={detach}>
            <LinkOutlined />&nbsp;
            Detach
          </Button>
        </Row>}
      </Column>
    </Column>
    <Column grow className="pad-h">
      <ProfileTags/>
    </Column>
    <Column grow/>
  </Column>
}

export function ContentCropper({ src, type, posX, posY, zoom, onModeChange, onZoomChange, onCropChange, onClick }) {
  const [dragging, setDragging] = useState(false)

  const [loading, setLoading] = useState(true)

  const [dX, setDX] = useState(0)
  const [dY, setDY] = useState(0)
  const [dZ, setDZ] = useState(0)

  useEffect(() => {
    const mouseUp = e => {
      if (onCropChange && dragging) {
        const z = (zoom || 1) + dZ
        onCropChange((e.clientX - dragging.sX) / z, (e.clientY - dragging.sY) / z)
        setDragging(false)
        setDX(0)
        setDY(0)
        onZoomChange(z)
        setDZ(0)
      }
      e.stopPropagation()
    }

    const mouseMove = e => {
      if (dragging) {
        const z = (zoom || 1) + dZ
        setDX((e.clientX - dragging.sX) / z)
        setDY((e.clientY - dragging.sY) / z)
      }
    }

    const mouseWheel = e => {
      const { zoom } = this.state
      if (e.deltaY > 0)
        onZoomChange((zoom || 1) - 0.1)
      else if (e.deltaY < 0)
        onZoomChange((zoom || 1) + 0.1)

      e.preventDefault()
      e.stopPropagation()
      return false
    }

    window.addEventListener("mousemove", mouseMove)
    window.addEventListener("mouseup", mouseUp)
    return function () {
      window.removeEventListener("mousemove", mouseMove)
      window.removeEventListener("mouseup", mouseUp)
    }
  }, [dragging])


  const changeZoom = newValue => {
    if (onZoomChange)
      onZoomChange(newValue || 1)
  }

  const active = onZoomChange && onCropChange

  let classes
  if (type === "contain")
    classes = "fit-x fit-y contain"
  else if (type === "cover")
    classes = "fit-x fit-y cover"

  const style = {
    pointerEvents: "none",
    transform: classes ? undefined : `translate(${(posX || 0) + dX}px, ${(posY || 0) + dY}px)`,
    transition: "translate 0.2s, scale 0.2s",
    userSelect: "none",
    display: loading ? "none" : undefined,
  }

  const mouseDown = e => {
    console.log("mousedown")
    if (onCropChange) {
      setDragging({ sX: e.clientX, sY: e.clientY })
      e.preventDefault()
    }
  }

  const onDoubleClick = e => {
    if (onClick)
      onClick(e)
    e.preventDefault()
    e.stopPropagation()
  }

  return <Column fit relative onMouseDown={mouseDown} onDoubleClick={onDoubleClick} style={{userSelect: "none"}}>
    {classes && <img muted autoPlay loop src={src} className={classes} style={style} onLoad={e=>setLoading()} onError={e=>setLoading()}/>}
    {!classes && <Column style={{ transform: `translate(-50%, -50%)`, top: "50%", left: "50%", cursor: active ? "move" : "pointer" }} absolute>
      <Column style={{ transform: `scale(${(zoom || 1) + dZ}) ` }}>
        {loading && <LoadingOutlined/>}
        <img muted autoPlay loop src={src} style={style} onLoad={e=>setLoading()} onError={e=>setLoading()} />
      </Column>
    </Column>}
    {active && <Column className="gaps background" left top absolute onMouseDown={e => e.stopPropagation()}>
      <Button onClick={e => onModeChange("contain")}>
        Contain
      </Button>
      <Button onClick={e => onModeChange("cover")}>
        Cover
      </Button>
      <Button onClick={e => onModeChange()}>
        Position
      </Button>
    </Column>}
    {active && !type && <Column className="gaps pad rounded" style={{background: "#eee", filter: "invert(100%)"}} right top absolute onMouseDown={e => e.stopPropagation()}>
      <Slider
        vertical
        step={0.01}
        defaultValue={zoom}
        min={0.25}
        max={3}
        style={{ height: "100px" }}
        value={(zoom || 1) + dZ}
        onChange={e => setDZ(e - (zoom || 1))}
        onAfterChange={e => {
          onZoomChange(e)
          setDZ(0)
        }}
      />
    </Column>}
    <Column absolute fit noClick style={{ boxShadow: "inset 0px 0px 10px black" }}/>
  </Column>
}

export function ProfileCover() {
  const { projectID, profiles } = useProject()
  const { profileID, loaded, name, alias, banner, icon, mapID, permission, templated: defaultTemplated, articleID: defaultArticle, tabs, temp } = useProfile()
  const navState = useNav()
  const currentTab = navState.tabs[profileID]
  const templated = tabs[currentTab] ? tabs[currentTab].templated : defaultTemplated
  const articleID = tabs[currentTab] ? tabs[currentTab].articleID : defaultArticle
  const [iconPicker, setIconPicker] = useState(false)
  const [editing, setEditing] = useState(false)
  
  const changeIcon = v => v ? getProfile(profileID).setIcon(v) : null
  const changeName = e => window.ask({ title: "Enter new Name", value: name }, v => v ? getProfile(profileID).setName(v) : null)
  const changeImage = e => window.ask({ title: "Select Image", type: "file" }, v => {
    getProfile(profileID).setBanner({ src: v.src })
    setEditing(true)
  })
  const changeImageSrc = e => window.ask({ title: "Select Image" }, v => {
    getProfile(profileID).setBanner({ src: v })
    setEditing(true)
  })

  const changeImageLocal = e => {
    const fileInput = document.createElement('input')
    fileInput.setAttribute("type", "file")
    fileInput.onchange = function (e) {
      if (this.files.length) {
        const { path } = this.files[0]
        fetch(`http://127.0.0.1:${window.location.port}/addFile`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ path, projectID, uid: profileID || projectID })
        })
        .then(response => response.json())
        .then(({src}) => {
          getProfile(profileID).setBanner({ src })
          setEditing(true)
        })
        .catch(e => console.warn(e))
      }
    }
    fileInput.click()
  }

  const visitMap = e => {
    if (!window.isLocal && !Network.host && mapID.includes("-local-"))
      window.warning("The web version of Mythic Archive can't visit local maps yet!")
    Nav.wiki = true
    Nav.compendium = false
    Nav.toggleMap(mapID)
    Nav.setScene()
  }

  const visitScene = e => {
    Nav.wiki = true
    Nav.compendium = false
    Nav.setMap()
    Nav.toggleScene(true)
  }

  let title = "Manage Permissions"
  let iconDiv = <LockFilled />
  // if (rights === null) {
  //   icon = <AccountTree fontSize="small" />
  //   title = "Default Permissions"
  // }
  // else if (rights <= 0) {
  //   icon = <NoEncryption fontSize="small" />
  //   title = "Handout only"
  // }
  // else if (rights <= 10) {
  //   icon = <Visibility fontSize="small" />
  //   title = "Identified"
  // }
  // else if (rights <= 20) {
  //   icon = <ChromeReaderMode fontSize="small" />
  //   title = "Read Only"
  // }
  // else if (rights <= 30) {
  //   icon = <ListAlt fontSize="small" />
  //   title = "Changeable"
  // }
  // else if (rights <= 40) {
  //   icon = <Edit fontSize="small" />
  //   title = "Writeable"
  // }

  const copyCode = e=>{
    let temp = document.createElement("input")
    temp.type = "text"
    temp.value = `[=${profileID}=]`
    document.body.appendChild(temp)
    temp.focus()
    temp.select()
    document.execCommand("copy")
    document.body.removeChild(temp)
    window.info("Copied logging code!")
  }

  const onCropChange = (dX, dY) => {
    const target = getProfile(profileID)
    banner.x = (banner.x || 0) + dX
    banner.y = (banner.y || 0) + dY
    target.emit("change")
  }
  const onZoomChange = newZoom => {
    const target = getProfile(profileID)
    banner.zoom = newZoom
    target.emit("change")
  }

  const onModeChange = type => {
    const target = getProfile(profileID)
    if (type) {
      delete banner.x
      delete banner.y
      delete banner.zoom
      banner.type = type
    }
    else {
      banner.x = 0
      banner.y = 0
      banner.zoom = 1
      delete banner.type
    }
    target.emit("change")
  }
  
  const changeAlias = e => window.ask({ title: "Enter new Alias", value: alias, allowEmpty: true }, v => getProfile(profileID).setAlias(v))
  
  const permit = e=> window.ask({
    title: "Manage Permissions",
    app: PermWrap,
    props: {
      onPermit: args => {
        window.close()
      },
      type: "profiles",
      uid: profileID,
      projectID
    },
    style: { height: "50vh" }
  })

  const canChange = hasPermission(permission, "CHANGE")
  return <Column>
    <Div style={{ height: (banner && banner.src) ? "25vh" : "0px", maxHeight: (banner && banner.src) ? "25vh" : "0px", overflow: "hidden", width: "100%" }}>
      {banner && banner.src && loaded && <ContentCropper
        posX={banner.x}
        posY={banner.y}
        zoom={banner.zoom}
        src={banner.src}
        type={banner.type}
        onModeChange={editing && canChange && onModeChange}
        onCropChange={editing && canChange && onCropChange}
        onZoomChange={editing && canChange && onZoomChange}
        onClick={canChange ? e => setEditing(!editing) : undefined}
      />}
      {!loaded && <Column fit center><LoadingOutlined/></Column>}
    </Div>
    <IconPicker visible={iconPicker} onSelect={changeIcon} onClose={e => setIconPicker(false)} />
    <Column fitX style={{padding: (banner && banner.src) ? "1rem 5% 0% 5%" : "5% 5% 0% 5%" }}>
      <Row between>
        <Row>
          <Column center className="pad-v-l pointer" onClick={canChange ? e => setIconPicker(!iconPicker) : undefined}>
            <a style={{ color: "inherit", paddingRight: "0.5rem" }}>
              <Icon i={(icon || "folder").replace("fa fa-", "")} style={{ fontSize: "2.0rem" }} />
            </a>
          </Column>
          <Column className="pad-l" onClick={e => e.stopPropagation()}>
            <h1 style={{ margin: "0" }}>
              {canChange ? <GhostInput onConfirm={v => v ? getProfile(profileID).setName(v) : null} canEdit={e=>(canChange)} placeholder="Enter Name" defaultValue={name} size="large">
                <Mention profileID={profileID} onClick={true}>
                  <a style={{ color: "inherit" }}>{name}</a>
                </Mention>
              </GhostInput> 
              : name}
            </h1>
            <h4 style={{ color: "grey", margin: "0" }}>
              {canChange ? <GhostInput onConfirm={v => v ? getProfile(profileID).setAlias(v) : null} canEdit={e=>(canChange)} placeholder="Enter Alias" defaultValue={alias} size="small">
                <a style={{ color: "inherit" }}><i>{alias}</i></a>
              </GhostInput> 
              : <i>{alias}</i>}
            </h4>
          </Column>
        </Row>
        <Row>
          <Row center>
            {profileID && navState.compendium && hasPermission(permission, "SHARE") && <Button className="size-large" title="Manage Permissions" onClick={permit}>
              <LockFilled />
            </Button>}
            {profileID && canChange && navState.compendium && <Button className="size-large" selected={navState.settings} title="View Data" onClick={e=>Nav.toggleSettings(true)}>
              <SettingFilled />
            </Button>}
            {(!profileID || (profileID && profiles && profiles.includes(profileID))) && hasPermission(permission, "WRITE") && <Button className="size-large" selected={navState.editing} title="Toggle Editing" onClick={e=>Nav.toggleEditing(true)}>
              <FormOutlined />
            </Button>}
          </Row>
        </Row>
      </Row>
      <Row fitX wrap>
        <Button style={{marginRight: "0.5em"}} childClass="grow" selected={!currentTab} primary small className={!currentTab ? "foreground" : "background"} style={{border: "1px solid #141414", marginRight: "0.5em"}} onClick={e=>Nav.setTab(profileID)}><HomeFilled/></Button>
        {Object.keys(tabs).map((v,key)=><Button selected={v === currentTab} key={key} primary small className={v === currentTab ? "foreground" : "background"} style={{border: "1px solid #141414", marginRight: "0.5em"}} onClick={e=>Nav.toggleTab(profileID, v)}>{v}</Button>)}
        {mapID && <Button onClick={visitMap} primary small className="foreground" style={{border: "1px solid #141414", marginRight: "0.5em"}} title="Visit Map">
          <CompassFilled />
        </Button>}
        {/* <Button primary small className="foreground" style={{marginRight: "0.5em"}} title="Visit Scene" onClick={visitScene}>
          <GlobalOutlined />
        </Button> */}
        <ArticleProvider articleID={articleID}>
          <ProfileNav />
        </ArticleProvider>
        {profileID && profiles && (!profiles.includes(profileID) || temp) && <Row center><i className="size-small text-grey">{temp ? "Temporary" : "Archived"}</i></Row>}
      </Row>
      <Row style={{borderBottom: "1px solid #333333", marginBottom: "1rem"}}/>
    </Column>
  </Column>
}

export function Profile({readOnly}) {
  const { profileID } = useProfile()
  const { tabs, editing } = useNav()

  return <Column grow style={{boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.7)"}}>
    <ProfileCover />
    <ProfileContents readOnly={readOnly ?? !editing} currentTab={tabs[profileID]} />
  </Column>
}
