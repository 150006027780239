import React from 'react'

import { LoadingOutlined, EyeOutlined, ReadOutlined, ProfileOutlined, EditOutlined, MailOutlined, PartitionOutlined, UserOutlined, DeleteFilled } from '@ant-design/icons'

import { Column, Row, Button, Scroll } from '../UI'
import useProject, {getProject} from './hooks/useProject'

export default ({ type, uid, parent, onPermit }) => {
  const {projectID, loaded, access} = useProject()

  if (!loaded)
    return <LoadingOutlined/>

  const {users, roles} = access

  const permissions = [
    ["Handout Only", <MailOutlined />],
    ["See", <EyeOutlined />],
    ["Read", <ReadOutlined />],
    ["Change", <ProfileOutlined/>],
    ["Write", <EditOutlined/>]
  ]

  return <Column fit>
    <h3>Roles</h3>
    <Column className="card-bg pad">
      {Object.keys(roles).map((role, idx) => <Row key={idx} className={idx % 2 ? "darker" : undefined}>
          <Column center className="pad-h">
            {role === "Guests" ? <UserOutlined /> : <Button small onClick={e => window.ask({ type: "confirm", title: "Remove user from project", subtitle: "This will remove the user from the project and clear all permissions for the user" }, res => {
              getProject(projectID).removeRole(role)
            })}><DeleteFilled /></Button>}
          </Column>
          <Row grow children={roles[role].config.name || role} />
          <Row className="gaps-h" />
          {permissions.map(([value, icon], i) => <Column key={i}>
            <Button
              small
              primary
              title={value}
              placement="top"
              selected={roles[role][type][uid] != null && roles[role][type][uid] >= i * 10}
              onClick={e => {
                getProject(projectID).permitRole(role, i * 10, type, uid)
                if (onPermit)
                  onPermit(role, i * 10, type, uid)
              }}>
              {icon}
            </Button>
          </Column>)}
        <Column>
          <Button
            small
            primary
            title="Inherit Permissions"
            placement="top"
            selected={roles[role][type][uid] == null}
            onClick={e => {
              getProject(projectID).permitRole(role, null, type, uid)
              if (onPermit)
                onPermit(role, null, type, uid)
            }}>
            <PartitionOutlined />
          </Button>
        </Column>
      </Row>)}
    </Column>
    <br/>
    <h3>Users</h3>
    <Scroll className="card-bg pad">
      {Object.keys(users).length < 1 ? <Row fit center>Add a user when they are connected to your lobby</Row> : undefined}
      {Object.keys(users).map((userID, idx) => <Row key={idx} className={idx % 2 ? "darker" : undefined}>
        <Column>
          <Button small onClick={e => window.ask({ type: "confirm", title: "Remove user from project", subtitle: "This will remove the user from the project and clear all permissions for the user" }, res => {
            getProject(projectID).removeUser(userID)
          })}>
            <DeleteFilled />
          </Button>
        </Column>
        <Row children={users[userID].config.name || userID} />
        <Row grow className="gaps-h" />
        {permissions.map(([value, icon], i) => <Column key={i}>
          <Button
            small
            primary
            title={value}
            placement="top"
            selected={users[userID][type][uid] != null && users[userID][type][uid] >= i * 10}
            onClick={e => {
              getProject(projectID).permitUser(userID, i * 10, type, uid)
              if (onPermit)
                onPermit(userID, i * 10, type, uid)
            }}>
            {icon}
          </Button>
        </Column>)}
        <Column>
          <Button
            small
            primary
            title="Inherit Permissions"
            placement="top"
            selected={users[userID][type][uid] == null}
            onClick={e => {
              getProject(projectID).permitUser(userID, null, type, uid)
              if (onPermit)
                onPermit(userID, null, type, uid)
            }}>
            <PartitionOutlined />
          </Button>
        </Column>
      </Row>)}
    </Scroll>
  </Column>
}