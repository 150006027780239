import React, { useEffect, useState, useContext, createContext } from 'react'
import Resources from '../state/Resources'
import Wizard from '../models/Wizard'
import Nav from '../state/Navigation'

const WizardContext = createContext()

export { WizardContext }

const wizard = new Wizard({name: "Creation Wizard"})
const { Wizards } = Resources

export default function useWizard() {
  return useContext(WizardContext) || {}
}

export function getWizard(wizardID) {
  return wizardID ? Wizards.get(wizardID) : wizard
}

export function useHook(wizardID) {
  const [loaded, setLoaded] = useState()
  const [steps, setSteps] = useState([])
  const [meta, setMeta] = useState({})
  const [formatID, setFormatID] = useState()
  const [script, setScript] = useState("")
  useEffect(() => {
    const w = getWizard(wizardID)
    function change() {
      setLoaded(w.loaded)
      setSteps(w.steps)
      setFormatID(w.format)
      setScript(w.script)
      setMeta({...w.meta})
    }
    change()
    w.on("change", change)
    w.on("ready", change)

    return function () {
      w.off("change", change)
      w.off("ready", change)
    }
  }, [wizardID])

  return { loaded, wizardID, steps, meta, formatID, script }
}

export function WizardProvider({ wizardID, children }) {
  return <WizardContext.Provider value={useHook(wizardID)} children={children} />
}