import React from 'react'
import Nav from '../state/Navigation'
import Resources from '../state/Resources'
import rfdc from 'rfdc'
import { hasRight, createProfile } from '../hooks/useProject'

import { Transforms, Editor } from 'slate'

import fuzzysearch from 'fuzzysearch'
import QuickSort from 'optimized-quicksort'
import { getProfile } from "../hooks/useProfile"
import { getProject } from "../hooks/useProject"

const clone = rfdc()
const { Profiles, Projects } = Resources

function find(type, editor) {
  const anchorPath = [...editor.selection.anchor.path]

  function bubble(path) {
    path.splice(path.length - 1, 1)
    const node = Editor.node(editor, path)
    if (node && node[0].type === type)
      return node
    else if (path.length)
      return bubble(path)
  }

  const table = bubble(anchorPath)
  return table
}

const commands = {
  "/": {
    menu: (editor, search, ctx) => {
      return [
        { name: "Normal Text", mark: true, result: { size: null, bold: null } },
        { name: "Header 1", icon: <b style={{ fontSize: "0.8rem" }}>&nbsp;H1</b>, result: { type: "header", children: [{ type: "paragraph", children: [{ text: "", size: "h1" }] }] } },
        { name: "Header 2", icon: <b style={{ fontSize: "0.8rem" }}>&nbsp;H2</b>, result: { type: "header", children: [{ type: "paragraph", children: [{ text: "", size: "h2" }] }] } },
        { name: "Header 3", icon: <b style={{ fontSize: "0.8rem" }}>&nbsp;H3</b>, result: { type: "header", children: [{ type: "paragraph", children: [{ text: "", size: "h3" }] }] } },
        { name: "Subheader", result: { type: "header", children: [{ type: "paragraph", children: [{ text: "", size: "sub" }] }] } },

        // {
        //   name: "Navmark", result: (cmd, cb, selection) => {
        //     const [container, path] = editor.getContainer(selection.anchor.path, [], ["header"])
        //     if (container.type !== "header" || path.length > 1)
        //       return window.warning("Navmark must be used on standalone headers")

        //     Transforms.setNodes(editor, { bookmark: v4() }, { at: [path[0]], match: e => e.type === "header" ? true : false })
        //     cb("")
        //   }
        // },
        // {
        //   name: "Unmark", result: (cmd, cb, selection) => {
        //     const [container, path] = editor.getContainer(selection.anchor.path, [], ["header"])
        //     Transforms.setNodes(editor, { bookmark: null }, { at: [path[0]], match: e => e.type === "header" ? true : false })
        //     cb("")
        //   }
        // },
        {
          name: "Reset Article", result: (cmd, cb, selection) => window.ask({ type: "confirm", title: "Reset article to default" }, res => {
            window.blurAll()
            editor.reset()
          })
        },
        { name: "Divider", result: { type: "divider", children: [{ text: "" }] } },
        { name: "Numbered List", result: { type: "numbered-list", children: [{ type: "list-item", children: [{ text: "" }] }] } },
        { name: "List", result: { type: "bulleted-list", children: [{ type: "list-item", children: [{ text: "" }] }] } },
        { name: "Quote", result: { type: "quote", children: [{ type: "paragraph", children: [{ text: "" }] }] } },
        
        { name: "Datapad", result: { type: "section", mode: "datapad", children: [
          { type: "paragraph", align: "center", children: [{ size: "h1", text: "Header" }]},
          { type: "divider", height: "4px", children: [{ text: "" }] },
          { type: "paragraph", children: [{ text: "" }] },
          { type: "paragraph", children: [{ text: "" }] },
          { type: "paragraph", align: "center", children: [{ text: "Datapad Contents" }] },
          { type: "paragraph", children: [{ text: "" }] },
          { type: "paragraph", children: [{ text: "" }] },
        ]} },
        { name: "Document", result: { type: "section", mode: "document", children: [
          { type: "paragraph", align: "center", children: [{ size: "h1", text: "Header" }]},
          { type: "divider", height: "4px", children: [{ text: "" }] },
          { type: "paragraph", children: [{ text: "" }] },
          { type: "paragraph", children: [{ text: "" }] },
          { type: "paragraph", align: "center", children: [{ text: "Document Contents" }] },
          { type: "paragraph", children: [{ text: "" }] },
          { type: "paragraph", children: [{ text: "" }] },
        ] } },
        { name: "Parchment", result: { type: "section", mode: "parchment", children: [
          { type: "paragraph", children: [{ text: "Dear Receiver," }] },
          { type: "paragraph", children: [{ text: "" }] },
          { type: "paragraph", children: [{ text: "Enter your own letter body here" }] },
          { type: "paragraph", children: [{ text: "" }] },
          { type: "paragraph", children: [{ text: "" }] },

          { type: "paragraph", align: "right", children: [{ size: "h3", italic: true, text: "Remark" }] },
          { type: "paragraph", align: "right", children: [{ italic: true, text: "~Signature" }] }
        ] } },
        { name: "Stone", result: { type: "section", mode: "stone", children: [
          { type: "paragraph", children: [{ text: "Dear Receiver," }] },
          { type: "paragraph", children: [{ text: "" }] },
          { type: "paragraph", children: [{ text: "Enter your own letter body here" }] },
          { type: "paragraph", children: [{ text: "" }] },
          { type: "paragraph", children: [{ text: "" }] },

          { type: "paragraph", align: "right", children: [{ size: "h3", italic: true, text: "Remark" }] },
          { type: "paragraph", align: "right", children: [{ italic: true, text: "~Signature" }] }
        ] } },
        { name: "Terminal", result: { type: "section", mode: "terminal", children: [
          { type: "paragraph", children: [{ size: "h3", text: ">WELCOME" }]},
          { type: "paragraph", children: [{ text: ">history()" }] },
          { type: "paragraph", children: [{ text: ">denied!" }] },
          { type: "paragraph", children: [{ text: ">enter command" }] }
        ]} },
        { name: "Component", result: (cmd, cb, selection) => {
          const { components } = getProject(ctx.projectID).getBank()

          window.ask({ title: "Enter Component Key", allowCustom: true, choices: Object.keys(components).map(value=>({value, text: components[value].name || value})) }, 
            res => { 
              const m = res.match(/[\w\d_]+/i)
              if (!m || m.index !== 0 || m[0].length !== res.length)
                return window.error("Invalid key, may only contain numbers, characters and _")
              if (m[0] === "_")
                return window.error("Key can't start with _, reserved for system")
              cb({ type: "component", key: String(res).toLowerCase(), children: [{ text: "" }]})
            }
          )
        }},
        { name: "Secret", description: "Must have writing permissions to see", result: { type: "secret", children: [{ type: "paragraph", children: [{ text: "" }] }] } },
        {
          name: "Layout Split",
          icon: <b>|</b>,
          result: (cmd, cb, selection) => {
            const [container, path] = editor.getContainer(selection.focus.path, [])
            if (container.type !== "paragraph")
              return window.warning("Can't split non-text")

            const [leaf, leafPath] = Editor.leaf(editor, selection)

            const left = []
            const right = []
            for (let i = 0; i < container.children.length; i++) {
              if (i <= leafPath[leafPath.length - 1]) {
                const s = clone(container.children[i])
                if (s.text)
                  s.text = s.text.replace("/" + search, "")
                left.push(s)
              }
              else
                right.push(clone(container.children[i]))
            }

            cb({
              type: "layout",
              children: [
                {
                  type: "column", width: "50%", children: [
                    { type: "paragraph", align: "left", children: left }
                  ]
                },
                {
                  type: "column", width: "50%", children: [
                    { type: "paragraph", align: "right", children: right }
                  ]
                }
              ]
            })
          }
        },
        {
          name: "Layout", result: {
            type: "layout",
            children: [
              {
                type: "column", width: "50%", children: [
                  { type: "paragraph", children: [{ text: "" }] }
                ]
              },
              {
                type: "column", width: "50%", children: [
                  { type: "paragraph", children: [{ text: "" }] }
                ]
              }
            ]
          }
        },
        // {
        //   name: "Image", result: (cmd, cb) => window.ask({ title: "Select Image", type: "file" },
        //     res => cb({
        //       type: "media",
        //       src: res.src || res,
        //       children: [{ text: "" }]
        //     }))
        // },
        // {
        //   name: "External Link", result: (cmd, cb) => {
        //     window.ask({ title: "Enter URL" }, res => {
        //       cb({
        //         type: "link",
        //         src: res,
        //         children: [{ text: "" }]
        //       })
        //     })
        //   }
        // },
        {
          name: "Align-Left", mark: true, result: (cmd, cb) => {
            const p = find("paragraph", editor)
            Transforms.setNodes(editor, { align: "left" }, { at: p[1], match: e => e.type === "paragraph" ? true : false })
            cb({ text: "" })
          }
        },
        {
          name: "Align-Center", mark: true, result: (cmd, cb) => {
            const p = find("paragraph", editor)
            Transforms.setNodes(editor, { align: "center" }, { at: p[1], match: e => e.type === "paragraph" ? true : false })
            cb({ text: "" })
          }
        },
        {
          name: "Align-Right", mark: true, result: (cmd, cb) => {
            const p = find("paragraph", editor)
            Transforms.setNodes(editor, { align: "right" }, { at: p[1], match: e => e.type === "paragraph" ? true : false })
            cb({ text: "" })
          }
        },
        {
          name: "Align-Justify", mark: true, result: (cmd, cb) => {
            const p = find("paragraph", editor)
            Transforms.setNodes(editor, { align: "justify" }, { at: p[1], match: e => e.type === "paragraph" ? true : false })
            cb({ text: "" })
          }
        },
        {
          name: "Line Above",
          mark: true,
          result: (cmd, cb, selection) => {
            cb()
            const [container, containerPath] = editor.getContainer(selection.focus.path, ["row", "cell", "paragraph", "column"])
            if (containerPath.length) {
              containerPath[containerPath.length - 1] = Math.max(containerPath[containerPath.length - 1], 0)
              Transforms.insertNodes(editor, { type: "paragraph", children: [{ text: "" }] }, { at: containerPath })
            }
          }
        },
        {
          name: "Line Remove",
          mark: true,
          result: (cmd, cb, selection) => {
            cb()
            const [container, containerPath] = editor.getContainer(selection.focus.path, [])
            if (containerPath.length)
              Transforms.delete(editor, { at: containerPath })
          }
        },
        {
          name: "Line Below",
          result: (cmd, cb, selection) => {
            cb()
            const [container, containerPath] = editor.getContainer(selection.focus.path, ["row", "cell", "paragraph", "column"])
            if (containerPath.length) {
              containerPath[containerPath.length - 1] = Math.max(containerPath[containerPath.length - 1] + 1, 0)
              Transforms.insertNodes(editor, { type: "paragraph", children: [{ text: "" }] }, { at: containerPath })
            }
          }
        },
        // {
        //   name: "Youtube", icon: <YouTube />, result: (cmd, cb) => {
        //     window.ask({ title: "Enter youtube URL" }, (res) => {
        //       cb({
        //         type: "media",
        //         src: res,
        //         children: [{ text: "" }]
        //       })
        //     })
        //   }
        // },
        {
          name: "Table", result: (cmd, cb) => {
            const children = []
            for (let i = 0; i < 2; i++)
              children.push({ type: "cell", children: [{ type: "paragraph", children: [{ text: "" }] }] })
            const rows = [{ type: "row", children: children }]
            for (let i = 0; i < 1; i++)
              rows.push({
                type: "row", children: children.map(value => ({ type: "cell", children: [{ type: "paragraph", children: [{ text: "" }] }] }))
              })

            cb({ type: "table", children: rows })
          }
        },
        { name: "Event '!'", desc: "Execute a script, or apply to target through drag and drop", result: { text: "!" } },
        { name: "Formula '='", desc: "Floating formula to make things clearer", result: { text: "=" }, auto: true },
        { name: "Mention '@'", desc: "Add a link to another profile", result: { text: "@" } },
        { name: "Stat Block '#'", desc: "Create a stat block", result: { text: "#" }, auto: true },
      ].filter(a => {
        if (!search)
          return true
        return a.name.toLowerCase().indexOf(search.toLowerCase()) > -1
      })
    }
  },
  "#": {
    auto: true, // filter for automation on things that don't use it
    menu: (editor, search, ctx) => {
      const { profileID } = ctx
      let mentions = search ? [] : [
        {
          name: "Stat Block '#'",
          desc: "Create a new stat",
          auto: true,
          result: (cmd, cb) => window.ask({ title: "Enter Stat Key" }, res => {
            const m = res.match(/[\w\d_]+/i)
            if (!m || m.index !== 0 || m[0].length !== res.length)
              return window.error("Invalid key, may only contain numbers, characters and _")
            if (m[0] === "_")
              return window.error("Key can't start with _, reserved for system")
            cb({ dataKey: res.toLowerCase(), type: "data", children: [{ text: "" }] })
          })
        }
      ]
      const profile = getProfile(profileID)
      if (profile) {
        const table = profile.getDataTable()
        for (let key in table)
          if (key.toLowerCase() !== search.toLowerCase())
            mentions.push({
              name: key,
              subtitle: table[key],
              auto: true,
              result: { type: "data", dataKey: key, children: [{ text: "" }] }
            })
      }
      if (search && search[0] !== "_") {
        mentions = mentions.filter(a => {
          return a.name.toLowerCase().indexOf(search.toLowerCase()) > -1
        })
        mentions = [...mentions, {
          name: search,
          desc: `Create new stat ${search}`,
          auto: true,
          result: (cmd, cb) => {
            const term = search.toLowerCase()
            cb({ dataKey: term, type: "data", children: [{ text: "" }] })
          }
        }]
      }

      return mentions
    }
  },
  "!": {
    menu: (editor, search, ctx) => {
      let mentions = [{
        name: "Custom Event",
        result: (cmd, cb) => {
          window.ask({ title: "Event Name" }, mask => {
            cb({ children: [{ text: "" }], type: "event", mask })
          })
        }
      }]
      let events = getProject(ctx.projectID).getEventBank()
      if (ctx.profileID)
        events = { ...events, ...getProfile(ctx.profileID).getEventsObject() }
      for (const eventID in events)
        mentions.push({
          name: events[eventID].name || eventID,
          result: (cmd, cb) => {
            const {choices} = events[eventID]
            if (choices && choices.length) 
              window.ask({title: "Select Configuration", allowEmpty: true, value: 0, choices: choices.map((v,i)=>({text: v.mask, value: i}))}, res=>{
                if (res == null)
                  cb({ children: [{ text: "" }], script: "\n" + `!${eventID}`, type: "event" })
                else 
                  cb({ children: [{ text: "" }], mask: choices[res].mask, script: (choices[res].script || "") + "\n" + `!${eventID}`, type: "event" })
              })
            else
              cb({ children: [{ text: "" }], script: "\n" + `!${eventID}`, type: "event" })
          }
        })

      mentions = mentions.filter(a => {
        return a.name.toLowerCase().indexOf(search.toLowerCase()) > -1
      })

      return mentions
    }
  },
  "=": {
    menu: (editor, search, ctx) => {
      return [{
        name: "Formula",
        desc: "Floating formula to make things clearer",
        result: (cmd, cb) => window.ask({ title: "Enter formula" }, formula => cb({ children: [{ text: "" }], formula, type: "formula" }))
      }]
    }
  },
  "@": {
    menu: (editor, search, ctx) => {
      const create = {
        name: `New Page '${search}'`,
        desc: "Create a new Page",
        result: (cmd, cb) => {
          const profile = createProfile()
          if (profile) {
            const profileID = profile.uid
            profile.loaded = true
            profile.setName(search)

            if (Nav.profile) {
              const fuid = project.wiki.findProfile(Nav.profile)
              if (fuid) {
                const folder = project.wiki.lookupFolder(fuid)
                if (folder)
                  folder.open()
              }
            }

            cb({ type: "mention", children: [{ text: "" }], profileID })
          }
        }
      }
      let List
      const project = Projects.get(Nav.project)
      if (search) {
        const s = search.toLowerCase()
        List = project.getProfileBank().filter(a => {
          let aa = Profiles.get(a) || { getName: () => { return "" } }
          return hasRight(a, "SEE") && fuzzysearch(s, aa.getName().toLowerCase())
        })
      }
      else
        List = project.getProfileBank()

      QuickSort.sort(List, (a, b) => {
        let aa = Profiles.get(a)
        let bb = Profiles.get(b)
        return aa.getName().toLowerCase().localeCompare(bb.getName().toLowerCase())
      })

      const mentions = []
      for (const profileID of List) {
        const profile = Profiles.get(profileID)
        if (hasRight(profileID, "SEE") && !profile.temp)
          mentions.push({
            name: profile.getName(),
            // desc: profile.getAlias(),
            result: { type: "mention", children: [{ text: "" }], profileID }
          })
      }
      if (mentions.length)
        return [...mentions, create]

      return project.isOwner() && (!ctx.profileID || !ctx.profileID.match("-temp-")) ? [create] : []
    }
  }
}

// const commands = {
//   "#": {
//     menu: (editor, search, ctx) => {
//       const { profileID } = ctx
//       let mentions = search ? [] : [
//         {
//           name: "Stat Block '#'",
//           desc: "Create a new stat",
//           result: (cmd, cb) => window.ask({ title: "Enter Stat Name" }, res => {
//             const m = res.match(/[\w\d_]+/i)
//             if (!m || m.index !== 0 || m[0].length !== res.length || m[0] === "_")
//               return window.error("Invalid key, may only contain numbers, characters and _")
//             cb({ dataKey: res.toLowerCase(), type: "data", children: [{ text: "" }] })
//           })
//         }
//       ]
//       const profile = Profiles.get(profileID)
//       if (profile) {
//         const table = profile.getDataTable()
//         for (let key in table) {
//           if (key.toLowerCase() !== search.toLowerCase()) {
//             mentions.push({
//               name: key,
//               subtitle: table[key],
//               result: { type: "data", dataKey: key, children: [{ text: "" }] }
//             })
//           }
//         }
//       }
//       if (search && search[0] !== "_") {
//         mentions = mentions.filter(a => {
//           return a.name.toLowerCase().indexOf(search.toLowerCase()) > -1
//         })
//         mentions = [...mentions, {
//           name: search,
//           desc: `Create new stat ${search}`,
//           result: (cmd, cb) => {
//             const profile = Profiles.get(profileID)
//             const term = search.toLowerCase()
//             cb({ dataKey: term, type: "data", children: [{ text: "" }] })
//           }
//           // result: { dataKey: search.toLowerCase(), type: "data" }
//         }]
//       }

//       return mentions
//     }
//   },
//   "!": {
//     menu: (editor, search, ctx) => {
//       let mentions = [{
//         name: "Custom Action",
//         result: (cmd, cb) => {
//           window.ask({ title: "Action Name" }, mask => {
//             cb({ children: [{ text: "" }], type: "event", mask })
//           })
//         }
//       }]
//       let events = getProject().getEventBank()
//       console.log(events)
//       if (ctx.profileID)
//         events = { ...events, ...getProfile(ctx.profileID).getEventsObject() }
//       for (const eventID in events)
//         mentions.push({
//           name: eventID,
//           result: (cmd, cb) => {
//             cb({ children: [{ text: "" }], eventID, type: "event" })
//           }
//         })

//       mentions = mentions.filter(a => {
//         return a.name.toLowerCase().indexOf(search.toLowerCase()) > -1
//       })

//       return mentions
//     }
//   },
//   "=": {
//     menu: (editor, search, ctx) => {
//       return [{
//         name: "Formula",
//         desc: "Floating formula to make things clearer",
//         result: (cmd, cb) => window.ask({ title: "Enter formula" }, formula => cb({ children: [{ text: "" }], formula, type: "formula" }))
//       }]
//     }
//   },
//   "@": {
//     menu: (editor, search, ctx) => {
//       let List
//       const project = Projects.get(Nav.project)
//       if (search) {
//         const s = search.toLowerCase()
//         List = project.getProfileBank().filter(a => {
//           let aa = Profiles.get(a) || { getName: () => { return "" } }
//           return hasRight(a, "SEE") && fuzzysearch(s, aa.getName().toLowerCase())
//         })
//       }
//       else
//         List = project.getProfileBank()

//       QuickSort.sort(List, (a, b) => {
//         let aa = Profiles.get(a)
//         let bb = Profiles.get(b)
//         return aa.getName().toLowerCase().localeCompare(bb.getName().toLowerCase())
//       })

//       const mentions = []
//       for (const profileID of List) {
//         const profile = Profiles.get(profileID)
//         if (hasRight(profileID, "SEE") && !profile.temp)
//           mentions.push({
//             name: profile.getName(),
//             // desc: profile.getAlias(),
//             result: { type: "mention", children: [{ text: "" }], profileID }
//           })
//       }
//       if (mentions.length)
//         return mentions

//       return project.isOwner() && (!ctx.profileID || !ctx.profileID.match("-temp-")) ? [{
//         name: `New Profile '${search}'`,
//         desc: "Create a new Profile",
//         result: (cmd, cb) => {
//           if (Nav.profile)
//             project.root.lookup(project.wiki.lookup(Nav.profile)).open()
//           const profile = createProfile()
//           if (profile) {
//             const profileID = profile.uid
//             profile.loaded = true
//             profile.setName(search)

//             cb({ type: "mention", children: [{ text: "" }], profileID })
//           }
//         }
//       }] : []
//     }
//   },
//   "/": {
//     menu: (editor, search, ctx) => {
//       return [
//         { name: "Normal Text", mark: true, result: { size: null, bold: null } },
//         { name: "Header 1", icon: <b style={{ fontSize: "0.8rem" }}>&nbsp;H1</b>, result: { type: "header", uid: v4(), children: [{ type: "paragraph", children: [{ text: "", size: "h1" }] }] } },
//         { name: "Header 2", icon: <b style={{ fontSize: "0.8rem" }}>&nbsp;H2</b>, result: { type: "header", uid: v4(), children: [{ type: "paragraph", children: [{ text: "", size: "h2" }] }] } },
//         { name: "Header 3", icon: <b style={{ fontSize: "0.8rem" }}>&nbsp;H3</b>, result: { type: "header", uid: v4(), children: [{ type: "paragraph", children: [{ text: "", size: "h3" }] }] } },
//         { name: "Subheader", result: { type: "header", uid: v4(), children: [{ type: "paragraph", children: [{ text: "", size: "sub" }] }] } },
//         // { name: "Numbered List", icon: <FormatListNumbered />, result: { type: "numbered-list", children: [{ type: "list-item", children: [{ text: "" }] }] } },
//         // { name: "List", icon: <FormatListBulleted />, result: { type: "bulleted-list", children: [{ type: "list-item", children: [{ text: "" }] }] } },
//         { name: "Quote", result: { type: "quote", children: [{ type: "paragraph", children: [{ text: "" }] }] } },
//         { name: "Secret", description: "Must have writing permissions to see", result: { type: "secret", children: [{ type: "paragraph", children: [{ text: "" }] }] } },
//         {
//           name: "Layout Split",
//           icon: <b>|</b>,
//           result: (cmd, cb, selection) => {
//             const [container, path] = editor.getContainer(selection.focus.path, [])
//             if (container.type !== "paragraph")
//               return window.warning("Can't split non-text")

//             const [leaf, leafPath] = Editor.leaf(editor, selection)

//             const left = []
//             const right = []
//             for (let i = 0; i < container.children.length; i++) {
//               if (i <= leafPath[leafPath.length - 1]) {
//                 const s = clone(container.children[i])
//                 if (s.text)
//                   s.text = s.text.replace("/" + search, "")
//                 left.push(s)
//               }
//               else
//                 right.push(clone(container.children[i]))
//             }

//             cb({
//               type: "layout",
//               children: [
//                 {
//                   type: "column", width: "50%", children: [
//                     { type: "paragraph", align: "left", children: left }
//                   ]
//                 },
//                 {
//                   type: "column", width: "50%", children: [
//                     { type: "paragraph", align: "right", children: right }
//                   ]
//                 }
//               ]
//             })
//           }
//         },
//         {
//           name: "Layout", result: {
//             type: "layout",
//             children: [
//               {
//                 type: "column", width: "50%", children: [
//                   { type: "paragraph", children: [{ text: "" }] }
//                 ]
//               },
//               {
//                 type: "column", width: "50%", children: [
//                   { type: "paragraph", children: [{ text: "" }] }
//                 ]
//               }
//             ]
//           }
//         },
//         {
//           name: "Image", result: (cmd, cb) => window.ask({ title: "Select Image", type: "file" },
//             res => cb({
//               type: "media",
//               src: res.src,
//               children: [{ text: "" }]
//             }))
//         },
//         {
//           name: "External Link", result: (cmd, cb) => {
//             window.ask({ title: "Enter URL" }, res => {
//               cb({
//                 type: "link",
//                 src: res,
//                 children: [{ text: "" }]
//               })
//             })
//           }
//         },
//         { name: "Divider", result: { type: "divider", children: [{ text: "" }] } },
//         {
//           name: "Align-Left", mark: true, result: (cmd, cb) => {
//             const p = find("paragraph", editor)
//             Transforms.setNodes(editor, { align: "left" }, { at: p[1], match: e => e.type === "paragraph" ? true : false })
//             cb({ text: "" })
//           }
//         },
//         {
//           name: "Align-Center", mark: true, result: (cmd, cb) => {
//             const p = find("paragraph", editor)
//             Transforms.setNodes(editor, { align: "center" }, { at: p[1], match: e => e.type === "paragraph" ? true : false })
//             cb({ text: "" })
//           }
//         },
//         {
//           name: "Align-Right", mark: true, result: (cmd, cb) => {
//             const p = find("paragraph", editor)
//             Transforms.setNodes(editor, { align: "right" }, { at: p[1], match: e => e.type === "paragraph" ? true : false })
//             cb({ text: "" })
//           }
//         },
//         {
//           name: "Align-Justify", mark: true, result: (cmd, cb) => {
//             const p = find("paragraph", editor)
//             Transforms.setNodes(editor, { align: "justify" }, { at: p[1], match: e => e.type === "paragraph" ? true : false })
//             cb({ text: "" })
//           }
//         },
//         {
//           name: "Line Above",
//           mark: true,
//           result: (cmd, cb, selection) => {
//             cb()
//             const [container, containerPath] = editor.getContainer(selection.focus.path, ["row", "cell", "paragraph", "column"])
//             if (containerPath.length) {
//               containerPath[containerPath.length - 1] = Math.max(containerPath[containerPath.length - 1], 0)
//               Transforms.insertNodes(editor, { type: "paragraph", children: [{ text: "" }] }, { at: containerPath })
//             }
//           }
//         },
//         {
//           name: "Line Remove",
//           mark: true,
//           result: (cmd, cb, selection) => {
//             cb()
//             const [container, containerPath] = editor.getContainer(selection.focus.path, [])
//             if (containerPath.length)
//               Transforms.delete(editor, { at: containerPath })
//           }
//         },
//         {
//           name: "Line Below",
//           result: (cmd, cb, selection) => {
//             cb()
//             const [container, containerPath] = editor.getContainer(selection.focus.path, ["row", "cell", "paragraph", "column"])
//             if (containerPath.length) {
//               containerPath[containerPath.length - 1] = Math.max(containerPath[containerPath.length - 1] + 1, 0)
//               Transforms.insertNodes(editor, { type: "paragraph", children: [{ text: "" }] }, { at: containerPath })
//             }
//           }
//         },
//         // {
//         //   name: "Youtube", icon: <YouTube />, result: (cmd, cb) => {
//         //     window.ask({ title: "Enter youtube URL" }, (res) => {
//         //       cb({
//         //         type: "media",
//         //         src: res,
//         //         children: [{ text: "" }]
//         //       })
//         //     })
//         //   }
//         // },
//         {
//           name: "Table", result: (cmd, cb) => {
//             window.ask({ title: "Enter how many columns" }, columns => {
//               const children = []
//               for (let i = 0; i < Math.min(Math.abs(parseInt(columns) || 1), 10); i++)
//                 children.push({ type: "cell", children: [{ type: "paragraph", children: [{ text: "" }] }] })
//               window.ask({ title: "Enter how many rows" }, res => {
//                 const rows = [{ type: "row", children: children }]
//                 for (let i = 0; i < (res - 1); i++)
//                   rows.push({
//                     type: "row", children: children.map(value => ({ type: "cell", children: [{ type: "paragraph", children: [{ text: "" }] }] }))
//                   })

//                 cb({ type: "table", children: rows })
//               })
//             })
//           }
//         },
//         { name: "Event '!'", desc: "Execute a script, or apply to target through drag and drop", result: { text: "!" } },
//         { name: "Formula '='", desc: "Floating formula to make things clearer", result: { text: "=" } },
//         { name: "Mention '@'", desc: "Add a link to another profile", result: { text: "@" } },
//         { name: "Stat Block '#'", desc: "Create a stat block", result: { text: "#" } },
//         // {
//         //   name: "Container",
//         //   desc: "A Container, like a pouch or backpack",
//         //   icon: <ViewModule />,
//         //   result: (cmd, cb, editor, ctx) => {
//         //     const { profileID } = ctx
//         //     window.ask({
//         //       title: "Pick a Container",
//         //       subtitle: "Containers",
//         //       app: CONTAINERPICKER,
//         //       props: {
//         //         profileID,
//         //         onSelect: args => {
//         //           cb({ type: "container", key: args, children: [{ text: "" }] })
//         //           window.close()
//         //         }, style: { height: "50vh", width: "50vw" }
//         //       }
//         //     })
//         //   }
//         // },
//         // {
//         //   name: "List",
//         //   desc: "A List, like a crew list or shop inventory",
//         //   icon: <ListAlt />,
//         //   result: (cmd, cb, editor, ctx) => {
//         //     const { profileID } = ctx
//         //     window.ask({
//         //       title: "Pick a list",
//         //       subtitle: "Lists",
//         //       app: LISTPICKER,
//         //       props: {
//         //         profileID,
//         //         onSelect: args => {
//         //           cb({ type: "list", key: args, children: [{ text: "" }] })
//         //           window.close()
//         //         }, style: { height: "50vh", width: "50vw" }
//         //       }
//         //     })
//         //   }
//         // },
//         // { name: "Framed", icon: <CheckBoxOutlineBlank />, result: { type: "section", children: [{ type: "paragraph", children: [{ text: "" }] }] } },
//       ].filter(a => {
//         if (!search)
//           return true
//         return a.name.toLowerCase().indexOf(search.toLowerCase()) > -1
//       })
//     }
//   }
// }

export { commands }