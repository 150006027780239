import EventEmitter from 'events'
import { database } from './Firebase'
import Account from './state/Account'

export default class Network extends EventEmitter {
  // transport socket for commmunication
  readied = true
  connected = {}
  server = false
  host = true
  commands = {
    "_reconnect": {
      msg: (msg, channel, from) => {
        const evt = JSON.parse(msg.data)
        const { readied, connected } = this
        if (!readied || evt.from === this.Account.id)
          return false

        var signal = JSON.parse(evt.sdp || "{}")
        if (signal.type === "offer")
          this.answer(evt.from, signal, channel)
        else if (signal.type === "answer") // acknowledge the answer
          connected[evt.from].setRemoteDescription(new RTCSessionDescription(signal))
        else if (signal.candidate) {
          const candidate = new RTCIceCandidate(signal.candidate)
          candidate.p2p = true
          connected[evt.from].addIceCandidate(new RTCIceCandidate(signal.candidate))
        }
      },
    }
  }

  peers() {
    const result = {}
    for (const peer in this.connected) {
      // collect meta data and return it
      result[peer] = { ...this.connected[peer].playerData }
      for (const r of this.connected[peer].getTransceivers())
        if (r.currentDirection !== "sendonly" && r.currentDirection !== "inactive")
          if (r.receiver.track.kind === "audio")
            result[peer].audio = r.receiver.track
          else if (r.receiver.track.kind === "video")
            result[peer].video = r.receiver.track

      result[peer].stream = this.connected[peer].stream
    }
    return result
  }

  channel(chnl, options) {
    this.commands[chnl] = { ...options }
  }

  broadcast(chnl, data) {
    const { connected } = this
    for (var peerID in connected) {
      var peer = connected[peerID]
      if (peer.channels[chnl] && peer.channels[chnl].readyState === "open")
        peer.channels[chnl].send(data)
    }
  }

  propose(chnl, data) {
    const { connected, server } = this
    if (connected[server])
      connected[server].channels[chnl].send(data)
  }

  updateStream() {
    const { stream } = window
    stream.getTracks().forEach(track => {
      for (const peer in this.connected)
        this.connected[peer].addTrack(track)
    })
  }

  send(chnl, data, peerID) {
    const { host, connected } = this
    if (peerID && connected[peerID])
      return connected[peerID].channels[chnl].send(data)
    return host ? this.broadcast(chnl, data) : this.propose(chnl, data)
  }

  errorCB(error) {
    console.error(error)
  }

  create(id) {
    const { lobby, commands, connected } = this
    var peer = new RTCPeerConnection({
      'iceServers': [
        { urls: 'stun:stun01.sipphone.com' },
        { urls: 'stun:stun.ekiga.net' },
        { urls: 'stun:stun.fwdnet.net' },
        { urls: 'stun:stun.ideasip.com' },
        { urls: 'stun:stun.iptel.org' },
        { urls: 'stun:stun.rixtelecom.se' },
        { urls: 'stun:stun.schlund.de' },
        { urls: 'stun:stun.l.google.com:19302' },
        { urls: 'stun:stun1.l.google.com:19302' },
        { urls: 'stun:stun2.l.google.com:19302' },
        { urls: 'stun:stun3.l.google.com:19302' },
        { urls: 'stun:stun4.l.google.com:19302' },
        { urls: 'stun:stunserver.org' },
        { urls: 'stun:stun.softjoys.com' },
        { urls: 'stun:stun.voiparound.com' },
        { urls: 'stun:stun.voipbuster.com' },
        { urls: 'stun:stun.voipstunt.com' },
        { urls: 'stun:stun.voxgratia.org' },
        { urls: 'stun:stun.xten.com' },
      ]
    })
    peer.channels = {}
    peer.onicecandidate = evt => {
      if (evt.candidate) {
        // console.log(evt.candidate)
        // communicate to that peer that I'm ready to go, tunneled through our socket.io server
        if (evt.candidate.p2p)
          // console.log("HERE")
          peer.channels["_reconnect"].send(JSON.stringify({ 'sdp': JSON.stringify({ type: evt.type, candidate: evt.candidate }), to: id, from: Account.id, p2p: true }))
        else
          database.ref(`lobbies/${lobby}/players/${id}/${Account.id}`).set({ 'sdp': JSON.stringify({ type: evt.type, candidate: evt.candidate }), to: id })
        //socket.emit('p2p', { 'sdp': JSON.stringify({ type: evt.type, candidate: evt.candidate }), to: id })
      }
    }
    peer.ondatachannel = evt => {
      peer.channels[evt.channel.label] = evt.channel
      peer.establishChannel(evt.channel, evt.channel.label)
      this.emit("channel", evt, id)
    }
    peer.ontrack = evt => {
      this.emit("change")
    }

    peer.establishChannel = function (channel, label) {
      channel.onmessage = function (evt) {
        if (commands[label].msg)
          commands[label].msg(evt, this, id)
      }
      channel.onopen = function (evt) {
        peer.channels[label] = this
        if (commands[label].open)
          commands[label].open(evt, this, id)
      }
      channel.onclose = function (evt) {
        delete peer.channels[label]
        if (commands[label].close)
          commands[label].close(evt, this, id)
      }
      channel.onerror = function (evt) {
        console.warn(evt, label)
        if (commands[label].error)
          commands[label].error(evt, this, id)
      }
    }

    peer.onconnectionstatechange = evt => {
      switch (peer.connectionState) {
        case "connected":
          // The connection has become fully connected
          this.emit("connected", id)
          break
        case "disconnected":
        case "failed":
        case "closed":
          delete connected[id]
          if (id === this.server && !this.host) {
            window.alert("Sorry! Connection to host lost... Reloading profile. I'll be adding better reconnect controls soon!")
            window.location.reload()
          }
          this.emit("disconnected", id)
          break
        default:
      }
      this.emit("change")
    }

    peer.onnegotiationneeded = evt => {
      // console.log(evt)
      if (peer.channels["_reconnect"])
        this.offer(id, peer.channels["_reconnect"])
    }

    peer.stream = new MediaStream()

    const { stream } = window
    if (stream)
      stream.getTracks().forEach(track => peer.addTrack(track))

    connected[id] = peer
    return peer
  }

  answer(id, signal, transport) {
    const { connected, lobby, errorCB } = this

    var pc = connected[id] || this.create(id)
    pc.setRemoteDescription(new RTCSessionDescription(signal), function () {
      pc.createAnswer(function (desc) {
        pc.setLocalDescription(desc, function () {
          if (transport) // reconnect through peers
            transport.send(JSON.stringify({ 'sdp': JSON.stringify(desc), to: id, from: Account.id }))
          else
            database.ref(`lobbies/${lobby}/players/${id}/${Account.id}`).set({ 'sdp': JSON.stringify(desc), to: id })
          //socket.emit('p2p', { 'sdp': JSON.stringify(desc), to: id })
        }, errorCB)
      }, errorCB)
    }, errorCB)
  }

  offer(id, transport) {
    const { connected, commands, lobby, errorCB } = this
    var pc = connected[id] || this.create(id)

    for (const key in commands)
      if (pc.channels && !pc.channels[key])
        pc.establishChannel(pc.createDataChannel(key, commands[key].options), key)

    pc.createOffer({ offerToReceiveVideo: true, offerToReceiveAudio: true }).then(function (desc) {
      pc.setLocalDescription(desc, function () {
        if (transport)
          transport.send(JSON.stringify({ 'sdp': JSON.stringify(desc), to: id, from: Account.id }))
        else
          database.ref(`lobbies/${lobby}/players/${id}/${Account.id}`).set({ 'sdp': JSON.stringify(desc), to: id })
        //socket.emit('p2p', { 'sdp': JSON.stringify(desc), to: id })
      }, errorCB)
    }, errorCB)
  }

  prepare(host) {
    // allows server to communicate peer information
    // const { socket } = this
    this.host = host

    // database.ref(`lobbies/${host}/players/${Account.id}`).off("value") // stop listening to old lobbies
    database.ref(`lobbies/${this.lobby}/players/${Account.id}`).on("value", v => {
      const json = v.val()
      delete json.active
      const { readied, connected } = this
      for (const key in json) {
        const evt = json[key]
        if (!readied || key === Account.id)
          return false
        // filter the blind forwarding of messages
        var signal = JSON.parse(evt.sdp || "{}")
        if (signal.type === "offer")
          this.answer(key, signal)
        else if (signal.type === "answer")
          // acknowledge the answer
          connected[key].setRemoteDescription(new RTCSessionDescription(signal))
        else if (signal.candidate)
          connected[key].addIceCandidate(new RTCIceCandidate(signal.candidate))
      }
    })

    // socket.off('p2p')
    // socket.on('p2p', evt => {
    //   // establish our connections
    //   const { readied, connected } = this
    //   if (!readied || evt.from === Account.id)
    //     return false
    //   // filter the blind forwarding of messages
    //   var signal = JSON.parse(evt.sdp || "{}")
    //   if (signal.type === "offer")
    //     this.answer(evt.from, signal)
    //   else if (signal.type === "answer")
    //     // acknowledge the answer
    //     connected[evt.from].setRemoteDescription(new RTCSessionDescription(signal))
    //   else if (signal.candidate)
    //     connected[evt.from].addIceCandidate(new RTCIceCandidate(signal.candidate))
    // })
    this.emit("change")
  }

  connect(to, host) {
    // starts connecting to a socket
    // const { readied, socket } = this
    this.prepare(host)
    this.create(to)
    this.offer(to)
  }

  joinLobby(lobbyID) {
    return new Promise(res => {
      database.ref(`lobbies/${lobbyID}`).once("value").then(v => {
        this.lobby = lobbyID
        this.emit("lobby", lobbyID)
        const host = v.val().host
        database.ref(`lobbies/${lobbyID}/players/${Account.id}`).set({ active: true }).then(v => {
          this.connect(host)
          res(lobbyID)
        })
      })
    })
  }

  async createLobby(lobbyID) {
    this.lobby = lobbyID
    this.emit("created")
    this.emit("lobby")
    return database.ref(`lobbies/${lobbyID}`).set({
      host: Account.id,
      kicked: [], // a list of people NOT allowed in
      players: {
        [Account.id]: { active: true } // an object containing every socket connect needed to connect to
      },
    })
  }
}