import React, { useState, useRef, useEffect } from 'react'
import useEvents from '../hooks/useEvents'
import Account from '../state/Account'
import Network from '../state/Network'

import { Button } from 'antd'

import { v4 } from 'uuid'

Account.on("meta", res => {
  if (Network.lobby)
    Network.broadcast("metadata", JSON.stringify({ name: Account.name, uid: Account.uid }))
})

const PeerPlate = ({ peerID, data }) => {
  const mediaRef = useRef()
  const { stream, name, video, audio } = data

  useEffect(() => {
    if (stream && mediaRef && mediaRef.current) {
      if (audio)
        stream.addTrack(audio)

      if (video)
        stream.addTrack(video)

      mediaRef.current.srcObject = stream
      mediaRef.current.addEventListener("loadeddata", function (evt) {
        // console.log(evt)
        mediaRef.current.play()
      })
    }
  }, [data, audio, stream, video])

  return <div className="column">
    {/* <video width={opts.width ?? 180} height={audio && video ? opts.height ?? 120 : 0} ref={mediaRef} /> */}
    <div>
      <Button className="click">
        {name}
      </Button>
      {/* {permitted ? <Button variant="contained" small title="Add User to Project" placement="top"><PersonAdd fontSize="small" /></Button> : null} */}
    </div>
  </div>
}

export const copySocket = uid => {
  let temp = document.createElement("input")
  temp.type = "text"
  temp.value = uid
  document.body.appendChild(temp)
  temp.focus()
  temp.select()
  document.execCommand("copy")
  document.body.removeChild(temp)
  window.info("Copied join code!")
}

export default opts => {
  const [lobby, setLobby] = useState()
  const [players, setPlayers] = useState()

  // const [mic, setMic] = useState(false)
  // const [camera, setCamera] = useState(false)

  const [name, setName] = useState(Account.name)
  useEvents(["loaded", "change"], () => setName(Account.name), Account)

  useEffect(() => {
    function force() {
      setPlayers(Network.peers())
      setLobby(Network.host || (Network.host !== true && Network.server))
    }
    Network.on("change", force)
    return () => {
      Network.off("change", force)
    }
  })


  const playerChips = []
  // if (!window.mobilecheck())
  for (const peer in players)
    playerChips.push(<PeerPlate key={peer} peerID={peer} data={players[peer]} />)

  // if (lobby)
  return <div className='column click'>
    <div className="black-tint rounded column">
      {!lobby ? <Button type="dashed" onClick={e => {
        // if (Account.uid) {
        let lobbyCode = v4()
        Network.createLobby(lobbyCode).then(v => { // host a lobby
          Network.prepare(Account.id)
          copySocket(lobbyCode)
          setLobby(lobbyCode)
        }).catch(e => window.warning("Could not create lobby, try again later"))
        // }
        // else
        //   window.warning("You must be logged in and have an open project to create a lobby (Your players don't though)")
      }
      }><span className="text-blue">Host Lobby</span></Button> :
        <Button type="dashed" onClick={e => window.notify("Lobby code : " + Network.lobby)}><span className="text-white">Lobby code</span></Button>}

      {!lobby && <Button type="dashed" onClick={e => {
        window.ask({ title: "Paste join code", subtitle: "Enter in the lobby code of the party you wish to join" }, res => {
          // now attempt to connect
          Network.joinLobby(res).then(v => setLobby(res))
        })}}>
        <span className="text-blue">Join Lobby</span>
      </Button>}
    </div>

    {/* <video width={opts.width ?? 180} height={audio && video ? opts.height ?? 120 : 0} ref={mediaRef} /> */}

    <div className="row-reverse">
      <Button
        className="click"
        type="primary"
        variant="text"
        onClick={e => window.ask({title: "Enter New Temporary Username", value: name}, username=>Account.setName(username))}
      >
        {name}
      </Button>
    </div>
    {playerChips}
  </div>
  // return <Box />
}