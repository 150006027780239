import React, { useEffect } from 'react'
import { Column, Row, Button } from '../UI'
import { Modal, Typography } from 'antd'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icons from '@fortawesome/free-solid-svg-icons'

const iconList = Object
  .keys(Icons)
  .filter(key => key !== "fas" && key !== "prefix")
  .map(icon => Icons[icon])

library.add(...iconList)

const icons = {
  organization: [
    "folder",
    "bookmark",
    "globe",
    "address-book",
    "atlas",
    "sitemap",
    "circle",
    "archive",
    "calendar-alt",
    "square",
    "map-marker",
    "file",
    "certificate",
    "play"
  ],
  events: [
    "bolt",
    "cloud",
    "cloud-rain",
    "cloud-showers-heavy",
    "smog",
    "snowflake",
    "sun",
    "temperature-high",
    "temperature-low",
    "wind",
    "ban",
    "bell",
    "exclamation",
    "exclamation-triangle",
    "radiation",
    "lock",
    "lock-open",
    "heart",
    "thumbs-up",
    "thumbs-down",
    "question-circle",
    "hand-holding-water",
    "leaf",
    "hand-holding-usd",
    "hand-holding-heart",
    "glass-cheers",
    "fire-alt",
    "heart-broken",
    "flag",
    "flag-checkered",
  ],
  locations: [
    "chess-rook",
    "anchor",
    "archway",
    "building",
    "campground",
    "church",
    "city",
    "dungeon",
    "hospital",
    "hotel",
    "igloo",
    "industry",
    "landmark",
    "monument",
    "store-alt",
    "torii-gate",
    "warehouse",
    "tree",
    "mountain",
    "broadcast-tower",
    "utensils",
    "satellite-dish",
    "road",
    "moon",
    "shoe-prints",
    "water",
    "umbrella-beach"
  ],
  beings: [
    "fish",
    "horse-head",
    "snowman",
    "dragon",
    "spider",
    "paw",
    "robot",
    "user",
    "user-friends",
    "users",
    "user-astronaut",
    "user-secret",
    "user-tie",
    "bug",
    "user-md",
    "user-injured",
    "user-graduate",
    "baby",
    "child",
    "crow"
  ],
  things: [
    "bullhorn",
    "coffee",
    "book",
    "book-dead",
    "bone",
    "drumstick-bite",
    "ice-cream",
    "seedling",
    "beer",
    "binoculars",
    "balance-scale",
    "bicycle",
    "bed",
    "briefcase",
    "compass",
    "dice",
    "dice-d20",
    "money-bill-wave",
    "map",
    "guitar",
    "gem",
    "tablet-alt",
    "flask",
    "lightbulb",
    "life-ring",
    "medkit",
    "microchip",
    "trash",
    "key",
    "mask",
    "camera",
    "envelope",
    "scroll",
    "umbrella",
    "box",
    "glasses",
    "gift",
    "wine-glass-alt",
    "cocktail",
    "glass-whiskey",
    "gas-pump",
    "bible",
    "book-medical",
    "tv",
    "mortar-pestle",
    "dumpster",
    "feather-alt",
    "weight-hanging",
    "sign",
    "ring"
  ],
  equipment: [
    "bomb",
    "shield-alt",
    "gavel",
    "meteor",
    "fist-raised",
    "ring",
    "graduation-cap",
    "tshirt",
    "hat-wizard",
    "hat-cowboy",
    "mitten",
    "hard-hat",
  ],
  vehicles: [
    "motorcycle",
    "bus-alt",
    "car-side",
    "shuttle-van",
    "truck-pickup",
    "truck",
    "truck-monster",
    "fighter-jet",
    "helicopter",
    "subway",
    "tram",
    "train",
    "snowplow",
    "space-shuttle",
    "satellite",
    "rocket"
  ],
  // "building": [
  //   "book",
  //   "user",
  //   "users",
  //   "map-marker",
  //   "map-signs",
  //   "globe-americas",
  //   "star",
  //   "calendar-alt",
  //   "exclamation",
  //   "exclamation-triangle",
  //   "dungeon",
  //   "bahai",
  //   "award",
  //   "bacterium",
  //   "balance-scale",
  //   "ban",
  //   "archway",
  //   "anchor",
  //   "beer",
  //   "bolt",
  //   "bomb",
  //   "bone",
  //   "biohazard",
  //   "bug",
  //   "car-side",
  //   "bus",
  //   "fighter-jet",
  //   "campground",
  //   "atom",
  //   "dice",
  //   "dice-d20",
  //   "church",
  //   "city",
  //   "flag",
  //   "fist-raised",
  //   "gavel",
  //   "key",
  //   "mobile-alt",
  //   "money-bill-wave",
  //   "monument",
  //   "motorcycle",
  //   "radiation",
  //   "shoe-prints",
  //   "skull-crossbones",
  //   "space-shuttle",
  //   "user-secret",
  //   "wine-bottle",
  //   "tools",
  // ],
}


Object.keys(icons).forEach(key => icons[key].sort())

let select, close
const ui = Object.keys(icons).map((d, key) => <Column key={key} className="gaps-v">
  <Typography variant="caption">{d.toLocaleUpperCase()}</Typography>
  <Row even wrap>
    {icons[d].map(i => <Button mouseEnterDelay={2} style={{width: "45px"}} className="foreground" title={i} small key={i} primary onClick={e => { select(i); close(); e.stopPropagation() }}>
      <FontAwesomeIcon icon={['fas', i]} />
    </Button>)}
  </Row>
</Column>)

export default function IconPicker({ visible, onSelect, onClose }) {
  // const style = { margin: "8px", padding: "8px", maxWidth: "30vw", fontSize: "0.8rem", background: "#090909" }
  if (visible) {
    select = onSelect
    close = onClose
  }
  useEffect(() => {
    if (visible) {
      select = onSelect
      close = onClose
    }
  }, [visible])

  return <Modal width={650} visible={visible} footer={false} onCancel={onClose}>
    {ui}
  </Modal>
}

export function Icon(opts) {
  return <FontAwesomeIcon {...opts} icon={['fas', opts.i]} />
}