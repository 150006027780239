import React, { useState, useEffect } from 'react'

import { DeleteFilled } from '@ant-design/icons'
import { Column, Row, Scroll, Button } from '../../UI'

import useEvents from '../hooks/useEvents'
import FileDrop from '../wrappers/FileDrop'
import Manager from '../state/FileManager'

export function FileFolder(opts){
  const [files, setFiles] = useState([...Manager.files])
  useEvents(["ready", "change"], () => setFiles([...Manager.files]), Manager)

  useEffect(() => {
    if (!Manager.loaded)
      Manager.rebuildFiles()
  }, [])

  const onSelect = (e, file) => {
    if (opts.onSelect)
      opts.onSelect(e, file)
  }

  const onDrop = (p, m, c) => {
    const item = m.getItem()
    if (item.files)
      for (const file of item.files) {
        Manager.upload(file)
        break // until you get bulk upload
      }
  }

  return <FileDrop onDrop={onDrop} className="fit-x fit-y" style={{display: "flex"}}>
    <Row wrap>
      {files.map(file => <Column relative onClick={e => onSelect(e, file)} className="background rounded gaps card-outline card-click card-shadow" style={{ overflow: "hidden", width: "100px" }}>
        <img width={100} height={100} src={file.src} className="cover" />
        <Row fitX absolute bottom className="black-tint">
          <div className="pad" style={{ maxWidth: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
            {file.title}
          </div>
        </Row>
        <Button className="black-tint absolute left top" small onClick={e => { window.ask({ type: "confirm", title: "Delete File", subtitle: "This operation can't be undone" }, e => { Manager.delete(file.name) }); e.stopPropagation() }}>
          <DeleteFilled className="text-red" />
        </Button>
      </Column>)}
    </Row>
  </FileDrop>
}

export default ({onSelect}) => {
  const [files, setFiles] = useState([...Manager.files])
  useEvents(["ready", "change"], () => setFiles([...Manager.files]), Manager)

  return <Row fit>
    {/* <Column width="20%" className="black-tint">      
      <Button>
        All Files (23)
      </Button>
      <Scroll>
        {files.map(file =><Row>
          <div className="pad-h" style={{ maxWidth: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
            {file.title}
          </div>
        </Row>)}
      </Scroll>
      <Row>
        <Button grow className="foreground">
          Add Folder
        </Button>
      </Row>
    </Column> */}
    <Column grow>
      <Scroll>
        <FileFolder onSelect={onSelect}/>
      </Scroll>
      <Row reverse>
        <Button className="foreground" onClick={e => document.getElementById("raised-button-file").click()}>
          <input
            id="raised-button-file"
            style={{ display: 'none' }}
            accept="image/*"
            multiple={true}
            type="file"
            onChange={e => Manager.upload(e.target.files[0])}
          />
          Upload File
        </Button>
      </Row>
    </Column>
  </Row>
}