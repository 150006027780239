import Resource from './Resource'
import { v4 } from 'uuid'

import Account from '../../core/state/Account'
import Logs from '../state/Logs'

export function scope(str, start = "{", end = "}") {
  // returns the contents within 
  let match = str.match(start === "(" ? /\(/ : start)
  if (match) {
    let scanIndex = match.index || 0
    let stack = []

    while (scanIndex < str.length) {
      if (str[scanIndex] === start)
        stack.push(start)
      else if (str[scanIndex] === end)
        stack.pop()

      if (!stack.length)
        return [str.substring(match.index + 1, scanIndex), match.index + 1, scanIndex]
      scanIndex++
    }
  }
}


export function reverseScan(str, start = "{", end = "}") {
  // returns the contents within 
  let scanIndex = str.length - 1
  let stack = []
  while (scanIndex >= 0) {
    if (str[scanIndex] === end)
      stack.push(end)
    else if (str[scanIndex] === start)
      stack.pop()

    if (!stack.length)
      return [str.substring(scanIndex + 1, str.length - 1), scanIndex, str.length - 1]
    scanIndex--
  }
}


function Ask(title, fallback, choices, event) {
  return new Promise((res, rej) => window.ask({ title, allowEmpty: true }, txt => res(txt || "0"), txt => res("0")))
}

function fillSource(eq, event, context) {
  let equation = eq
  // fill in #context
  const src = /#([\d\w_]+|[^\x00-\x7F]+)/iu
  let srcMatch = equation.match(src)
  while (srcMatch) {
    const [match, key] = srcMatch
    const term = context.source[clean(key)]
    equation = equation.replace(match, term && isNaN(term) ? `(${term})` : term || 0)
    srcMatch = equation.match(src)
    // console.log(srcMatch)
  }
  return equation
}

function fillTarget(eq, event, context) {
  let equation = eq
  // fill in @context
  const target = /@([\d\w_]+|[^\x00-\x7F]+)/iu
  let tgtMatch = equation.match(target)
  while (tgtMatch) {
    const [match, key] = tgtMatch
    const term = context.target[clean(key)]
    equation = equation.replace(match, term && isNaN(term) ? `(${term})` : term || 0)
    tgtMatch = equation.match(target)
    // console.log(tgtMatch)
  }
  return equation
}

function fillDefines(eq, event, context) {
  let equation = eq
  // fill in $roll context
  const defines = /\$([\d\w_]+|[^\x00-\x7F]+)/iu
  let defineMatch = equation.match(defines)
  while (defineMatch) {
    const [match, key] = defineMatch
    const term = event.defines[clean(key)]
    if (isNaN(term) && term && term.length && term.charAt(0) === "{" && term.charAt(term.length-1) === "}")
      equation = equation.replace(match, term.substring(1,term.length-1).replace(/\[@\]/g, "@").replace(/\[#\]/g, "#"))
    else
      equation = equation.replace(match, term && isNaN(term) ? `(${term})` : term || 0)
    defineMatch = equation.match(defines)
    // console.log(defineMatch)
  }

  return equation
}

function fillTags(eq, event, context) {
  let equation = eq
  // fill in $roll context
  const tags = /%([\d\w_]+|[^\x00-\x7F]+)/iu
  let tagMatch = equation.match(tags)
  while (tagMatch) {
    const [match, key] = tagMatch
    const term = event.tags[clean(key)]
    equation = equation.replace(match, term && isNaN(term) ? `(${term})` : term || 0)
    tagMatch = equation.match(tags)
  }

  return equation
}

function fillComponents(eq, event, context) {
  let equation = eq
  if (context.source && context.source._components)
    for (const container in context.source._components) {
      const reg = new RegExp(`([#])${container}\\((.*)\\)`, "iu")
      let match = equation.match(reg)
      while (match) {
        let key = match[2]
        if (context.components && context.components[container] && context.components[container].map && context.components[container].map[key])
          key = context.components[container].map[key].profileID
          
        const term = context.source._components[container][key]
        equation = equation.replace(match[0], term && isNaN(term) ? `(${term})` : term || 0)
        match = equation.match(reg)
      }
    }

  if (context.target && context.target._components)
    for (const container in context.target._components) {
      const reg = new RegExp(`([@])${container}\\((.*)\\)`, "iu")
      let match = equation.match(reg)
      while (match) {
        let key = match[2]
        
        if (context.components && context.components[container] && context.components[container].map && context.components[container].map[key])
          key = context.components[container].map[key].profileID
        const term = context.target._components[container][key]
        equation = equation.replace(match[0], term && isNaN(term) ? `(${term})` : term || 0)
        match = equation.match(reg)
      }
    }

  return equation
}

function fill(eq, event, context) {
  let equation = eq
  equation = fillComponents(equation, event, context)
  equation = fillSource(equation, event, context)
  equation = fillTarget(equation, event, context)
  equation = fillDefines(equation, event, context)
  equation = fillTags(equation, event, context)
  return equation
}


export const dicePool = {
  "keys": {
    "a": {
      "name": "Advantage",
      "img": "/dice/advantage.png",
      "swimg": "/dice/advantage_sw.png"
    },
    "f": {
      "name": "Failure",
      "img": "/dice/failure.png",
      "swimg": "/dice/failure_sw.png"
    },
    "s": {
      "name": "Success",
      "img": "/dice/success.png",
      "swimg": "/dice/success_sw.png"
    },
    "t": {
      "name": "Threat",
      "img": "/dice/threat.png",
      "swimg": "/dice/threat_sw.png"
    },
    "tri": {
      "name": "Triumph",
      "img": "/dice/triumph.png",
      "swimg": "/dice/triumph_sw.png"
    },
    "des": {
      "name": "Despair",
      "img": "/dice/despair.png",
      "swimg": "/dice/despair_sw.png"
    },
    "light": {
      "name": "Lightside",
      "img": "/dice/lightside.png",
      "swimg": "/dice/lightside.png"
    },
    "dark": {
      "name": "Darkside",
      "img": "/dice/darkside.png",
      "swimg": "/dice/darkside.png"
    }
  },
  "pool": {
    "proficiency": {
      "value": "d12",
      "display": {
        "background": "rgb(255,230,0)",
        "border": "1px solid rgba(0,0,0,0.87)",
        "color": "black"
      },
      "results": {
        "1": {
          "a": 2
        },
        "2": {
          "a": 1
        },
        "3": {
          "a": 2
        },
        "4": {
          "tri": 1,
          "s": 1
        },
        "5": {
          "s": 1
        },
        "6": {
          "s": 1,
          "a": 1
        },
        "7": {
          "s": 1
        },
        "8": {
          "s": 1,
          "a": 1
        },
        "9": {
          "s": 2
        },
        "10": {
          "s": 1,
          "a": 1
        },
        "11": {
          "s": 2
        }
      }
    },
    "ability": {
      "value": "d8",
      "display": {
        "background": "rgb(80,185,75)",
        "border": "1px solid rgba(0,0,0,0.87)"
      },
      "results": {
        "1": {
          "s": 1
        },
        "2": {
          "a": 1
        },
        "3": {
          "s": 1,
          "a": 1
        },
        "4": {
          "s": 2
        },
        "5": {
          "a": 1
        },
        "6": {
          "s": 1
        },
        "7": {
          "a": 2
        }
      },
    },
    "boost": {
      "value": "d6",
      "display": {
        "background": "rgb(135,215,245)",
        "border": "1px solid rgba(0,0,0,0.87)"
      },
      "results": {
        "1": {
          "s": 1,
          "a": 1
        },
        "2": {
          "a": 1
        },
        "3": {
          "a": 2
        },
        "4": {
          "s": 1
        }
      },
    },
    "challenge": {
      "value": "d12",
      "display": {
        "background": "rgb(230,25,55)",
        "border": "1px solid rgba(0,0,0,0.87)"
      },
      "results": {
        "1": {
          "t": 2
        },
        "2": {
          "t": 1
        },
        "3": {
          "t": 2
        },
        "4": {
          "t": 1
        },
        "5": {
          "f": 1,
          "t": 1
        },
        "6": {
          "f": 1
        },
        "7": {
          "f": 1,
          "t": 1
        },
        "8": {
          "f": 1
        },
        "9": {
          "des": 1,
          "f": 1
        },
        "10": {
          "f": 2
        },
        "11": {
          "f": 2
        }
      },
    },
    "difficulty": {
      "value": "d8",
      "display": {
        "background": "rgb(85,35,130)",
        "border": "1px solid rgba(0,0,0,0.87)"
      },
      "results": {
        "1": {
          "t": 1
        },
        "2": {
          "f": 1
        },
        "3": {
          "f": 1,
          "t": 1
        },
        "4": {
          "t": 1
        },
        "6": {
          "t": 2
        },
        "7": {
          "f": 2
        },
        "8": {
          "t": 1
        }
      },
    },
    "setback": {
      "value": "d6",
      "display": {
        "background": "#141414",
        "border": "1px solid rgba(0,0,0,0.87)"
      },
      "results": {
        "1": {
          "f": 1
        },
        "2": {
          "f": 1
        },
        "3": {
          "t": 1
        },
        "4": {
          "t": 1
        }
      },
    },
    "force": {
      "value": "d12",
      "display": {
        "background": "white",
        "border": "1px solid rgba(0,0,0,0.87)",
        "color": "black"
      },
      "results": {
        "1": {
          "dark": 1
        },
        "2": {
          "light": 2
        },
        "3": {
          "dark": 1
        },
        "4": {
          "light": 2
        },
        "5": {
          "dark": 1
        },
        "6": {
          "light": 2
        },
        "7": {
          "dark": 1
        },
        "8": {
          "light": 1
        },
        "9": {
          "dark": 1
        },
        "10": {
          "light": 1
        },
        "11": {
          "dark": 1
        },
        "12": {
          "dark": 2
        }
      },
    }
  }
}

function define(eq, event, context) {
  const regex = /(^[%$@#_])([^$%#@}\-+\/\*=]+)([-+/*]?=)/

  let cmd = eq
  let match = cmd.match(regex)
  if (match) {
    const key = clean(match[2])
    const contents = replaceStrings(cmd.substring(match.index + match[0].length, cmd.length), context)
    // console.log(`${match[1]}${key}`, contents)
    if (match[1] === "$" && event.defines[key] == null)
      event.defines[key] = contents
    else if (match[1] === "_") {
      event.api[key] = contents
      if (match[2] === "swffg" || match[2] === "genesis") {
        // resolve the pool
        const rolls = contents.split("|")
        for (const rollStr of rolls) {
          const rollMatch = rollStr.split("=")
          if (rollMatch.length > 1) {
            const filled = event.eval(fill(rollMatch[1], event, context))
            const dicePoolData = dicePool.pool[rollMatch[0]]
            if (dicePoolData && !isNaN(filled)) {
              roll(`${filled || 1}${dicePoolData.value}`, event, context)
              // disect the roll and save defines for the answers
              const rollData = event.rolls[event.rolls.length-1]
              rollData.diceKey = rollMatch[0]
              for (const rollValue of rollData.rolled)
                for (const key in dicePoolData.results[rollValue]) {
                  const defineKey = `_${dicePool.keys[key].name.toLowerCase()}`
                  console.log(defineKey)
                  event.defines[defineKey] = String(parseInt(event.defines[defineKey] || 0) + parseInt(dicePoolData.results[rollValue][key]))
                }
            }
          }
        }
      }
    }
    else if (match[1] === "%")
      event.tags[key] = contents
    else if (match[1] === "#")
      event.source[key] = contents
    else if (match[1] === "@")
      event.target[key] = contents

    cmd = cmd.replace(cmd, `${match[1]}${key}`)
    match = cmd.match(regex)
  }
  
  return event
}

function clean(key) { // cleans a key
  return key.trim().replace(/\s/g, "_").toLowerCase()
}

async function undefines(event, context) {
  const keys = /\$([\d\w_]+|[^\x00-\x7F]+)/igu
  for (const key in event.defines) {
    let cmd = event.defines[key]
    let keyMatch = keys.exec(cmd)
    while (keyMatch) {
      const key = clean(keyMatch[1])
      if (event.defines[key] === undefined && key[0] !== "_")
        event.defines[key] = await Ask(key)
      keyMatch = keys.exec(cmd)
    }
  }

  for (const key in event.tags) {
    let cmd = event.tags[key]
    let keyMatch = keys.exec(cmd)
    while (keyMatch) {
      const key = clean(keyMatch[1])
      if (event.defines[key] === undefined && key[0] !== "_")
        event.defines[key] = await Ask(key)
      keyMatch = keys.exec(cmd)
    }
  }

  for (const cmd of event.text) {
    let keyMatch = keys.exec(cmd)
    while (keyMatch) {
      const key = clean(keyMatch[1])
      if (event.defines[key] === undefined && key[0] !== "_")
        event.defines[key] = await Ask(key)
      keyMatch = keys.exec(cmd)
    }
  }
  return event
}

function roll(eq, event, context) {
  let equation = eq
  let evalRegex = /{([^}]*)}d{?([^}]+)}/i
  let evalMatch = equation.match(evalRegex)
  while (evalMatch) {
    const [full, eval1, eval2] = evalMatch
    equation = equation.replace(full, `${event.eval(eval1)}d${event.eval(eval2)}`)
    evalMatch = equation.match(evalRegex)
  }


  let diceRegex = /(\d*)d([\dF]+)((([kd])([lh])?)?([\d]*))?(!(([><])?([\d]+))?p?)?/i
  
  let diceMatch = equation.match(diceRegex)
  while (diceMatch) {
    const [full, count, facesRaw] = diceMatch
    let faces = facesRaw
    if (facesRaw === "F")
      faces = 3

    const type = diceMatch[5]
    const high = diceMatch[6]
    const number = (diceMatch[7]) || 1
    const exploding = diceMatch[8]
    const condition = diceMatch[10]
    const expOn = diceMatch[11] || faces
    const pen = diceMatch[12]

    let rolled = []
    let tracked = {} // order is preserved

    function makeRoll() {
      return Math.ceil(faces * Math.random())
    }
    function explode(penetrate) {
      let roll = makeRoll()

      if (exploding) {
        if (penetrate)
          roll--
        if (!condition) {
          if (roll === expOn)
            roll += explode(penetrate)
        }
        else if (condition === ">" && roll > expOn) {
          roll += explode(penetrate)
        }
        else if (condition === "<" && roll < expOn) {
          roll += explode(penetrate)
        }
      }

      return roll || 0
    }

    for (let i = 0; i < (count || 1); i++) {
      let roll = explode(pen)
      if (facesRaw === "F")
        rolled.push(roll-2)
      else
        rolled.push(roll)
      if (type == null)
        tracked[i] = true
    }

    if (type != null) {
      const working = Object.keys(rolled).sort((a, b) => { return parseInt(rolled[a]) - parseInt(rolled[b]) })
      // console.log(rolled)
      // console.log(working)
      const len = working.length
      for (let i = 0; i < len; i++) {
        const rollIndex = working[i]
        if (type === "k") { // keep
          if (high === "l")
            tracked[rollIndex] = (i < number) ? true : false
          else if (high === "h")
            tracked[rollIndex] = (i >= len - number) ? true : false
          else
            tracked[i] = (i < number) ? true : false
        }
        else { // drop
          if (high === "h")
            tracked[rollIndex] = (i >= len - number) ? false : true
          else if (high === "l")
            tracked[rollIndex] = (i < number) ? false : true
          else
            tracked[i] = (i < number) ? false : true
        }
      }
    }

    equation = equation.replace(full, `[%[${event.rolls.length}]%]`)
    event.rolls.push({ roll: full, rolled, tracked })
    diceMatch = equation.match(diceRegex)
  }
  return equation
}

function replaceStrings(equation, context) {
  let result = equation
  for (const key in context.source) 
    if (context.source[key] != null && isNaN(context.source[key])) {
      const reg = new RegExp(`#${key}`, "igu")
      let match = reg.exec(result)
      while (match) {
        result = result.replace(match[0], context.source[key])
        match = reg.exec(result)
      }
    }

  for (const key in context.target) 
    if (context.target[key] != null && isNaN(context.target[key])) {
      const reg = new RegExp(`@${key}`, "igu")
      let match = reg.exec(result)
      while (match) {
        result = result.replace(match[0], context.target[key])
        match = reg.exec(result)
      }
    } 

  return result
}

async function preProcess(event, context) {
  // process variables
  const { equation } = event

  let eq = equation
  const { bank } = context
  if (bank) {
    const { bank } = context
    const reg = /^!([\w_\d]+)/igm
    let match = reg.exec(eq)
    while (match) {
      eq = eq.replace(match[0], bank[match[1]].script)
      match = reg.exec(eq)
    }
  }

  const stack = eq.split("\n")
  for (const cmd of stack) {
    if (cmd[0] === ">")
      event.text.push(replaceStrings(cmd.substring(1, cmd.length), context))
    else if (cmd[0] === "|")
      event.pipes.push(replaceStrings(cmd.substring(1, cmd.length), context))
    else {
      define(cmd, event, context)
      // define_inline(cmd, event, context)
    }
  }

  // fill in the asks
  if (!context.quiet)
    await undefines(event, context)

  for (const key in event.defines)
    event.defines[key] = roll(fill(event.defines[key], event, context), event, context)

  for (const key in event.tags)
    event.tags[key] = roll(fill(event.tags[key], event, context), event, context)

  for (const key in event.source)
    event.source[key] = roll(fillDefines(event.source[key], event, context), event, context)

  for (const key in event.target)
    event.target[key] = roll(fillDefines(event.target[key], event, context), event, context)


  for (const key in event.text) {
    let workingStr = event.text[key]
    let scopeMatch = scope(workingStr)
    while (scopeMatch) {
      const [full, start, end] = scopeMatch
      const scopeEq = roll(full, event)
      event.text[key] = event.text[key].replace(`{${full}}`, `{${scopeEq}}`)
      workingStr = workingStr.substring(end + 1, workingStr.length)
      scopeMatch = scope(workingStr)
    }
    event.text[key] = fill(event.text[key], event, context)
  }

  if (event["api"].scramble) {
    const newText = event.text
    const regex = /([\w]+)/ig
    const characters = 'bcdfghjklmnpqrstvwxz'
    const vowels = 'aeiouáàãâéèêíìîõóòôúùû'

    for (let i=0; i<newText.length; i++) {
      let match = regex.exec(newText[i])
      while (match) {
        let scrambled = match[0] 
        let replace = ""
        for (let j=0; j<scrambled.length; j++) {
          const upper = Math.random() > 0.5
          if (vowels.match(scrambled[j].toLowerCase()))
            replace += upper ? vowels.charAt(Math.floor(Math.random() * vowels.length)).toUpperCase() : vowels.charAt(Math.floor(Math.random() * vowels.length))
          else
            replace += upper ? characters.charAt(Math.floor(Math.random() * characters.length)).toUpperCase() : characters.charAt(Math.floor(Math.random() * characters.length))
        }
        
        newText[i] = newText[i].replace(match[0], replace)
        match = regex.exec(newText[i])
      }
    }
    event.meta.scrambled = newText
  }

  return event
}

export default class Event extends Resource {
  api = {} // api hooks for deeply embedded changes
  defines = {} // values given for the ask script
  source = {} // effects to apply to the source
  target = {} // effects to apply to the target
  rolls = [] // Grouping of dice with a label
  tags = {} // counters signaling unique events (like success/failure)
  pipes = [] // effects to apply to the profile's components (adding/removing items etc etc)
  text = []
  meta = {}

  constructor(ctx = {}) {
    super(ctx)
    this.uid = ctx.uid || v4()
    this.equation = ctx.equation
    this.meta = { name: ctx.name || Account.name, creator: ctx.creator || Account.uid, created: Date.now() }

    this.setMaxListeners(Infinity) // Potentially thousands of references due to wiki
  }

  roll(equation, context = { source: {}, target: {} }) { // starts over using the original equation
    this.defines = {}// values given for the ask script
    this.source = {} // effects to apply to source/target
    this.target = {}
    this.rolls = [] // Grouping of dice with a label
    this.tags = {} // counters signaling unique events (like success/failure)
    this.pipes = []
    this.equation = equation // original equation used for the roll
    this.text = []
    return preProcess(this, context)
  }

  fillRoll(equation = "") {
    // fills in the gaps on everything
    // handle counting
    let evl = String(equation)
    const count = /(\[%\[([\d]+)\]%\])(\|([><=]*)(\d+))/i
    const roll = /\[%\[([\d]+)\]%\]/i

    let countMatch = evl.match(count)
    let rollMatch
    while (countMatch) {
      let total = 0
      let [full, roll, i, opseg, op, amt] = countMatch
      const { rolled, tracked } = this.rolls[i]
      if (op && op.length) 
        for (let i = 0; i < rolled.length; i++) {
          if (tracked[i])
            if (op === ">" && rolled[i] > amt)
              total++
            else if (op === "=" && rolled[i] === amt)
              total++
            else if (op === "<" && rolled[i] < amt)
              total++
            else if (op === ">=" && rolled[i] >= amt)
              total++
            else if (op === "<=" && rolled[i] <= amt)
              total++
        }
      else
        total = rolled[Math.max(parseInt(amt)-1, 0)]

      evl = evl.replace(full, `${total || 0}`)
      countMatch = evl.match(count)
    }
    
    rollMatch = evl.match(roll)
    while (rollMatch) {
      const [full, index] = rollMatch
      const { rolled, tracked } = this.rolls[index]
      let total = 0
      for (let i = 0; i < rolled.length; i++)
        if (tracked[i])
          total += parseInt(rolled[i])

      evl = evl.replace(full, total)
      rollMatch = evl.match(roll)
    }

    // cleanup results such as (((320)))
    const dupe = /\(([\d]+)\)/g
    let cleanMatch = dupe.exec(evl)
    while (cleanMatch) {
      evl = evl.replace(cleanMatch[0], `${cleanMatch[1]}`)
      cleanMatch = dupe.exec(evl)
    }

    const excess = /([-\*+\/]\s*)\(([\d]+)\)/
    let excessMatch = evl.match(excess)
    while (excessMatch) {
      evl = evl.replace(excessMatch[0], `${excessMatch[1]}${excessMatch[2]}`)
      excessMatch = evl.match(excess)
    }
    
    return evl
  }

  fill(equation = "", context = { source: {}, target: {} }) {
    return fill(this.fillRoll(equation), this, context)
  }

  eval(equation = "", context = { source: {}, target: {} }) {
    try {
      const res = eval(`let fn =()=>{
        const {min, max, floor, ceil, round, PI, sign, sqrt, abs} = Math
        let maxCount = 0
        const copy = (string, count=1)=>{
          let output = ""
          for (let i=0; i<count; i++) {
            if (maxCount > 100) {
              console.warn("TOO MANY INSTRUCTIONS TERMINATING")
              return "ERROR"
            }
            output += string
            maxCount++
          }
          return output
        }
        return ${String(this.fill(equation, context)).replace(/--/ig, "+").replace(/\+\+/ig, "+")}
      }; fn()`)
      if (!context.code)
        if (res instanceof Object)
          return equation
      return res
    }
    catch (e) {
      console.warn(e)
      return equation
    }
  }

  refresh(equation) {
    // just rebuilds chat
  }

  record() {
    // send to chat log
    Logs.record(this.uid)
  }

  apply(sourceIDs, targetIDs) {
    // apply the event to the source/target
  }

  pack() {
    return {
      uid: this.uid,
      api: this.api,
      defines: this.defines,
      source: this.source,
      target: this.target,
      rolls: this.rolls,
      tags: this.tags,
      pipes: this.pipes,
      equation: this.equation,
      text: this.text,
      date: this.date,
      meta: this.meta,
    }
  }

  serialize() {
    return JSON.stringify(this.pack())
  }

  load(json) {
    this.children = []
    this.uid = json.uid || this.uid
    this.api = json.api || {}
    this.defines = json.defines || {}
    this.source = json.source || {}
    this.target = json.target || {}
    this.rolls = json.rolls || []
    this.tags = json.tags || {}
    this.pipes = json.pipes || []
    this.equation = json.equation
    this.text = json.text || []
    this.date = json.date || Date.now()
    this.meta = json.meta || this.meta
    this.loaded = true
    this.emit("ready")
  }

  deserialize(rawJSON) {
    this.load(JSON.parse(rawJSON))
  }
}