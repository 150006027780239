var mods = { Scripts: {}, Formats: {}, Keyphrases: {} }

let solve = (equation, rolls, context) => {
  try {
    let mega = JSON.stringify({ equation, rolls, context })
    return eval(`let fn =()=>{
            let max = Math.max
            let min = Math.min
            let floor = Math.floor
            let ceil = Math.ceil
            let b = (content)=>{return "[" + content + "]"}
            let a = (content)=>{return "@" + content}
            let d = (faces, amt)=>{return (amt || "") + "d" + (faces || 2)}
            let script = (mod, ...args)=>{return mods.Scripts[mod.toLowerCase()].execute(`+ mega + `, ...args)}
            return `+ equation + `
        }; fn()`)
  }
  catch (e) {
    //console.log(e)
    return equation
  }
}
//=[new Array(b('h1')+"Damage Applied", "wounds-="+b('max(d10-'+a('t_bonus')+'-max('+a('armor_body')+'-3, 0), 0)'))]

let roll = (eq, context) => {
  let equation = eq
  let diceRegex = /(\d*)d(\d+)([k|d]([l|h])?[\d+])?/i
  let match = equation.match(diceRegex)
  let rolls = ""
  while (match) {
    let total = 0
    rolls += match[0] + "="
    for (let i = 0; i < (match[1] || 1); i++) {
      let roll = Math.ceil(match[2] * Math.random())
      total += roll
      rolls += roll + "+"
    }
    rolls = rolls.substr(0, rolls.length - 1) + ","
    equation = equation.replace(match[0], total)
    match = equation.match(diceRegex)
  }
  rolls = rolls.substr(0, rolls.length - 1)
  return ({ equation, rolls })
}

let resolveAsk = (eq, context, cb) => {
  let equation = String(eq)
  let diceRegex = /ask\(([^)]+)\)/i
  function resolve(depth) {
    let match = equation.match(diceRegex)
    if (match) {
      let args = solve(`[` + match[1] + `]`)
      if (Array.isArray(args)) {
        let prompt = { label: args[0] }
        if (args.length > 1) {
          prompt.equation = args[1]
        }
        window.ask(prompt, (newEquation) => {
          equation = equation.replace(match[0], newEquation)
          resolve((depth || 0) + 1)
        },
          (newEquation) => {
            equation = equation.replace(match[0], newEquation || prompt.equation || "0")
            resolve((depth || 0) + 1)
          })
      }
      else {
        cb(equation)
      }
    }
    else {
      cb(equation)
    }
  }
  resolve()
}

let rollcb = (eq, context, cb) => {
  let equation = eq
  let diceRegex = /(\d*)d(\d+)([k|d]([l|h])?[\d+])?/i
  let rolls = ""
  function resolve(depth) {
    let match = equation.match(diceRegex)
    if (match) {
      if (window.manualRolling) {
        window.ask({ label: match[1] + "d" + match[2] }, (newEquation) => {
          equation = equation.replace(match[0], newEquation)
          rolls += match[0] + "==" + newEquation + " "
          resolve((depth || 0) + 1)
        },
          () => {
            let total = 0
            rolls += match[0] + "="
            for (let i = 0; i < (match[1] || 1); i++) {
              let roll = Math.ceil(match[2] * Math.random())
              total += roll
              rolls += roll + "+"
            }
            rolls = rolls.substr(0, rolls.length - 1) + ","
            equation = equation.replace(match[0], total)
            resolve((depth || 0) + 1)
          })
      }
      else {
        let total = 0
        rolls += match[0] + "="
        for (let i = 0; i < (match[1] || 1); i++) {
          let roll = Math.ceil(match[2] * Math.random())
          total += roll
          rolls += roll + "+"
        }
        rolls = rolls.substr(0, rolls.length - 1) + ","
        equation = equation.replace(match[0], total)
        resolve((depth || 0) + 1)
      }
    }
    else {
      rolls = rolls.substr(0, rolls.length - 1)
      cb({ equation, rolls })
    }
  }
  resolve()
}

let fill = (str, context, depth) => {
  context = context || {}
  if (isNaN(str)) {
    let math = /(`([^`]+)`)/i
    let data = /([#][\w_|\d]+)/i
    let match = str.match(data)
    // console.log(match)
    while (match) {
      let key = match[0].replace("#", "").toLowerCase()

      let value = key
      if (isNaN(key)) {
        let mathMatch = String(context[key]).match(math)
        if (mathMatch) {
          if (depth > 1000) {
            alert(key + " infinite loop detected")
            return '"Error"'
          }
          let content = fill(mathMatch[2], context, (depth || 0) + 1)
          try {
            value = solve(content)
          }
          catch (e) {
            value = content
          }
        }
        else {
          value = context[key]
        }
      }
      if (value === undefined || value === null)
        value = 0
      // console.log(value, match[0])
      str = str.replace(match[0], value)
      // console.log(str)
      match = str.match(data)
    }
  }
  return str
}

let resolve = (equation, context) => {
  // return solve(roll(fill(equation, context)).equation, null, context)
  return solve(fill(equation, context), null, context)
}


let resolveRoll = (equation, context) => {
  // return solve(roll(fill(equation, context)).equation, null, context)
  return solve(roll(fill(equation, context)).equation, null, context)
}

let exec = (equation, context, cb) => {
  let filled = fill(equation, context)
  resolveAsk(filled, context, (askedEQ) => {
    rollcb(askedEQ, context, (results) => {
      cb(solve(results.equation, results.rolls, context))
    })
  })
}

// execute returns a full object describing what happened
let execute = (equation, context, cb) => {
  let filled = fill(equation, context)
  resolveAsk(filled, context, (askedEQ) => {
    rollcb(askedEQ, context, (results) => {
      cb({ result: solve(results.equation, results.rolls, context), equation: filled, rolls: results.rolls })
    })
  })
}

export default {
  load: function (str) {
    try {
      mods = eval(`let fn = ()=>{
        const MOD = {}
        ${str}
        return MOD
      }
      fn()`)
      window.success(`${mods.Name} loaded successfully`)
    }
    catch (err) {
      console.warn(err)
      window.error(`${mods.Name} unable to load`)
    }
  },
  getFormats: function () { return mods.Formats },
  getScripts: function () { return mods.Scripts },
  getKeyphrases: function () { return mods.Keyphrases },
  fill,
  roll,
  solve,
  exec,
  resolve,
  resolveRoll,
  execute
}
