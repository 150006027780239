import EventEmitter from "events"

import n from './Names'

import { v4 } from 'uuid'
import { database } from './Firebase'

export default class User extends EventEmitter {
  // a generalized representation of players
  constructor(ctx = {}) {
    super(ctx)
    this.id = v4() // the player's session reference
    this.uid = ctx.uid // the player's acccount reference
    this.name = ctx.name ?? n.generate() //display name
    this.data = ctx.data ?? {} // general information regarding the player
    this.details = ctx.details || {} // more generic account information about the player
  }

  getAccountDetails() {
    if (!this.uid)
      return window.warning("User is not signed in")
    database.ref(`users/${this.uid}`).once("value").then(snap => {
      const val = snap.val()
      this.details = val
      this.emit("change")
    })
  }

  setName(newName) {
    this.name = newName
    this.emit("meta")
    this.emit("change")
  }
}