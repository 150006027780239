import React from 'react'
import { useFocused, useSelected } from "slate-react"
// import { Transforms } from 'slate'
// import { ReactEditor, useEditor } from 'slate-react'
// import { TextField, Tooltip } from "@material-ui/core"
// import { withStyles } from '@material-ui/core/styles'

// const CustomTooltip = withStyles(theme => ({
//   tooltip: {
//     maxWidth: window.mobilecheck() ? "80vw" : "30vw"
//   }
// }))(Tooltip)

export default opts => {
  const { attributes, children, element, style, child } = opts
  const selected = useSelected()
  const focused = useFocused()
  // const [editing, setEditing] = useState(false)
  // const editor = useEditor()

  const contextMenu = e => {
    //   if (rights < 40)
    //     return window.notify("Not Permitted")
    //   setEditing(true)
    //   e.preventDefault()
  }

  // const finishEditing = e => {
  //   const { ref } = attributes
  //   if (!ref || !ref.current)
  //     return window.error("Unable to resolve document")

  //   try {
  //     const path = ReactEditor.findPath(editor, ReactEditor.toSlateNode(editor, ref.current))
  //     Transforms.setNodes(editor, { mask: e.target.value }, { at: path, match: e => JSON.stringify(e) === JSON.stringify(element) })
  //     setEditing(false)
  //   }
  //   catch (e) {
  //     console.warn(e)
  //     window.error("Unable to resolve document")
  //   }
  //   e.preventDefault()
  // }

  // const onKeyDown = e => {
  //   if (e.key === "Enter") {
  //     e.target.blur()
  //     e.preventDefault()
  //     e.stopPropagation()
  //   }
  // }

  // const entry = <TextField
  //   label="Mask"
  //   defaultValue={element.mask || ""}
  //   autoFocus
  //   color="primary"
  //   onBlur={finishEditing}
  //   onKeyDown={onKeyDown}
  //   variant="filled"
  //   InputLabelProps={{ className: "white-subtitle" }}
  //   InputProps={{ className: "white-title" }}
  // />

  const CHILD = child
  return <span {...attributes} style={style} onContextMenu={contextMenu}>
    {/* <CustomTooltip interactive={true} open={editing} arrow={true} placement="bottom" title={editing ? entry : null}> */}
    <span contentEditable={false}>
      <CHILD {...element} children={undefined} attributes={attributes} selected={selected} focused={focused}/>
    </span>
    {/* </CustomTooltip> */}
    {children}
  </span>
}