import React, { useState } from 'react'
import { Typography, Input } from 'antd'
import { DeleteFilled, PlusSquareFilled, WarningFilled } from '@ant-design/icons'
import { Column, Row, Scroll, Button } from '../UI'
import { ProfileName } from './Profile'

import useProject, { hasRight, getProject, ProjectProvider } from './hooks/useProject'
import Resources from './state/Resources'
import Mention from './slate/inline/Mention'
import { WizardProvider } from "./hooks/useWizard"
import { WizardManager, WizardName } from "./Wizard"
import { EventBuilder } from "./Events"
const { Profiles } = Resources

function WizardManagerWrap(opts){
  return <ProjectProvider projectID={opts.projectID}>
    <WizardManager {...opts}/>
  </ProjectProvider>
}

export default function TemplateList({ style }){
  const {templates, projectID} = useProject()

  const setTemplate = e=>window.ask({title: "Enter Unique Key"}, key=>{
    if (!key.length || !key.trim() || key.match(/[^ \d\w]/ig))
      return window.error("Invalid key")

    if ((templates || {})[String(key).toLowerCase()])
      return window.error("Template already exists")

    getProject(projectID).setTemplate(String(key).toLowerCase(), { event: "" })
    window.close()
    pick(key)
  })

  const pick = key => window.pick((profileID, path) => {
    if (!hasRight(profileID, "CHANGE") && getProject(projectID).profiles.includes(profileID))
      return window.notify("Not permitted")

    if (!getProject(projectID).profiles.includes(profileID))
      window.warning("You are referencing an external source, be careful to bundle this package with that source")
      
    const src = Profiles.get(profileID)
    if (src.loaded && src.getArticleID() && !src.getTemplated())
      getProject(projectID).updateTemplate(String(key).toLowerCase(), { profileID })
    else
      window.warning("Invalid choice... no article or still loading")
  })
  
  const pickWizard = key => window.ask({title: "Pick a wizard", app: WizardManagerWrap, 
    bodyStyle: {padding: 0},
    style: {height: "50vh"},
    props: { 
      projectID,
      onSelect: wizardID=>{
        getProject(projectID).updateTemplate(key, { wizardID })
        window.close()
      },
      picker: true
    }
  })
   

  const tData = getProject(projectID).getTemplates()
  return <Column grow>
    <Scroll scrollY>
      {Object.keys(templates || {}).map((key, index) => <Row between key={index} className="background">
          <Row style={{minWidth: "300px"}}>
            <Row center className="black-tint pad-h rounded" style={{minWidth: "100px"}}>
              {key}
            </Row>
            <Column center className="pad-h">
              <Input
                size="small"
                className="card-bg"
                defaultValue={tData[key].name}
                onChange={e => getProject(projectID).updateTemplate(key, { name: e.target.value })}
                placeholder="Display Name"
              />
            </Column>
          </Row>
          <Row grow>
            <Row center>
              <Button small title="Set Creation Wizard" onClick={e => pickWizard(key)}>
                <PlusSquareFilled />
              </Button>
              {tData[key].wizardID && <WizardProvider wizardID={tData[key].wizardID}><WizardName/></WizardProvider>}
              {tData[key].wizardID && <Button small title="Remove Wizard" onClick={e => getProject(projectID).updateTemplate(key, { wizardID: undefined })}>
                <DeleteFilled />
              </Button>}
            </Row>
          </Row>
          <Row center>
            {tData[key].profileID && <Mention profileID={tData[key].profileID}>
              <a className="text-dull"><ProfileName profileID={tData[key].profileID} /></a>
            </Mention>}
            <Button small title="Set template page" onClick={e => pick(key)}>
              <PlusSquareFilled />
            </Button>
          </Row>
          <Row center>
            <Button className="foreground" onClick={
              e => window.build({title: "Change Event", width: "85vw", bodyStyle: {padding: 0}, style: {height: "85vh"}, app: EventBuilder, props: {defaultValue: getProject(projectID).getTemplate(key).event, onFinalize: res=>{
                getProject(projectID).updateTemplate(key, { event: res })
                window.close()
              }}})}>
              Event Script
            </Button>
            <Button onClick={e => getProject(projectID).deleteTemplate(key)}>
              <DeleteFilled />
            </Button>
          </Row>
        </Row>)}
    </Scroll>
    <Row center>
      <Button className="foreground" onClick={setTemplate}>Register Template</Button>
    </Row>
  </Column>
}
