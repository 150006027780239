import React, { useRef, useState } from 'react'

import { Row, Column, Button } from '../UI'

import useNav from './hooks/useNav'
import { WikiList, ProfileView, ProfileMeta, ProjectList, Crumbs } from './Browser'
import { ProjectProvider, createArticle } from './hooks/useProject'

import Nav from './state/Navigation'
import { DoubleLeftOutlined, CloseOutlined, DoubleRightOutlined } from '@ant-design/icons'
import { SlateContext } from "./slate/Slate"
import useAccount from "../core/hooks/useAccount"
import { ProjectOverview, ProjectSettings, StartProjectWizard } from './Projects'
import Resources from './state/Resources'
import { v4 } from 'uuid'
import Network from "../core/state/Network"
import { copySocket } from "../core/apps/Players"
import WizardBuilder from "./Wizard"
import { WizardProvider } from "./hooks/useWizard"
import Workshop from "./Workshop"
import {HotMenu} from "./Hotbar"
import SignIn from "../core/SignIn"

export function MainMenu(opts) {
  const { id, uid } = useAccount()
  const notAuthed = !uid
  const [hide, setHide] = useState()

  function openDLC(){
    const a = document.createElement("a")
    a.href = "https://store.steampowered.com/app/1834270/Mythic_Archive__Host_License/"
    a.target = "_blank"
    a.click()
  }

  const types = {
    "New Project": {
      desc: notAuthed ? "Sign in or purchase Mythic Archive" : "Create a new Project and start working on it privately",
      icon: "/assets/icons/png_128/icon_game/icon_game_64.png",
      disabled: notAuthed || window.isDemo,
      onClick: e=>{
        if (notAuthed || window.isDemo)
          return openDLC()

        StartProjectWizard().then(({name, type, system, banner, archives, wallpaper})=>{
          let prefix = ""
          if (window.isLocal)
            prefix = "-local-"
          
          if (window.isDemo)
            prefix = "-temp-"
    
          const uid = prefix + v4()
          const project = Resources.Projects.create(uid)
          project.setName(name)
          for (const key of (archives || []))
            project.codexes[key] = {}

          const d20Test = `$roll=d20
%🎲=$roll
%🎯=$threshold
%✔️=$roll >= $threshold
%❌=$roll < $threshold
_class=Test
_mini=true
>Rolled {$roll}`

const d20Combat = `$roll=d20
>Rolled {$roll}
_category=$roll+0`

const d100Test = `$roll=d100
%🎲=$roll
%🎯=$threshold
%✔️=$roll <= $threshold
%❌=$roll > $threshold
_class=Test
_mini=true
>Rolled {$roll}`

const d100Combat = `$roll=d10
>Rolled {$roll}
_category=$roll+0`

          if (type === "RPG Skeleton - d20") {
            project.profile.updateEvent("test", {script: d20Test, name: "d20 Roll"})
            project.meta.descriptors = {"system": true}
            project.components = {
              "inventory": {tags: ["item"], name: "Inventory"},
              "conditions": {tags: ["condition"], name: "Status Effects"}
            }
            project.trackers = { "combat": { enter: d20Combat, name: "Combat", sort: "number" } }
          }
          else if (type === "RPG Skeleton - d100") {
            project.profile.updateEvent("test", {script: d100Test, name: "d100 Roll"})
            project.meta.descriptors = {"system": true}
            project.components = {
              "inventory": {tags: ["item"], name: "Inventory"},
              "conditions": {tags: ["condition"], name: "Status Effects"}
            }
            project.trackers = { "combat": { enter: d100Combat, name: "Combat", sort: "number" } }
          }
          else if (type === "Content") {
            project.meta.descriptors = {"content": true}
          }
          else if (type === "Fluff") {
            project.meta.descriptors = {"fluff": true}
          }
          else if (type === "Adventure") {
            project.meta.descriptors = {"adventure": true}
          }

          if (system)
            project.meta.projectSystem = system
            
          if (wallpaper)
            project.setBackground(wallpaper)
          else
            project.setBackground({src: "/bg.jpg", fit: "cover"})
          project.loaded = true
          project.profile.loaded = true
    
          Nav.setProject(uid)
  
          const article = createArticle()
          project.profile.setArticleID(article.uid)
          article.emit("change")
          project.emit("change")
        })
      }
    },
    "Load Project": {
      desc: notAuthed ? "Sign in or purchase Mythic Archive" : "Open an existing project",
      icon: "/assets/icons/png_128/icon_tool/icon_tool_77.png",
      disabled: notAuthed || window.isDemo,
      onClick: e=>{
        if (notAuthed || window.isDemo)
          return openDLC()
        window.ask({
          title: "Load a project",
          app: ProjectList,
          bodyStyle: {padding: 0},
          style: {height: "75vh"},
          width: "75vw",
          props: {
            onSelect: e=>{
              window.close()
            }
          }
        })
      }
    },
    "Join Session": {
      desc: "Connect to a session in progress",
      icon: "/assets/icons/png_128/icon_app/icon_app_2.png",
      onClick: e=>{
        window.ask({title: "Enter Lobby Code"}, res=> Network.joinLobby(res).then(v => {
          window.success("Connected!")
          Nav.setApp("Logs", true)
        }))
      }
    },
    "Start Session": {
      desc: notAuthed ? "Sign in or purchase Mythic Archive" : "Creates a multiplayer lobby for one of your projects",
      icon: "/assets/icons/png_128/icon_app/icon_app_18.png",
      disabled: notAuthed || window.isDemo,
      onClick: e=>{
        if (notAuthed || window.isDemo)
          return openDLC()
        window.ask({
          title: "Load a project",
          app: ProjectList,
          bodyStyle: {padding: 0},
          style: {height: "75vh"},
          width: "75vw",
          props: {
            onSelect: e=>{
              const lobbyCode = v4()
              Network.createLobby(lobbyCode).then(v => { // host a lobby
                Network.prepare(id)
                copySocket(lobbyCode)
                Nav.setApp("Logs", true)
                window.success(`Created Lobby: ${lobbyCode} (copied to clipboard)`)
              }).catch(e=>console.warn(e)).catch(e => window.warning("Could not create lobby, try again later"))
              window.close()
            }
          }
        })
      },
    },
  }

  if (window.isLocal)
    types["Manage Workshop"] = {
      desc: <span>
        Manage and upload Workshop content. 
        <a className="text-blue" href="https://steamcommunity.com/app/1334470/workshop" target="_blank" onClick={e=>e.stopPropagation()}>
          &nbsp;Browse the Workshop
        </a>
      </span>,
      icon: "/assets/icons/png_128/icon_game/icon_game_121.png",
      onClick: e=> window.build({title: "Manage Workshop", app: Workshop, style: {height: "50vh"}, bodyStyle: {padding: 0}})
    }

  if (window.isDemo) {
    delete types["Load Project"]
    types["Host License"] = {
      desc: <span>
        <a className="text-blue" href="https://store.steampowered.com/app/1834270/Mythic_Archive__Host_License/" target="_blank" onClick={e=>e.stopPropagation()}>
          Start your own projects and track your campaigns! 
        </a>
      </span>,
      icon: "/ma_icon.png",
      onClick: e=> {

      }
    }
  }

  return <Column fit className="pointer" style={{background: "url('bg.jpg')", backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat"}} onClick={e=>setHide(!hide)}>
    <Column className={`${hide ? "invisible no-click" : "visible"}`} fit center>
      <a className="pad-l" href="https://store.steampowered.com/app/1334470/Mythic_Archive" onClick={e=>e.stopPropagation()}>
        <img src="/mythicarchive_alpha.png" height={128} style={{marginTop: "-128px"}}/>
      </a>
      <Column className="card-bg rounded" width="33vw" style={{border: "1px solid #222222", boxShadow: "0px 0px 10px rgba(0,0,0,0.7)"}} onClick={e=>e.stopPropagation()}>
        {Object.keys(types).map(key=><Row className="pad-l clickable" key={key} style={{opacity: types[key].disabled ? "0.5" : undefined}} onClick={types[key].onClick}>
          <Column center className="pad-h">
            <img height={64} width={64} className="contain" src={types[key].icon} alt=""/>
          </Column>
          <Column grow className="pad">
            <h3 style={{margin: 0}}>{key}</h3>
            <span>{types[key].desc}</span>
          </Column>
        </Row>)}
        <Column className="foreground">
          <SignIn />
        </Column>
      </Column>
    </Column>
  </Column>
}


export default opts => {
  const { wiki, projectID, profileID, browser, compendium, homepage, wizardID, settings } = useNav()
  const submenu = browser
  const ref = useRef()
  const refMini = useRef()
  const c = <Row between className="foreground rounded" style={{borderBottom: "1px solid transparent", borderTop: "1px solid #141414"}}>
    <Button noClick className="invisible" title="Compact Mode" placement="right" mouseEnterDelay={0.5} onClick={e => Nav.setCompendium(false)}>
      <DoubleLeftOutlined />
    </Button>
    <Row center grow className="pad-h" >
      {projectID && <ProjectProvider projectID={projectID}><Crumbs /></ProjectProvider>}
    </Row>
    <Button onClick={e => {
      Nav.setHomePage()
      Nav.setProfile()
    }}>
      <CloseOutlined />
    </Button>
  </Row>

  const b = <ProjectProvider projectID={projectID}>
    <Row between className="foreground rounded">
      <Button className="foreground" onClick={e => {
          Nav.setBrowser()
          Nav.setCompendium(true)
        }}>
          <DoubleRightOutlined />
      </Button>
      <Row center grow className="pad-h" >
        {projectID && <Crumbs compact={true} />}
      </Row>
      <ProjectSettings/>
    </Row>
  </ProjectProvider>
  const open = projectID && (profileID || homepage || wizardID || settings)
  const bar = <div className="column absolute bottom fit-x no-click" ref={ref} style={{ paddingRight: "12px", zIndex: 1200 }} />
  const barMini = <div className="column absolute bottom fit-x no-click" ref={refMini} style={{ paddingRight: "12px", zIndex: 1200 }} />
  
  return <Row noClick={!wiki} relative style={{transition: "background 0.5s", paddingLeft: "0"}} className={`${((profileID || homepage) && compendium && wiki) ? "black-tint" : ""}`} fit>
    <Column className="size-2 card-bg" click style={{zIndex: 1250}}>
      <HotMenu/>
    </Column>
    <Column grow relative>
      <Row absolute className={`${wiki ? "visible" : "invisible"}`} fit style={{ borderRadiusBottomRight: "4px", borderRadiusTopRight: "4px", borderRadiusTopLeft: "0px", borderRadiusBottomLeft: "0px", overflow: "hidden", transition: "left 0.5s, opacity 0.25s", left: wiki ? "0px" : "-300px", top: 0 }}>
        <Column click={wiki} relative className="background" style={{ width: "17vw", minWidth: "400px" }}>
          {projectID && !compendium && b}
          <Column grow relative>
            {projectID && !compendium && <SlateContext.Provider value={{toolbar: refMini, flatten: true, rootID: profileID}}>
              <ProjectProvider projectID={projectID}>
                <ProfileView />
              </ProjectProvider>
            </SlateContext.Provider>}
            <Column noClick={(submenu || compendium) ? false : true} fit absolute className={`${(submenu || compendium) ? "visible" : "invisible"} background`} style={{ zIndex: 1 }}>
              {!projectID ? <ProjectList /> : <WikiList compact={!compendium} />}
            </Column>
          </Column>
          {barMini}
        </Column>
        <Column className={(wiki && open && compendium) ? "click" : "no-click"} relative style={{ width: "60%", overflow: "hidden" }}>
          <Column absolute fit style={{transition: "left 0.2s", left: (open && compendium) ? "0vw" : "-60vw"}}>
            {settings && !profileID && <ProjectProvider projectID={projectID}>
              <ProjectOverview />
            </ProjectProvider>}
            {wizardID && <Column grow className="background">
              <ProjectProvider projectID={projectID}>
                <WizardProvider wizardID={wizardID}>
                  <WizardBuilder/>
                </WizardProvider>
              </ProjectProvider>
            </Column>}
            {!wizardID && (!settings || profileID)  && <SlateContext.Provider value={{toolbar: ref, rootID: profileID}}>
              {c}
              <ProjectProvider projectID={projectID}>
                <ProfileView />
              </ProjectProvider>
              {bar}
            </SlateContext.Provider>}
          </Column>
        </Column>
        {open && compendium && <Column grow className={(wiki && open && compendium) ? "click" : "no-click"}>
          {(wizardID || !profileID) ? undefined : <ProjectProvider projectID={projectID}>
            <ProfileMeta />
          </ProjectProvider>}
        </Column>}
      </Row>
    </Column>
  </Row>
}