import React, { useRef, useState, useEffect, useMemo, useCallback, useContext } from 'react'
import { createEditor, Editor, Transforms, Text, Range } from 'slate'
import { Slate, Editable, withReact, ReactEditor, useSelected, useEditor, useSlate } from 'slate-react'
import { withHistory } from 'slate-history'
import Resources from '../state/Resources'
import CreateValue from './CreateValue'
import AddSave from '../util/SaveStack'
import clone from 'rfdc'
import { Column, Row, Button, GhostInput } from '../../UI'
import Toolbar from './plugins/Toolbar'
import { commands } from './Commands'
import Mask from './inline/Mask'
import Event from './inline/Event'
import EventModel from '../models/Event'

import Reference from './inline/Reference'
import Mention from './inline/Mention'
import useArticle from "../hooks/useArticle"
import useEvent, { applyEvent, createEvent, getEvent } from "../hooks/useEvent"

import { Tooltip, Input, Menu, Dropdown, Switch, Checkbox, Tag } from 'antd'
import { PlusSquareFilled } from '@ant-design/icons'
import useProject, { getProject, hasPermission } from "../hooks/useProject"
import useProfile, { getProfile } from "../hooks/useProfile"
import useNav from "../hooks/useNav"
import MediaApp from '../Media'
import Context from '../util/Context'
import UniversalDrop from "../../core/wrappers/UniversalDrop"

import Logs from '../state/Logs'
import { ProjectComponentApp } from "../Projects"
import Network from "../../core/state/Network"

export const SlateContext = React.createContext({})

const LINK = ({ src, mask }) => <a className="mention" href={src} target="_blank">{mask || src}</a>

const copy = clone()
const DefaultElement = ({ attributes, children, element, className, style }) => {
  const editor = useEditor()
  const Tag = editor.isInline(element) ? 'span' : 'div'

  return <Tag {...attributes} className={className} style={{ position: 'relative', textAlign: element.align, ...style }}>
    {children}
  </Tag>
}

const SmartDie = ({ dieIndex, index }) => {
  const { eventID, rolls } = useEvent()

  const reRoll = e => {
    const event = getEvent(eventID)
    window.success(`Re-rolling ${event.rolls[index].roll.match(/(d[\d+])/i)[0]}`)
    Context.exec(event.rolls[index].roll.match(/(d[\d+])/i)[0], {}, res=>{
      event.rolls[index].rolled[dieIndex] = res
      event.emit("change")
    })
  }

  const changeResult = e => {
    window.ask({ title: "Change dice roll", value: rolls[index].rolled[dieIndex] }, res => {
      const event = getEvent(eventID)
      event.rolls[index].rolled[dieIndex] = parseInt(res) || 0
      event.emit("change")
    })
    e.preventDefault()
  }

  return <b onClick={reRoll} onContextMenu={changeResult} className={`roll-dice ${rolls[index].tracked[dieIndex] ? "text-white" : "text-disabled"}`}>
    {rolls[index].rolled[dieIndex]}
  </b>
}

const SmartRoll = ({ index, showTotal }) => {
  const { loaded, rolls } = useEvent()
  if (!loaded)
    return <span />

  const rollData = rolls[index]
  if (!rollData)
    return <span />

  const children = []
  const { rolled, tracked, roll, total } = rollData
  const type = roll.match(/(d[\d]+)/)
  const die = (type && type[1]) || roll

  for (let i = 0; i < rolled.length; i++) {
    if (tracked[i])
      children.push(<SmartDie key={i} dieIndex={i} index={index} />)
    else
      children.push(<SmartDie key={i} dieIndex={i} index={index} />)

    if (i < rolled.length - 1)
      children.push(<b> </b>)
  }

  // /d4+d6+d8+d10+d12+d20+10
  return <Tooltip title={<Row wrap>{children}</Row>}>
    <b className="text-white roll-dice rounded relative">
      {showTotal ? total : String(roll).replace(/[{}\(\)\[\]]/ig, "")}
    </b>
  </Tooltip>
}

const SmartMacro = ({ equation, inline, children }) => {
  const { eventID, rolls, loaded, value: raw } = useEvent()

  if (!loaded)
    return <div />

  const result = []
  const roll = /\[%\[([\d]+)\]%\]/i
  let evl = equation
  const rollClean = /\((\[%\[([\d]+)\]%\])\)/i
  let cleanMatch = evl.match(rollClean)
  while (cleanMatch) {
    evl = evl.replace(cleanMatch[0], cleanMatch[1])
    cleanMatch = evl.match(rollClean)
  }
  let eq = evl

  let rollMatch = eq.match(roll)
  let start
  let child = []
  let lastIndex = 0
  if (rollMatch) {
    start = evl.substring(0, rollMatch.index)
    result.push(eq.substring(lastIndex, rollMatch.index) ? <span className="text-white no-click">{eq.substring(lastIndex, rollMatch.index).trim()}</span> : <span/>)
    eq = evl.substring(rollMatch.index, eq.length)
  }

  let leftOver
  while (rollMatch) {
    const [full, index] = rollMatch
    const { rolled, tracked } = rolls[index] || {rolled: []}
    let total = 0
    result.push(<SmartRoll index={index} />)
    for (let i = 0; i < rolled.length; i++)
      if (tracked[i])
        total += parseInt(rolled[i])


    evl = evl.replace(full, total)
    eq = rollMatch.input.substring(rollMatch.index + full.length, rollMatch.input.length)
    rollMatch = eq.match(roll)
    if (rollMatch) {
      leftOver = rollMatch.input.substring(0, rollMatch.index)
      result.push(<span className="text-white no-click">{String(leftOver).trim()}</span>)
    }
  }

  // if (leftOver[0] === "(" && leftOver[leftOver.length - 1] === ")")
  //   leftOver = leftOver.substring(1, leftOver.length - 1)
  // leftOver = leftOver.replace(/\+-/g, "-")
  result.push(eq.trim() ? <span className="text-white no-click">{eq.trim()}</span> : <span/>)

  let res
  try {
    res = getEvent(eventID).eval(equation)
  }
  catch (e) {
    console.warn(e)
    res = 0
  }
  child.push(<span className="grow">{result}</span>)
  const style = { alignItems: "center", textShadow: "none", color: "#111" }

  const isMobile = false //window.mobilecheck()

  const title = <Column grow fitX>
    {inline && <span className="text-dull size-small pad-h">{raw}</span>}
    <Row className="roll-bg" center wrap style={style}>
      <span style={{maxWidth: "100%"}}>
        {child}
      </span>
    </Row>
    <span className="row center roll-result relative">
      <b className="white-title">
        {/* {isNaN(res) && "..."}
        {!isNaN(res) && res} */}
        {res}
      </b>
    </span>
  </Column>

  if (!inline)
    return title
  else
    return <Tooltip placement="top" mouseEnterDelay={0.5} overlayInnerStyle={{ padding: "0", minWidth: "10vw", maxWidth: isMobile ? "80vw" : "30vw" }} title={title} arrow={true}>
      <span>
        {res !== false && res !== "" && <b className="gaps roll-result-small white-title">
          {res}
        </b>}
      </span>
    </Tooltip>
}

const SmartData = ({ element, attributes }) => {
  const { dataKey: target, checkbox, select, bonus } = element
  const { checkon, checkoff, checked, switched } = checkbox ?? {}

  const { profileID, data, permission } = useProfile()
  const ref = useRef()
  const [classes, setClasses] = useState("data")
  const [value, setValue] = useState(data[target])
  const [open, setOpen] = useState()

  const editor = useEditor()

  const [path, setPath] = useState()
  useEffect(() => {
    const { ref } = attributes
    if (!ref || !ref.current)
      return
    const path = ReactEditor.findPath(editor, ReactEditor.toSlateNode(editor, ref.current))
    setPath(path)
  }, [attributes, editor])

  useEffect(()=>{
    if (ref && ref.current && open) {
      const formula = getProfile(profileID).getFormula(target)
      setEntryValue((formula || value) ?? 0)
      window.blurAll()
      setTimeout(ref.current.focus)
    }
  }, [open])

  const formula = getProfile(profileID).getFormula(target)
  const [entryValue, setEntryValue] = useState(formula ?? data[target])

  const edit = <div>
    <span className="pad-h">{`#${target} ${open === "FORMULA" ? "- FORMULA" : ""}`}</span>
    <Input.TextArea
      ref={ref}
      className="card-bg"
      value={entryValue ?? 0}
      autoSize={{ minRows: 1, maxRows: 6 }}
      onFocus={e => e.target.select()}
      onChange={e=>setEntryValue(e.target.value)}
      onBlur={e => {
        const value = e.target.value
        const p = getProfile(profileID)
        if (open === "FORMULA")
          getProfile(profileID).setFormula(target, isNaN(value) ? value : Number(value))
        else {
          getProfile(profileID).removeFormula(target)
          getProfile(profileID).setData(target, isNaN(value) ? value : Number(value))
        }
          
        setOpen(false)
      }}
      onKeyDown={e => {
        if (!e.shiftKey && e.key === "Enter") {
          e.target.blur()
          setOpen(false)
          e.preventDefault()
          e.stopPropagation()
        }
      }}
      placeholder={`#${target}`}
    />
  </div>

  useEffect(() => {
    const d = getProfile(profileID).getContext()
    if (value !== d[target] && Object.keys(data).length) {
      setClasses("data changed")
      setTimeout(() => setClasses("data"), 1)
    }
    setValue(d[target])
  }, [value, profileID, data, target])

  function changeVisiblity(e) {
    const formula = getProfile(profileID).getFormula(target)
    if (hasPermission(permission, "CHANGE"))
      setOpen(formula ? "FORMULA" : true)
    else {
      window.warning("Permission Denied")
      setOpen(false)
    }
  }

  const tempEvent = new EventModel()

  const context = {source: {}, target: {}}
  context.source = {...getProfile(profileID).getContext()}

  const res = tempEvent.eval(checked ?? value, context)
  const setCheckbox = e=>{
    Transforms.setNodes(editor, { checkbox: {} }, { at: path })
  }

  const setSwitch = e=>{
    Transforms.setNodes(editor, { checkbox: {...checkbox, switched: e} }, { at: path })
  }

  const reset = e=>{
    Transforms.setNodes(editor, { checkbox: null, select: null }, { at: path })
  }

  const setCond = e=>window.ask({title: "Check Condition (Return true for checked)", value: checkoff}, checked=>
    Transforms.setNodes(editor, { checkbox: {...checkbox, checked} }, { at: path })
  )

  const setCheckOn = e=>window.ask({title: "On Checked", value: checkon}, checkon=>
    Transforms.setNodes(editor, { checkbox: {...checkbox, checkon} }, { at: path })
  )

  const setCheckOff = e=>window.ask({title: "On unchecked", value: checkoff}, checkoff=>
    Transforms.setNodes(editor, { checkbox: {...checkbox, checkoff} }, { at: path })
  )

  const canChange = hasPermission(permission, "CHANGE")


  const addOption = e=>{

  }
  
  const renameOption = e=>{

  }
  
  const setOptionValue = e=>{

  }

  const removeOption = e=>{

  }

  let selectField

  const ELEMENT = switched ? Switch : Checkbox
  let text = (value ?? "")
  if (!isNaN(text) && bonus && Number(text) >= 0)
    text = "+" + text

  return <Dropdown trigger="contextMenu" arrow={true} overlayStyle={{zIndex: 1550}} overlay={<Menu>
    {!editor._readOnly && !checkbox && !select && <Menu.Item key={"formula"} onClick={e=>setOpen(formula ? true : "FORMULA")}>{formula ? "Make Stat" : "Make Formula"}</Menu.Item>}
    {!editor._readOnly && !checkbox && !select && <Menu.Item key={"checkbox"} onClick={setCheckbox}>Make Checkbox</Menu.Item>}
    {!editor._readOnly && !checkbox && !select && !bonus && <Menu.Item key={"bonuson"} onClick={e=>Transforms.setNodes(editor, { bonus: true }, { at: path })}>Mark as Bonus</Menu.Item>}
    {!editor._readOnly && !checkbox && !select && bonus && <Menu.Item key={"bonusoff"} onClick={e=>Transforms.setNodes(editor, { bonus: false }, { at: path })}>Unmark as Bonus</Menu.Item>}

    {/* {!editor._readOnly && !checkbox && !select && <Menu.Item key={"select"} onClick={setCheckbox}>Make Select</Menu.Item>}

    {!editor._readOnly && select && <Menu.Item key={"addoption"} onClick={addOption}>Add Option</Menu.Item>}
    {!editor._readOnly && select && <Menu.Item key={"renameoption"} onClick={renameOption}>Rename Option</Menu.Item>}
    {!editor._readOnly && select && <Menu.Item key={"optionvalue"} onClick={setOptionValue}>Option Value</Menu.Item>}
    {!editor._readOnly && select && <Menu.Item key={"removeoptions"} onClick={removeOption}>Remove Option</Menu.Item>} */}

    {!editor._readOnly && (checkbox || select) && <Menu.Item key={"input"} onClick={reset}>Make Input</Menu.Item>}
    {!editor._readOnly && checkbox && <Menu.Item key={"checked"} onClick={setCond}>Set Checked Condition</Menu.Item>}
    {!editor._readOnly && checkbox && <Menu.Item key={"checkon"} onClick={setCheckOn}>On Check Value</Menu.Item>}
    {!editor._readOnly && checkbox && <Menu.Item key={"checkoff"} onClick={setCheckOff}>On Unchecked Value</Menu.Item>}
    {!editor._readOnly && checkbox && switched && <Menu.Item key={"switchon"} onClick={e=>setSwitch(false)}>Set Checkbox</Menu.Item>}
    {!editor._readOnly && checkbox && !switched && <Menu.Item key={"switchoff"} onClick={e=>setSwitch(true)}>Set Switch</Menu.Item>}
  </Menu>}>
    <span>
      {select && selectField}
      {checkbox && <span><ELEMENT size="small" checked={res} disabled={editor._readOnly && !canChange} onChange={e=>{
        getProfile(profileID).setData(target, tempEvent.eval(res ? (checkoff ?? 0) : (checkon ?? 1), context))
      }}/></span>}
      {!checkbox && !select && <Tooltip placement="bottom" title={edit} visible={open} onVisibleChange={changeVisiblity} trigger="click" overlayInnerStyle={{ padding: "0" }}>
        <span className={classes}>{text}</span>
      </Tooltip>}
    </span>
  </Dropdown>
}

const SmartFormula = ({ formula, attributes }) => {
  const editor = useEditor()
  const { rootID } = useContext(SlateContext)
  const { profileID, permission } = useProfile()
  const canChange = hasPermission(permission, "CHANGE")

  const [path, setPath] = useState()
  useEffect(() => {
    const { ref } = attributes
    if (!ref || !ref.current)
      return
    const path = ReactEditor.findPath(editor, ReactEditor.toSlateNode(editor, ref.current))
    setPath(path)
  }, [attributes, editor])

  const click = e=> {
    window.ask({title: "Change Formula", textarea: true, value: formula}, res=>Transforms.setNodes(editor, { formula: res }, { at: path }))
  }

  const context = {source: {}, target: {}}
  if (rootID !== profileID && rootID) {
    const rootCTX = getProfile(rootID).getContext()
    const srcCTX = getProfile(profileID).getContext()
    context.source = {...rootCTX, ...srcCTX}
    // context.source._components = rootCTX._components
  }
  else
    context.source = {...getProfile(profileID).getContext()}

  const tempEvent = new EventModel()
  const res = tempEvent.eval(formula, context)
  if (editor._readOnly || !canChange)
    return <span className="formula" title={formula}>{res}</span>

  return <span className="formula" onClick={canChange ? click : e=>{}}>{res}</span>
}

const Secret = ({ attributes, children, }) => {
  const {permission} = useProfile()
  const selected = useSelected()
  return hasPermission(permission, "WRITE") ? <blockquote className="secret relative" {...attributes}>
    <div className={`fit-x row-reverse absolute bottom hover-visible size-small pad-h ${selected ? "visible click" : "invisible click"}`} contentEditable={false}>
      <Row center className="pad-h-l mention" click>
        secret
      </Row>
    </div>
    {children}
  </blockquote> : <div {...attributes}/>
}

const Component = ({ element, attributes, children }) => {
  const { permission, profileID } = useProfile()
  const { projectID } = useNav()
  const canChange = hasPermission(permission, "CHANGE")
  
  const [path, setPath] = useState()
  const selected = useSelected()
  const editor = useSlate()
  useEffect(() => {
    const { ref } = attributes
    if (!ref || !ref.current)
      return
    const path = ReactEditor.findPath(editor, ReactEditor.toSlateNode(editor, ref.current))
    setPath(path)
  }, [attributes, editor])

  const selectClick = e => {
    Transforms.select(editor, Editor.range(editor, { path: editor.getFirstLeaf(path)[1], offset: 0 }, { path: editor.getFirstLeaf(path)[1], offset: 0 }))
    e.preventDefault()
    window.pick(key=>{
      const correctKey = `[=${key}=]`
      const addProfile = getProfile(key)
      const { tags, enter } = getProject(projectID).getComponentOptions(element.key) || {}
      if (tags && tags.length) {
        let shouldContinue = false
        for (const tag of tags) 
          if (addProfile.meta.tags.includes(tag)) {
            shouldContinue = true
            break
          }
        if (!shouldContinue)
          return window.warning("Missing tag(s)")
      }

      if (enter && profileID) {
        const {event, context} = createEvent({ sourceID: profileID, targetID: addProfile.uid })
        event.roll(enter, context).then(e=>applyEvent(event, context, {sourceID: profileID}))
      }

      p.addToComponent(element.key, correctKey)
    }, {title: `Add to ${element.key}`})
  }
  
  const p = getProfile(profileID)
  const component = p.getComponent(element.key) || {}

  function remove(key, e) {
    const mention = /(\[=([-\d\w]+)=\])(`([^`]+)`)?/i
    const match = String(key).match(mention)
    if (match) {
      const { exit } = getProject(projectID).getComponentOptions(element.key) || {}
      if (exit && match[2]) {
        const {event, context} = createEvent({targetID: profileID, sourceID: match[2]})
        event.roll(exit, context).then(e=>applyEvent(event, context))
      }
    }
      
    p.removeFromComponent(element.key, key)
    e.stopPropagation()
    e.preventDefault()
    return false
  }

  function processKey(key){
    const data = p.getComponent(element.key)
    const mention = /(\[=([-\d\w]+)=\])(`([^`]+)`)?/i
    const match = String(key).match(mention)
    if (match)
      return <span>
        <Mention item={{sourceID: profileID, component: element.key, key}} componentID={match[2]} rootID={profileID} profileID={match[2]} mask={match[3]}/>
      <span>{data[key] > 1 ? ` (${data[key]})` : undefined}</span></span>
    return <span>{key}<span>{data[key] > 1 ? ` (${data[key]})` : undefined}</span></span>
  }

  const onDrop = (p,m,c)=>{
    const item = m.getItem()

    const { tags, enter, exit } = getProject(projectID).getComponentOptions(element.key) || {}

    if (item.component) {
      if (item.component !== element.key || item.sourceID !== profileID) {
        const mention = /(\[=([-\d\w]+)=\])(`([^`]+)`)?/i
        const match = String(item.key).match(mention)
        let addProfile
        if (match[2])
          addProfile = getProfile(match[2])
          
        if (tags && tags.length) {
          let shouldContinue = false
          for (const tag of tags) 
            if (addProfile.meta.tags.includes(tag)) {
              shouldContinue = true
              break
            }
          if (!shouldContinue)
            return window.warning("Missing tag(s)")
        }

        if (item.sourceID)
          getProfile(item.sourceID).removeFromComponent(item.component, item.key)

        if (exit && item.sourceID) {
          const {event, context} = createEvent({targetID: profileID, sourceID: item.sourceID, componentID: item.componentID})

          event.roll(exit, context).then(e=>applyEvent(event, context))
        }
        
        if (enter && profileID) {
          const {event, context} = createEvent({targetID: profileID, sourceID: item.sourceID, componentID: item.componentID})

          event.roll(enter, context).then(e=>applyEvent(event, context))
        }

        getProfile(profileID).addToComponent(element.key, item.key)
      }
    }
    else if (item.profileID) {
      const p = getProfile(profileID)
      const correctKey = `[=${item.profileID}=]`
      const { tags } = getProject(projectID).getComponentOptions(element.key) || {}
      const addProfile = getProfile(item.profileID)
      if (tags && tags.length) {
        let shouldContinue = false
        for (const tag of tags) 
          if (addProfile.meta.tags.includes(tag)) {
            shouldContinue = true
            break
          }
        if (!shouldContinue)
          return window.warning("Missing tag(s)")
      }

      if (exit && item.profileID) {
        const {event, context} = createEvent({targetID: profileID, sourceID: item.profileID, componentID: item.componentID})
        event.roll(exit, context).then(e=>applyEvent(event, context))
      }
      
      if (enter && profileID) {
        const {event, context} = createEvent({targetID: profileID, sourceID: item.profileID, componentID: item.componentID})
        event.roll(enter, context).then(e=>applyEvent(event, context))
      }

      p.addToComponent(element.key, correctKey)
    }
  }

  const configure = e=>window.ask({
    title: `Change ${element.key} Options`,
    app: ProjectComponentApp,
    props: {
      projectID,
      target: element.key
    }
  })

  const menu = <Menu>
    {/* {canChange && <Menu.Item>Add</Menu.Item>} */}
    {/* {canChange && element.custom && <Menu.Item>Add Custom</Menu.Item>} */}
    {hasPermission(permission, "WRITE") && <Menu.Item onClick={configure}>Configure</Menu.Item>}
  </Menu>

  return <div contentEditable={false} {...attributes}>
    <UniversalDrop onDrop={onDrop} onClick={canChange && selectClick}>
      <Dropdown trigger="contextMenu" overlay={menu}>
        <div className="component relative pointer">
          <Column reverse fit bottom noClick absolute={Object.keys(component).length > 0} className={`size-small pad-h hover-visible ${selected ? "visible" : "invisible"}`}>      
            <Row reverse>
              <Row center className="pad-h-l" click>
                {element.key}
              </Row>
            </Row>
          </Column>
          <Row wrap>
            {Object.keys(component).map(key=><Tag key={key} onContextMenu={e=>e.stopPropagation()} onClick={e=>e.stopPropagation()} onClose={e=>remove(key, e)} closable={canChange}>
              {processKey(key)}
            </Tag>)}
          </Row>
        </div>
      </Dropdown>
    </UniversalDrop>
    {children}
  </div>
}

const Media = ({ leaf, attributes, children }) => {
  const { rootID } = useContext(SlateContext)
  const { profileID } = useProfile()

  const url = String(leaf.src)
  // const [focused, setFocused] = useState(false)
  const [path, setPath] = useState()
  const editor = useEditor()
  const selected = useSelected()

  useEffect(() => {
    const { ref } = attributes
    if (!ref || !ref.current)
      return
    const path = ReactEditor.findPath(editor, ReactEditor.toSlateNode(editor, ref.current))
    setPath(path)
  }, [attributes, editor])

  const style = {
    position: "relative",
    float: leaf.float,
    marginLeft: leaf.float === "right" ? "0.5rem" : "",
    marginRight: leaf.float === "left" ? "0.5rem" : "",
    maxWidth: leaf.maxWidth || (leaf.float ? "50%" : null),
    maxHeight: leaf.maxHeight || (leaf.float ? "50%" : null),
    border: selected ? "2px dashed white" : `${leaf.isBlock ? 0 : 2}px solid transparent`,
    boxShadow: "0px 0px 10px black",
    borderRadius: selected ? "2px" : null
  }

  if (editor._readOnly)
    delete style.border

  const selectClick = e => {
    Transforms.select(editor, Editor.range(editor, { path: editor.getFirstLeaf(path)[1], offset: 0 }, { path: editor.getFirstLeaf(path)[1], offset: 0 }))
    e.preventDefault()
    e.stopPropagation()
  }

  const setOptions = opts=>{
    Transforms.setNodes(editor, { ...leaf, ...opts }, { at: path })
  }

  const classes = `fit-x fit-y clickable ${leaf.isBlock ? "contain" : "cover"}`

  let caption
  if (leaf.caption) {
    const context = {source: {}, target: {}}
    if (rootID !== profileID && rootID) {
      const rootCTX = getProfile(rootID).getContext()
      const srcCTX = getProfile(profileID).getContext()
      context.source = {...rootCTX, ...srcCTX}
      // context.source._components = rootCTX._components
    }
    else
      context.source = {...getProfile(profileID).getContext()}
  
    const tempEvent = new EventModel()
    const res = tempEvent.eval(leaf.caption, context)

    caption = <Row absolute bottom className="black-tint size-large text-dull pad" style={{zIndex: 2}}>{res}</Row>
  }

  return <div className={`column rounded click relative`} {...attributes} onContextMenu={e=>e.stopPropagation()} contentEditable={false} style={style} onClick={selectClick}>
    {/* <UniversalDrag item={{ url }} className="grow" style={{ display: "inline", margin: leaf.isBlock ? null : "1rem" }}> */}
    <Dropdown trigger="contextMenu" overlay={<Menu>
      {editor._readOnly && <Menu.Item onClick={e=>window.isLocal ? Logs.chat(`${window.location.origin}${url}`) : Logs.chat(url)}>Share in Chat</Menu.Item>}
      
      {!editor._readOnly && leaf.floating && <Menu.Item onClick={e=>window.ask({title: "Pick File", type: "file"}, res=>setOptions({src: res.url}))}>Change Image</Menu.Item>}
      {!editor._readOnly && <Menu.Item onClick={e=>window.ask({title: "Pick File", type: "file"}, res=>setOptions({src: res.src}))}>Change Image</Menu.Item>}
      {!editor._readOnly && <Menu.Item onClick={e=>window.ask({title: "Set Caption (Event Script)", value: leaf.caption, allowEmpty: true}, res=>setOptions({caption: res}))}>Set Caption</Menu.Item>}
      {!editor._readOnly && <Menu.Item onClick={e=>window.ask({title: "Set Max Width (% or px)", value: leaf.maxWidth, allowEmpty: true}, res=>setOptions({maxWidth: res}))}>Set Max Width</Menu.Item>}
      {!editor._readOnly && <Menu.Item onClick={e=>window.ask({title: "Set Max Height (% or px)", value: leaf.maxHeight, allowEmpty: true}, res=>setOptions({maxHeight: res}))}>Set Max Height</Menu.Item>}
    </Menu>}>
      <MediaApp controls className={classes} src={url} onMouseDown={selectClick} style={{ zIndex: 1 }} />
    </Dropdown>
    {caption}
    {/* </UniversalDrag> */}
    {children}
  </div>
}



const TableCell = ({ attributes, element, children }) => {
  return <td className={`${element.compact ? "compact" : ""}`} width={element.width} {...attributes}/**  onMouseUp={rightClick} style={{ minWidth: rowWidth, maxWidth: rowWidth }} */>
    {children}
  </td>
}

const TableRow = ({ attributes, children }) => {
  return <tr {...attributes} className="dark">
    {children}
  </tr>
}

const Table = ({ attributes, element, children }) => {
  const { profileID } = useProfile()
  const editor = useSlate()
  const { selection, _readOnly } = editor
  const { flatten } = useContext(SlateContext)

  const rollTable = gm => window.ask({title: "Enter roll equation", value: element.die}, res=>{
    const path = ReactEditor.findPath(editor, ReactEditor.toSlateNode(editor, attributes.ref.current))
    let rowID = 0
    const r = Context.roll(res, {})
    for (const row of element.children) {
      const header = Editor.string(editor, [...path, rowID, 0])
      // if (rowID === 0) {
      //   const dice = /([\d]*)d([\d]+)/i
      //   const die = header.match(dice)
      //   console.log(dice[1])
      // }
      // else {
      const reg = /([\d]+)(-([\d]+))?/i
      const match = header.match(reg)
      if (match) 
        if ((match[1] == Number(r.equation) && (match[3] == null || match[3] == undefined)) || (Number(r.equation) >= match[1] && Number(r.equation) <= match[3])) {
          const l = element.children[rowID].children.length-1
          const data = element.children[rowID].children[l].children

          const event = Resources.Events.create()
          const profile = Resources.Profiles.get(profileID)
          let source = {}
          if (profile) {
            source = profile.getContext()
            event.meta.sourceID = profileID
          }
      
          const context = { source, target: {} }

          event.meta.gmRoll = gm
          event.roll(element.context || "", context).then(e => {
            if (profile) {
              for (const key in event.source)
                profile.changeData(key, event.eval(event.source[key], context))
      
              profile.emit("change")
            }
            event.text = [...event.text, ...data]
            event.record()
          })
          break
        }
      rowID++
    }
  })

  const style = flatten ? {
    display: "block",
    overflowX: "auto",
    whiteSpace: "nowrap",
  } : undefined

  const tStyle = flatten ? {
    display: "table",
    width: "100%"
  } : undefined

  if ( _readOnly && element.die) 
    return <Dropdown trigger="contextMenu" overlay={<Column className="background rounded">
      {element.die && editor._readOnly ? <Button key="roll" onClick={e=>rollTable()}>Roll on Table</Button> : undefined}
      {element.die && editor._readOnly ? <Button key="rollgm" onClick={e=>rollTable(true)}>Roll on Table (GM)</Button> : undefined}
    </Column>}>
      <table className={`fit-x ${element.compact ? "compact" : "editing"}`} style={style} {...attributes}>
        <tbody style={tStyle}>
          {children}
        </tbody>
      </table>
    </Dropdown>


  return <table className={`fit-x ${element.compact ? "compact" : "editing"}`} style={style} {...attributes}>
    <tbody style={tStyle}>
      {children}
    </tbody>
  </table>
}
const isMobile = false
const LayoutColumn = ({ attributes, element, children }) => {
  const { flatten } = useContext(SlateContext)
  const [path, setPath] = useState()
  const editor = useEditor()
  const selected = useSelected()
  useEffect(() => setPath(ReactEditor.findPath(editor, ReactEditor.toSlateNode(editor, attributes.ref.current))), [])

  const flat = (isMobile || flatten) && (path && path.length === 2)
  return <div className={`${editor._readOnly ? "" : "hover-background"} ${selected ? "darker" : ""} grow`} {...attributes} style={{ minWidth: flat ? null : element.width, maxWidth: flat ? null : element.width }}>
    <div className="fit-x">
      {children}
    </div>
  </div>
}

const Layout = ({ attributes, children }) => {
  const { flatten } = useContext(SlateContext)
  const [path, setPath] = useState()
  const editor = useEditor()

  useEffect(() => setPath(ReactEditor.findPath(editor, ReactEditor.toSlateNode(editor, attributes.ref.current))), [])

  const flat = (isMobile || flatten) && (path && path.length === 1)
  return <div className={`fit-x ${flat ? "" : "row"} between ${editor._readOnly ? "" : "hover-border"}`} {...attributes}>
    {children}
  </div>
}


const withHelpers = editor => {
  const { isVoid, normalizeNode, isInline, deleteBackward, deleteForward } = editor
  editor.deleteBackward = unit => {
    if (unit === "character") {
      const { selection } = editor
      if (Range.isCollapsed(selection)) {
        const [node, nodePath] = Editor.node(editor, selection)
        const before = Editor.before(editor, selection)

        const [container, containerPath] = editor.getContainer(nodePath)
        const [parent, parentPath] = editor.getContainer(nodePath, [])
        const atHead = selection.anchor.offset === 0

        if (editor.isContainer(container)) {
          // delete the whole container
          const [item, itemPath] = editor.getContainer(nodePath, ["paragraph", "mention", "data", "event"])
          const start = itemPath[itemPath.length - 1] === 0

          const sameParent = JSON.stringify(itemPath) === JSON.stringify(containerPath)
          if (editor.isEmpty(container))
            return Transforms.removeNodes(editor, { at: containerPath })
          else if (editor.isEmpty(item))
            return Transforms.removeNodes(editor, { at: itemPath })
          else if (sameParent && parentPath[parentPath.length - 1] === 0 && atHead) {
            if (editor.isEmpty(parent) && container.type !== "cell") {
              Transforms.removeNodes(editor, { at: parentPath })
              const newPath = [...containerPath]
              Transforms.insertNodes(editor, { type: "paragraph", children: [{ text: "" }] }, { at: newPath })
              newPath.push(0)
              Transforms.setSelection(editor, Editor.range(editor, Editor.start(editor, newPath), Editor.end(editor, newPath)))
            }
            return
          }
          else if (!sameParent && start && atHead && !editor.isEmpty(item))
            return
        }
      }
    }
    return deleteBackward(unit)
  }

  editor.deleteForward = unit => {
    if (unit === "character") {
      const { selection } = editor
      if (Range.isCollapsed(selection)) {
        const [node, nodePath] = Editor.node(editor, selection)

        const [parent, parentPath] = editor.getContainer(nodePath, [])
        const [container, containerPath] = editor.getContainer(nodePath)
        const atTail = selection.focus.offset === node.text.length

        if (editor.isContainer(container)) {
          // delete the whole container
          const [item, itemPath] = editor.getContainer(nodePath, ["paragraph", "mention", "data", "event"])
          const end = itemPath[itemPath.length - 1] === container.children.length - 1

          const sameParent = JSON.stringify(itemPath) === JSON.stringify(containerPath)
          if (editor.isEmpty(container))
            return Transforms.removeNodes(editor, { at: containerPath })
          else if (editor.isEmpty(item)) {
            Transforms.removeNodes(editor, { at: itemPath })
            const newPath = [...itemPath, 0]
            Transforms.setSelection(editor, Editor.range(editor, Editor.start(editor, newPath), Editor.start(editor, itemPath)))
            return
          }
          else if (sameParent && parentPath[parentPath.length - 1] === (container.children.length - 1) && atTail) {
            if (editor.isEmpty(parent) && container.type !== "cell") {
              Transforms.removeNodes(editor, { at: parentPath })
              const newPath = [...containerPath]
              newPath[newPath.length - 1] += 1
              Transforms.insertNodes(editor, { type: "paragraph", children: [{ text: "" }] }, { at: newPath })
              newPath.push(0)
              Transforms.setSelection(editor, Editor.range(editor, Editor.start(editor, newPath), Editor.end(editor, newPath)))
            }
            return
          }
          else if (!sameParent && end && atTail && !editor.isEmpty(item))
            return
        }

        // if (editor.isContainer(container)) {
        //   // if the container is empty
        //   const listEnd = parentPath[parentPath.length - 1] === container.children.length - 1

        //   if (container.children.length <= 1 && parent.children.length <= 1 && parent.children[0].text === "") {
        //     // delete the whole container
        //     Transforms.removeNodes(editor, { at: containerPath })
        //   }
        //   else if (parent.children.length <= 1 && parent.children[0].text === "") {
        //     Transforms.removeNodes(editor, { at: parentPath })
        //     const newPath = [...containerPath, 0]
        //     Transforms.setSelection(editor, Editor.range(editor, Editor.start(editor, newPath), Editor.start(editor, newPath)))
        //   }
        //   return
        // }
      }
    }
    return deleteForward(unit)
  } 

  editor.isVoid = element => {
    switch (element.type) {
      case "media":
        return true
      case "component":
        return true
      case "list":
        return true
      case "element":
        return true
      case "divider":
        return true
      case "data":
        return true
      case "effect":
        return true
      case "formula":
        return true
      case "event":
        return true
      case "mention":
        return true
      case "link":
        return true
      case "component":
        return true
      // case "table":
      //   return true
      // case "row":
      //   return true
      // case "cell":
      //   return true
      default:
        return isVoid(element)
    }
  }

  editor.canGrow = element => {
    switch (element.type) {
      case "data":
        return true
      case "formula":
        return true
      case "roll":
        return true
      case "event":
        return true
      case "link":
        return true
      case "mention":
        return true
      default:
        return false
    }
  }

  editor.isText = element => {
    return element.text != null ? true : Text.isText(element)
  }

  editor.isContainer = element => {
    switch (element.type) {
      case "section":
        return true
      case "secret":
        return true
      case "quote":
        return true
      case "numbered-list":
        return true
      case "bulleted-list":
        return true
      case "cell":
        return true
      default:
        return false
    }
  }

  editor.isSection = element => {
    switch (element.type) {
      case "cell":
        return true
      case "section":
        return true
      case "secret":
        return true
      case "quote":
        return true
      case "column":
        return true
      case "header":
        return true
      case "list-item":
        return true
      // case "table":
      //   return true
      // case "row":
      //   return true
      // case "cell":
      //   return true
      default:
        return false
    }
  }

  editor.isInline = element => {
    if (element)
      switch (element.type) {
        case "data":
          return true
        case "mention":
          return true
        case "media":
          return true
        case "formula":
          return true
        case "event":
          return true
        case "macro":
          return true
        case "roll":
          return true
        case "link":
          return true

        default:
          return isInline(element)
      }
    return isInline(element)
  }

  editor.getFirstLeaf = currentPath => {
    function getLeaf(path) {
      if (path.length)
        if (path) {
          const result = Editor.first(editor, path)
          if (result) {
            const [child, childPath] = result
            if (editor.isText(child))
              return result
            else
              return getLeaf(childPath)
          }
        }

      return [editor, []]
    }

    return getLeaf(currentPath ?? editor.selection.focus.path)
  }


  editor.getLastLeaf = currentPath => {
    function getLeaf(path) {
      if (path.length)
        if (path) {
          const result = Editor.last(editor, path)
          if (result) {
            const [child, childPath] = result
            if (editor.isText(child))
              return result
            else
              return getLeaf(childPath)
          }
        }

      return [editor, []]
    }

    return getLeaf(currentPath ?? editor.selection.focus.path)
  }

  editor.isEmpty = element => {
    if (element.type === "paragraph")
      return element.children.length === 1 && element.children[0].text === ""

    if (element.children && element.children.length === 1)
      return editor.isEmpty(element.children[0])
  }

  editor.getContainer = (currentPath, ignore = ["paragraph", "mention", "data", "event", "list-item"], whitelist) => {
    function getParent(path) {
      if (path.length) {
        const result = Editor.parent(editor, path)
        if (result) {
          const [parent, parentPath] = result
          if (!editor.isInline(parent) && !ignore.includes(parent.type) && !whitelist)
            return result
          else if (!editor.isInline(parent) && whitelist && whitelist.includes(parent.type))
            return result
          else
            return getParent(parentPath)
        }
      }
      return [editor, []]
    }

    return getParent(currentPath ?? editor.selection.anchor.path)
  }

  // console.log(editor)
  editor.normalizeNode = ([node, path]) => {
    if (node.type === "paragraph") {
      const head = node.children[0]
      // const tail = node.children[node.children.length - 1]
      // ensure inlines are buffered
      if (head && head.isBlock)
        return

      if (!node.children.length) {
        Transforms.insertNodes(editor, { text: "" }, { at: [...path, 0] })
        return normalizeNode([node, path])
      }

      let rewrite = false
      let newChildren = []
      for (let i = 0; i < node.children.length; i++) {
        const text = node.children[i]
        if (i === 0 && editor.isInline(text)) {
          newChildren.push({ text: "" })
          rewrite = true
        }

        if (editor.isInline(text) && i >= 0) {
          const last = node.children[i - 1]
          if (editor.isInline(last)) {
            newChildren.push({ text: "" })
            rewrite = true
          }
        }

        if (text && text.text !== null)
          newChildren.push(text)
        else {
          rewrite = true
        }

        if (i == node.children.length - 1 && editor.isInline(text)) {
          newChildren.push({ text: "" })
          rewrite = true
        }
      }
      if (rewrite) {
        const newInsert = { ...node, children: newChildren }
        Transforms.delete(editor, { at: path, unit: "block" })
        Transforms.insertNodes(editor, newInsert, { at: path })
        return
      }
      // if (editor.isInline(tail) || tail.children) {
      //   const end = Editor.point(editor, [...path, node.children.length - 1], { edge: "end" })
      //   Transforms.insertNodes(editor, { text: " " }, { at: end })
      // }
      // else if (editor.isInline(head) || head.children)
      //   // const start = Editor.point(editor, [...path, 0], { edge: "start" })
      //   Transforms.insertNodes(editor, { text: " " }, { at: [...path, 0] })

      return normalizeNode([node, path])
    }
    else if (node.type === "table" && path.length !== 0) {
      // const head = node.children[0]
      // const tail = node.children[node.children.length - 1]
      if (!node.children || !node.children.length)
        Transforms.delete(editor, { at: path })
      else {
        let rowLength = 0
        for (const child of node.children)
          rowLength = Math.max(rowLength, child.children.length)

        let childIndex = 0
        for (const child of node.children) {
          if (child.children.length < rowLength)
            Transforms.insertNodes(editor, [{ type: "cell", children: [{ type: "paragraph", children: [{ text: "" }] }] }], { at: [...path, childIndex, 0] })
          childIndex++
        }
      }
      return
    }
    else if (editor.isSection(node)) {
      if (node.children.length === 0)
        Transforms.insertNodes(editor, { type: "paragraph", children: [{ text: "" }] }, { at: [...path, 0] })
      else if (node.children.length === 1 && !node.children[0].type) {
        Transforms.delete(editor, { at: [...path, 0] })
        Transforms.insertNodes(editor, { type: "paragraph", children: [{ text: "" }] }, { at: [...path, 0] })
      }
      return
    }
    return normalizeNode([node, path])
  }

  return editor
}


function prepareValue(raw) {
  const v = CreateValue(raw)
  // CLEANUP ERRORS
  function recurse(v) {
    if (v.children)
      if (v.children.length)
        for (const key in v.children)
          recurse(v.children[key])
      else
        v.children = [{ type: "paragraph", children: [{ text: "" }] }]
  }
  for (const key in v)
    recurse(v[key])

  return v
}


const SelectableElement = ({ attributes, element, content, children }) => {
  return <div {...attributes}>
    <div contentEditable={false}>
      {content}
    </div>
    {children}
  </div>
}

let lastChange
export default ({ style, raw, readOnly, onChange, hideAuto, placeholder, className, noDecorate }) => {
  const editor = useMemo(() => withReact(withHistory(withHelpers(createEditor()))), [])
  const [value, setValue] = useState(prepareValue(raw))
  editor._readOnly = readOnly
  const [command, setCommand] = useState()
  const [pos, setPos] = useState({})
  const [target, setTarget] = useState()
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false)
  const [reverse, setReverse] = useState(false)

  const { articleID } = useArticle()
  const { toolbar } = useContext(SlateContext)
  const anchorEl = useRef()
  const ref = useRef()
  useEffect(() => setValue(prepareValue(raw)), [raw])

  const { profileID } = useProfile()
  const { phrases } = useProject()

  // let org = {}
  // for (const category in phrases)
  //   if (phrases[category].phrases && phrases[category].phrases.length)
  //     for (const _term of phrases[category].phrases) {
  //       org[_term] = org[_term] || { categories: [] }
  //       org[_term].categories.push(category)
  //     }


  // let keys = Object.keys(org).sort((a, b) => { return b.length - a.length })
  // let organized = {}
  // for (const _term of keys)
  //   organized[_term] = org[_term]
    
  // const decorate = useCallback(
  //   ([node, path]) => {
  //     const ranges = []
  //     if (!noDecorate)
  //       for (const _term in organized)
  //         if (editor.isText(node) && !node.size && !node.type) {
  //           const term = _term.replace(/\(/g, "\\(").replace(/\)/g, "\\)")
  //           const reg = new RegExp(`([^\\w\\d]${term}[^\\w\\d])`, "gm")
  //           const regm = new RegExp(`([^\w\d]${term})$`, "gm")
  //           const first = new RegExp(`(${term})`, "gm")
  //           const text = node.text

  //           let m = first.exec(text)
  //           if (m && m.index === 0) 
  //             if (m[0].length === text.length || text[m[0].length] === " ")
  //               ranges.push({
  //                 anchor: { path, offset: m.index },
  //                 focus: { path, offset: m.index + _term.length },
  //                 reference: organized[_term].categories[0],
  //               })

  //           m = regm.exec(text) || reg.exec(text)
  //           while (m) {
  //             ranges.push({
  //               anchor: { path, offset: m.index },
  //               focus: { path, offset: m.index + _term.length },
  //               reference: organized[_term].categories[0],
  //             })
  //             m = reg.exec(text)
  //           }
  //         }
  //     return ranges
  //   },
  //   [phrases]
  // )

  const insertCommand = function (cmd, selection) {
    const backSelection = selection
    const cb = result => {

      if (result) {
        setTimeout(() => {
          ReactEditor.focus(editor)
          setTimeout(() => {
            Transforms.setSelection(editor, backSelection)
            for (let i = 0; i < search.length; i++)
              Editor.deleteBackward(editor, { unit: "character" }) // delete the search term

            Editor.deleteBackward(editor, { unit: "character" }) // delete the command 

            if (!result.children)
              result.text = result.text || ""

            if (cmd.mark)
              Transforms.setNodes(editor, result, { match: editor.isText, split: true })
            else if (editor.isText(result)) {
              const [node, path] = Editor.node(editor, selection)
              if (node.size)
                result.size = node.size
              Editor.insertNode(editor, result)
              if (editor.isInline(result)) {
                const [newNode, p] = Editor.node(editor, selection)
                const [next, np] = Editor.next(editor, { at: p })
                const [startNode, sp] = Editor.next(editor, { at: np })
                const start = Editor.range(editor, sp, sp)
                Transforms.setSelection(editor, start)
              }
            }
            else if (editor.isInline(result)) {
              const [node, path] = Editor.node(editor, selection)
              if (node.size)
                result.size = node.size
              Editor.insertNode(editor, result)
              const [newNode, p] = Editor.node(editor, selection)
              const [next, np] = Editor.next(editor, { at: p })
              const [startNode, sp] = Editor.next(editor, { at: np })
              const start = Editor.range(editor, sp, sp)
              start.focus.offset = start.anchor.offset
              Transforms.setSelection(editor, start)
            }
            else {
              const [container, path] = editor.getContainer(selection.anchor.path, [])
              if (container.type === "paragraph" && result.type !== "table" && result.type !== "row" && result.type !== "layout") {
                const wk = copy(container.children)
                if (result.children && result.children[0].children && (result.children[0].children[0].size || result.children[0].children[0].bold))
                  for (const idx in wk) {
                    if (editor.isText(wk[idx])) {
                      wk[idx].size = result.children[0].children[0].size
                      wk[idx].bold = result.children[0].children[0].bold
                    }
                  }
                result.children[0].children = wk
              }

              Transforms.insertNodes(editor, result, { at: path })
              const dPath = [...path]
              dPath[dPath.length - 1]++
              Transforms.delete(editor, { at: dPath, unit: "block" })
              const [node, p] = Editor.node(editor, path)
              const [s, sp] = editor.getFirstLeaf(p)
              const start = Editor.range(editor, sp, sp)
              Transforms.setSelection(editor, start)
            }
          }, 1)
        }, 0)
      }
      else {
        Transforms.setSelection(editor, selection)
        for (let i = 0; i < search.length; i++)
          Editor.deleteBackward(editor, { unit: "character" }) // delete the search term

        Editor.deleteBackward(editor, { unit: "character" }) // delete the command 
      }
    }

    if (cmd.result instanceof Function)
      cmd.result(cmd, cb, selection)
    else
      cb(cmd.result)
  }

  let results = commands[command] ? commands[command].menu(editor, search, { profileID }) : []
  if (hideAuto)
    results = results.filter(data=>!(data.auto))
    
  useEffect(() => {
    const height = window.innerHeight * 0.2
    if (anchorEl.current && ref.current && open && target) {
      // console.log(target)
      // const domRange = ReactEditor.toDOMRange(editor, target)
      const wide = anchorEl.current.getBoundingClientRect()
      // const rect = domRange.getBoundingClientRect()

      const domSelection = window.getSelection()
      const domRange = domSelection.getRangeAt(0)
      const rect = domRange.getBoundingClientRect()

      if (rect.top + 16 + 4 + height > window.innerHeight * 0.8) {
        setReverse(true)
        setPos({
          width: `${Math.max(wide.width / 2, 300)}px`,
          left: `${rect.left}px`,
          top: `${rect.top - rect.height - height}px`,
          margin: "16px 0px",
          zIndex: 1500,
          height
        })
      }
      else {
        setReverse(false)
        setPos({
          width: `${Math.max(wide.width / 2, 300)}px`,
          left: `${rect.left}px`,
          top: `${rect.top + 4 + 2}px`,
          margin: "16px 0px",
          zIndex: 1500,
          height
        })
      }

      if (document.getElementById("scroll-target-editor") && rect.height < 100)
        document.getElementById("scroll-target-editor").scrollIntoView({ inline: "center", block: "center" })
    }
    else
      setPos({ ...pos })
  }, [open, editor, index, search, target])



  const isInViewport = function (elem) {
    var bounding = elem.getBoundingClientRect()
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  const onKeyDown = e => {
    // focus your scroll
    const { selection } = editor
    if (selection) {
      const range = ReactEditor.toDOMRange(editor, selection)
      if (range && !isInViewport(range.endContainer.parentElement))
        range.endContainer.parentElement.scrollIntoView({ inline: "end", block: "end" })
    }

    switch (e.key) {
      case ' ': {
        const [start, end] = Range.edges(selection)
        const leaf = Editor.leaf(editor, selection)
        if (Editor.isEnd(editor, end, selection) && leaf && leaf[0].type === "link") {
          Transforms.setNodes(
            editor,
            { type: null },
            { at: selection, split: true }
          )
          e.preventDefault()
          return
        }
        break
      }
      case 'Tab': {
        if (!command) {
          const node = Editor.node(editor, selection)
          const parent = editor.getContainer(node[1])

          if (parent[0].type === "cell" || parent[0].type === "row" || parent[0].type === "table" || parent[0].type === "column") {
            function find(type, editor) {
              const anchorPath = [...editor.selection.anchor.path]
            
              function bubble(path) {
                path.splice(path.length - 1, 1)
                const node = Editor.node(editor, path)
                if (node && node[0].type === type)
                  return node
                else if (path.length)
                  return bubble(path)
              }
            
              const table = bubble(anchorPath)
              return table
            }
            const row = find(parent[0].type === "column" ? "layout" : "row", editor)
            const path = [...row[1]]
            if (parent[1][parent[1].length - 1] + 1 < row[0].children.length) {
              // const p = Range.at(editor, { path: [...path, parent[1][parent[1].length - 1] + 1] })
              // console.log(p)
              const p = [...path, parent[1][parent[1].length - 1] + 1]
              Transforms.select(editor, p)
              Transforms.collapse(editor, { edge: "start" })
              e.preventDefault()
              return
            }
            else {
              const nextRow = Editor.after(editor, row[1])
              if (nextRow) {
                Transforms.select(editor, nextRow)
                Transforms.collapse(editor, { edge: "start" })
                e.preventDefault()
                return
              }
            }
          }
          else if (editor.isSection(parent[0])) {
            const path = [...parent[1]]
            path.splice(parent[1].length - 1, 1)
            const p = [...path, parent[1][parent[1].length - 1] + 1]
            Transforms.select(editor, p)
            Transforms.collapse(editor, { edge: "start" })
            e.preventDefault()
            return
          }
        }
        break
      }
      case 'Enter': {
        if (command && results.length) {
          insertCommand(results[index], editor.selection)
          e.preventDefault()
          e.stopPropagation()
          e.nativeEvent.stopPropagation()
          return
        }
        else if (selection && Range.isCollapsed(selection)) {
          const [node, nodePath] = Editor.node(editor, selection)
          const [container, containerPath] = editor.getContainer(nodePath)

          const atHead = selection.anchor.offset === 0
          const atTail = selection.focus.offset === node.text.length

          if (container && (container.type === "header")) {
            if (atHead) {
              const newPath = [...containerPath]
              Transforms.insertNodes(editor, { type: "paragraph", children: [{ text: "" }] }, { at: newPath })

              newPath.push(0)
              Transforms.setSelection(editor, Editor.range(editor, Editor.start(editor, newPath), Editor.end(editor, newPath)))
            }
            else if (atTail) {
              const newPath = [...containerPath]
              newPath[newPath.length - 1] += 1
              Transforms.insertNodes(editor, { type: "paragraph", children: [{ text: "" }] }, { at: newPath })

              newPath.push(0)
              Transforms.setSelection(editor, Editor.range(editor, Editor.start(editor, newPath), Editor.end(editor, newPath)))
            }

            e.preventDefault()
            return
          }
          else if (container && (container.type === "numbered-list" || container.type === "bulleted-list")) {
            const [item, itemPath] = editor.getContainer(nodePath, [], ["list-item"])

            if (editor.isEmpty(container)) {
              Transforms.removeNodes(editor, { at: containerPath })
              Transforms.insertNodes(editor, { type: "paragraph", children: [{ text: "" }] }, { at: containerPath })
              e.preventDefault()
              return
            }
            else if (editor.isEmpty(item)) {
              Transforms.removeNodes(editor, { at: itemPath })
              const newPath = [...containerPath]
              newPath[newPath.length - 1] += 1
              Transforms.insertNodes(editor, { type: "paragraph", children: [{ text: "" }] }, { at: newPath })
              newPath.push(0)
              Transforms.setSelection(editor, Editor.range(editor, Editor.start(editor, newPath), Editor.end(editor, newPath)))

              e.preventDefault()
              return
            }
            else {
              const newPath = [...itemPath]
              newPath[newPath.length - 1] += 1
              Transforms.insertNodes(editor, { type: "list-item", children: [{ type: "paragraph", children: [{ text: "" }] }] }, { at: newPath })

              newPath.push(0)
              Transforms.setSelection(editor, Editor.range(editor, Editor.start(editor, newPath), Editor.end(editor, newPath)))
              e.preventDefault()
              return
            }
          }
          else if (editor.isContainer(container) && editor.isEmpty(container) && container.type !== "cell") {
            Transforms.setNodes(editor, { type: "paragraph", children: [{ text: "" }] }, { at: containerPath })
            e.preventDefault()
            return
          }
        }
        break
      }
      case 'ArrowDown': {
        if (command) {
          e.preventDefault()
          e.stopPropagation()
          e.nativeEvent.stopPropagation()
          if (reverse) {
            let newSelection = index - 1
            if (newSelection < 0)
              newSelection = Math.max(results.length - 1, 0)
            setIndex(newSelection)
          }
          else
            setIndex((index + 1) % results.length)
        }
        return
      }
      case 'ArrowUp': {
        if (command) {
          e.preventDefault()
          e.stopPropagation()
          e.nativeEvent.stopPropagation()
          if (reverse)
            setIndex((index + 1) % results.length)
          else {
            let newSelection = index - 1
            if (newSelection < 0)
              newSelection = Math.max(results.length - 1, 0)
            setIndex(newSelection)
          }
        }
        return
      }
      default:
        break
    }

    if (!e.ctrlKey)
      return

    switch (e.key) {
      case 'a': {
        const [node, path] = Editor.node(editor, selection)
        const [parent, pPath] = editor.getContainer(path, ["paragraph", "mention", "data", "event"])
        if (editor.isSection(parent)) {
          Transforms.setSelection(editor, Editor.range(editor, Editor.start(editor, [...pPath, 0]), Editor.end(editor, [...pPath, parent.children.length - 1])))
          e.preventDefault()
          return
        }
        break
      }
      case '`': {
        e.preventDefault()
        const [match] = Editor.nodes(editor, {
          match: n => n.code, at: editor.selection
        })
        Transforms.setNodes(
          editor,
          { code: match ? null : true },
          { match: editor.isText, split: true }
        )
        break
      }
      case 's': {
        e.preventDefault()
        const [match] = Editor.nodes(editor, {
          match: n => n.strike, at: editor.selection
        })
        Transforms.setNodes(
          editor,
          { strike: match ? null : true },
          { match: editor.isText, split: true }
        )
        break
      }
      case 'u': {
        e.preventDefault()
        const [match] = Editor.nodes(editor, {
          match: n => n.underline, at: editor.selection
        })
        Transforms.setNodes(
          editor,
          { underline: match ? null : true },
          { match: editor.isText, split: true }
        )
        break
      }
      case 'i': {
        e.preventDefault()
        const [match] = Editor.nodes(editor, {
          match: n => n.italic, at: editor.selection
        })
        Transforms.setNodes(
          editor,
          { italic: match ? null : true },
          { match: editor.isText, split: true }
        )
        break
      }
      case 'b': {
        e.preventDefault()
        const [match] = Editor.nodes(editor, {
          match: n => n.bold, at: editor.selection
        })
        Transforms.setNodes(
          editor,
          { bold: match ? null : true },
          { match: editor.isText, split: true }
        )
        break
      }
      default:
        break
    }
  }


  const onKeyUp = e => {
    if (e.key === "Enter") {
      e.preventDefault()
      e.stopPropagation()
      e.nativeEvent.stopPropagation()
    }
    return false
  }

  function settle() {
    if (lastChange > Date.now())
      return
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      const [focus, focusPath] = Editor.node(editor, selection.focus)
      if (focus.type === "table" || focus.type === "row" || focus.type === "cell" || focus.type === "secret" || focus.type === "section" || focus.type === "quote" || focus.type === "layout") {
        const [leaf, leafPath] = editor.getFirstLeaf()
        Editor.setSelection(editor, Editor.range(editor, leafPath, leafPath))
      }
      else {
        // this isn't the best way, but it was easier at the time, for little complication
        const [start] = Range.edges(selection)
        const charBefore = Editor.before(editor, start, { unit: 'character' })
        const beforeNode = Editor.before(editor, start) ? Editor.node(editor, Editor.before(editor, start)) : [{}]

        if (charBefore && !Editor.leaf(editor, charBefore)[0].type && !beforeNode[0].type) {
          const beforeRangeChar = charBefore && Editor.range(editor, charBefore, start)
          const beforeTextChar = beforeRangeChar && Editor.string(editor, beforeRangeChar)
          const beforeMatchChar = beforeTextChar && beforeTextChar.match(/([/@#!=])/)
          if (beforeMatchChar) {
            const anchorRange = Editor.range(editor, charBefore, { path: start.path, offset: 0 })
            setTarget(anchorRange)
            setSearch("")
            setCommand(beforeMatchChar[1])
            setIndex(0)
            setOpen(true)
            return
          }
          else {
            const wordBefore = Editor.before(editor, start, { unit: 'word' })
            const before = wordBefore && Editor.before(editor, wordBefore)
            const beforeRange = before && Editor.range(editor, before, start)
            const beforeText = beforeRange && Editor.string(editor, beforeRange)
            const beforeMatch = beforeText && beforeText.match(/([/@#!=]([\w_]*))$/)
            // const after = Editor.after(editor, start)
            // const afterRange = Editor.range(editor, start, after)
            // const afterText = Editor.string(editor, afterRange)
            // const afterMatch = afterText.match(/^(\s|$)/)
            if (beforeMatch && JSON.stringify(start.path) === JSON.stringify(before.path)) { // on the same line
              const anchorRange = Editor.range(editor, before, { path: start.path, offset: 0 })
              setTarget(anchorRange)
              setSearch(beforeMatch[2])
              setCommand(beforeMatch[0][0])
              setIndex(0)
              setOpen(true)
              return
            }
          }
        }
      }
    }
    setCommand(null)
    setTarget(null)
    setSearch(null)
    setOpen(false)
  }

  if (readOnly)
    editor.setFragmentData = e=>{} // fixes drag and drop issues

  editor.reset = () => {
    setTimeout(() => {
      setValue([{ type: "paragraph", children: [{ text: "" }] }])
      settle()
    }, 10)
  }

  const renderElement = useCallback(props => {
    const { attributes, children, element } = props
    const sizes = {
      "h1": "size-2",
      "h2": "size-larger",
      "h3": "size-large",
      "sub": "size-small",
    }
    const heights = {
      "h1": "125%",
      "h2": "125%",
      "h3": "125%",
      "sub": "175%",
    }
    // console.log(sizes)


    const style = {
      // textDecoration: leaf.underline ? 'underline' : 'normal',
      // fontStyle: leaf.italic ? 'italic' : 'normal',
      lineHeight: element.size ? heights[element.size] : null,
    }

    const classes = `${sizes[element.size] || ""} ${element.size ? "text-title" : "text"}`

    // if (props.element.type === "header")
    //   console.log(`${articleID}-${props.element.uid}-${seed}`)
    switch (element.type) {
      case "header":
        return <div id={`${articleID}-${element.bookmark}`} classes={classes}>
          <div {...attributes} style={{ textShadow: "1px 1px 1px black" }}>
            {children}
          </div>
          <div contentEditable={false}>
            <hr style={{ minHeight: "4px", marginTop: "0", marginBottom: "1em" }}/>
          </div>
        </div>

      case "divider":
        return <SelectableElement {...props} content={<hr style={{minHeight: element.height, background: element.background}}/>} />
      case "numbered-list":
        return <ol {...attributes}>{children}</ol>
      case "bulleted-list":
        return <ul {...attributes}>{children}</ul>
      case "list-item":
        return <li {...attributes}>{children}</li>
      case "quote":
        return <Dropdown trigger="contextMenu" overlay={<Menu>
          <Menu.Item onClick={e=>{
            const evt = Resources.Events.create()
            evt.loaded = true
            evt.text = [element]
            evt.record()
          }}>
            Share in Chat
          </Menu.Item>
        </Menu>}>
          <blockquote onContextMenu={e=>e.stopPropagation()} {...attributes}>{children}</blockquote>
        </Dropdown>
      case "secret":
        return <Secret {...props} />
      case "section":
        return <Dropdown trigger="contextMenu" overlay={<Menu>
          <Menu.Item onClick={e=>{
            const evt = Resources.Events.create()
            evt.loaded = true
            evt.text = [element]
            evt.record()
          }}>
            Share in Chat
          </Menu.Item>
        </Menu>}>
        <div onContextMenu={e=>e.stopPropagation()} className={`section ${element.mode}`} {...attributes}>
          {children}
        </div>
      </Dropdown>
      case "component":
        return <Component {...props}/>

      case "media":
        return <Media {...props} leaf={element} />

      case "table":
        return <Table {...props} />
      case "row":
        return <TableRow {...props} />
      case "cell":
        return <TableCell {...props} />
      case "layout":
        return <Layout {...props} />
      case "column":
        return <LayoutColumn {...props} />
      case "data":
        return <span {...attributes} className={classes} style={{ ...style }} contentEditable={false}>
          <SmartData attributes={attributes} element={element} />
          {children}
        </span>
      case "formula":
        return <span {...attributes} className={classes} style={{ ...style }} contentEditable={false}>
          <SmartFormula formula={element.formula} attributes={attributes} />
          {children}
        </span>
      case "event":
        return <span {...attributes} className={classes} style={{ ...style }} contentEditable={false}>
          <Event attributes={attributes} eventID={element.eventID} prefix={element.script} mask={element.mask} />
          {children}
        </span>
      case "mention":
        return <Mask
          attributes={attributes}
          element={element}
          children={children}
          child={Mention}
          className={classes}
          style={style}
        />
      case "link":
        return <Mask
          attributes={attributes}
          element={element}
          children={children}
          child={LINK}
          className={classes}
          style={style}
        />
      case "paragraph":
        return <DefaultElement {...props} className={classes} style={style} />
      default:
        return <DefaultElement {...props} className={classes} style={style} />
    }
  }, [articleID])

  // Define a leaf rendering function that is memoized with `useCallback`.
  const renderLeaf = useCallback(props => {
    const { leaf, attributes } = props
    const sizes = {
      "h1": "size-2",
      "h2": "size-larger",
      "h3": "size-large",
      "sub": "size-small",
    }
    const heights = {
      "h1": "125%",
      "h2": "125%",
      "h3": "125%",
      "sub": "175%",
    }
    // console.log(sizes)

    const style = {
      // textDecoration: leaf.underline ? 'underline' : 'normal',
      // fontStyle: leaf.italic ? 'italic' : 'normal',
      // color: leaf.size ? "white" : null,
      // fontSize: leaf.size ? sizes[leaf.size] : null,
      lineHeight: leaf.size ? heights[leaf.size] : null,
    }
    const classes = `${sizes[leaf.size] || ""} ${leaf.size ? "text-title" : "text"}`

    let children = props.children
    // console.log(style, leaf)
    if (leaf.bold)
      children = <b>{children}</b>

    if (leaf.italic)
      children = <i>{children}</i>

    if (leaf.underline)
      children = <u>{children}</u>

    if (leaf.code)
      children = <p className="code">{children}</p>

    if (leaf.strike)
      children = <s>{children}</s>

    if (leaf.superscript)
      children = <sup>{children}</sup>

    if (leaf.subscript)
      children = <sub>{children}</sub>

    if (leaf.reference)
      children = <Reference term={leaf.reference} phrases={phrases}>{children}</Reference>

    if (leaf.type === "macro")
      return <span className={classes + (leaf.inline ? "" : " row")} {...attributes} style={style} contentEditable={false}>
        <SmartMacro equation={leaf.equation} mask={leaf.mask} inline={leaf.inline} />
        {children}
      </span>
    else if (leaf.type === "roll")
      return <span {...attributes} className={classes} style={style} contentEditable={false}>
        <SmartRoll index={leaf.index} mask={leaf.mask} showTotal />
        {children}
      </span>

    return <span {...attributes} className={classes} style={style}>
      {children}
    </span>
  }, [])

  return <div className={(className || "") + " grow"} style={style} ref={anchorEl}>
    <div className={`${reverse ? "column-reverse" : "column"} fixed no-click ${results.length ? "visible" : "invisible"}`} style={pos} ref={ref} onScroll={e => e.stopPropagation()}>
      <Column reverse={reverse} className="black card-outline rounded card-shadow" click={results.length ? true : false} style={{ overflowY: "auto", maxHeight: pos.height }}>
        {results.map((item, i) => <Row id={index === i ? "scroll-target-editor" : undefined} className={`pad-h ${index === i ? "foreground" : ""} clickable`} key={i} onMouseDown={e => {
          insertCommand(results[i], editor.selection)
          e.preventDefault()
        }}>
          {item.name}
        </Row>)}
      </Column>
    </div>
    <Slate editor={editor} value={value} onChange={newValue => {
      if (editor._readOnly)
        return
      if (newValue[newValue.length - 1].type === "table" || newValue[newValue.length - 1].type === "layout")
        setValue([...newValue, { type: "paragraph", children: [{ text: "" }] }])
      else
        setValue(newValue)

      if (onChange && JSON.stringify(value) !== JSON.stringify(newValue))
        AddSave("change", e => onChange(newValue))

      lastChange = 0
      settle()
    }}>
      <Editable
        spellCheck
        // decorate={decorate}
        readOnly={readOnly ?? false}
        onCut={readOnly ? (e=>(true)): (e=>(true))} // hack to force the interal editor to update when read only changes
        onCopy={readOnly ? (e=>(true)): (e=>(true))}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder={placeholder || (readOnly ? "" : "Type '/' for commands")}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
      />
      {!readOnly && toolbar && toolbar.current && <Toolbar readOnly={readOnly} anchorEl={anchorEl} />}
    </Slate>
  </div>
}