import React, { useEffect, useState, useRef } from 'react'
import Row from './Row'
import { Input } from 'antd'

export default opts => {
  const { inputClass, type, size, onChange, onConfirm, canEdit, defaultValue, placeholder } = opts
  const props = { ...opts }
  delete props.inputClass
  delete props.type
  delete props.size
  delete props.onChange
  delete props.onConfirm

  const saveInputRef = useRef()

  const [enterSearch, setEnterSearch] = useState(false)
  const [inputValue, setInputValue] = useState(defaultValue)

  useEffect(()=>setInputValue(defaultValue), [defaultValue])

  useEffect(() => {
    if (enterSearch && saveInputRef && saveInputRef.current) {
      setInputValue(defaultValue)
      saveInputRef.current.focus()
    }
  }, [enterSearch, defaultValue])

  const showInput = () => setEnterSearch(!enterSearch)
  const handleInputChange = e => {
    setInputValue(e.target.value)
    if (onChange)
      onChange(e.target.value)
  }
  const handleInputConfirm = () => {
    console.log(inputValue)
    if (onConfirm)
      onConfirm(inputValue)
    setInputValue("")
    setEnterSearch(false)
  }

  return enterSearch ?
    <Row style={props.style} className={props.className || props.sizeClassName}>
      {props.textarea ? <Input.TextArea
      ref={saveInputRef}
      size={props.rows}
      value={inputValue}
      placeholder={placeholder}
      onChange={handleInputChange}
      onBlur={handleInputConfirm}
      onPressEnter={handleInputConfirm}
      className={inputClass ?? "foreground grow"}
      style={{boxShadow: "0px 0px 10px rgba(0,0,0,0.7)"}}
    /> : <Input
      ref={saveInputRef}
      type={type ?? "text"}
      size={size}
      value={inputValue}
      placeholder={placeholder}
      onChange={handleInputChange}
      onBlur={handleInputConfirm}
      onPressEnter={handleInputConfirm}
      className={inputClass ?? "foreground grow"}
      style={{boxShadow: "0px 0px 10px rgba(0,0,0,0.7)"}}
    />}</Row> :
    <Row style={props.style} className={props.className} onClick={e => {
      if (canEdit == null || canEdit(e))
        showInput()
    }}>{props.children}</Row>
}