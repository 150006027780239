import Serial from '../../core/models/Serial'

import Account from '../../core/state/Account'
import Network from '../../core/state/Network'
import { Projects, Profiles, Articles } from '../../core/state/Database'
import Resources from '../state/Resources'
import { Wing, localSocket } from '../../Wing'
// import Library from '../state/Library'
import rfdc from 'rfdc'

const copy = rfdc()

export default class Navigation extends Serial {
  bookmarks = []
  history = []
  future = []
  opened = {} // state describing if folders are opened or closed
  tabs = {} // state describing if folders are opened or closed
  phrases = {}
  trackers = []
  hotbar = {}
  apps = {}
  wiki = true
  scene = false
  editing = false
  compendium = true
  
  saveState() {
    // adds a state to the stack
  }

  setFocus(value) {
    delete this.compendium
    if (!value)
      delete this.focus
    else
      this.focus = value
    this.emit("change")
  }
  
  toggleFocus(value) {
    if (this.focus === value)
      this.setFocus()
    else
      this.setFocus(value)
  }

  setTab(profile, value) {
    if (!value)
      delete this.tabs[profile]
    else
      this.tabs[profile] = value
    this.emit("change", "tabs")
  }
  
  toggleTab(profile, value) {
    if (this.tabs[profile] === value)
      this.setTab(profile)
    else
      this.setTab(profile, value)
  }

  setApp(app, value) {
    this.apps[app] = value
    this.emit("change", "apps")
  }

  toggleApp(app) {
    if (this.apps[app])
      this.setApp(app)
    else
      this.setApp(app, true)
  }

  toggleHotbar(app) {
    const { hotbar } = this
    if (hotbar[app])
      delete hotbar[app]
    else
      hotbar[app] = true

    this.emit("change", "hotbar")
  }

  toggleBookmark(id) {
    if (this.bookmarks.includes(id))
      this.removeBookmark(id)
    else
      this.addBookmark(id)
  }

  addBookmark(id) {
    this.removeBookmark(id, true)
    this.bookmarks.push(id)
    this.emit("change")
  }

  removeBookmark(id, quiet) {
    for (let i in this.bookmarks)
      if (this.bookmarks[i] === id) {
        this.bookmarks.splice(i, 1)
        return quiet ? false : this.emit("change")
      }
  }

  setTracker(uid) {
    this.tracker = uid
    this.emit("change")
  }

  toggleTracker(uid) {
    if (this.tracker === uid)
      this.setTracker()
    else
      this.setTracker(uid)
  }
  
  setHomePage(uid) {
    this.homepage = uid
    this.emit("change")
  }

  toggleHomePage(uid) {
    if (this.homepage === uid)
      this.setHomePage()
    else
      this.setHomePage(uid)
  }

  setCompendium(uid) {
    this.compendium = uid
    this.emit("change")
  }

  toggleCompendium(uid) {
    if (this.compendium === uid)
      this.setCompendium()
    else
      this.setCompendium(uid)
  }

  setBrowser(uid) {
    this.browser = uid
    this.emit("change")
  }

  toggleBrowser(uid) {
    if (this.browser === uid)
      this.setBrowser()
    else
      this.setBrowser(uid)
  }

  setArticle(uid) {
    this.article = uid
    this.emit("change")
  }

  toggleArticle(uid) {
    if (this.article === uid)
      this.setArticle()
    else
      this.setArticle(uid)
  }

  setSlideShow(uid) {
    this.slideshow = uid
    this.emit("change")
  }

  toggleSlideShow(uid) {
    if (this.slideshow === uid)
      this.setSlideShow()
    else
      this.setSlideShow(uid)
  }
  
  setScene(uid) {
    this.scene = uid
    this.emit("change")
  }

  toggleScene(uid) {
    if (this.scene === uid)
      this.setScene()
    else
      this.setScene(uid)
  }

  setMap(uid) {
    this.map = uid
    this.emit("change")
  }

  toggleMap(uid) {
    if (this.map === uid)
      this.setMap()
    else
      this.setMap(uid)
  }

  setProfile(uid) {
    delete this.preview
    if (this.profile !== uid && window.history && window.history.state && window.history.state.profile !== uid)
      window.history.pushState({ profile: uid }, '')

    delete this.settings
    this.profile = uid
    // if (history[history.length-1] !== uid)
    //   history[history.length-1].push(uid)
    this.emit("change")
  }

  toggleProfile(uid) {
    if (this.profile === uid)
      this.setProfile()
    else
      this.setProfile(uid)
  }

  setPreview(uid) {
    this.preview = uid
    this.emit("change")
  }

  togglePreview(uid) {
    if (this.preview === uid)
      this.setPreview()
    else
      this.setPreview(uid)
  }

  setWizard(uid) {
    this.wizard = uid
    this.emit("change")
  }

  toggleWizard(uid) {
    if (this.wiki === uid)
      this.setWizard()
    else
      this.setWizard(uid)
  }

  setWiki(uid) {
    this.wiki = uid
    this.emit("change")
  }

  toggleWiki(uid) {
    if (this.wiki === uid)
      this.setWiki()
    else
      this.setWiki(uid)
  }

  setEditing(uid) {
    this.editing = uid
    this.emit("change")
  }

  toggleEditing(uid) {
    if (this.editing === uid)
      this.setEditing()
    else
      this.setEditing(uid)
  }

  setSettings(uid) {
    this.settings = uid
    if (uid) {
      this.wiki = true
      this.compendium = true
      delete this.wizardID
    }
    this.emit("change")
  }

  toggleSettings(uid) {
    if (this.settings === uid)
      this.setSettings()
    else
      this.setSettings(uid)
  }

  setBuilding(uid) {
    this.building = uid
    this.emit("change")
  }

  toggleBuilding(uid) {
    if (this.building === uid)
      this.setBuilding()
    else
      this.setBuilding(uid)
  }

  async getProjects() {
    // window.wing = Wing
    // window.account = Account

    const opts = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        route: "Projects",
        noAddons: true
      })
    }

    // console.log(localSocket, localSocket.connected)
    if (window.isLocal)
      return await fetch(`http://127.0.0.1:${window.location.port}/resources/find`, opts).then(r => r.json()).then(data => ({ data })).catch(e=>console.warn(e))

    const service = Wing.service("projects")
    if (Account.uid)
      return await service.find({
        query: {
          $limit: 20,
          $or: [
            { "meta.creator": Account.uid, "meta.deleted": null },
            {
              [`access.users`]: { $ne: {} },
              [`access.users.${Account.uid}`]: { $ne: null },
              "meta.deleted": null
            }
          ]
        }
      })
    else
      return { data: [], total: 0 }
  }

  setProject(uid) {
    this.setProfile()
    this.compendium = true
    this.project = uid
    // when a new project is loaded, flush out all the locally stored content
    if (uid === "local")
      Resources.offline = true
    else
      delete Resources.offline

    if (!Network.host) { // flush cache if you are joining
      Articles.clear()
      Profiles.clear()
      Projects.clear()
    }
    else
      setTimeout(()=>this.loadProjectCodexes(uid), 100) // race condition
    this.emit("change")
  }

  pack() {
    const { history, future, bookmark, article, profile, compendium, project, opened, mode, engine, map, scene, hotbar, app } = this
    return { history, future, bookmark, article, profile, compendium, project, opened, mode, engine, map, scene, hotbar, app }
  }

  load(json) {
    this.history = json.history
    this.future = json.future
    this.bookmark = json.bookmark
    this.article = json.article
    this.profile = json.profile
    this.project = json.project
    this.opened = json.opened
    this.map = json.map

    this.hotbar = json.hotbar || {}
    this.app = json.app

    this.mode = json.mode
    if (this.profile && String(this.profile.match("-temp-")))
      delete this.profile

    this.loaded = true
    this.emit("ready", true)
    if (this.project) {
      this.loadProjectCodexes(this.project)
      // Library.mountProject(this.project)
    }
  }

  deserialize(str) {
    this.load(JSON.parse(str))
  }

  serialize() {
    return JSON.stringify(this.pack())
  }

  removeProject(_id) {
    if (localSocket.connected) {
      const opts = {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          route: "Projects",
          uid: _id
        })
      }
      return fetch(`http://127.0.0.1:${window.location.port}/resources/remove`, opts)
    }
    const service = Wing.service("projects")
    return service.patch(_id, { "meta.deleted": Account.uid })
  }

  async loadCodex(uid) {
    const { Profiles, Projects } = Resources
    function loadProfiles(project) {
      if (Network.host) {
        const remaining = project.profiles
        if (localSocket.connected) {
          for (const uid of remaining) {
            const opts = {
              method: "POST",
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                route: "profiles",
                uid
              })
            }
            fetch(`http://127.0.0.1:${window.location.port}/resources/load`, opts).then(r => r.json()).then(profileData => {
              Profiles.create(profileData.uid, true)
              const cache = Profiles.find(profileData.uid, true)
              cache.target.once("ready", e => cache.saveStorage())
              cache.target.load(profileData)
            }).catch(e => console.warn(e))
          }
        }
        else
          Wing.service("profiles").find({ query: { uid: { $in: remaining }, $limit: 1000 } }).then(e => {
            if (e.total) {
              for (const profileData of e.data) {
                Profiles.create(profileData.uid, true)
                const cache = Profiles.find(profileData.uid, true)
                cache.target.once("ready", e => cache.saveStorage())
                cache.target.load(profileData)
              }
            }
          }).catch(e => console.warn(e))
      }
      else  // source it from the host so data stays consistent
        for (const profileID of project.profiles)
          Profiles.find(profileID, true)
    }

    if (Projects.exists(uid) && Projects.exists(uid).target.loaded) {
      loadProfiles(Projects.get(uid))
      this.phrases = { ...this.phrases }
      Projects.get(this.project).emit("ready")
    }
    else {
      const project = Projects.get(uid)
      project.once("ready", e => {
        loadProfiles(project)
        Projects.get(this.project).emit("ready")
      })
    }
    console.log("Caching offline content")
  }

  async loadProjectCodexes(uid = this.project) {
    const { Projects, Profiles } = Resources
    this.phrases = {}

    if (Projects.exists(uid) && Projects.exists(uid).target.loaded) {
      const project = Projects.get(uid)
      const list = [...project.profiles]
      for (const key in project.codexes)
        if (!project.meta.gmOnly || (!project.meta.gmOnly[uid] || Network.host))
          this.loadCodex(key)

      if (localSocket.connected) {
        for (const uid of list) {
          const opts = {
            method: "POST",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              route: "profiles",
              uid
            })
          }
          fetch(`http://127.0.0.1:${window.location.port}/resources/load`, opts).then(r => r.json()).then(profileData => {
            Profiles.create(profileData.uid, true)
            const cache = Profiles.find(profileData.uid, true)
            if (cache.target)
              cache.target.load(profileData)
          }).catch(e => console.warn(e))
        }
      }
      else
        Wing.service("profiles").find({ query: { uid: { $in: list }, $limit: 1000 } }).then(e => {
          if (e.total) {
            for (const profileData of e.data) {
              Profiles.create(profileData.uid, true)
              const cache = Profiles.find(profileData.uid, true)
              cache.target.load(profileData)
            }
          }
        }).catch(e => console.warn(e))

      // for (const profileID of project.profiles) { // load profiles upfront
      //   const pid = Profiles.get(profileID)
      //   console.log(pid)
      //   if (!pid.loaded)
      //     pid.once("ready", e => {
      //       list.splice(list.indexOf(pid.uid), 1)
      //       articles.splice(articles.indexOf(pid.getArticleID()), 1)
      //       console.log(list.length, articles.length)
      //     })
      //   else {
      //     list.splice(list.indexOf(pid.uid), 1)
      //     articles.splice(articles.indexOf(pid.getArticleID()), 1)
      //     console.log(list.length, articles.length)
      //   }
      // }
    }
    else if (uid) {
      const project = Resources.Projects.get(uid)
      project.once("ready", e => {
        const list = [...project.profiles]

        for (const key in project.codexes)
          if (!project.meta.gmOnly || (!project.meta.gmOnly[uid] || Network.host))
            this.loadCodex(key)

        // Wing.service("profiles").find({ query: { uid: { $in: list }, $limit: 1000 } }).then(e => {
        //   if (e.total) {
        //     for (const profileData of e.data) {
        //       Profiles.create(profileData.uid, true)
        //       const cache = Profiles.find(profileData.uid, true)
        //       cache.target.load(profileData)
        //     }
        //   }
        // }).catch(e => console.warn(e))


        // console.log(list.length, articles.length)
        // for (const profileID of project.profiles) { // load profiles upfront
        //   const pid = Profiles.get(profileID)
        //   if (!pid.loaded)
        //     pid.once("ready", e => {
        //       list.splice(list.indexOf(pid.uid), 1)
        //       articles.splice(articles.indexOf(pid.getArticleID()), 1)
        //       console.log(list.length, articles.length)
        //     })
        //   else {
        //     list.splice(list.indexOf(pid.uid), 1)
        //     articles.splice(articles.indexOf(pid.getArticleID()), 1)
        //     console.log(list.length, articles.length)
        //   }
        // }

      })
    }

    //load up the first project
  }

  getPhrases() {
    const { Projects } = Resources
    let phrases = {}
    if (this.project) {
      const projects = Projects.get(this.project)
      phrases = copy(projects.getPhrases())
      for (const uid in projects.codexes)
        phrases = { ...Projects.get(uid).getPhrases(), ...phrases }
    }
    return phrases
  }

  getEvents() {
    const { Projects } = Resources
    let phrases = copy(this.phrases)
    for (const uid in this.codexes)
      phrases = { ...Projects.get(uid).getEvents(), phrases }
    return phrases
  }

  constructor(ctx) {
    super(ctx)
    this.setMaxListeners(Infinity) // potentially a large number of listeners due to wiki and folders
  }
}
