import React, { createContext, useState, useEffect, useContext } from 'react'
import Account from '../state/Account'
import rfdc from 'rfdc'
const copy = rfdc()
const AccountContext = createContext()

export { AccountContext }

export function WatchAccount(acc = Account) {
  const [uid, setUID] = useState(acc.uid)
  const [id, setID] = useState(acc.id)
  const [name, setName] = useState(acc.name)
  const [rank, setRank] = useState(acc.rank)
  const [avatar, setAvatar] = useState(acc.avatar)
  const [details, setDetails] = useState(copy(acc.details))
  useEffect(() => {
    function ready() {
      setUID(acc.uid)
      setID(acc.id)
      setName(acc.name)
      setRank(acc.rank)
      setAvatar(acc.avatar)
      setDetails(copy(acc.details))
    }

    function auth() {
      setUID(acc.uid)
      setID(acc.id)
      setName(acc.name)
      setRank(acc.rank)
      setAvatar(acc.avatar)
      setDetails(copy(acc.details))
    }

    function authorized() {
      console.log(acc.uid)
      // setUID(acc.uid)
    }

    function change() {
      setUID(acc.uid)
      setID(acc.id)
      setName(acc.name)
      setRank(acc.rank)
      setAvatar(acc.avatar)
      setDetails(copy(acc.details))
    }

    acc.on("ready", ready)
    acc.on("authenticated", authorized)
    acc.on("auth", auth)
    acc.on("change", change)

    return function () {
      acc.off("ready", ready)
      acc.off("authenticated", authorized)
      acc.off("auth", auth)
      acc.off("change", change)
    }
  }, [acc])

  return { name, rank, uid, id, avatar, accountID: uid, details }
}

export function AccountProvider({ acc = Account, children }) {
  return <AccountContext.Provider value={WatchAccount(acc)} children={children} />
}

export default function useAccount() {
  return useContext(AccountContext)
}



