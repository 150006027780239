import React, { useState, useRef, useEffect } from 'react'
import ReactPlayer from 'react-player'
import { Column, Row } from '../UI'
import SyncMedia from './state/SyncMedia'
// use raw HTML so it works in a window

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useNav from "./hooks/useNav"

function formatTime(str, cull, noSeconds) {
  var sec_num = parseInt(str, 10) // don't forget the second param
  var hours = Math.floor(sec_num / 3600)
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60)
  var seconds = sec_num - (hours * 3600) - (minutes * 60)
  if (hours || noSeconds) {
    if (hours < 10)
      hours = "0" + hours
    hours = hours + ":"
  }
  else
    hours = ""

  if (minutes < 10) {
    minutes = "0" + minutes
    if (cull) {
      if (cull >= 1) {
        // minutes = minutes
        if (!minutes)
          minutes = ""
        else
          minutes = minutes + ":"
      }
      else
        minutes = ""
    }
    else
      minutes = minutes + ":"

  }
  else if (!noSeconds)
    minutes = minutes + ":"

  if (seconds < 10)
    seconds = "0" + seconds

  if (noSeconds)
    seconds = ""
  return hours + minutes + seconds
}
window.formatTime = formatTime

export function WrappedVid(opts){
  const { focus } = useNav()
  const [force, setForce] = useState()
  useEffect(()=>{
    function change(){
      setForce(SyncMedia.url)
      console.warn(SyncMedia.url)
    }
    SyncMedia.on("video", change)
    return function(){
      SyncMedia.off("video", change)
    }
  }, [])

  return force ? <Row {...opts}>
    <MediaPlayer />
  </Row> : <Row/>
}

const isMobile = false
export default function MediaPlayer({ width, height }) {
  const [vid, setVideo] = useState(SyncMedia.url)
  const [progress, setProgress] = useState()
  const [duration, setDuration] = useState()
  const [playing, setPlaying] = useState(SyncMedia.playing)
  const [volume, setVolume] = useState(0.5)
  const ref = useRef()

  const video = String(vid || "")

  useEffect(() => {
    function update() {
      setPlaying(SyncMedia.playing)
      setProgress(SyncMedia.progress)
      setDuration(SyncMedia.duration)
      setVolume(SyncMedia.volume)
    }
    function seek(percent) {
      if (!isNaN(duration))
        ref.current.seekTo(percent * duration)
      SyncMedia.setProgress({ played: percent })
    }
    function video() {
      setVideo(SyncMedia.url)
      setPlaying(SyncMedia.playing)
    }
    SyncMedia.on("seek", seek)
    SyncMedia.on("update", update)
    SyncMedia.on("video", video)
    return function () {
      SyncMedia.off("seek", seek)
      SyncMedia.off("update", update)
      SyncMedia.off("video", video)
    }
  })

  return <Column grow center>
    <div className="text-dull card-bg click">
      <ReactPlayer
        url={video}
        volume={Number(volume)}
        width='100%'
        height='100%'
        ref={ref}
        onClick={e=> {
          if (video && (video.match(".mp4") || video.match(".ogg") || video.match(".webm")))
            if (SyncMedia.playing) 
              ref.current.getInternalPlayer().pause()
            else 
              ref.current.getInternalPlayer().play()
        }}
        onDuration={e => SyncMedia.setDuration(e)}
        onProgress={e => SyncMedia.setProgress(e)}
        onPlay={e => { SyncMedia.play(); SyncMedia.emit("play", e); }}
        onPause={e => { SyncMedia.pause(); SyncMedia.emit("pause", e) }}
        onReady={e => SyncMedia.emit("ready", e)}
        onStart={e => SyncMedia.emit("start", e)}
        onEnded={e => SyncMedia.emit("end", e)}
        onBuffer={e => SyncMedia.emit("buffering", e)}
        onBufferEnd={e => SyncMedia.emit("bufferend", e)}
        playing={playing}
      />
    </div>
    {!isMobile && <input
      className="slider click"
      type="range"
      style={{ width: "100%"/*parseInt(width || "640px") - 4*/ }}
      step="0.1"
      onChange={e => SyncMedia.seekTo(e.target.value / duration)}
      value={progress ? duration * progress.played : 0}
      max={duration ?? 0}
      min="0"
    />}
    <div className="row between" style={{ width: "100%"/*parseInt(width || "640px") - 12*/, padding: "0px 4px" }}>
      <span className="row between wrap">
        {!isMobile && <a className="text-dull click" onClick={e=>SyncMedia.setURL()}><FontAwesomeIcon icon={['fas', "times"]}/></a>}
        <input className="slider gaps-h click" type="range" step="0.01" max="1" min="0" style={{ maxWidth: "100%" /*parseInt(width || "640px") - 16*/ }} value={volume} onChange={e => SyncMedia.setVolume(e.target.value)} />
        {!isMobile && <b><FontAwesomeIcon icon={['fas', "volume-up"]}/></b>}
      </span>
      {!isMobile && <span>{duration && progress ? formatTime(String(Math.floor(duration * progress.played)), 1) + " / " + formatTime(String(duration), 1) : "Loading..."}</span>}
      {/* <button onClick={e => setVideo(window.prompt("Enter URL"))}><b>Change Video</b></button> */}
    </div>
  </Column>
}