import React, { useRef, useState } from 'react'

import { Row, Column, Button, Scroll } from '../UI'
import { LoadingOutlined, ReadFilled, DeleteFilled, DoubleRightOutlined } from '@ant-design/icons'
import { Breadcrumb, Tooltip, Switch, Menu, Dropdown } from 'antd'

import { Projects } from '../core/state/Database'
import Resources from "./state/Resources" // inclusion/intiialization dependency, solve it if u can
import Project from './models/Project'
import Nav from './state/Navigation'
import useNav from './hooks/useNav'
import { ProfileProvider } from "./hooks/useProfile"
import useProject, { getProject, ProjectProvider, createArticle, hasRight } from "./hooks/useProject"

import { Profile, ProfileName, ProfileMetaData } from './Profile'
import Wiki from './Wiki'

import { v4 } from 'uuid'

import ProfileDrop from "./ProfileDrop"
import { SlateContext } from './slate/Slate'
import Mention from './slate/inline/Mention'

import { ProjectAvatar, ProjectName, StartProjectWizard, ProjectTags, ProjectSystem } from "./Projects"

import useContent, { ContentProvider } from "./hooks/useContent"

import Logs from './state/Logs'


const ContentButton = opts=>{
  const { projectID } = useProject()
  const { contentID, published } = useContent()
  
  const makePrivate = e => {
    const project = getProject(projectID)
    if (!project.isOwner())
      return window.notify("Not Permitted")

    if (!project.getCommunityID()) {
      // no community precesnse
      project.setCommunityID(project.uid)
    }

    // if (!Account.isMember()) {
    //   window.login(true)
    //   return window.notify("Support Mythic Archive to have private projects")
    // }

    const content = Resources.Community.get(project.getCommunityID())
    if (content.loaded)
      content.setPublic(false)
    else
      content.once("ready", e => content.setPublic(false))
  }

  const makePublic = e => {
    const project = getProject(projectID)
    if (!project.isOwner())
      return window.notify("Not Permitted")

    if (!project.getCommunityID()) {
      // no community precesnse
      project.setCommunityID(project.uid)
    }

    const content = Resources.Community.get(project.getCommunityID())
    content.loaded = true
    content.setName(project.getName())
    content.setImage(project.profile.getBanner().src)
    content.setPublic(true)
    content.setTarget(project.uid)
    content.setArticleID(project.profile.getArticleID())
    content.setSystem(project.getSystem())
    content.setType("project")
    content.setTags(Object.keys(project.meta.descriptors || {}))
  }

  return <Row center>
    <Tooltip title={published ? "Make Private" : "Make Public"}>
      <Switch checked={published} onClick={published ? makePrivate : makePublic}/>
    </Tooltip>
  </Row>
}

export function ProjectCodexButton(opts){
  const { projectID, meta, loaded } = useProject()
  const { communityID: contentID } = meta
  const workingProject = getProject(projectID)

  if (!loaded)
    return <Column><LoadingOutlined/></Column>
  
  if (workingProject.local || !workingProject.isOwner())
    return <div/>

  return <ContentProvider contentID={contentID}>
    <ContentButton />
  </ContentProvider>
}

export function ProjectList(opts) {
    // if (!uid)
  //   return <Column fit center>
  //     <Button className="background" primary>Sign In</Button>
  //   </Column>

  const [projects, setProjects] = useState([])

  const loadLocal = e => {
    const initOffline = () => {
      const proj = new Project({ name: "Local Project" })
      proj.uid = "local"
      Projects.set("local", proj.serialize()).then(e => load(proj))
        .catch(e => {
          console.warn(e)
          window.error("Unable to create local project")
        })
    }
    Projects.get("local").then(e => {
      if (!e)
        initOffline()
      else
        load({ uid: "local" })
    }).catch(err => {
      console.warn(err)
      initOffline(err)
    })
  }

  const createProject = e => {
    // if (!window.isLocal && (!Account.uid || !Account.isMember())) {
    //   // window.login(true)
    //   window.info("Please sign in first")
    //   return false
    // }
    
    // window.ask({ title: "Enter Project Name" }, e => {
      // const uid = (window.isLocal ? "-local-" : "") + v4()
      // const project = Resources.Projects.create(uid)
      // project.setName(e)
      // project.loaded = true
      // project.profile.loaded = true

      // Nav.setProject(uid)

      // const article = createArticle()
      // project.profile.setArticleID(article.uid)
      // article.emit("change")
    // })
    StartProjectWizard().then(({name, type, banner})=>{
      let prefix = ""
      if (window.isLocal)
        prefix = "-local-"
      
      if (window.isDemo)
        prefix = "-temp-"

      const uid = prefix + v4()
      window.warning(uid)
      const project = Resources.Projects.create(uid)
      project.setName(name)
      project.profile.addTag(type)
      project.loaded = true
      project.profile.loaded = true

      Nav.setProject(uid)

      const article = createArticle()
      project.profile.setArticleID(article.uid)
      article.emit("change")
    })
  }


  Nav.getProjects().then(e => {
    //TODO: wayyy too many loads
    // if (e.data && e.data.length)
      // console.log(e.data.map(d=>getProject(d.uid)))
    if (e.data.length !== projects.length)
      setProjects(e.data.map(d=>(d.uid || d)))
  })

  const load = uid => {
    Nav.setProject(uid)
    const project = getProject(uid)
    if (!project.loaded)
      project.once("ready", e=>{
        Logs.state.theater = project.getBackground()
        Logs.emit("change")
      })
    else {
      Logs.state.theater = project.getBackground()
      Logs.emit("change")
    }

    if (opts.onSelect)
      opts.onSelect(uid)
  }
  const remove = uid => window.ask({ type: "confirm", title: "Delete Project", subtitle: "This operation can't be undone." }, e => {
    getProject(uid).meta.deleted = true
    getProject(uid).emit("change")
    const list = [...projects]
    list.splice(list.indexOf(uid), 1)
    setProjects(list)
    Nav.removeProject(getProject(uid)._id).then(r => r.json()).then(e => Nav.emit("change")).catch(e=>console.warn(e, uid))
  })
    
  return <Column fit between>
    <Scroll scrollY>
      {/* {projects.map((tile, index) => <Column center key={index} className="pad-l">
        <ProjectProvider projectID={tile}>
          <Card
            style={{boxShadow: "0px 0px 10px rgba(0,0,0,0.7)"}}
            cover={<ProjectAvatar className="cover" style={{width: "200px", height: "200px"}} />}
            actions={[
              <Button onClick={e => load(tile)}>Load</Button>, 
              // <ProjectCodexButton/>,
              <Button title="Delete Project" onClick={e => remove(tile)}><DeleteFilled/></Button>,
            ]}>
            <Card.Meta title={<ProjectName />}/>
          </Card>
        </ProjectProvider>
      </Column>)} */}
      {projects.map((tile, index)=><ProjectProvider projectID={tile}>
        <Row between index={index} className="background clickable" style={{ borderBottom: "1px solid #141414" }} onClick={e => load(tile)}>
          <Row>
            <Column width="192px" relative>
              <ProjectAvatar className="cover absolute fit-x fit-y" />
            </Column>
            <Column className="pad-l">
              <a className="size-2 text-white"><ProjectName /></a>
            </Column>
          </Row>
          <Row grow reverse wrap>
            <ProjectTags />
          </Row>
          <Column center>
            <Row reverse>
              <Button title="Delete Project" onClick={e => {e.stopPropagation(); remove(tile)}}>
                <DeleteFilled />
              </Button>
              <ProjectSystem/>
            </Row>
          </Column>
        </Row>
      </ProjectProvider>)}
    </Scroll>
    <Row center>
      <Column center className="pad-l">
        <Button className="foreground" primary onClick={createProject} childStyle={{width: "200px"}}>
          New Project
        </Button>
      </Column>
      {/* {!localSocket.connected && <Column className="gaps-l">
        <Card
          actions={[<Button onClick={loadLocal}>Load</Button>]}>
          <Card.Meta title="Sandbox Project" />
        </Card>
      </Column>} */}
      {/* <Button className="background" primary>Browse the Compendium</Button> */}
    </Row>
  </Column>
}

export function ProfileView() {
  const { loaded } = useProject()
  const { profileID } = useNav()
  if (!loaded)
    return <LoadingOutlined/>

  return <ProfileProvider profileID={profileID}>
    <ProfileDrop className="column fit" placement="right">
      <Column fit relative>
        <Scroll scrollX="hidden" scrollY scrollClassName="column">
          <Column style={{ minHeight: "100%" }} className="card-bg">
            <Profile />
          </Column>
          {/* <Row between fitX absolute bottom className="black-tint pad-h text-dull">
                <Row>Author: Grim Dark Dev</Row><Row>Last Changed: Nerd</Row>
              </Row> */}
        </Scroll>
      </Column>
    </ProfileDrop>
  </ProfileProvider>
}

export function ProfileMeta() {
  const { loaded } = useProject()
  const { profileID } = useNav()
  if (!loaded)
    return <LoadingOutlined/>

  return <ProfileProvider profileID={profileID}>
    <Scroll scrollX="hidden" scrollClassName="column">
      <Column style={{ minHeight: "100%" }}>
        <ProfileMetaData />
      </Column>
      {/* <Row between fitX absolute bottom className="black-tint pad-h text-dull">
            <Row>Author: Grim Dark Dev</Row><Row>Last Changed: Nerd</Row>
          </Row> */}
    </Scroll>
  </ProfileProvider>
}


export function Crumbs({compact}) {
  const { loaded, projectID } = useProject()
  const { profileID, compendium } = useNav()
  if (!loaded)
    return <LoadingOutlined />

  const path = profileID ? getProject(projectID).wiki.findProfile(profileID) : "root"

  const stack = getProject(projectID).wiki.root.stack(path) || []

  const selectProfile = profileID => {
    if (!profileID)
      Nav.setHomePage(true)
    if (profileID === Nav.profile)
      Nav.toggleBrowser(true)

    Nav.setProfile(profileID)
  }

  const dropmenu = stack.splice(0, stack.length - 2).map((f, i) => <Menu.Item key={i} onClick={e => selectProfile(f.data)}>{
    <Mention profileID={f.data} placement="right" onClick={true}>
      <ProfileName  title={undefined} />
    </Mention>
  }</Menu.Item>)

  return <Row grow center wrap>
    <Breadcrumb>
      {(!profileID || getProject(projectID).profiles.includes(profileID)) && <Breadcrumb.Item onClick={e => selectProfile()}>
        <a>
          <Mention onClick={true}>
            <ProfileName initials={compact} style={{ fontWeight: !stack.length ? "bold" : "" }} />
          </Mention>
          {!compendium && !stack.length && <a style={{color: "inherit", marginLeft: "4px"}} title="Maximize"><ReadFilled/></a>}
        </a>
      </Breadcrumb.Item>}
      {/* {profileID && !getProject(projectID).profiles.includes(profileID) && !compendium && !stack.length && <Breadcrumb.Item onClick={e => Nav.toggleBrowser(true)}>
        <a>
          <Mention profileID={profileID} onClick={true}>
            <ProfileName initials={compact} style={{ fontWeight: !stack.length ? "bold" : "" }} />
          </Mention>
          <a style={{color: "inherit", marginLeft: "4px"}} title="Maximize"><ReadFilled/></a>
        </a>
      </Breadcrumb.Item>} */}
      {dropmenu.length ? <Breadcrumb.Item>
        <Dropdown placement="topLeft" overlay={<Menu>{dropmenu}</Menu>}>
          <a>
            ...
          </a>
        </Dropdown>
      </Breadcrumb.Item> : null}
      {stack.map((f, i) => <Breadcrumb.Item key={i} onClick={e => selectProfile(f.data)}>
        <a style={{whiteSpace: !compendium && (i === stack.length-1) ? "nowrap" : undefined}}>
          <Mention profileID={f.data} placement="right" onClick={true}>
            <ProfileName initials={compact && !(i === stack.length-1)} maxCharacters={compact ? 20 : undefined} style={{display: "inline-block", fontWeight: (i === stack.length-1) ? "bold" : ""}}/>
          </Mention>
          {!compendium && (i === stack.length-1) && <a style={{color: "inherit", marginLeft: "4px"}} title="Maximize"><ReadFilled/></a>}
        </a>
      </Breadcrumb.Item>)}
    </Breadcrumb>
  </Row>
}

export function WikiList(opts) {
  const { projectID } = useNav()
  return projectID ? <ProjectProvider projectID={projectID}><Wiki {...opts} /></ProjectProvider> : <div />
}

export default ({open, flatten}) => {
  const { projectID, profileID, browser, editing } = useNav()
  const ref = useRef()
  const opacity = open ? 1 : 0
  const left = open ? 0 : -window.innerWidth / 2 + "px"
  const height = editing ? ((window.innerHeight - 250) + "px") : "100%" 
  const submenu = browser || !projectID

  return <Column noClick top absolute className="pad-l" style={{ zIndex: 1299, transition: "left 0.1s, opacity 0.1s, height 0.1s", opacity, left, height }}>
    <Column fit relative click style={{ minWidth: "25vw", maxWidth: "25vw" }}>
      {projectID && <Row between className="foreground" style={{border: "1px solid #141414", borderBottomColor: browser ? "transparent" : "#141414"}}>
        <Column>
          <Button title="Compendium View" placement="right" mouseEnterDelay={0.5} onClick={e => Nav.setCompendium(true)}>
            <DoubleRightOutlined />
          </Button>
        </Column>
        <Row click grow className="pad-h">
          {projectID && hasRight(profileID, "WRITE") ? <ProjectProvider projectID={projectID}><Crumbs /></ProjectProvider> : null}
        </Row>
        <Column>
          {/* <Button click primary className="foreground gaps-h"><MoreFilled /></Button> */}
          <Button click primary className="foreground" onClick={e => {
            Nav.setBrowser()
            Nav.setCompendium(true)
          }}><DoubleRightOutlined /></Button>
        </Column>
      </Row>}
      <Column grow relative style={{zIndex: 0}}>
        {projectID && <SlateContext.Provider value={{toolbar: ref, flatten, rootID: profileID}}>
          <ProjectProvider projectID={projectID}>
            <ProfileView />
          </ProjectProvider>
        </SlateContext.Provider>}
        <Column noClick={submenu ? false : true} fit absolute className={submenu ? "background visible" : "invisible"} style={{ transition: "background 0.1s", zIndex: 5 }}>
          {!projectID ? <ProjectList /> : <WikiList compact={true} />}
        </Column>
      </Column>
      <div className="row fit-x absolute bottom no-click" ref={ref} style={{ paddingRight: "12px", zIndex: 1200 }} />
    </Column>
  </Column>
}


