import React, { useEffect, useState, useRef, useContext } from 'react'
import { createPortal } from 'react-dom'
import { Row, Column, Button as AntButton } from '../../../UI'
import Button from './ToolbarButton'
import { Editor, Range, Transforms } from 'slate'
import { ReactEditor, useSlate, useFocused } from 'slate-react'

import {
  BorderLeftOutlined,
  LinkOutlined,
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  FontSizeOutlined,
  AlignCenterOutlined,
  MenuOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  LockFilled,
  LineOutlined,
  TableOutlined,
  BorderlessTableOutlined,
  FileImageFilled,
  UnorderedListFilled,
  OrderedListFilled,
  SplitCellsOutlined,
  DeleteFilled
} from '@ant-design/icons'
import { Menu, Dropdown } from 'antd'

import rfdc from 'rfdc'
import { v4 } from 'uuid'

import Tables from './Tables'
import Layouts from "./Layouts"

import { SlateContext } from '../Slate'
import { localSocket } from "../../../Wing"
import useProject from "../../hooks/useProject"
import useProfile from "../../hooks/useProfile"

const copy = rfdc()

let lastChange = 0
const setSize = (editor, size, e) => {
  Transforms.setNodes(
    editor,
    { size: size === "" ? null : size },
    { match: editor.isText, split: true, hanging: true }
  )
  // handle inlines
  Transforms.setNodes(
    editor,
    { size: size === "" ? null : size },
    { match: editor.canGrow, voids: true, hanging: true }
  )
}

const toggleFormat = (editor, format, e) => {
  e.preventDefault()
  const isActive = isFormatActive(editor, format)
  Transforms.setNodes(
    editor,
    { [format]: isActive ? null : true },
    { match: editor.isText, split: true }
  )
}

const isFormatActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n[format] === true,
    mode: 'all',
  })
  return !!match
}


export default ({ target, readOnly, ctx }) => {
  const { projectID } = useProject()
  const { profileID } = useProfile()

  const ref = useRef()
  const editor = useSlate()
  const focused = useFocused()
  const open = true
  const { selection } = editor
  const visible = focused
  const { toolbar, flatten, small } = useContext(SlateContext)
  const smallToolbar = flatten || small
  const [menus, setMenus] = useState({})
  function settle() {
    if (lastChange > Date.now())
      return
    if (selection && focused) {
      const obj = {}

      const tbl = isContained("table")
      const [table, tablePath] = tbl
      if (tablePath.length && table.type)
        obj.table = tbl

      const rw = isContained("row")
      const [row, rowPath] = rw
      if (rowPath.length && row.type)
        obj.row = rw

      const cl = isContained("cell")
      const [cell, cellPath] = cl
      if (cellPath.length && cell.type)
        obj.cell = cl

      const lyt = isContained("layout")
      const [layout, layoutPath] = lyt
      if (layoutPath.length && layout.type)
        obj.layout = lyt

      const clm = isContained("column")
      const [column, columnPath] = clm
      if (columnPath.length && column.type)
        obj.column = clm

      const hdr = isContained("header")
      const [header, headerPath] = hdr
      if (headerPath.length && header.type)
        obj.header = hdr

      const sec = isContained("section")
      const [section, sectionPath] = hdr
      if (sectionPath.length && section.type)
        obj.section = sec

      if (selection && Range.isCollapsed(selection)) {
        const [d, pth] = Editor.leaf(editor, selection)
        const [parent, parentPath] = Editor.node(editor, pth.splice(0, pth.length - 1))
        if (parentPath.length && parent.type === "media")
          obj.media = [parent, parentPath]
      }

      const p = isContained("paragraph")
      const [paragraph, paragraphPath] = p
      if (paragraphPath.length && paragraph.type) {
        obj.paragraph = p
        if (!Range.isCollapsed(selection) && Editor.string(editor, selection).length)
          obj.selected = p
      }
      setMenus(obj)
    }
    else
      setMenus({})
  }

  useEffect(() => {
    lastChange = 0//Date.now()
    if (selection && focused)
      settle()
    else
      setMenus({})

  }, [selection, focused])

  const alignRight = e => {
    Transforms.setNodes(editor, { align: "right" }, { match: e => e.type === "paragraph" ? true : false })
    e.preventDefault()
  }
  const alignLeft = e => {
    Transforms.setNodes(editor, { align: "left" }, { match: e => e.type === "paragraph" ? true : false })
    e.preventDefault()
  }
  const alignCenter = e => {
    Transforms.setNodes(editor, { align: "center" }, { match: e => e.type === "paragraph" ? true : false })
    e.preventDefault()
  }
  const alignJustify = e => {
    Transforms.setNodes(editor, { align: "justify" }, { match: e => e.type === "paragraph" ? true : false })
    e.preventDefault()
  }


  function isContained(type) {
    return editor.getContainer(selection.focus.path, [], [type])
  }

  function wrap(e, type, mode) {
    const { anchor, focus } = selection
    if (Range.isCollapsed(selection))
      Transforms.insertNodes(editor, { type, mode, children: [{ type: "paragraph", children: [{ text: "" }] }] }, { at: selection, split: true })
    else {
      const children = []
      const endIndex = Math.min(anchor.path.length - 1, focus.path.length - 1)
      var [aC, aP] = editor.getContainer(anchor.path, [])
      var [fC, fP] = editor.getContainer(focus.path, [])

      if (!aP.length)
        var [aC, aP] = editor.getContainer(anchor.path, [], ["paragraph"])

      if (!fP.length)
        var [fC, fP] = editor.getContainer(focus.path, [], ["paragraph"])

      const commonPath = aP.length > fP.length ? [...fP] : [...aP]
      const start = Math.min(aP[commonPath.length - 1], fP[commonPath.length - 1])
      const end = Math.max(aP[commonPath.length - 1], fP[commonPath.length - 1])
      commonPath.splice(commonPath.length - 1, 1)

      for (let i = end; i >= start; i--) {
        const [element, ePath] = Editor.node(editor, [...commonPath, i])
        children.push(copy(element))
        Transforms.delete(editor, { unit: "block", at: [...commonPath, i] })
      }
      children.reverse()
      Transforms.insertNodes(editor, { type, mode, children }, { at: [...commonPath, start] })
    }
    e.preventDefault()
  }

  function heading(e) {
    const { anchor, focus } = selection
    const text = Editor.string(editor, selection)
    const children = [{ type: "paragraph", children: [{ text, size: "h1", uid: v4() }] }]
    if (Range.isCollapsed(selection))
      Transforms.insertNodes(editor, { type: "header", uid: v4(), children }, { at: selection, split: true })
    else {
      const endIndex = Math.min(anchor.path.length - 1, focus.path.length - 1)
      var [aC, aP] = editor.getContainer(anchor.path, [])
      var [fC, fP] = editor.getContainer(focus.path, [])

      if (!aP.length)
        var [aC, aP] = editor.getContainer(anchor.path, [], ["paragraph"])

      if (!fP.length)
        var [fC, fP] = editor.getContainer(focus.path, [], ["paragraph"])

      const commonPath = aP.length > fP.length ? [...fP] : [...aP]
      const start = Math.min(aP[commonPath.length - 1], fP[commonPath.length - 1])
      const end = Math.max(aP[commonPath.length - 1], fP[commonPath.length - 1])
      commonPath.splice(commonPath.length - 1, 1)

      for (let i = end; i >= start; i--) {
        const [element, ePath] = Editor.node(editor, [...commonPath, i])
        Transforms.delete(editor, { unit: "block", at: [...commonPath, i] })
      }
      Transforms.insertNodes(editor, { type: "header", uid: v4(), children }, { at: [...commonPath, start] })
    }

    e.preventDefault()
  }

  const onSelect = (e, size) => {
    console.log(e)
    setSize(editor, size, e)
    e.preventDefault()
    e.stopPropagation()
    e.nativeEvent.stopPropagation()
  }


  const externalLink = e => {
    const selection = copy(editor.selection)
    const startPath = selection.anchor.path
    window.ask({ title: "Enter URL" }, res => {
      ReactEditor.focus(editor)
      setTimeout(() => {
        console.log(selection)

        Transforms.setSelection(editor, selection)//Editor.range(editor, selection.focus, selection.focus))
        // Transforms.delete(editor)
        // Editor.insertNode(editor, {
        //   type: "link",
        //   src: res,
        //   children: [{ text: "" }]
        // })
        const result = {
          type: "link",
          src: res,
          children: [{ text: "" }]
        }
        const [node, path] = Editor.node(editor, selection)
        if (node.size)
          result.size = node.size

        Transforms.insertNodes(editor, result, {at: selection, split: true})

        const [newNode, p] = Editor.node(editor, selection)
        const [next, np] = Editor.next(editor, { at: p })
        const [startNode, snp] = Editor.next(editor, { at: np })
        const [s, sp] = editor.getFirstLeaf(snp)
        const start = Editor.range(editor, sp, sp)
        Transforms.setSelection(editor, { anchor: { ...start.anchor, offset: 0 }, focus: { ...start.focus, offset: 0 } })
      }, 1)
    })
  }

  const media = e => {
    const { selection } = editor

    // if (window.isLocal)
      window.ask({type: "file", profileID, projectID, filename: true}, ({src})=>{
        Transforms.setSelection(editor, selection)//Editor.range(editor, selection.focus, selection.focus))
        Transforms.delete(editor)

        Editor.insertNode(editor, {
          type: "media",
          src,
          children: [{ text: "" }]
        })

        const [newNode, p] = Editor.node(editor, selection)
        const [next, np] = Editor.next(editor, { at: p })
        const [startNode, snp] = Editor.next(editor, { at: np })
        const [s, sp] = editor.getFirstLeaf(snp)
        const start = Editor.range(editor, sp, sp)
        Transforms.setSelection(editor, { anchor: { ...start.anchor, offset: 0 }, focus: { ...start.focus, offset: 0 } })
      })
    // else
      // window.warning("The web version doesn't support custom files yet")
  }

  const component = e => {
    const { selection } = editor

    // if (window.isLocal)
      window.ask({type: "file", profileID, projectID, filename: true}, ({src})=>{
        Transforms.setSelection(editor, selection)//Editor.range(editor, selection.focus, selection.focus))
        Transforms.delete(editor)

        Editor.insertNode(editor, {
          type: "component",
          src,
          children: [{ text: "" }]
        })

        const [newNode, p] = Editor.node(editor, selection)
        const [next, np] = Editor.next(editor, { at: p })
        const [startNode, snp] = Editor.next(editor, { at: np })
        const [s, sp] = editor.getFirstLeaf(snp)
        const start = Editor.range(editor, sp, sp)
        Transforms.setSelection(editor, { anchor: { ...start.anchor, offset: 0 }, focus: { ...start.focus, offset: 0 } })
      })
    // else
      // window.warning("The web version doesn't support custom files yet")
  }


  const divider = e => Transforms.insertNodes(editor, { type: "divider", children: [{ text: "" }] }, { split: true })

  const table = e => {
    const selection = copy(editor.selection)
    window.ask({ title: "Enter how many columns" }, columns => {
      const children = []
      for (let i = 0; i < Math.min(Math.abs(parseInt(columns) || 1), 10); i++)
        children.push({ type: "cell", children: [{ type: "paragraph", children: [{ text: "" }] }] })
      window.ask({ title: "Enter how many rows" }, res => {
        const rows = [{ type: "row", children: children }]
        for (let i = 0; i < (res - 1); i++)
          rows.push({
            type: "row", children: children.map(value => ({ type: "cell", children: [{ type: "paragraph", children: [{ text: "" }] }] }))
          })

        ReactEditor.focus(editor)
        setTimeout(() => {
          const [container, path] = editor.getContainer(selection.anchor.path, [])
          Transforms.setSelection(editor, selection)
          Transforms.insertNodes(editor, { type: "table", children: rows }, { at: path, split: true })
        }, 1)
      })
    })
  }

  const remove = e => {
    const [media, path] = menus.media
    Transforms.delete(editor, { at: path, unit: "block" })
  }

  const fill = e => {
    const [media, path] = menus.media
    const [container, containerPath] = editor.getContainer(path)
    if (!containerPath.length) {
      const [container, containerPath] = editor.getContainer(path, [])
      const el = { ...media, float: null, isBlock: true }
      const newInsert = media.isBlock ? { type: "paragraph", children: [{ text: "" }, { ...el, isBlock: null }, { text: "" }] } : { type: "paragraph", children: [el] }
      console.log(newInsert)
      Transforms.delete(editor, { at: path, unit: "block" })
      Transforms.insertNodes(editor, newInsert, { at: containerPath })
    }
    else {
      const el = { ...media, float: null, isBlock: true }
      console.log(media)
      const newInsert = container.children[0].src === media.src ? { type: "paragraph", children: [{ ...el, isBlock: null }] } : el

      Transforms.insertNodes(editor, newInsert, { at: [...containerPath, container.children.length] })
      for (let i = container.children.length - 1; i >= 0; i--)
        Transforms.delete(editor, { at: [...containerPath, i], unit: "block" })
    }
  }

  const split = e => {
    const [container, path] = editor.getContainer(selection.focus.path, [])
    if (container.type !== "paragraph")
      return window.warning("Can't split non-text")

    const [leaf, leafPath] = Editor.leaf(editor, selection)

    const left = []
    const right = []
    for (let i = 0; i < container.children.length; i++)
      if (i <= leafPath[leafPath.length - 1])
        left.push(copy(container.children[i]))
      else
        right.push(copy(container.children[i]))


    const result = {
      type: "layout",
      children: [
        {
          type: "column", width: "50%", children: [
            { type: "paragraph", align: "left", children: left }
          ]
        },
        {
          type: "column", width: "50%", children: [
            { type: "paragraph", align: "right", children: right }
          ]
        }
      ]
    }

    Transforms.insertNodes(editor, result, { at: path })
    const dPath = [...path]
    dPath[dPath.length - 1]++
    Transforms.delete(editor, { at: dPath, unit: "block" })
    const [node, p] = Editor.node(editor, path)
    const [s, sp] = editor.getFirstLeaf(p)
    const start = Editor.range(editor, sp, sp)
    Transforms.setSelection(editor, start)

    e.preventDefault()
  }

  const ImageMenu = <Row>
    <Row center className="black rounded pad">
      <FileImageFilled />
    </Row>
    <Button className="foreground" color="secondary" small onClick={remove}>
      Delete
    </Button>
    <Button className="foreground" small onClick={e => Transforms.setNodes(editor, { float: "left", isBlock: false }, { at: menus.media[1] })}>
      Left
    </Button>
    <Button className="foreground" small onClick={e => Transforms.setNodes(editor, { float: null, isBlock: false }, { at: menus.media[1] })}>
      Normal
    </Button>
    <Button className="foreground" small onClick={e => Transforms.setNodes(editor, { float: "right", isBlock: false }, { at: menus.media[1] })}>
      Right
    </Button>
    <Button className="foreground" small onClick={fill}>
      Fill
    </Button>
    {/* <Button small onClick={e => Transforms.setNodes(editor, { src: window.prompt("URL") }, { at: menus.media[1] })}>
      URL
    </Button> */}
  </Row>

  const HeaderMenu = <Row style={{boxShadow: "0px 0px 4px rgba(0,0,0,0.7)"}}>
    {menus.header && menus.header[0].bookmark && <Button className="foreground" small onClick={e => {
      Transforms.setNodes(editor, { bookmark: null }, { at: menus.header[1] })
      settle()
    }}>
      Un-Bookmark
    </Button>}
    {menus.header && !menus.header[0].bookmark && <Button className="foreground" small onClick={e => {
      Transforms.setNodes(editor, { bookmark: v4() }, { at: menus.header[1] })
      settle()
    }}>
      Bookmark
    </Button>}
{/* 
    {menus.section && menus.section[0].bookmark && <Button className="foreground" small onClick={e => {
      Transforms.setNodes(editor, { bookmark: null }, { at: menus.section[1] })
      settle()
    }}>
      Un-mark Section
    </Button>}
    {menus.section && !menus.section[0].bookmark && <Button className="foreground" small onClick={e => {
      Transforms.setNodes(editor, { bookmark: v4() }, { at: menus.section[1] })
      settle()
    }}>
      Mark Section
    </Button>} */}
  </Row>

  const bar = <Column fitX className="black">
    <Row fitX wrap between className={`${visible ? "visible" : "no-click"}`}>
      <Layouts menus={menus}/>
      <Tables menus={menus}/>
      {menus.header && menus.header[1].length <= 1 && HeaderMenu}
      {menus.media && ImageMenu}
    </Row>
    <Row fitX style={{ flexWrap: "wrap-reverse" }} between className={`${visible ? "visible" : "invisible no-click"}`}>
      <Row style={{boxShadow: "0px 0px 4px rgba(0,0,0,0.7)"}}>
        <Dropdown trigger="click" placement="topLeft" overlay={<Menu>
          <Menu.Item onMouseDown={e => onSelect(e, "h1")}>Heading 1</Menu.Item>
          <Menu.Item onMouseDown={e => onSelect(e, "h2")}>Heading 2</Menu.Item>
          <Menu.Item onMouseDown={e => onSelect(e, "h3")}>Heading 3</Menu.Item>
          <Menu.Item onMouseDown={e => onSelect(e)}>Normal Text</Menu.Item>
          <Menu.Item onMouseDown={e => onSelect(e, "sub")}>Subheading 1</Menu.Item>
        </Menu>}>
          <Row className="foreground" onMouseDown={e => e.preventDefault()} onClick={e => e.preventDefault()}>
            <Button small={smallToolbar}>
              <FontSizeOutlined />
            </Button>
          </Row>
        </Dropdown>

        <Button small={smallToolbar} title="Bold" onClick={e => toggleFormat(editor, "bold", e)}><BoldOutlined /></Button>
        <Button small={smallToolbar} title="Italic" onClick={e => toggleFormat(editor, "italic", e)}><ItalicOutlined /></Button>
        <Button small={smallToolbar} title="Underline" onClick={e => toggleFormat(editor, "underline", e)}><UnderlineOutlined /></Button>
      </Row>
      <Row style={{boxShadow: "0px 0px 4px rgba(0,0,0,0.7)"}}>
        <Button small={smallToolbar} title="Align Left" onClick={alignLeft}><AlignLeftOutlined /></Button>
        <Button small={smallToolbar} title="Align Center" onClick={alignCenter}><AlignCenterOutlined /></Button>
        <Button small={smallToolbar} title="Align Right" onClick={alignRight}><AlignRightOutlined /></Button>
        <Button small={smallToolbar} title="Align Justified" onClick={alignJustify}><MenuOutlined /></Button>
      </Row>
      <Row>
        {/* <Button title="Numbered List" onClick={e => wrap(e, "numbered-list")}>
        <OrderedListFilled />
      </Button>

      <Button title="Bulleted List" onClick={e => wrap(e, "bulleted-list")}>
        <UnorderedListFilled />
      </Button> */}

        <Button small={smallToolbar} title="External Link" onClick={externalLink}>
          <LinkOutlined />
        </Button>

        <Button small={smallToolbar} title="Media" onClick={media}>
          <FileImageFilled />
        </Button>

        {/* <Button small={smallToolbar} title="Mention" onClick={divider}>
          <b>@</b>
        </Button> */}
        <Dropdown trigger="click" placement="topLeft" overlay={<Menu>
          <Menu.Item onMouseDown={e => wrap(e, "section", "datapad")}>Datapad</Menu.Item>
          <Menu.Item onMouseDown={e => wrap(e, "section", "document")}>Document</Menu.Item>
          <Menu.Item onMouseDown={e => wrap(e, "section", "parchment")}>Parchment</Menu.Item>
          <Menu.Item onMouseDown={e => wrap(e, "quote")}>Quote</Menu.Item>
          <Menu.Item onMouseDown={e => wrap(e, "secret")}>Secret</Menu.Item>
          <Menu.Item onMouseDown={e => wrap(e, "section", "stone")}>Stone</Menu.Item>
          <Menu.Item onMouseDown={e => wrap(e, "section", "terminal")}>Terminal</Menu.Item>
        </Menu>}>
          <Row className="foreground" onMouseDown={e => e.preventDefault()} onClick={e => e.preventDefault()}>
            <Button small={smallToolbar} title="Wrap">
              <BorderLeftOutlined />
            </Button>
          </Row>
        </Dropdown>

        {/* <Button title="Secret" onClick={e => wrap(e, "secret")}>
          <LockFilled />
        </Button> */}
      </Row>

      <Row style={{boxShadow: "0px 0px 4px rgba(0,0,0,0.7)"}}>
        <Button small={smallToolbar} title="Divider" onClick={divider}>
          <LineOutlined />
        </Button>

        <Button small={smallToolbar} title="Layout Split" onClick={split}>
          <SplitCellsOutlined />
        </Button>

        <Button small={smallToolbar} title="Table" onClick={table}>
          <TableOutlined />
        </Button>
      </Row>

      {/* <Button className="black" primary>Bold</Button>
    <Button className="black" primary>Outline</Button>
    <Button className="black" primary>Underline</Button>
    <Button className="black" primary>Strike</Button>
    <Button className="black" primary>Subscript</Button>
    <Button className="black" primary>Superscript</Button>
    <Button className="black" primary>Mention</Button>
    <Button className="black" primary>Align</Button>
    <Button className="black" primary>Link</Button>
    <Button className="black" primary>Quote</Button>
    <Button className="black" primary>Secret</Button>
    <Button className="black" primary>Table</Button>
    <Button className="black" primary>Line</Button> */}
    </Row>
  </Column>
  return createPortal(
    bar,
    toolbar.current
  )
}