import React, { useEffect, useState, useRef } from 'react'
import { Input } from 'antd'
import Push from '../../util/ChangeStack'

// const commands = { // samples
//   "#": entry => { // determines options to select
//     // these are updated everytime text is entered/removed
//     return [
//       {
//         name: "Roll '/roll'",
//         desc: 'desc',
//         icon: <Functions />,
//         result: cb => console.log("Red"),
//       },
//       {
//         name: "Roll '/roll'",
//         desc: 'desc',
//         icon: <Functions />,
//         result: cb => {
//           console.log("Blue")
//           return "blue" // return the text you want to replace what you've entered
//         },
//       },
//       {
//         name: "Roll '/roll'",
//         desc: 'desc',
//         icon: <Functions />,
//         result: cb => {
//           console.log(entry)
//           // Context.exec(search || `ask("Enter in an equation")`, getNavAssetData(), eq => {
//           //   cb("**Rolled " + search.trim() + "** = " + eq)
//           // })
//         }
//       },
//     ]
//   }
// }

export default ({ ref, baseCommand, commands, placeholder, style: styling, multiline, focusCallback, id, size, rows, variant }) => {
  const reference = useRef()
  const anchorEl = useRef()
  const [open, setOpen] = useState(false)
  const [style, setStyle] = useState({})
  const [command, setCommand] = useState("")
  const [results, setResults] = useState([])
  const [value, setValue] = useState("")
  const [index, setIndex] = useState()

  // match the size
  useEffect(() => {
    const height = window.innerHeight * 0.2
    if (anchorEl.current && reference.current && open) {
      const rect = anchorEl.current.getBoundingClientRect()

      setStyle({
        width: `${rect.width}px`,
        left: `${rect.left}px`,
        top: `${rect.top - 16 * 2 - height}px`,
        height: height,
        margin: "16px 0px",
        minWidth: "325px",
        zIndex: 1
      })
      if (document.getElementById("scroll-target"))
        document.getElementById("scroll-target").scrollIntoView({ inline: "start", block: 'nearest', behavior: "smooth" })
    }
    else
      setStyle({ ...style })
  }, [open, anchorEl, reference, command, index, value])

  useEffect(() => {
    Push("cmd", () => {
      let final = []
      // const regex = new Regex()
      if (value[0] && commands[value[0]]) {
        final = commands[value[0]](value.replace(value[0], "")) ?? final
        // if (command !== value[0])
        setCommand(value[0])
      }
      else
        setCommand(null)
      setResults(final)
      setIndex(0)
    })
  }, [value])

  if (focusCallback)
    useEffect(() => focusCallback(open), [open])

  const execCommand = (cmd, idx) => {
    if (cmd.result instanceof Function)
      setValue(cmd.result(cmd, idx, value) ?? "")
    else
      setValue(cmd.result)
  }

  // const onKeyUp = e => {
  //   switch (e.key) {
  //     case 'Enter': {
  //       e.preventDefault()
  //       if (e.shiftKey)
  //         return

  //       if (!command)
  //         execCommand({ result: baseCommand ? baseCommand : s => { return "" } })
  //       else
  //         execCommand(results[index], index)
  //       e.target.blur()
  //       setOpen(false)
  //       e.stopPropagation()
  //       return
  //     }
  //     default:
  //       break
  //   }
  // }

  const onKeyDown = e => {
    switch (e.key) {
      case 'Enter': {
        if (e.shiftKey)
          return

        e.preventDefault()

        if (!command)
          execCommand({ result: baseCommand ? baseCommand : s => { return "" } })
        else
          execCommand(results[index], index)
        e.target.blur()
        setOpen(false)
        e.stopPropagation()
        return
      }
      case 'ArrowDown': {
        if (command) {
          e.preventDefault()
          var newSelection = index - 1
          if (newSelection < 0)
            newSelection = Math.max(results.length - 1, 0)

          setIndex(newSelection)
        }
        break
      }
      case 'ArrowUp': {
        if (command) {
          e.preventDefault()
          setIndex((index + 1) % results.length)
        }
        break
      }
      // case 'Tab':
      default:
        break
    }
  }

  const onFocus = e => setOpen(true)
  const onChange = e => setValue(e.target.value)

  return <Input.TextArea
    id={id}
    placeholder={placeholder ?? ""}
    value={value}
    rows={rows || 1}
    size={size}
    onChange={onChange}
    onFocus={onFocus}
    onKeyDown={onKeyDown}
    style={styling}
    ref={ref}
  />
}