import React, {useEffect, useState} from 'react'

import { Column, Scroll, Row, Button } from '../UI' 

function PackagePicker({onSelect, list}){
  return <Scroll>
    {list.map((v,i)=><Row key={i} onClick={e=>onSelect(v)} className="pad background clickable size-large">{v}</Row>)}
  </Scroll>
}

export default opts => {
  const [items, setItems] = useState([])
  const [packages, setPackages] = useState([])

  function update(){
    fetch("/myWorkshop")
    .then(r=>r.json())
    .then(r=>setItems(r))
    .catch(e=>console.warn(e))

    fetch("/getPackages")
    .then(r=>r.json())
    .then(r=>setPackages(r))
    .catch(e=>console.warn(e))
  }
  useEffect(()=>update(),[])
  
  const addItem = e=>window.ask({title: "Pick a package to submit", app: PackagePicker, style:{height: "50vh"}, bodyStyle: {padding: 0}, props: {onSelect: res=>{
    // window.ask({title: "Enter a Title", desc: "You will need to add a description through steam's website, and will be given a link after upload"},res=>{
      window.info(`Uploading ${res}...`)
      fetch("/workshopPublish", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          file: res,
          title: res,
          // path: "false" 
        })
      }).then(r=>{
        if (r.error)
          return window.error("Something went wrong")
        window.success(`${res} uploaded successfully!`)
        update()
      }).catch(e=>{window.error("Uploading Failed"); console.warn(e)})
    // })
  }, list: packages}})
  
  return <Column grow>
    <Scroll>
      {items.map((v,key)=><Row key={key} between className="background">
        <Row center className="size-large pad-h">
          <a href={`https://steamcommunity.com/sharedfiles/filedetails/?id=${v.publishedFileId}`} className="text-white" target="_blank">{v.title}</a>
        </Row>
        <Row>
          <Button className="foreground" onClick={e=>{
            window.ask({title: "Pick a package to send as an update", app: PackagePicker, style:{height: "50vh"}, bodyStyle: {padding: 0}, props: {onSelect: res=>{
            window.info(`Uploading ${v.title}...`)
            fetch("/workshopUpdate", {
              method: "POST",
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                localFilePath: res,
                ...v,
                // path: "false" 
              })
            }).then(r=>{
              if (r.error)
                return window.error("Something went wrong")
              window.success(`${v.title} updated successfully!`)
              update()
            }).catch(e=>{window.error("Uploading Failed"); console.warn(e)})
            }, list: packages}})
          }}>
            Update
          </Button>
        </Row>
      </Row>)}
    </Scroll>
    {!window.isDemo && <Row center>
      <Button className="foreground" onClick={addItem}>Upload to Workshop</Button>
    </Row>}
  </Column>
}