import { useEffect } from 'react'

/* eslint-disable */
export default (e, fn, Emitter = window, deps = []) => useEffect(() => {
  if (Array.isArray(e))
    for (const ev of e)
      if (Emitter !== window)
        Emitter.on(ev, fn)
      else
        Emitter.addEventListener(ev, fn)
  else
    if (Emitter !== window)
      Emitter.on(e, fn)
    else
      Emitter.addEventListener(e, fn)

  return function () {
    if (Array.isArray(e))
      for (const ev of e)
        if (Emitter !== window)
          Emitter.off(ev, fn)
        else
          Emitter.removeEventListener(ev, fn)
    else
      if (Emitter !== window)
        Emitter.off(e, fn)
      else
        Emitter.removeEventListener(e, fn)
  }
}, deps)
/* eslint-enable */