import Resource from './Resource'
import Account from '../../core/state/Account'
import { v4 } from 'uuid'

import Context from '../util/Context'
import Resources from '../state/Resources'
import rfdc from 'rfdc'
const copy = rfdc()

export default class Profile extends Resource {
  states = {
    "Base State": {
      name: "Untitled",
      alias: "",
      desc: "",
      icon: "",
      media: [],
      data: {},
      lists: {},
      tabs: {},
      components: {},
      containers: {},
      slots: {},
      profiles: {}, // profile cache that this profile is responsible for
      events: {}, // chat events
      effects: {} // effects
    }
  }
  state = "Base State"

  _ATTACHED = {}

  constructor(ctx = {}) {
    super(ctx)

    this._id = ctx._id
    this.uid = ctx.uid || v4()
    this.meta = { creator: ctx.creator || Account.uid, created: Date.now(), tags: [] }

    // this.on("change", e => this.updateAttachments())
    // this.on("change", e => {
    //   if (Object.keys(this._ATTACHED).length)
    //     console.warn(this.uid, this.getProfiles().length)
    // })
    this.on("change", res=>this._needsRebuild = true)
    this.setMaxListeners(Infinity) // Potentially thousands of references due to wiki
  }

  detachAll() {
    for (const pID in this._ATTACHED) {
      const profile = Resources.Profiles.get(pID)
      profile.off("change", this._ATTACHED[this.uid])
      delete this._ATTACHED[pID]
    }
  }

  updateAttachments() {
    // for temporary stuff
    const profiles = this.getProfiles()
    for (const pID in this._ATTACHED)
      if (!profiles[pID]) {
        const profile = Resources.Profiles.get(pID)
        if (this._ATTACHED[pID])
          profile.off("change", this._ATTACHED[pID])

        delete this._ATTACHED[pID]
      }

    for (const pID of profiles) {
      const profile = Resources.Profiles.get(pID)
      if (!profile.loaded)
        profile.load({ ...this.getProfileContent(pID) })

      if (pID !== this.uid && !this._ATTACHED[pID]) {
        this._ATTACHED[pID] = e => this.setProfile(pID, profile.pack())
        profile.on("change", this._ATTACHED[pID])
      }
    }
  }

  get name() { // need to help fuse search
    return String(this.getName())
  }

  get alias() {
    return String(this.getAlias())
  }

  getName(state = this.state) {
    return String(this.states[state].name || "")
  }

  setName(name, state = this.state) {
    if (name !== this.states[state].name) {
      this.states[state].name = name
      this.emit("change")
    }
  }

  getAlias(state = this.state) {
    return String(this.states[state].alias || "")
  }

  setAlias(alias, state = this.state) {
    if (alias !== this.states[state].alias) {
      this.states[state].alias = alias
      this.emit("change")
    }
  }

  getIcon(state = this.state) {
    return String(this.states[state].icon)
  }

  setIcon(icon, state = this.state) {
    if (icon !== this.states[state].icon) {
      this.states[state].icon = icon
      this.emit("change")
    }
  }

  getTemplated(state = this.state) {
    return this.states[state].templated
  }

  setTemplated(templated, state = this.state) {
    if (templated !== this.states[state].templated) {
      this.states[state].templated = templated
      this.emit("change")
    }
  }

  getTags() {
    return this.meta.tags
  }

  setTags(tags) {
    this.meta.tags = tags
    this.emit("change")
  }

  addTag(tag) {
    this.meta.tags = this.meta.tags || []
    this.meta.tags.push(tag.toLowerCase())
    this.emit("change")
  }

  removeTag(tag) {
    this.meta.tags = this.meta.tags || []
    if (this.meta.tags.includes(tag.toLowerCase())) {
      this.meta.tags.splice(this.meta.tags.indexOf(tag.toLowerCase()), 1)
      this.emit("change")
    }
  }

  // getImage(state = this.state) {
  //   return this.states[state].img
  // }

  // setImage(img, state = this.state) {
  //   if (img !== this.states[state].img) {
  //     this.states[state].img = img
  //     this.emit("change", `states.${state}.img`)
  //   }
  // }

  getBanner(state = this.state) {
    return this.states[state].media.length ? this.states[state].media[0] : ""
  }

  setBanner(banner, state = this.state) {
    if (this.states[state].media.length)
      this.states[state].media[0] = banner
    else
      this.states[state].media.push(banner)

    this.emit("change")
  }

  getMedia(state = this.state) {
    return this.states[state].media
  }

  getVisualData(state = this.state) {
    return this.states[state].visual
  }

  setVisualData(visual, state = this.state) {
    this.states[state].visual = visual
    this.emit("change")
  }

  getModel(state = this.state) {
    return this.states[state].visual.model
  }

  setModel(model, state = this.state) {
    this.states[state].visual.model = model
    this.emit("change")
  }

  getComponentTable(state = this.state) {
    return this.states[state].components
  }

  getDataTable(state = this.state) {
    return this.states[state].data
  }

  setDataTable(data = {}, state = this.state) {
    this.states[state].data = data
    for (const stat in data)
      if (this.states[state].formulas && !this.states[state].formulas[stat])
        this.changeData(stat, 0)
    this.emit("change")
  }

  getFormulaTable(state = this.state) {
    this.states[state].formulas = this.states[state].formulas || {}
    return this.states[state].formulas
  }

  setFormulaTable(formulas = {}, state = this.state) {
    this.states[state].formulas = formulas
    for (const stat in formulas)
      if (formulas[stat])
        this.changeData(stat, 0)
    this.emit("change")
  }

  getData(stat, state = this.state) {
    const target = String(stat).toLowerCase()
    const dataTable = this.getDataTable(state)
    if (dataTable[target]) {
      return dataTable[target]
    }
    else {
      for (var key in this.states) {
        if (key !== state && this.states[key].data[target]) {
          return this.states[key].data[target]
        }
      }
    }
  }

  getComponent(key, state = this.state) {
    this.states[state].components = this.states[state].components || {}
    this.states[state].components[key] = this.states[state].components[key] || {}
    return this.states[state].components[key]
  }

  addToComponent(key, value, amount=1, state = this.state) {
    this.states[state].components = this.states[state].components || {}
    this.states[state].components[key] = this.states[state].components[key] || {}
    this.states[state].components[key][value] = this.states[state].components[key][value] || 0
    this.states[state].components[key][value] += amount
    this.emit("change")
  }

  setComponentTo(key, value, amount=1, state = this.state) {
    this.states[state].components = this.states[state].components || {}
    this.states[state].components[key] = this.states[state].components[key] || {}
    this.states[state].components[key][value] = this.states[state].components[key][value] || 0
    this.states[state].components[key][value] = amount
    this.emit("change")
  }

  removeFromComponent(key, value, amount=1, state = this.state) {
    this.states[state].components = this.states[state].components || {}
    this.states[state].components[key] = this.states[state].components[key] || {}
    if (this.states[state].components[key][value] && this.states[state].components[key][value] > 1)
      this.states[state].components[key][value] -= amount
    else
      delete this.states[state].components[key][value]
    this.emit("change")
  }

  getContext(state = this.state) {
    //returns a copy of the data table with data evaluated out if its a formula
    // organize it based on length to avoid lookup conflicts 
    if (this._needsRebuild || !this._context) {
      const returnObj = {}
      const data = this.getDataTable(state)
      let keys = Object.keys(data).sort((a, b) => { return b.length - a.length })
      for (const key of keys)
        returnObj[key] = copy(data[key])

      returnObj["_name"] = this.getName()
      returnObj["_alias"] = this.getAlias()
      returnObj["_link"] = `[=${this.uid}=]`
      returnObj["_code"] = `${this.uid}`

      const components = this.getComponentTable(state)
      returnObj["_components"] = copy(components)
      this._context = returnObj
      delete this._needsRebuild
    }
    
    return this._context || {}
  }

  getFormula(stat, state = this.state) {
    const target = String(stat).toLowerCase()
    const dataTable = this.getFormulaTable(state)
    if (dataTable[target]) 
      return dataTable[target]
    else 
      for (var key in this.states) 
        if (key !== state && this.states[key].data[target]) 
          return this.states[key].data[target]   
  }

  setFormula(stat, value, state = this.state) {
    const target = String(stat).toLowerCase()

    if (value == null || value === undefined)
      delete this.getFormulaTable(state)[target]
    else
      this.getFormulaTable(state)[target] = value

    const context = this.getContext()

    this.changeData(stat, Context.resolve(value, context), state)
    this.emit("change")
  }

  removeFormula(stat, state = this.state) {
    const target = String(stat).toLowerCase()
    delete this.getFormulaTable(state)[target]
    this.emit("change")
  }

  changeData(stat, value, state = this.state) {
    const target = String(stat).toLowerCase()
    const data = this.getDataTable(state)
    if (value == null || value === undefined)
      delete data[target]
    else
      data[target] = value
    // updates all the formulas that associate with this value
    const formulas = this.getFormulaTable(state)
    for (const key in formulas) {
      let formula = this.getFormula(key, state)
      if (formula) {
        let dt = /([#][\w_\d]+)/ig
        let match = dt.exec(formula)
        while (match) {
          let k = match[0].replace("#", "").toLowerCase()
          if (k === key || isNaN(data[k])) // can't evaulate complex equations
            formula = formula.replace(new RegExp(`#${key}`, "ig"), 0)
          match = dt.exec(formula)
        }
        data[key] = Context.resolve(formula, data)
      }
    }
  }

  setData(stat, value, state = this.state) {
    this.changeData(stat, value, state)
    // updates all the formulas that associate with this value
    this.emit("change")
  }

  getArticleID(state = this.state) {
    return this.states[state].articleID
  }

  setArticleID(articleID, state = this.state) {
    if (articleID !== this.states[state].articleID) {
      this.states[state].articleID = articleID
      this.emit("change")
    }
  }

  getMapID(state = this.state) {
    return this.states[state].mapID
  }

  setMapID(mapID, state = this.state) {
    if (mapID !== this.states[state].mapID) {
      this.states[state].mapID = mapID
      this.emit("change")
    }
  }

  destroy(key, object, state = this.state) {
    delete this.states[state][key][object]
    this.emit("change")
    // this.emit("change", `states.${state}.${key}`)
  }

  rename(key, object, hint, state = this.state) {
    // need to preserve order
    let newTabs = {}
    for (let k in this.states[state][key]) {
      console.log(k, hint)
      if (k === object)
        newTabs[hint] = this.states[state][key][k]
      else
        newTabs[k] = this.states[state][key][k]
    }

    this.states[state][key] = newTabs
    this.emit("change")
    // this.emit("change", `states.${state}.${key}.${object}`)
  }

  list(key, object, state = this.state) {
    if (object == null)
      return Object.keys(this.states[state][key])
    return Object.keys(this.states[state][key][object] || {})
  }

  set(key, object, data, state = this.state) {
    this.states[state][key][object] = data
    this.emit("change") 
    // this.emit("change", `states.${state}.${key}.${object}`)
  }

  get(key, object, state = this.state) {
    return this.states[state][key][object]
  }

  update(key, object, data, state = this.state) {
    const workingObj = this.states[state][key][object]
    if (!workingObj || !data)
      this.states[state][key][object] = data
    else
      for (const k in data)
        workingObj[k] = data[k]

    this.emit("change")
    // this.emit("change", `states.${state}.${key}.${object}`)
  }

  setList(key, data, state = this.state) {
    return this.set("lists", key, data, state)
  }
  deleteList(key, state = this.state) {
    return this.destroy("lists", key, state)
  }
  updateList(key, data, state = this.state) {
    return this.update("lists", key, data, state)
  }
  getList(key, state = this.state) {
    return this.get("lists", key, state)
  }
  getLists(state = this.state) {
    return this.list("lists", undefined, state)
  }
  getListContent(key, state = this.state) {
    return this.states[state].lists[key].data
  }
  getListsObject(key, state = this.state) {
    return this.states[state].lists
  }

  setContainer(key, data, state = this.state) {
    return this.set("containers", key, data, state)
  }
  deleteContainer(key, state = this.state) {
    return this.destroy("containers", key, state)
  }
  updateContainer(key, data, state = this.state) {
    return this.update("containers", key, data, state)
  }
  getContainer(key, state = this.state) {
    return this.get("containers", key, state)
  }
  getContainers(state = this.state) {
    return this.list("containers", undefined, state)
  }
  getContainerContent(key, state = this.state) {
    return this.states[state].containers[key].data
  }
  getContainerObject(key, state = this.state) {
    return this.states[state].containers
  }

  setSlot(key, data, state = this.state) {
    return this.set("slots", key, data, state)
  }
  deleteSlot(key, state = this.state) {
    return this.destroy("slots", key, state)
  }
  updateSlot(key, data, state = this.state) {
    return this.update("slots", key, data, state)
  }
  getSlot(key, state = this.state) {
    return this.get("slots", key, state)
  }
  getSlots(state = this.state) {
    return this.list("slots", null, state)
  }
  getSlotContent(key, state = this.state) {
    return this.states[state].slots[key].data
  }
  getSlotObject(key, state = this.state) {
    return this.states[state].slots
  }


  setProfile(key, data, state = this.state) {
    return this.set("profiles", key, data, state)
  }
  deleteProfile(key, state = this.state) {
    return this.destroy("profiles", key, state)
  }
  updateProfile(key, data, state = this.state) {
    return this.update("profiles", key, data, state)
  }
  getProfile(key, state = this.state) {
    return this.get("profiles", key, state)
  }
  getProfiles(state = this.state) {
    return this.list("profiles", null, state)
  }
  getProfileContent(key, state = this.state) {
    return this.states[state].profiles[key]
  }
  getProfilesObject(key, state = this.state) {
    return this.states[state].profiles
  }

  setEvent(key, data, state = this.state) {
    return this.set("events", key, data, state)
  }
  deleteEvent(key, state = this.state) {
    return this.destroy("events", key, state)
  }
  updateEvent(key, data, state = this.state) {
    return this.update("events", key, data, state)
  }
  getEvent(key, state = this.state) {
    return this.get("events", key, state)
  }
  getEvents(state = this.state) {
    return this.list("events", null, state)
  }
  getEventContent(key, state = this.state) {
    return this.states[state].events[key]
  }
  getEventsObject(key, state = this.state) {
    return this.states[state].events
  }


  setEffect(key, data, state = this.state) {
    return this.set("effects", key, data, state)
  }
  deleteEffect(key, state = this.state) {
    return this.destroy("effects", key, state)
  }
  updateEffect(key, data, state = this.state) {
    return this.update("effects", key, data, state)
  }
  getEffect(key, state = this.state) {
    return this.get("effects", key, state)
  }
  getEffects(state = this.state) {
    return this.list("effects", null, state)
  }
  getEffectContent(key, state = this.state) {
    return this.states[state].effects[key]
  }
  getEffectsObject(key, state = this.state) {
    return this.states[state].effects
  }



  setTab(key, data, state = this.state) {
    return this.set("tabs", key, data, state)
  }
  renameTab(key, data, state = this.state) {
    return this.rename("tabs", key, data, state)
  }
  deleteTab(key, state = this.state) {
    return this.destroy("tabs", key, state)
  }
  updateTab(key, data, state = this.state) {
    return this.update("tabs", key, data, state)
  }
  getTab(key, state = this.state) {
    return this.get("tabs", key, state)
  }
  getTabs(state = this.state) {
    return this.list("tabs", null, state)
  }
  getTabContent(key, state = this.state) {
    return this.states[state].tabs[key]
  }
  getTabsObject(key, state = this.state) {
    return this.states[state].tabs
  }

  // createState(state) {
  // // TODO: update me, i'm out of date
  //   this.states[state] = {
  //     name: this.states[this.state].name,
  //     label: this.states[this.state].label,
  //     data: this.states[this.state].data,
  //     media: this.states[this.state].media,
  //   }
  //   this.emit("change")
  // }

  destroyState(state) {
    if (Object.keys(this.states).length > 1) {
      delete this.states[state]
      this.emit("change")
    }
  }

  setState(newState) {
    this.state = newState
    this.emit("change")
  }

  getState() {
    return this.state
  }

  getStateData(state = this.state) {
    return this.states[state]
  }

  pack() {
    return {
      _id: this._id,
      uid: this.uid,
      state: this.state,
      states: this.states,
      meta: this.meta || {}
    }
  }

  deserialize(json) {
    this.load(JSON.parse(json))
  }

  load(json) {
    this._id = json._id
    this.uid = json.uid || this.uid
    this.states = json.states || this.states
    // if (this.state !== json.state)
    //   this.detachAll()

    this.state = json.state || Object.keys(this.states)[0]
    for (const state in this.states) {
      this.states[state].events = this.states[state].events || {}
      this.states[state].tabs = this.states[state].tabs || {}
    }
    this.meta = json.meta || this.meta
    this.loaded = true
    this.emit("ready")
    this.updateAttachments()
  }

  serialize() {
    return JSON.stringify(this.pack())
  }

  instance() {
    const result = this.pack()
    delete result._id
    result.uid = "-temp-" + v4()
    result.meta.originalID = this.uid
    return JSON.parse(JSON.stringify(result)) // MUST DUPLICATE, OTHERWISE BAAAAD STUFF HAPPENS
  }
}