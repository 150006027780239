import React, { Component } from 'react'

import { DragSource } from 'react-dnd'

const source = {
  beginDrag(props) {
    // Return the data describing the dragged item
    return props.item || {}
  },

  endDrag(props, monitor, component) {
    if (props.onLeave) {
      props.onLeave(props, monitor, component)
    }
  },
}

function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    // You can ask the monitor about the current drag state:
    isDragging: monitor.isDragging(),
  }
}

class UniversalDrag extends Component {
  render() {
    const { isDragging, onDragStart, onDragEnd, connectDragSource, className, onClick, disabled, onContextMenu } = this.props

    const opacity = isDragging ? 0.5 : 1

    let baseStyle = {...this.props.style}
    baseStyle.opacity = opacity

    if (disabled)
      return <div className={className} style={baseStyle} onClick={onClick} onContextMenu={onContextMenu} onDragStart={onDragStart} onDragEnd={onDragEnd}>
        {this.props.children}
      </div>
    return connectDragSource(
      <div className={className} style={baseStyle} onClick={onClick} onContextMenu={onContextMenu} onDragStart={onDragStart} onDragEnd={onDragEnd}>
        {this.props.children}
      </div>
    )
  }
}

export default DragSource("universal", source, collect)(UniversalDrag)
