import React, { useEffect, useState, useContext, createContext } from 'react'
import Radial from '../state/Radial'

const RadialContext = createContext()
export { RadialContext }

export default function useRadial() {
  return useContext(RadialContext)
}

export function useHook() {
  const [x, setX] = useState(Radial.x)
  const [y, setY] = useState(Radial.y)

  const [opened, setOpened] = useState(Radial.opened)
  const [hideClose, setHideClose] = useState(Radial.hideClose)
  const [options, setOptions] = useState(Radial.options)
  const [menu, setMenu] = useState(Radial.menu)

  useEffect(() => {
    function change() {
      setX(Radial.x)
      setY(Radial.y)
      setOpened(Radial.opened)
      setHideClose(Radial.hideClose)
      setOptions(Radial.options)
      setMenu(Radial.menu)
    }

    Radial.on("change", change)
    Radial.on("ready", change)

    return function () {
      Radial.off("change", change)
      Radial.off("ready", change)
    }
  }, [])

  return { x, y, options, opened, menu, hideClose }
}

export function RadialProvider({ children }) {
  return <RadialContext.Provider value={useHook()} children={children} />
}
