import { Dropdown, Input, Modal, Menu, Switch, Tag } from "antd"
import { useState, useRef, useEffect } from 'react'
import Push from './util/EventStack'
import { Column, Button, Row, Scroll, Tabs } from '../UI'
import { EventProvider } from "./hooks/useEvent"
import { ChatBubble } from './slate/ChatApp'
import Event, { scope, reverseScan } from './models/Event'
import Resources from './state/Resources'
import { DeleteFilled, CloseOutlined, EditOutlined } from '@ant-design/icons'
import { getProject } from "./hooks/useProject"
import Mention from "./slate/inline/Mention"
import Slate from "./slate/Slate"
import { v4 } from "uuid"

const evt = Resources.Events.get("-temp-event-builder")

export function EventBreakdown({script, onChange}){
  const split = script.split("\n")

  const children = []
  let j = 0
  for (const line of split) {
    j = Math.min(j+1, split.length-1)
    const nextLine = split[j][0]
    const addRow = (label, header, value)=>{
      children.push(<Column className="show-hover" key={v4()} between style={{marginBottom: nextLine === line[0] ? "0" : "2.0rem"}}>
        <Row grow>
          <Row reverse style={{minWidth: "150px"}}>
            <Row className="roll-dice size-large pad-h rounded">{header}</Row>
          </Row>
          <Row grow center>
            <Equation value={value} onChange={e=>{
              onChange(script.replace(line, `${header}=${e}`), true)
            }}/>
          </Row>
          <Row center className="foreground">
            <Row center className="size-small pad-h hover-visible invisible">{label}</Row>
            <Button className="black" small onClick={e=>onChange(script.replace(line, ""), true)}>
              <CloseOutlined />
            </Button>
          </Row>
        </Row>
      </Column>)
    }

    if (line[0] === "_")
      addRow("API", line.split("=")[0], line.replace(`${line.split("=")[0]}=`, ""))
    else if (line[0] === "$") {
      let evalRegex = /{([^}]*)}d{?([^}]+)}/i
      let diceRegex = /(\d*)d([\dF]+)((([kd])([lh])?)?([\d]*))?(!(([><])?([\d]+))?p?)?/i

      if (line.match(diceRegex) || line.match(evalRegex))
        addRow("Dice Roll", line.split("=")[0], line.replace(`${line.split("=")[0]}=`, ""))
      else
        addRow("Definition", line.split("=")[0], line.replace(`${line.split("=")[0]}=`, ""))
    }
    else if (line[0] === "#") 
      addRow("Source Change", line.split("=")[0], line.replace(`${line.split("=")[0]}=`, ""))
    else if (line[0] === "@") 
      addRow("Target Change", line.split("=")[0], line.replace(`${line.split("=")[0]}=`, ""))
    else if (line[0] === "|") {
      if (line[1] === "#") {
        const reg = /([#])([\w_]+)\((.*)\)/
        let match = line.match(reg)
        
        children.push(<Row key={v4()} className="foreground" between style={{marginBottom: nextLine === line[0] ? "0" : "2.0rem"}}>
          <Column className="pad-h">
            <Row center className="size-small">Source {match[2]}</Row>
            <Row center className="roll-dice pad-h rounded">{line.replace("|"+match[0], "").split("=")[0] || "="}</Row>
          </Column>
          <Row className="pad-h" center>
            <Column>
              <Row>
                <Equation value={line.replace("|"+match[0], "").split("=")[1]} onChange={e=>onChange(script.replace(line, `|${match[0]}${line.replace("|"+match[0], "").split("=")[0]}=${e}`))} />
              </Row>
            </Column>
            <Tag>
              <Mention profileID={match[3].replace("[=", "").replace("=]", "")}/>
            </Tag>
          </Row>
          <Row center className="foreground">
            <Button className="black" small onClick={e=>onChange(script.replace(line, ""), true)}>
              <CloseOutlined />
            </Button>
          </Row>
        </Row>)
      }
      else if (line[1] === "@") {
        const reg = /([@])([\w_]+)\((.*)\)/
        let match = line.match(reg)

        children.push(<Row key={v4()} className="foreground" between style={{marginBottom: nextLine === line[0] ? "0" : "2.0rem"}}>
          <Column className="pad-h">
            <Row center className="size-small">Target {match[2]}</Row>
            <Row center className="roll-dice rounded">{line.replace("|"+match[0], "").split("=")[0] || "="}</Row>
          </Column>
          <Row className="pad-h" center>
            <Column>
              <Row>
                <Equation value={line.replace("|"+match[0], "").split("=")[1]} onChange={e=>script.replace(line, `${match.input}${line.replace("|"+match[0], "").split("=")}=${e}`)} />
              </Row>
            </Column>
            <Tag>
              <Mention profileID={match[3].replace("[=", "").replace("=]", "")}/>
            </Tag>
          </Row>
          <Row center className="foreground">
            <Button className="black" small onClick={e=>onChange(script.replace(line, ""), true)}>
              <CloseOutlined />
            </Button>
          </Row>
        </Row>)
      }
    }
    else if (line[0] === "!") {
      children.push(<Row key={v4()} className="foreground show-hover" between style={{marginBottom: nextLine === line[0] ? "0" : "2.0rem"}}>
        <Row center>
          <Row center className="roll-dice pad-h rounded">{line.split("=")[0]}</Row>
        </Row>
        <Row grow center>
          Executes event {line.split("=")[0]} using definitions from this event
        </Row>
        <Row center className="foreground">
          <Row center className="size-small pad-h hover-visible invisible">Event Injection</Row>
          <Button className="black" small onClick={e=>onChange(script.replace(line, ""), true)}>
            <CloseOutlined />
          </Button>
        </Row>
      </Row>) 
    }
    else if (line[0] === "%") 
      addRow("Tag Result", line.split("=")[0], line.replace(`${line.split("=")[0]}=`, ""))
    else if (line[0] === ">") 
      children.push(<Column key={v4()} between style={{marginBottom: nextLine === line[0] ? "0" : "2.0rem"}}>
        <Row grow>
          <Row reverse style={{minWidth: "150px"}}>
            <Row center className="roll-dice pad-h rounded size-large">Text Output</Row>
          </Row>
          <Row grow center>
            <Equation value={line.substring(1, line.length)} onChange={e=>{
              onChange(script.replace(line, `>${e}`), true)
            }}/>
          </Row>
          <Row center className="foreground">
            <Button className="black" small onClick={e=>onChange(script.replace(line, ""), true)}>
              <CloseOutlined />
            </Button>
          </Row>
        </Row>
      </Column>)
  }

  return <Column>
    {children}
  </Column>
}

export function EventBuilder({defaultValue, onFinalize}) {

  const src = Resources.Profiles.get("-temp-source")
  src.loaded = true
  src.setName("Source Name")
  src.setAlias("Source Alias")

  const tgt = Resources.Profiles.get("-temp-target")
  tgt.loaded = true
  tgt.setName("Target Name")
  tgt.setAlias("Target Alias")

  evt.loaded = true
  const roll = (val, noQuiet)=>{
    const blank = new Event().pack()
    delete blank.uid
    evt.unpack(blank)
    
    evt.roll(val, {quiet: !noQuiet, source: {hp: 5, hpmax: 20, ...src.getContext()}, target: {hp: 10, hpmax: 20, ...tgt.getContext()}}).then(e=>{
      if (Object.keys(evt.source).length)
      evt.meta.sourceID = "-temp-source"
      
      if (Object.keys(evt.target).length)
        evt.meta.targetID = "-temp-target"
      evt.emit("change")
    })
  }
  const ref = useRef()

  const [value, setValueEQ] = useState(defaultValue || "")
  const helpers = {
    "Action Tag": {},
    "Event Class": {},
    "Result Tag": {},
    "Header": {},
    "Table": {},
    "Text": {},
  }

  const templates = {
    "Star Wars Dice Pool": `_swffg=ability=1|proficiency=1|boost=1|challenge=1|difficulty=1|setback=1|force=1
>|Triumph{{$_triumph}}|Success{{$_success}}|Advantage{{$_advantage}}
>|Despair{{$_despair}}|Failure{{$_failure}}|Threat{{$_threat}} 
>|Lightside{{$_lightside}}|Darkside{{$_darkside}}`,
    "Genesis Dice Pool": `_genesis=ability=1|proficiency=1|boost=1|challenge=1|difficulty=1|setback=1|force=1
>|Triumph{{$_triumph}}|Success{{$_success}}|Advantage{{$_advantage}}
>|Despair{{$_despair}}|Failure{{$_failure}}|Threat{{$_threat}} 
>|Lightside{{$_lightside}}|Darkside{{$_darkside}}`,
    "Simple Roll": `$roll=2d20+5+#dex_bonus
>Rolled a {{$roll}} with a dex bonus included
>Bigger breakdown {$roll}`,
  "Reactive Roll Result": `$roll=1d20
%CRIT=($roll >= 20)?("💥"):(false)
>Roll will crit on a natural 20, you can change the roll manually by clicking on the dice roll and entering a new value
>{$roll+5+#dex_bonus}
>You can also show the tag's result in the output {{%CRIT}}`,
    "Table": `>|Table|Head1|Head2<br>Row1|Cell2|Cell3<br>Row2|Cell3|Cell4`,
    "Decision": `>[h3]Yes or No
>[!!=\`>Yes!\`]\`Yes\`
>[!!=\`>No!\`]\`No\``,
    "Chat command": `_act=YELLED FROM THE ROOFTOPS
_actcolor=orange
_class=SPEECH
_classcolor=orange
>**Hello World**, its me!`,
"Target Stat Change": `@hp=@hp_max
>#_link full healed @_link
>After the result was applied to an object you can log an embedded event so you can apply this result to multiple targets.
>[!!=\`[@]hp=@hpmax\\n>Healed to @hpmax\`]\`Heal Result\`
>Embedded event's syntax gets messy, so try to keep it simple.`,
"Source Stat Change": `#hp=#hp_max
>Full healed #_name, alias #_alias, link: #_link`,
"Damage Roll with prompt": `@hp=@hp-$dmg
>@_link took damage {{$dmg}}
>Any unmentioned $ variable will be prompted by the user to fill in, you can use this to allow customized rolling of events without re-writing the same event a bunch of times`,
  }

  useEffect(()=>roll(defaultValue || ""),[])

  const setValue = (v,quiet)=>{
    setValueEQ(v.trim())
    if (!quiet)
      Push("event", e=>roll(v.trim()))
  }

  const addCondition = e=>window.ask({title: "Condition (ex. $roll > 20)"}, conditionStatement=>{
    window.close()
    window.ask({title: "Condition is met result"}, trueStatement=>{
      window.close()
      window.ask({title: "Condition isn't met result"}, falseStatement=>{
        setValue(value + "\n" + `$condition=(${conditionStatement})?(${trueStatement}):(${falseStatement})`)
      })
    })
  })

  const addDiceRoll = e=>window.ask({title: "How many dice"},count=>{
    window.close()
    window.ask({title: "How many faces"}, faces=>{
      window.close()
      window.ask({title: "Additional Bonus"},bonus=>{
        setValue(value + "\n" + `$roll={${count}}d{${faces}} + ${bonus}`)
      })
    })
  })

  const addEquation = e=> window.ask({title: "Add an equation (ex. #str + #dex)"},bonus=>{
    setValue(value + "\n" + `$equation=${bonus}`)
  })

  const addDefinition = e=> window.ask({title: "Set Key for Definition"},res=>{
    const m = res.match(/[\w\d_]+/i)
    if (!m || m.index !== 0 || m[0].length !== res.length)
      return window.error("Invalid key, may only contain numbers, characters and _")
    if (m[0] === "_")
      return window.error("Key can't start with _, reserved for system")

    setValue(value + "\n" + `$${res}=`)
  })


  const sortScript = e=>{
    const splitVal = value.split("\n")
    const order = ["$", "#", "@", "|", "!", "%", ">", "_"]
    let newValue = ""
    for (const delimiter of order) 
      for (const v of splitVal)
        if (v.charAt(0) === delimiter)
          newValue += v + "\n"


    newValue = newValue.substring(0, newValue.length-1)
    setValue(newValue)
  }

  const askStat = symbol=>{
    window.ask({title: "Stat to Be Changed"}, key=>{
      window.close()
      setValue(value + "\n" + `${symbol}${key}=`)
    })
  }
  

  const askComponent = (symbol, op)=>{
    const { components } = getProject().getBank()
    window.ask({title: "Component to Be Changed", choices: Object.keys(components).map(value=>({value, text: components[value].name || value})) }, res=>{
      window.close()
      window.pick(profileID=>setValue(value + "\n" + `|${symbol}${res}([=${profileID}=])${op}${1}`))
    })
  }

  const inlineRollOutput = e=>{
    setValue(value + "\n" + ">Rolled {{$roll}}")
  }

  const rollOutput = e=>{
    setValue(value + "\n" + ">Rolled {$roll}")
  }

  const chatOutput = e=>{
    setValue(value + "\n" + ">Change the Text here")
  }

  const minimize = e=>{
    if (!value.match("_mini="))
      setValue(value + "\n" + "_mini=true")
  }

  const scramble = e=>{
    if (!value.match("_scramble="))
      setValue(value + "\n" + "_scramble=true")
  }

  const category = e=>{
    if (!value.match("_category="))
      setValue(value + "\n" + "_category=surprised")
  }

  const newLine = e=> window.ask({title: `Add a new line to the Event Script`}, res=>{
    setValue(value + "\n" + `${res}=${true}`)
  })
  

  const existingEvent = e=>{
    const bank = getProject().getBank().events
    window.ask({title: "Pick a System Event", choices: Object.keys(bank).map(v=>({value: v, text: bank[v].name || v}))}, res=>{
      setValue(value + "\n" + `!${res}`)
    })
  }

  const result = e=>{
    setValue(value + "\n" + "%result=true")
  }

  const classTag = e=> window.ask({title: "Enter Label"}, res=>{
    setValue(value + "\n" + `_class=${res}`)
  })

  const actionLabel = e=> window.ask({title: "Enter Label"}, res=>{
    setValue(value + "\n" + `_act=${res}`)
  })

  return <Row fit className="card-bg">
    <Column grow>
      <Row grow>
        <Column className="pad">
          <Column center className="size-small">Click to Add</Column>
          <Column>
            <Button className="foreground" small onClick={addDiceRoll}>Dice Roll</Button>
            <Button className="foreground" small onClick={addCondition}>Condition</Button>
            <Button className="foreground" small onClick={addEquation}>Equation</Button>
            <Button className="foreground" small onClick={addDefinition}>Definition</Button>
          </Column>
          <br/>
          <Column>
            <Dropdown trigger="click" overlay={<Menu><Menu.Item onClick={e=>askStat("#")}>Source</Menu.Item><Menu.Item onClick={e=>askStat("@")}>Target</Menu.Item></Menu>}>
              <Button className="foreground" small>Stat Change</Button>
            </Dropdown>
            <Dropdown trigger="click" overlay={<Menu>
              <Menu.SubMenu title="Source">
                <Menu.Item onClick={e=>askComponent("#", "-=")}>Remove Quantity</Menu.Item>
                <Menu.Item onClick={e=>askComponent("#", "=")}>Set Quantity</Menu.Item>
                <Menu.Item onClick={e=>askComponent("#", "+=")}>Add Quantity</Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu title="Target">
                <Menu.Item onClick={e=>askComponent("@", "-=")}>Remove Quantity</Menu.Item>
                <Menu.Item onClick={e=>askComponent("@", "=")}>Set Quantity</Menu.Item>
                <Menu.Item onClick={e=>askComponent("@", "+=")}>Add Quantity</Menu.Item>
              </Menu.SubMenu>
            </Menu>}>
              <Button className="foreground" small>Component Change</Button>
            </Dropdown>
          </Column>
          <br/>
          <Column>
            <Button className="foreground" small onClick={actionLabel}>Action Label</Button>
            <Button className="foreground" small onClick={classTag}>Class Tag</Button>
            <Button className="foreground" small onClick={result}>Result</Button>
          </Column>
          <br/>
          <Column>
            <Button className="foreground" small onClick={existingEvent}>Inject System Event</Button>
            <Button className="foreground" small onClick={chatOutput}>Chat Output</Button>
            <Button className="foreground" small onClick={rollOutput}>Roll breakdown</Button>
            <Button className="foreground" small onClick={inlineRollOutput}>Roll result</Button>
          </Column>
          <br/>
          <Column>
            <Button className="foreground" small title="Sets the category/group in a tracker (use for initiative rolling)" onClick={category}>Category</Button>
            <Button className="foreground" small onClick={minimize}>Minimize</Button>
            <Button className="foreground" small onClick={scramble}>Scramble</Button>
            <Button className="foreground" small onClick={newLine}>New Line</Button>
          </Column>
        </Column>
        <Scroll>
          <EventBreakdown script={value} onChange={setValue}/>
        </Scroll>
      </Row>
      <Row>
        {/* <Dropdown trigger="click" overlay={<Menu>
          {Object.keys(helpers).sort().map((key,i)=><Menu.Item key={i}>
            {key}
          </Menu.Item>)}
        </Menu>}>
          <Button grow className="foreground">Add</Button>
        </Dropdown> */}
      </Row>
    </Column>
    <Column className="black-tint" style={{width: "20vw", overflow: "hidden"}}>
      <Scroll>
        <EventProvider eventID={evt.uid}>
          <ChatBubble browsing={true}/>
        </EventProvider>
      </Scroll>
      <Column grow center>
        <Column>
          <Button small className="foreground" onClick={e=>window.ask({title: "Edit Event Script", textarea: true, value}, res=>setValue(res))}>Edit Script</Button>
          <Button className="foreground" small onClick={sortScript}>Cleanup Script</Button>
          <Button small className="foreground" onClick={e=>roll(value, true)}>Re-Roll w/Prompts</Button>
          <Dropdown trigger="click" overlay={<Menu>
              {Object.keys(templates).sort().map((key,i)=><Menu.Item value={key} key={key} onClick={item=>{
              setValue(templates[key], true)
              roll(templates[key])
            }}>
              {key}
            </Menu.Item>)}
          </Menu>}>
            <Button small className="foreground">Examples</Button>
          </Dropdown>
        </Column>
      </Column>
      {onFinalize && <Column>
        <Button className="foreground" onClick={e=>onFinalize(value)}>Finalize</Button>
      </Column>}
    </Column>
  </Row>
}

export function Equation({value, onChange=()=>{}, onPressEnter, onBlur, rootVal, start}){
  const regex = /\)\?\(/i
  let root = rootVal || value
  let v = value

  const tree = []
  let condition = false
  let match = v.match(regex)//value.match(regex)
  if (match && v.charAt(0) === "(" && v.charAt(v.length-1) === ")") {
    const ts = scope(v.substring(match.index+match[0].length-1, v.length), "(", ")")
    const canContinue = v.charAt(match.index+match[0].length+ts[2]) === ":" // properly formatted
    if (canContinue) {
      const rs = reverseScan(v.substring(0, match.index+1), "(", ")")
      const fs = scope(v.substring(match.index+match[0].length+ts[2], v.length), "(", ")")
      const conditionStatement = rs[0]
      const trueStatement = ts[0]
      const falseStatement = fs[0]

      const i = (start || 0)
      const conditionRoot = i + 1
      const trueRoot = i+match.index+match[0].length
      const falseRoot = trueRoot+trueStatement.length+3

      condition = newVal=>{
        const index = conditionRoot
        let replaceVal = newVal

        try {
          if (!isNaN(eval(replaceVal)) && !replaceVal.match(/["'`]/)) 
            replaceVal = eval(replaceVal)
        }
        catch (e) {
          console.warn(e)
        }

        if (isNaN(replaceVal) && !replaceVal.match(/[#@$%"'`]/) && isNaN(eval(replaceVal)))
          replaceVal = `"${replaceVal}"`
        const replaced = root.substring(0, index) + replaceVal + root.substring(index+conditionStatement.length, root.length)
        onChange(replaced)
      }
      
      function change(newVal, type){
        const index = type ? trueRoot : falseRoot

        let replaceVal = newVal
        try {
          if (!isNaN(eval(replaceVal)) && !replaceVal.match(/["'`]/)) 
            replaceVal = eval(replaceVal)
        }
        catch (e) {
          console.warn(e)
        }

        if (isNaN(replaceVal) && !replaceVal.match(/[#@$%"'`]/))
          replaceVal = `"${replaceVal}"`
        const replaced = root.substring(0, index) + replaceVal + root.substring(index+(type ? trueStatement : falseStatement).length, root.length)
        onChange(replaced)
      }
      v = conditionStatement      

      tree.push(<Column key={Math.random()} className="rounded" style={{background: "rgba(255,255,255,0.1)"}}>
        <Row grow>
          <Row center className="pad-h">✔️</Row>
          <Equation value={trueStatement} rootVal={root} onChange={onChange} onPressEnter={e=>change(e.target.value, true)} onBlur={e=>change(e.target.value, true)} start={trueRoot}/>
        </Row>
        <Row grow>
          <Row center className="pad-h">❌</Row>
          <Equation value={falseStatement} rootVal={root} onChange={onChange} onPressEnter={e=>change(e.target.value, false)} onBlur={e=>change(e.target.value, false)} start={falseRoot}/>
        </Row>
      </Column>)
    }
  }
  
  return <Column grow className="rounded" style={{background: "rgba(0,0,0,0.25)", border: "1px solid black"}}>
    <Row grow>
      {condition && <Row center style={{background: "rgba(0,0,0,0.25)", border: "1px solid transparent", borderRight: "4px solid transparent", borderBottom: "1px solid black"}} className="pad-h">❔</Row>}
      {condition && <Input defaultValue={v} className="black-tint" onBlur={e=>condition(e.target.value)} onPressEnter={e=>condition(e.target.value)} />}
      {!condition && <Input defaultValue={v} className="black-tint" onBlur={onBlur || (e=>onChange(e.target.value))} onPressEnter={onPressEnter || (e=>onChange(e.target.value))} />}
    </Row>
    {tree}
  </Column>
}

export function BuilderList({onChange, defines, prefix, hideAdd}){
  return <Column grow>
    <Scroll>
      {Object.keys(defines).map(key=><Column between key={key} className="foreground">
        <Row>
          <Button small onClick={e=>{
            const newKeys = {...defines}
            delete newKeys[key]
            onChange(newKeys)
          }}>
            <DeleteFilled/>
          </Button>
          <Row center className="pad-h black-tint">{prefix}{key}</Row>
        </Row>
        {defines[key] && <Equation value={defines[key]} onChange={e=>onChange({...defines, [key]: e})} />}
        {!defines[key] && <Row grow center>
          <Button className="card-bg" onClick={e=>window.ask({title: "How many dice"},count=>{
            window.close()
            window.ask({title: "How many faces"}, faces=>{
              window.close()
              window.ask({title: "Additional Bonus"},bonus=>onChange({...defines, [key]: `{${count}}d{${faces}} + ${bonus}`}))
            })
          })}>
            Dice Roll
          </Button>
          <Button className="card-bg" onClick={e=>window.ask({title: "Condition (ex. #str > 20)"},conditionStatement=>{
            window.close()
            window.ask({title: "Condition is met result"}, trueStatement=>{
              window.close()
              window.ask({title: "Condition isn't met result"}, falseStatement=>onChange({...defines, [key]: `(${conditionStatement})?(${trueStatement}):(${falseStatement})`}))
            })
          })}>
            Conditional
          </Button>
          <Button className="card-bg" onClick={e=>window.ask({title: "Equation"}, res=>onChange({...defines, [key]: res || " "}))}>
            Equation
          </Button>
        </Row>}
      </Column>)}
    </Scroll>
    {!hideAdd && <Row center>
      <Button className="foreground" childClass="grow" onClick={e=>window.ask({title: "Enter Key"}, res=>{
        const m = res.match(/[\w\d_]+/i)
        if (!m || m.index !== 0 || m[0].length !== res.length)
          return window.error("Invalid key, may only contain numbers, characters and _")
        if (m[0] === "_")
          return window.error("Key can't start with _, reserved for system")
        onChange({...defines, [res.toLowerCase()]: ""})
      })}>
        Add
      </Button>
    </Row>}
  </Column>
}

export function ComponentList({onChange, defines, prefix, hideAdd}){
  const components = getProject().components

  function select(component, op){
    window.ask({title: "Select a target", value: "_CUSTOM", choices: [
      {value: "_CUSTOM", text: "[Select Page]"},
      ...Object.keys(components[component].map || {}).map(k=>({value: k, text: components[component].map[k].name || k})),
    ]}, res=>{
      window.close()

      function resolve(target) {
        const list = defines[component] || {}
        list[target] = {op, equation: "1"}
        onChange({...defines, [component]: list})
      }

      if (res === "_CUSTOM") 
        return window.pick(pageID=>resolve(pageID))
      resolve(res)
    })
  }

  function onChangeEq(component, target, op, equation) {
    const list = defines[component] || {}
    if (equation == null || equation === "")
      delete list[target]
    else
      list[target] = {op, equation}
    onChange({...defines, [component]: list})
  }

  return <Column grow>
    <Scroll>
      {Object.keys(defines).map(key=><Column between key={key} className="foreground">
        <Row>
          <Button small onClick={e=>{
            const newKeys = {...defines}
            delete newKeys[key]
            onChange(newKeys)
          }}>
            <DeleteFilled/>
          </Button>
          <Row center className="pad-h black-tint">{prefix}{key}</Row>
        </Row>
        {defines[key] && Object.keys(defines[key]).length ? Object.keys(defines[key]).map((target, i)=>{
          let profileID = target
          if (components[key].map && components[key].map[target] && components[key].map[target].profileID) 
            profileID = components[key].map[target].profileID.split("=")[1]
          
          const {op, equation} = defines[key][target]
          return <Row>
            <Row center>
              <Mention profileID={profileID}/>
            </Row>
            <Row center className="pad-h">{op}</Row>
            <Equation value={equation} onChange={e=>onChangeEq(key, target, op, e)} />
          </Row>
        }) : <div/>}
        {<Row grow around className="foreground pad rounded">
          <Row center>Add</Row>
          <Button small className="card-bg" onClick={e=>select(key, "=")}>
            Set Amount
          </Button>
          <Button small className="card-bg" onClick={e=>select(key, "-=")}>
            Remove Amount
          </Button>
          <Button small className="card-bg" onClick={e=>select(key, "+=")}>
            Add Amount
          </Button>
        </Row>}
      </Column>)}
    </Scroll>
    {!hideAdd && <Row center>
      <Button className="foreground" childClass="grow" onClick={e=>window.ask({title: "Enter Component", choices: Object.keys(components).map(value=>({value, text: components[value].name || value})) }, res=>{
        const m = res.match(/[\w\d_]+/i)
        if (!m || m.index !== 0 || m[0].length !== res.length)
          return window.error("Invalid key, may only contain numbers, characters and _")
        if (m[0] === "_")
          return window.error("Key can't start with _, reserved for system")
        onChange({...defines, [res.toLowerCase()]: ""})
      })}>
        Add
      </Button>
    </Row>}
  </Column>
}


function Builder({onFinalize}){
  const [defines, setDefines] = useState({})
  const [source, setSource] = useState({})
  const [target, setTarget] = useState({})
  const [sourceComps, setSourceComps] = useState({})
  const [targetComps, setTargetComps] = useState({})
  const [results, setResults] = useState({})
  const [flags, setFlags] = useState({})
  const [text, setText] = useState({})

  function genDefault(){ 
    const { components } = getProject().getBank()
    
    let script = ""
    for (const key in defines) 
      script += `$${key}=${defines[key]}\n`
      
    for (const key in flags) 
      script += `_${key}=${flags[key]}\n`
    
    for (const key in source) 
      script += `#${key}=${source[key]}\n`
      
    for (const key in target) 
      script += `@${key}=${target[key]}\n`
    
    for (const key in sourceComps) 
      for (const target in sourceComps[key]) {
        let profileID = target
        if (!(components[key].map && components[key].map[target]))
          profileID = `[=${profileID}=]`

        script += `|#${key}(${profileID})${sourceComps[key][target].op}${sourceComps[key][target].equation}\n`
      }

    for (const key in targetComps) 
      for (const target in targetComps[key]) {
        let profileID = target
        if (!(components[key].map && components[key].map[target]))
          profileID = `[=${profileID}=]`

        script += `|@${key}(${profileID})${targetComps[key][target].op}${targetComps[key][target].equation}\n`
      }
    

    for (const key in results) 
      script += `%${key}=${results[key]}\n`

    for (const key in text) 
      script += `>${key}=${text[key]}\n`

    return script
  }
  const script = genDefault()

  const tabs = {
    "Variables": opts=><BuilderList prefix="$" defines={defines} onChange={setDefines}/>,
    "Results": opts=><BuilderList prefix="%" defines={results} onChange={setResults}/>,
    "Modify Source": opts=><Row grow>
      <Column grow>
        <h3 className="pad">Stats</h3>
        <BuilderList prefix="#" defines={source} onChange={setSource}/>
      </Column>
      <Column grow>
        <h3 className="pad">Components</h3>
        <ComponentList prefix="#" defines={sourceComps} onChange={setSourceComps}/>
      </Column>
    </Row>,
    "Modify Target": opts=><Row grow>
      <Column grow>
        <h3 className="pad">Stats</h3>
        <BuilderList prefix="@" defines={target} onChange={setTarget}/>
      </Column>
      <Column grow>
        <h3 className="pad">Components</h3>
        <ComponentList prefix="@" defines={targetComps} onChange={setTargetComps}/>
      </Column>
    </Row>,
    "Engine Vars": ()=><Column className="pad" grow>
      <Row center>
      <Row>
        <Row center>
          <Switch checked={flags["mini"] != null} onChange={e=>setFlags({...flags, "mini": "true"})}/>
          <span className="pad-h">
            Minimized
          </span>
        </Row>
      </Row>
      <Row>
        <Row center>
          <Switch checked={flags["act"] != null} onChange={e=>setFlags({...flags, "actcolor": "", "act": "ACT LABEL"})}/>
          <span className="pad-h">
            Action Label
          </span>
        </Row>
      </Row>
      <Row>
        <Row center>
          <Switch checked={flags["class"] != null} onChange={e=>setFlags({...flags, "classcolor": "", "class": "CLASS LABEL"})}/>
          <span className="pad-h">
            Class Tag
          </span>
        </Row>
      </Row>
      {/* <Row>
        <Row center>
          <Switch checked={flags["aoe"] != null} onChange={e=>setFlags({...flags, "aoe": "1"})}/>
          <span className="pad-h">
            Area of Effect Radius
          </span>
        </Row>
      </Row> */}
      <Row>
        <Row center>
          <Switch checked={flags["scramble"] != null} onChange={e=>setFlags({...flags, "scramble": "true"})}/>
          <span className="pad-h">
            Language Scrambler
          </span>
        </Row>
      </Row>
      </Row>
      <BuilderList prefix="_" defines={flags} onChange={setFlags} hideAdd/>
    </Column>,
    "Finalize": ()=><EventBuilder onFinalize={onFinalize} defaultValue={script} />
  }

  return <Column fit>
    <Tabs defaultTab="Finalize" tabs={tabs} />
  </Column>
}

export default ({visible, onFinalize, onClose, title, defaultValue}) => {
  return <Modal width="85vw" centered bodyStyle={{height: "85vh", background: "#141414", border: "1px solid #333333", padding: 0, display: "flex"}} title={title || "Event Builder"} visible={visible} footer={false} onCancel={onClose}>
    <EventBuilder defaultValue={defaultValue} onFinalize={onFinalize}/>
  </Modal>
}