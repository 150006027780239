import Resource from './Resource'
import Account from '../../core/state/Account'
import { v4 } from 'uuid'

export default class Map extends Resource {
  constructor(ctx = {}) {
    super(ctx)

    this._id = ctx._id
    this.uid = ctx.uid || v4()
    this.meta = { name: ctx.name, creator: ctx.creator || Account.uid, created: Date.now() }
    this.layers = {}
    this.src = ``

    this.setMaxListeners(Infinity) // Potentially thousands of references due to wiki
  }

  setBackground(newSrc) {
    this.src = newSrc
    this.emit("change")
  }

  add(options, quiet) {
    const uid = options.uid || v4()
    this.layers = this.layers || {}
    this.layers[uid] = { ...options }
    if (!quiet)
      this.emit("change", options.uid)
    return uid
  }

  update(options, quiet) {
    if (!options.uid)
      return
    if (!this.layers[options.uid])
      return this.add(options)

    for (const key in options)
      this.layers[options.uid][key] = options[key]

    this.emit("change", options.uid)
  }

  lookup(uid) {
    return this.layers[uid]
  }

  remove(uid, quiet, layer = "base") {
    if (!this.layers)
      return
    this.layers = this.layers || {}
    delete this.layers[uid]
    if (!quiet)
      this.emit("change")
  }

  pack() {
    return {
      _id: this._id,
      uid: this.uid,
      layers: this.layers || {},
      meta: this.meta || {},
      src: this.src,
    }
  }

  deserialize(json) {
    this.load(JSON.parse(json))
  }

  load(json) {
    this._id = json._id
    this.uid = json.uid || this.uid
    this.layers = json.layers || this.layers
    this.meta = json.meta || this.meta
    this.src = json.src || this.src
    this.loaded = true
    this.emit("ready")
  }

  serialize() {
    return JSON.stringify(this.pack())
  }
}