import React, { useEffect, useState, useContext, createContext } from 'react'
import Resources from '../state/Resources'
import Event from '../models/Event'
import Nav from '../state/Navigation'
import clone from 'rfdc'
import { getProject } from "./useProject"

const copy = clone()
const EventContext = createContext()

export { EventContext }

const event = new Event()
const { Events, Profiles } = Resources

export function getEvent(eventID) {
  return Events.get(eventID) || event
}

export default function useEvent() {
  return useContext(EventContext)
}

export function useHook(eventID, project = Nav.project) {
  const [loaded, setLoaded] = useState()
  const [meta, setMeta] = useState()
  const [text, setText] = useState()
  const [tags, setTags] = useState()
  const [rolls, setRolls] = useState()
  const [tracked, setTracked] = useState()
  const [api, setApi] = useState({})
  const [defines, setDefines] = useState({})
  const [script, setScript] = useState("")
  const [equation, setEquation] = useState("")

  useEffect(() => {
    const event = getEvent(eventID)
    function change() {
      setMeta(copy(event.meta))
      let workingText = copy(event.text)
      if (Array.isArray(workingText) && workingText.length && (typeof workingText[0] === "string"))
        workingText.forEach((v,i)=>{
          const actions = /(\[![^\!]*!=`[^`]+`?\]`[^`]+`?)/g
          let match = actions.exec(workingText[i])
          while (match) {
            workingText[i] = workingText[i].replace(match[0], event.fillRoll(match[0]))
            match = actions.exec(workingText[i])
          }
        })
      
      setText(workingText)
      setTags(copy(event.tags))
      setRolls(copy(event.rolls))
      setTracked(copy(event.tracked))
      setApi(copy(event.api))
      setDefines(copy(event.defines))
      setLoaded(event.loaded)
      setEquation(event.equation)
      
      let s = ""
      for (const key in event.target)
        s += `@${key}=${event.fillRoll(event.target[key])}\n`

      if (s.length) // if there is something to change
        for (const key in event.api)
          s += `_${key}=${event.fillRoll(event.api[key])}\n`
      
      setScript(s.substring(0, s.length - 1))
    }
    change()

    event.on("change", change)
    event.on("ready", change)

    return function () {
      event.off("change", change)
      event.off("ready", change)
    }
  }, [eventID, project])

  return { eventID, loaded, text, meta, script, tags, rolls, tracked, equation, api, defines }
}

export function EventProvider({ eventID, children }) {
  return <EventContext.Provider value={useHook(eventID)} children={children} />
}

export function createEvent({targetID, componentID, sourceID, gmRoll, uid}) {
  // source uses a component, to apply to a target
  const event = Events.create(uid)
  let source = {}, target = {}
  if (sourceID) {
    const sourceProfile = Profiles.get(sourceID)
    if (sourceProfile) {
      if (componentID !== sourceID && componentID) {
        const cCtx = Profiles.get(componentID).getContext()
        cCtx["_itemname"] = cCtx["_name"]
        cCtx["_itemlink"] = cCtx["_link"]
        cCtx["_itemcode"] = cCtx["_code"]
        cCtx["_itemalias"] = cCtx["_alias"]
        delete cCtx["_name"]
        delete cCtx["_link"]
        delete cCtx["_code"]
        delete cCtx["_alias"]

        source = {...cCtx, ...sourceProfile.getContext()}
        event.meta.componentID = componentID
      }
      else
        source = {...sourceProfile.getContext()}

      event.meta.sourceID = sourceID
    }
  }

  if (targetID) {
    const targetProfile = Profiles.get(targetID)
    target = targetProfile.getContext()
    event.meta.targetID = targetID
  }

  const { components, events } = getProject().getBank()

  event.meta.gmRoll = gmRoll
  const context = { source, target, bank: events, components }
  return {event, context}
}

export function applyEvent(event, context, options={}) {
  const sourceID = options.sourceID || event.meta.sourceID
  const targetID = options.targetID || event.meta.targetID

  if (sourceID) {
    const sourceProfile = Profiles.get(sourceID)
    for (const key in event.source)
      sourceProfile.setData(key, event.eval(event.source[key], context))
  }

  if (targetID) {
    const targetProfile = Profiles.get(targetID)
    for (const key in event.target)
      targetProfile.setData(key, event.eval(event.target[key], context))
  }

  if (sourceID || targetID) {
    const reg = /([#@])([^(]+)\(([^\)]+)\)([\+-])?=(\d+)/im
    for (const key in event.pipes) {
      const match = event.pipes[key].match(reg)
      if (match) {
        let target = match[1] === "#" ? Profiles.get(sourceID) : Profiles.get(targetID)
        if (match[5] === "+")
          target.addToComponent(match[2], match[3], Number(match[5]))
        else if (match[5] === "-")
          target.removeFromComponent(match[2], match[3], Number(match[5]))
        else 
          target.setComponentTo(match[2], match[3], Number(match[5]))
      }
    }
  }
}
