import React, { useEffect, useState, useContext, createContext } from 'react'
import Resources from '../state/Resources'
import Article from '../models/Article'
import Nav from '../state/Navigation'

const ArticleContext = createContext()

export { ArticleContext }

const art = new Article()
const { Articles, Projects } = Resources

export default function useArticle() {
  return useContext(ArticleContext) || {}
}

export function getArticle(articleID) {
  return articleID ? Articles.get(articleID) : (Nav.project ? Projects.get(Nav.project).description : art)
}

export function useHook(articleID) {
  const [content, setContent] = useState({})
  const [body, setBody] = useState()
  const [loaded, setLoaded] = useState()
  const [tabs, setTabs] = useState({})
  useEffect(() => {
    const a = getArticle(articleID)
    function change() {
      setContent(a.getContent())
      setLoaded(a.loaded)
      setBody(a.body)
      const list = {}

      function findText(children) {
        let text = ""
        if (children[0].text) {
          for (const child of children)
            text += child.text

          return text
        }
        if (!children[0].children || children[0].children.length === 0)
          return ""

        return findText(children[0].children)
      }

      a.body.forEach(({ text, bookmark, children }, i) => {
        if (bookmark)
          list[bookmark] = (text ?? findText(children))
      })
      setTabs(list)
    }
    change()
    a.on("change", change)
    a.on("ready", change)

    return function () {
      a.off("change", change)
      a.off("ready", change)
    }
  }, [articleID, Nav.project])

  return { loaded, content, body, tabs, articleID }
}

export function ArticleProvider({ articleID, children }) {
  return <ArticleContext.Provider value={useHook(articleID)} children={children} />
}