import React, { useEffect, useState } from 'react'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

// import GUI from './util/IconGen'
// import GUI from './outbreak/GUI'
// import GUI from './wareffort/GUI'
import GUI from './mythic/GUI'
// import GUI from './castle/GUI'
// import GUI from './editor/GUI'
// import GUI from './threefx/GUI'

import { AccountProvider } from './core/hooks/useAccount'

const isMobile = false
const MobileBackend = {
  enableTouchEvents: false,
  enableMouseEvents: false,
  enableKeyboardEvents: false,
  ignoreContextMenu: false,
  enableHoverOutsideTarget: false,
  delayTouchStart: 0,
  delayMouseStart: 0,
  touchSlop: 0,
  scrollAngleRanges: undefined
}

Math.dist = function (x1, x2, y1, y2) {
  return Math.sqrt(Math.pow((x1 - x2), 2) + Math.pow((y1 - y2), 2))
}

window.notify = e => console.log(e)
window.info = e => console.log(e)
window.success = e => console.log(e)
window.warning = e => console.warn(e)
window.error = e => console.warn(e)
window.input = ({ title }, cb) => cb(window.prompt(title))

function App(opts) {
  const [style, setStyle] = useState({})
  useEffect(() => {
    function resize() {
      setStyle({ height: window.innerHeight + "px", width: window.innerWidth + "px" })
    }
    resize()
    window.addEventListener("resize", resize)
    return function () {
      window.removeEventListener("resize", resize)
    }
  }, [])

  return <DndProvider backend={isMobile ? TouchBackend : HTML5Backend} options={isMobile ? MobileBackend : {}}>
    <AccountProvider>
      <div style={style}>
        <div id="debug" className="fixed left no-click column" style={{ zIndex: 1300, top: 100, display: "none", pointerEvents: "none" }}></div>
        <GUI />
      </div>
    </AccountProvider>
  </DndProvider>
}

export default App