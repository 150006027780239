import React, { useState } from 'react'

import UniversalDrop from '../core/wrappers/UniversalDrop'
import Event from './models/Event'
import Slate from './slate/Slate'
import { getProject, hasRight } from './hooks/useProject'
import rfdc from 'rfdc'
import useProfile, { getProfile } from "./hooks/useProfile"
import { Tooltip } from "antd"
import { Column } from '../UI'
import { applyEvent, createEvent } from "./hooks/useEvent"
const copy = rfdc()

const tempEvent = new Event()

export default opts => {
  const { placement, children } = opts
  const { profileID } = useProfile()
  const [preview, setPreview] = useState("")

  const onEnter = (item, anchor) => {
    if (hasRight(profileID, "CHANGE") && item.script) {
      // console.log(item.script)
      const { context } = createEvent({ sourceID: item.profileID, componentID: item.componentID, targetID: profileID })
      const ctx = {...context, quiet: true}
      const tgt = getProfile(profileID)
      tempEvent.roll(item.script, ctx).then(e => {
        let preview = `|[h0]**${tgt.getName()}**<br>`

        if (item.profileID === profileID)
          for (const key in tempEvent.source)
            preview += `${key} = ${tempEvent.eval(tempEvent.source[key], ctx)}<br>`

        for (const key in tempEvent.target)
          preview += `${key} = ${tempEvent.eval(tempEvent.target[key], ctx)}<br>`

        preview = preview.substring(0, preview.length - 4)
        setPreview(preview)
      })
    }
  }

  const onLeave = item => setPreview("")

  const onDrop = (props, monitor, component) => {
    if (opts.onDrop)
      if (opts.onDrop(props, monitor, component))
        return

    const item = monitor.getItem()
    if (!hasRight(profileID, "CHANGE") && getProject().profiles.includes(profileID))
      return window.notify("Not permitted")

    if (item && item.script) {
      const { componentID, profileID: sourceID } = item
      const {event, context} = createEvent({ componentID, sourceID, targetID: profileID, gmRoll: item.gmRoll })
      const tempData = copy(tempEvent.pack())
      delete tempData.meta
      delete tempData.uid
      event.load(tempData)
      if (getProject().profiles.includes(profileID))
        applyEvent(event, context)
      event.record()
    }
  }

  const isMobile = false//window.mobilecheck()
  const popContent = <Column className="background-tint text-white" style={{ maxWidth: isMobile ? "80vw" : "15vw" }}>
    <Slate readOnly={true} raw={preview} />
  </Column>

  return <UniversalDrop {...opts} onDrop={onDrop} onEnter={onEnter} onLeave={onLeave}>
    <Tooltip title={popContent} placement={placement} visible={preview} overlayInnerStyle={{ background: "#141414", padding: "0" }}>
      {children}
    </Tooltip>
  </UniversalDrop>
}