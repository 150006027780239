import EventEmitter from 'events'
import SourcedList from '../models/SourcedList'
import Article from '../models/Article'
import Content from '../models/Content'
import Project from '../models/Project'
import Profile from '../models/Profile'
import Map from '../models/Map'
import Event from '../models/Event'
import Tracker from '../models/Tracker'
import Wizard from '../models/Wizard'

import Account from '../../core/state/Account'
import Network from '../../core/state/Network'
import { Articles, Projects, Profiles, Maps, Changes, Wizards } from '../../core/state/Database'

import { Wing, socket } from '../../Wing'

class Manager extends EventEmitter {
  Articles = new SourcedList({ key: "Articles", Model: Article, service: Wing.service("articles"), storage: Articles, offline: () => (this.offline) })
  Projects = new SourcedList({ key: "Projects", Model: Project, service: Wing.service("projects"), storage: Projects, offline: () => (this.offline) })
  Profiles = new SourcedList({ key: "Profiles", Model: Profile, service: Wing.service("profiles"), storage: Profiles, offline: () => (this.offline) })
  Maps = new SourcedList({ key: "Maps", Model: Map, service: Wing.service("maps"), storage: Maps, offline: () => (this.offline) })
  Events = new SourcedList({ key: "Events", Model: Event, storage: Profiles, offline: () => (this.offline) }) // these are temporary
  Trackers = new SourcedList({ key: "Trackers", Model: Tracker, offline: () => (this.offline)}) // these are temporary
  Community = new SourcedList({ key: "Community", Model: Content, service: Wing.service("community"), /*storage: Community,*/ offline: () => (this.offline)})
  Wizards = new SourcedList({ key: "Wizards", Model: Wizard, service: Wing.service("wizards"), storage: Wizards, offline: () => (this.offline) })

  lookup(key, uid) {
    return this[key].find(uid)
  }

  get(key, uid) {
    return this[key].get(uid)
  }

  delete(key, uid) {
    return this[key].delete(uid)
  }

  get connected() {
    return !socket.disconnected
  }

  get available() {
    return !socket.disconnected
  }

  getOffline() {
    return this.offline // Local Project Saving
  }
}

const Resources = new Manager()
Resources.on("disconnect", e => Resources.emit("update"))

Account.on("authenticated", authenticated => {
  if (authenticated && Network.host) // only push changes if you are the host
    Changes.keys().then(keys => {
      // console.log(keys)
      for (const key of keys)
        Changes.get(key).then(changeStr => {
          Changes.delete(key).then(e => {
            const changes = JSON.parse(changeStr)
            for (const uid of changes) {
              // pull from local storage
              console.log(uid, key)
              const dest = Resources.lookup(key, uid)
              dest.once("ready", e => {
                console.log(JSON.stringify(dest.target.states))
                dest.saveService()
              }) // sync to cloud
              dest.loadStorage()
            }
            window.success("Data-services back online. Offline changes have been accounted for")
          })
        })
    })
  // else {
  // window.error("Unable to authenticate with data-services")
  // }

  Resources.emit("update")
})

socket.on("disconnect", e => Resources.emit("disconnect"))
socket.on("connect", e => Resources.emit("connect"))

export default Resources